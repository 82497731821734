import React, { useState } from 'react';
import { Accordion, Card , Container, Row, Col } from 'react-bootstrap';
import './checkbox.accordion.scss';
import { IFeature } from 'components/profile/types';
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

interface AccordionProps {
  title: string;
  label?: string;
  fixedCount: number;
  content: IFeature[];   
  accordionCallBack: Function;
  features: IFeature[];
}

export const CheckboxAccordion = ({ title, label, content, fixedCount, features, accordionCallBack }: AccordionProps) => {
const fixedItems = content.slice(0, fixedCount); 
const accordionItems =  content.slice(fixedCount); 
const [isOpen, setIsOpen] = useState(false);

const toggleIcon = () => {
      setIsOpen(!isOpen);  
}

const addFeatures = (event : any, object: IFeature) => {
  accordionCallBack(event.target.checked, object);
}

const loadCheckedState = (object: IFeature): boolean => {
  const copyArray = [...features]; 
  const indexArray = copyArray.findIndex(x => x.id === object.id);
  return indexArray > -1; 
}

    return (
    <Container fluid className="accordion-container"> 
       <div className="title-container">{title.toUpperCase()}</div>
       {label &&
        <div className="section-label">{label}</div>
       }
       <Row className="accordion-row">
          <Col>
             {
             fixedItems.map((object, index) => (
              (index % 2 === 0) &&
                  <div key={object.id} >
                      <label key={object.id} className="checkbox-label" >
                          <input className="checkbox-margin" 
                          type="checkbox" 
                          name="checkbox"
                          value={object.id}
                          checked={loadCheckedState(object)}
                          onChange={(e: any) => addFeatures(e, object)}/>
                          {object.name} 
                      </label>
                  </div>
                ))             
             }
          </Col>
          <Col>
              {fixedItems.map((object, index) => (
                   (index % 2 !== 0) &&
                   <div key={object.id} >
                      <label key={object.id} className="checkbox-label">
                          <input className="checkbox-margin"
                          type="checkbox" 
                          name="checkbox"
                          value={object.id}
                          checked={loadCheckedState(object)}
                          onChange={(e: any) => addFeatures(e, object)}/>
                          {object.name} 
                      </label>
                  </div>
             ))}
          </Col>
        </Row>
      
      {(content && content.length > 6) &&
        <Accordion>
          <Card className="accordion-border">          
            <Accordion.Collapse eventKey="0">
              <Card.Body >           
                <Row className="accordion-row">
                  <Col className="accordion-column">          
                    {accordionItems.map((object, index) => (
                      (index % 2 === 0) &&
                      <div key={object.id} >
                        <label key={object.id} className="checkbox-label">
                            <input  className="checkbox-margin"
                            type="checkbox" 
                            name="checkbox"
                            value={object.id}
                            checked={loadCheckedState(object)}
                            onChange={(e: any) => addFeatures(e, object)}/>
                            {object.name} 
                        </label>
                      </div>
                      
                      ))}
                  </Col>
                  <Col>
                    {accordionItems.map((object, index) => (
                      (index % 2 !== 0 ) &&         
                      <div key={object.id} >
                        <label key={object.id} className="checkbox-label">
                            <input className="checkbox-margin"
                            type="checkbox" 
                            name="checkbox"
                            value={object.id}
                            checked={loadCheckedState(object)}
                            onChange={(e: any) => addFeatures(e, object)}/>
                            {object.name} 
                        </label>
                      </div>
                      ))}
                  </Col>
                </Row>              
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle onClick={(e: any) => toggleIcon()}  className="cursor-pointer accordion-button-icon accordion-collapse-button"  as={Card.Header} eventKey="0">    
                {(!isOpen) && <span > Show more <BsChevronDown className="toggleIcon"/></span> }
                {(isOpen) &&  <span > Show less <BsChevronUp className="toggleIcon"/></span>}            
            </Accordion.Toggle>
          </Card>         
        </Accordion>     
      }
        </Container>
    )
  }