import React from 'react';
import { typesArray } from 'app/config';
import './wizard.step-1.scss';
import { WizardCard } from 'components/wizard.card/wizard.card';
import { UpdateProfileType } from "components/profile/profile.slice";
import { useDispatch, useSelector } from 'react-redux';
import { SuggestionModal } from 'components/suggestion.modal/suggestion.modal';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { IsStepComplete } from 'utils/Validation';
import { WizardCallback } from 'components/wizard/types';
import { useEffect } from 'react';
import { RootState } from 'app/root-reducer';

const WizardStep1Page = ({callBack}: WizardCallback) => {
  const dispatch = useDispatch();
  const { type } = useSelector((state: RootState) => state.profileSlice.profile);

  useEffect(() => {
    const stepComplete = IsStepComplete([type]);
    callBack({id: 1, completed: stepComplete, skip: false, displayFooterNavigation: true, displayNext: true});
  }, [type, callBack]);

  const selectionCallBack = (value:string) => {
    dispatch(UpdateProfileType(value));
  }

  const suggestionCallBack = (value:string) => {   
    //add dispatch for email
  }

  return (
    <Container fluid>
      <Row className="margin-top-20">
        <Col xs="2">
        </Col>
        <Col xs="8">
          <Card className="card-outer" border="dark">
            <Card.Body className="wizard-card-body">
              <Card.Title>Create a Profile on Wetu in no time!</Card.Title>
              <Card.Subtitle>To get started, choose the type of profile you want to create.</Card.Subtitle>
              <div className="card-container">
                  {typesArray.map(item => <WizardCard name={item.name} description={item.description} value={item.value} key={item.name} callBack={selectionCallBack}/>)}
              </div>
            </Card.Body>
          </Card>
          <div className="enquire-link">
            <SuggestionModal title="Type" callBack={suggestionCallBack}/>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default WizardStep1Page