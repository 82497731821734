import { ITranslationItem, IFeature, EntityTypeEnum, IMediaItem } from "../types";

export enum ProfileItemTypeEnum
 {
     None = "None",
     Activities = "Activities",
     Apartment = "Apartment",
     Cabana = "Cabana",
     Cabin = "Cabin",
     Carriage = "Carriage",
     Dome = "Dome",
     Feature = "Feature",
     Unit = "Unit",
     Villa = "Villa",
     Restaurant = "Restaurant",
     Room = "Room",
     SleepingArrangement = "Sleeping Arrangement",
     Suite = "Suite",
     Tent = "Tent",
 }
 
 export enum ProfileItemUnitOfAreaEnum
 {
   SquareMeter = "Square meters",
   SquareFeet = "Square feet",
 }
 

 export interface IProfileItem  {
    id: string;
    profileId: string;
    entityType: EntityTypeEnum.profileItem;
    features: IFeature[];
    name: string;
    configuration: string;  //Single, double, twin etc.
    description: ITranslationItem; 
    type: ProfileItemTypeEnum,
    media: IMediaItem[];
    sequence: number;
    isChanged: boolean;
    units: string;
    size: number;
    count: number;
 }

 export interface IProfileItemMediaUpdate {
   id: string;
   media: IMediaItem[];
}

export interface IProfileItemDeletePayload
{
   id: string;
   profileId: string;
   media: IMediaItem[];
   translationItemId: string;
   type: ProfileItemTypeEnum;
}

 export interface IProfileItemPayload {
   id: string;
   profileId: string;
   entityType: EntityTypeEnum.profileItem;
   features: IFeature[];
   name: string;
   configuration: string;
   description: ITranslationItem; 
   type: ProfileItemTypeEnum,
   media: IMediaItem[];
}

export interface IRoomPayload extends IProfileItemPayload  {
   units: string;
   size: number;
   count: number;
   sequence: number;
   isChanged: boolean;
}
