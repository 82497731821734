import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col, Spinner, Form } from 'react-bootstrap';
import './image.edit.modal.scss';
import { IMediaItem, ITranslatableText, IDeleteMediaPayload, MediaTypeEnum, IUpdateMediaPayload} from 'components/profile/types';
import { BsChevronRight, BsChevronLeft, BsTrash } from 'react-icons/bs';
import { TranslatableTextEditor } from 'components/translatable.text.editor/translatable.text.editor';
import _ from 'lodash';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import { useAppDispatch } from 'app/store';
import { updateMedia, deleteMedia, UpdateMedia_Reducer, isMediaComplete } from 'components/profile/mediaitem/mediaitem.slice';
import { updateProfileFromStore, UpdateSectionCompleteWithValue} from 'components/profile/profile.slice';
import { ProfileFormPageEnum } from 'pages/profile-form/profile.form.types';

interface IProps {
    showModal?: boolean;
    profileName?: string;
    closeCallBack: () => void;
    images: IMediaItem[];
    currentImageIndex: number;
    isEnhanced: boolean;
    languageCodes: string[];
    deleteCallback: (newIndex: number) => void;
    saveCallback: (newImages: IMediaItem[], newImageIndex: number) => void;
}

export const ImageEditModal = ({profileName, showModal, closeCallBack, images, currentImageIndex, isEnhanced, languageCodes, deleteCallback, saveCallback}: IProps) => {
    const [ imagesLocal, setImagesLocal ] = useState<IMediaItem[]>(images);
    const [ currentImageIndexLocal, setCurrentImageIndexLocal ] = useState<number>(currentImageIndex);
    const [ isImageLoaded, setIsImageLoaded ] = useState<boolean>(false);
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);
    const [ showCloseConfirmModal, setShowCloseConfirmModal ] = useState<boolean>(false);
    const [ initialJson, setInitialJson ] = useState<string>();
    const dispatch = useAppDispatch();

    /***********   When you first open the modal (when images is changed):
        - (showModal opens the modal)
        - ImagesLocal is created from images
        - currentImageIndexLocal is set from currentImageIndex
    ************/
    useEffect(() => {
        setImagesLocal(images);
        setCurrentImageIndexLocal(currentImageIndex);
    }, [images, showModal, currentImageIndex]);
    
    useEffect(() => {
        //When you change image within the modal (when currentImageIndexLocal is changed) set current image from imagesLocal.
        setIsImageLoaded(false);
    }, [currentImageIndexLocal]);

    useEffect(() => {
        if (!initialJson && images && images.length > 0) {
            setInitialJson(JSON.stringify(images));
        }
    }, [images, initialJson])

    const closeButtonClick = () => {
        const isChanges = initialJson !== JSON.stringify(imagesLocal);
        setShowCloseConfirmModal(isChanges);
        if (!isChanges) {
            closeCallBack();
        }
    }

    const closeConfirmCallBack = useCallback((leavePage: boolean) => {
        setShowCloseConfirmModal(false);
        if (leavePage) {
            closeCallBack();
        }
    }, [closeCallBack]);

    const formatFileSize = (fileSize: number): string => {
        if (fileSize > 0) {
            if (fileSize < 1000) {
                return fileSize + ' KB';
            }
            return (fileSize / 1000) + ' MB';
        }
        return '';
    }
    
    const formatImageResolution = (width: number, height: number): string => {
        if (width > 0 && height > 0) {
            const megapixels: number = (width * height) / 1000000;
            return `${width} x ${height}px (${megapixels.toFixed(1)} MP)`;
        }
        return '';
    }

    const nextButtonClick = () => {
        if (currentImageIndexLocal < images?.length) {
            setCurrentImageIndexLocal(index => ++index);
        }
    }

    const backButtonClick = () => {
        if (currentImageIndexLocal > 0) {
            setCurrentImageIndexLocal(index => --index);
        }
    }

    const credit_onChange = (credit: string) => {
        const imageCollection = _.cloneDeep(imagesLocal);
        imageCollection[currentImageIndexLocal].credit = credit;
        setImagesLocal(imageCollection);
    }   

    const onDeleteClick = () => {
        setShowDeleteModal(true);
    }

    const deleteImagesCallBack = useCallback((deleteImages: boolean) => {
        setShowDeleteModal(false);
        if (deleteImages) {
            let mediaItems: IMediaItem[] = _.cloneDeep(imagesLocal);
            let indexTemp = currentImageIndexLocal;
            if (currentImageIndexLocal === (imagesLocal.length - 1) && currentImageIndexLocal > 0) {
                indexTemp--;
                setCurrentImageIndexLocal(indexTemp);
                deleteCallback(indexTemp);      //Call parent (eg. Dropzone.tsx) to reset the current image index.
            }
            mediaItems.splice(currentImageIndexLocal, 1);
            setImagesLocal(mediaItems);
            const payload: IDeleteMediaPayload = { mediaItems: [imagesLocal[currentImageIndexLocal]], showToast: true, mediaType: MediaTypeEnum.image };
           
            const isCompleteBefore = isMediaComplete(ProfileFormPageEnum.media)
            Promise.all([
                dispatch(deleteMedia(payload))
            ]).then(() => {
                const isCompleteAfter = isMediaComplete(ProfileFormPageEnum.media);
                if (isCompleteBefore && !isCompleteAfter) {
                    Promise.all([
                        dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.media, value: false })),
                        dispatch(updateProfileFromStore())
                    ])
                }
            })
           

            const updatePayload : IUpdateMediaPayload = {mediaItems: mediaItems, mediaType: MediaTypeEnum.image }
            dispatch(UpdateMedia_Reducer(updatePayload));
            if (mediaItems?.length === 0) {
                closeCallBack();
            }
            else {
                setIsImageLoaded(true);
            }            
        }
    }, [dispatch, imagesLocal, currentImageIndexLocal, deleteCallback, closeCallBack]);
    
    const setTranslatableTitleCallback = useCallback((translatableText: ITranslatableText) => {
        const imageCollection = _.cloneDeep(imagesLocal);
        imageCollection[currentImageIndexLocal].label = translatableText;
        setImagesLocal(imageCollection);
    }, [imagesLocal, currentImageIndexLocal]);

    const setTranslatableDescriptionCallback = useCallback((translatableText: ITranslatableText) => {
        const imageCollection = _.cloneDeep(imagesLocal);
        imageCollection[currentImageIndexLocal].description = translatableText;
        setImagesLocal(imageCollection);
    }, [imagesLocal, currentImageIndexLocal]);

    const onSaveClick = () => {
        //Calls UpdateProfileImages to save Media (label, description and credit en text and translation are saved on the MediaItem document) AND the profile (label/description is dependent on profile.availableLanguages).
        dispatch(updateMedia(imagesLocal));
        setInitialJson(JSON.stringify(imagesLocal));
        saveCallback(imagesLocal, currentImageIndexLocal);
    }

    return (
        <Modal show={showModal} onHide={() => closeButtonClick()} className="image-edit-modal" animation={false}>
            <Modal.Header closeButton closeLabel="Close">
                <Container className="header-container">
                    <Row>
                        <Col className="header-inner-container">
                            <AcceptDeclineModal showModal={showCloseConfirmModal} callBack={closeConfirmCallBack} title={`Unsaved changes`}
                                body={`If you leave now, any changes you've made to ${imagesLocal?.length > 1 ? "these images" : "this image"} will be lost.`} declineButtonText="Stay on page" acceptButtonText="Leave page" />
                            <div>
                            {profileName} <br />
                            <span className="lower-text">Image {currentImageIndexLocal + 1} of {imagesLocal?.length}</span>
                            </div>
                            <div className="middle-button-container">
                                {/* <Button className="middle-buttons" disabled onClick={() => onResetClick()}>Reset image edits</Button> */}
                                <Button className="middle-buttons save" onClick={() => onSaveClick()}>Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container fluid className="container-fluid-height">
                    <Row className="modal-body container-fluid-height">
                        <Col className="container-background">
                            <Row className="edit-container">
                                <Col md={1} className={isImageLoaded ? "button-column container-background" : ""}>
                                { images?.length > 1 && currentImageIndexLocal > 0 && isImageLoaded &&
                                    <Button className="back-next-button" id="back-button" variant="light" onClick={() => backButtonClick()}><BsChevronLeft size={96}/></Button>
                                }
                                </Col>
                                <Col md={10} className={isImageLoaded ? "edit-image-container container-background" : ""} >
                                    <img style={isImageLoaded ? {} : {display: 'none'}} src={imagesLocal ? imagesLocal[currentImageIndexLocal]?.url : ""} alt="" 
                                    onLoad={() => setIsImageLoaded(true)} />
                                    {!isImageLoaded &&
                                        <Spinner animation="border" variant="primary" />
                                    }
                                </Col>
                                <Col md={1} className={isImageLoaded ? "button-column container-background" : ""}>
                                { currentImageIndexLocal < (images?.length - 1) && isImageLoaded &&
                                    <Button className="back-next-button" id="next-button" variant="light" onClick={() => nextButtonClick()}><BsChevronRight size={96}/></Button>
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                { isImageLoaded &&
                                    <div className="footer-buttons">
                                        {/* <Button onClick={(e: any) => onCropClick(e)}>
                                            <BsCrop size={16} />
                                        </Button> */}
                                        <Button onClick={() => onDeleteClick()}>
                                            <BsTrash size={16} />
                                        </Button>
                                        <AcceptDeclineModal showModal={showDeleteModal} callBack={deleteImagesCallBack} title={`Delete 1 of ${imagesLocal.length} images`}
                                            body={"This can't be undone."} declineButtonText='Keep image' acceptButtonText='Delete image' />
                                    </div>
                                }
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} md={2} className="right-side-bar">
                            {isImageLoaded &&
                                <>
                                <Container fluid className="image-details">
                                    <Row>
                                        <Col md={4}>Resolution:</Col>
                                        <Col md={7}>{formatImageResolution(imagesLocal[currentImageIndexLocal]?.width, imagesLocal[currentImageIndexLocal]?.height)}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>File-size:</Col>
                                        <Col md={7}>{formatFileSize(imagesLocal[currentImageIndexLocal]?.fileSizeKb)}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>File name:</Col>
                                        <Col md={7}>{imagesLocal[currentImageIndexLocal]?.originalFileName}</Col>
                                    </Row>
                                </Container>
                                <Form.Group className="title-section">
                                    <Form.Label>Title <span className="red-text">*</span></Form.Label>
                                    <TranslatableTextEditor setTranslationText={setTranslatableTitleCallback} enhanced={isEnhanced} 
                                    translatableText={imagesLocal[currentImageIndexLocal]?.label} size={1} applyWordCount={false} useMultiLineTextArea={false}
                                    availableLanguages={languageCodes} />
                                </Form.Group>
                                <Form.Group className="credit-section">
                                    <Form.Label>Photographer credit <span className="red-text">*</span></Form.Label>
                                    <Form.Control value={imagesLocal[currentImageIndexLocal]?.credit} onChange={(e) => credit_onChange(e.target.value)} id="credit-input" type="text" />
                                </Form.Group>
                                <Form.Group className="description-section">
                                    <Form.Label>Description</Form.Label>
                                    <TranslatableTextEditor setTranslationText={setTranslatableDescriptionCallback} enhanced={isEnhanced} 
                                    translatableText={imagesLocal[currentImageIndexLocal]?.description} size={6} applyWordCount={true} useMultiLineTextArea={true}
                                    availableLanguages={languageCodes} />
                                </Form.Group>
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}