import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './show.more.scss';

interface IProps {
  label?: string;
  callback: () => void;
  show: boolean,
  children: React.ReactNode
}

export const ShowMore = ({callback, label = '+ Add item', show = false, children}: IProps) => {
  const [showContent, setShowContent] = useState<boolean>(show);

  useEffect(() => {
    setShowContent(show);
  }, [setShowContent, show]);

  const showMore = () => {
    setShowContent(true);
    callback();
  }

  return (
    <Container fluid className="show-more">
      <Row>
        <Col>
        {!showContent &&
            <Button variant="link" onClick={() => showMore()}>{ label } </Button>
        }
        {
            showContent && (
                children
            )
        } 
        </Col>
      </Row>
    </Container>
  );
}
