export const loadMapApi = () => {
    const mapsURL = "https://maps.googleapis.com/maps/api/js?v=3&libraries=places&key=AIzaSyBCwh6Ojt_w2K1BYwJteHhkpbRA6JG05Yc";
    const scripts = document.getElementsByTagName('script');
   
    for (let i = 0; i < scripts.length; i++){
        if (scripts[i].src.indexOf(mapsURL) === 0 ){
            return scripts[i];
        }
    }

    const googleMapScript = document.createElement('script');
    googleMapScript.src = mapsURL;
    googleMapScript.async = true;
    googleMapScript.defer = true;  
    window.document.body.appendChild(googleMapScript);
    return googleMapScript;
};

const getGoogleField = (googlePlacesResult: google.maps.places.PlaceResult, searchFieldName: string) => {
    return googlePlacesResult.address_components?.find(x => x.types.find(y => y === searchFieldName))?.long_name
}

export const getAddress = (googlePlacesResult: google.maps.places.PlaceResult): string => {
    let address = '';

    const streetNumber: string | undefined = getGoogleField(googlePlacesResult, 'street_number');
    if(streetNumber) {
      address += streetNumber;
    }

    const route: string | undefined = getGoogleField(googlePlacesResult, 'route');
    if(route) {
      address += (streetNumber ? ', ' : '') + route;
    }

    const sublocality: string | undefined = getGoogleField(googlePlacesResult, 'sublocality_level_1') || getGoogleField(googlePlacesResult, 'sublocality_level_2');
    if(sublocality) {
      address += (route ? ', ' : '') + sublocality;
    }

    return address;
}

export const getCity = (place: google.maps.places.PlaceResult): string => {
    //Google Search Results API is not consistent with which field is used to store city. This logic was derived from our own test data.
    let city = getGoogleField(place, 'postal_town');
    if (!city) {
        city = getGoogleField(place, 'locality');
    }
    if (!city) {
        city = getGoogleField(place, 'administrative_area_level_2');
    }
    if (!city) {
        city = getGoogleField(place, 'administrative_area_level_1');
    }
    return city ? city : '';
}

export const getCountry = (place: google.maps.places.PlaceResult): string => {
    const country = getGoogleField(place, 'country');
    return country ? country : '';
}

 export const getPostalCode = (place: google.maps.places.PlaceResult): string => {
    const postalCode = getGoogleField(place, 'postal_code');
    return postalCode ? postalCode : '';
}