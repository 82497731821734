import { Dictionary } from "@reduxjs/toolkit"
//#region Enums

export enum ProfileStatusEnum {
    none = "",
    active = "Active",
    disabled = "Disabled",
    rejected = "Rejected",
    inProgress = "InProgress",
    inReview = "InReview",
    closed = "Closed"
}

export enum ContactTypeEnum {
    none = "",
    physicalAddress = "PhysicalAddress",
    marketing = "Marketing",
    sales = "Sales",
    frontDesk = "FrontDesk",
    reservation = "Reservation",
    accounts = "Accounts"
}

//Social media types
export enum LinkTypeEnum {
    facebook = "Facebook",
    twitter = "Twitter",
    instagram = "Instagram",
    pinterest = "Pinterest"
}

export enum EntityTypeEnum {
    none = 'None',
    profile = 'Profile',
    profileItem = 'ProfileItem',
    media = 'Media',
    translationItem = 'TranslationItem',
    directions = 'Directions',
}

export enum MediaTypeEnum {
    none = 'None',
    image = 'Image',
    video = 'Video',
    embedVideo = 'EmbedVideo',
    virtualTour = 'VirtualTour',
    panorama = 'Panorama',
    documentation = 'Documentation',
    splashImage = 'SplashImage',
    photosCoverImage = 'PhotosCoverImage',
    roomsCoverImage = 'RoomsCoverImage',
    videosCoverImage = 'VideosCoverImage',
    activitiesCoverImage = 'ActivitiesCoverImage',
    panoramasCoverImage = 'PanoramasCoverImage',
    destinationsCoverImage = 'DestinationsCoverImage',
    accommodationsCoverImage = 'AccommodationsCoverImage',
    amenitiesCoverImage = 'AmenitiesCoverImage',
    attractionsCoverImage = 'AttractionsCoverImage',
    documentsCoverImage = 'DocumentsCoverImage',
    facilitiesCoverImage = 'FacilitiesCoverImage',
    libraryCoverImage = 'LibraryCoverImage',
    mapCoverImage = 'MapCoverImage',
    restaurantsCoverImage = 'RestaurantsCoverImage',
    sliderImages = 'SliderImages',
    specialsCoverImage = 'SpecialsCoverImage',
    travelGuidesCoverImage = 'TravelGuidesCoverImage',
    whyStayHereCoverImage = 'WhyStayHereCoverImage'
}

export const getMediaTypeEnumAsLabel = (type: MediaTypeEnum, isPlural: boolean) => {
    let label: string;
    switch (type) {
        case MediaTypeEnum.embedVideo:
        case MediaTypeEnum.video: {
            label = "Video";
            break;
        }
        case MediaTypeEnum.virtualTour: {
            label = "Virtual tour";
            break;
        }
        case MediaTypeEnum.documentation: {
            label = "Document";
            break;
        }
        default: {
            label = "Image";
            break;
        }
    }
    if (isPlural) {
        label += "s";
    }
    return label;
}

export enum TranslationItemTypeEnum {
    none = "None",
    name = "Name",
    label = "Label",
    description = "Description",
    shortDescription = "ShortDescription",
    richDescription = "RichDescription",
    conditions = "Conditions",
    included = "Included",
    rateSeason = "RateSeason",
    direction = "Direction"
  }

export enum FeatureTypeEnum {
    none = "None",
    language = "Language",
    facility = "Facility"
 }
 
export enum TimeFormatEnum {
    Hours12 = "12Hour",
    Hours24 = "24Hour"
}

export enum BookingAffiliateEnum {
    none = "None",
    nightsbridge = "Nightsbridge",
    resRequest = "ResRequest"
}

export enum GDSEnum {
    amadeus = "Amadeus",
    sabre = "Sabre",
    travelPort = "Travelport",
    galileo = "Galileo",
    worldSpan = "Worldspan",
    pegasus = "Pegasus"
}

export enum DocumentTypeEnum {
    none = 'None',
    covidNotice = 'CovidNotice',
    factSheet = 'FactSheet',
    rates = 'Rates',
    specials = 'Specials',
    foodAndBeverage = 'FoodAndBeverage',
    brochure = 'Brochure',
    conferencing = 'Conferencing',
    directions = 'Directions',
    faqs = 'Faqs',
    menu = 'Menu',
    activities = 'Activities',
    spa = 'Spa',
    weddings = 'Weddings',
    otherGeneral = 'OtherGeneral'
}

export enum DocumentVisibilityEnum {
    publicAndTrade = 'PublicAndTrade',
    tradeOnly = 'TradeOnly',
    private = 'Private'
}

//#endregion Enums

//#region Interfaces
export interface IProfile {
    id: string;
    type: string;
    entityType: EntityTypeEnum;
    category: string;
    name: string; 
    streetAddress: string;
    country: string;
    postalCode: string;
    city: string;
    isEnhanced: boolean;
    searchAliases: string[];
    availableLanguages: string[];
    shortDescription: ITranslatableText;
    richDescription: ITranslatableText;
    contacts: IContact[];
    links: Dictionary<string>;
    unitsId: string;
    pin: IPin;
    gdsCodes: Dictionary<string>;
    isPublished: boolean; 
    status: ProfileStatusEnum;
    features: IFeature[];
    config: { [key:string]: IPageProps };
    checkinTimeFrom: ITime;
    checkinTimeTo: ITime;
    checkoutTimeFrom: ITime;
    checkoutTimeTo: ITime;
    roomCount: number;
    starRating: number;
    translationItems: ITranslationItem[];
    contentRating: number;
    qualityAssuranceBody: string;
    alternativeRating: string;
    timeFormat: TimeFormatEnum;
    minimumChildAge: number;
    sectionsComplete: Dictionary<boolean>;
    affiliate: BookingAffiliateEnum;
    affiliateCode: string;
    clientName: string;
    isLoaded: boolean;
}

export interface IProfileSummary {
    id: string;
    type: string;
    entityType: EntityTypeEnum;
    category: string;
    imageURL: string;
    name: string; 
    isEnhanced: boolean;
    isPublished: boolean; 
    status: ProfileStatusEnum;
    contentRating: number;
    lastModified: string;
    profileId: string;
}

export interface ITime {
    hours: number;
    minutes: number;
}

export interface IMediaIEmbedApiPayload {    
    IMediaIEmbed: IMediaIEmbed;     
    enableToast : boolean;
    toastMessage: string,
}

export interface IMediaIEmbed {  
    label: string;  
    entityType: EntityTypeEnum;
    mediaType : MediaTypeEnum;
    url: string;  
    profileId: string;   
    azureContainerName: string;
    originalFileName: string
    sequence: number;
    hasAcceptedUsagePolicy : boolean;
}

export interface IMediaItem {
    id: string;
    label: ITranslatableText;
    description: ITranslatableText;
    entityType: EntityTypeEnum;
    mediaType: MediaTypeEnum;
    documentType: DocumentTypeEnum;        // Covid-19, Menu, Rate Sheet, Fact Sheet, etc.
    url: string;
    credit: string;
    profileId: string;
    hasFileSizeWarning: boolean;
    filePath: string;
    azureContainerName: string;
    file?: File;
    isChecked: boolean;
    parentId: string;
    width: number;
    height: number;
    fileSizeKb: number;
    originalFileName: string
    sequence: number;
    isChanged: boolean;     //This is only needed on the UI, and not on the API. Just a temp variable to show if sequence number has changed from what is in the DB.
    visibility: DocumentVisibilityEnum;
}

export interface ITranslatableText  {
    enText: string;
    translations: Dictionary<string>;
    machineTranslations: Dictionary<string>;
}

export interface ILatLong  {
    latitude: number;
    longitude: number;
}

export interface IContact {
    type?: string;
    name?: string;
    address?: string;
    email?: string;
    telephone?: string;
    countryCode?: string;
    dialCode?: string;
    skype?: string;
    website?: string;
    onlineReservationUrl?: string;
}

export interface IFeature {
    id: number;
    name: string;
    type: string;
}


export interface IMenuItem {
    key: string;
    label: string;
    privacy: string;
    tier: string;
}

export interface ISidebarMenuItem {
    label: string;
    isSelected: boolean;
    isCompleted: boolean;
    url: string;
    isEnhancedOnly: boolean;
}

export interface IUpdateProfileNameAddressLocation {
    name: string,
    pin: IPin,
    address: string,
    city: string;
    country: string;
    postalCode: string;
}

export interface IUpdateProfileCompleteAddress {
    address: string,
    city: string;
    country: string;
    postalCode: string;
}

export interface IUpdateProfileLocation {
    latitude: number,
    longitude: number,
}

export interface IUpdateProfilePayload { 
    id: string;
    name: string;
    isEnhanced: boolean;
    entityType: EntityTypeEnum;
    isPublished: boolean;
    type: string;
    category: string;
    latitude: number;
    longitude: number;
    checkinTimeFrom: ITime;
    checkoutTimeFrom: ITime;
    checkinTimeTo: ITime;
    checkoutTimeTo: ITime;
    features: IFeature[];
    searchAliases: string[];
    translationItems: ITranslationItem[];
    shortDescription: ITranslatableText;
    richDescription: ITranslatableText;
    availableLanguages: string[];
    status: string;
    qualityAssuranceBody: string;
    starRating: number;
    alternativeRating: string;
    roomCount: number;
    timeFormat: TimeFormatEnum;
    minimumChildAge: number;
    sectionsComplete: Dictionary<boolean>;
    gdsCodes: Dictionary<string>;
    clientName: string;
    affiliateCode: string;
    affiliate: BookingAffiliateEnum;
    contacts: IContact[];
    links: Dictionary<string>;
}

export interface IUploadBlob {
    files: File[];
}

export interface ICreateProfilePayload { 
    name: string;
    isEnhanced: boolean;
    entityType: EntityTypeEnum;
    isPublished: boolean;
    type: string;
    category: string;
    latitude: number;
    longitude: number;
    translationItems: ITranslationItem[];
    checkinTimeFrom: ITime;
    checkoutTimeFrom: ITime;
    checkinTimeTo: ITime;
    checkoutTimeTo: ITime;
    features: IFeature[];
    starRating: number;
    roomCount: number;
}

export const initialCreateProfile: ICreateProfilePayload = {
    name: '',
    type: '',
    category: '',
    entityType: EntityTypeEnum.profile,
    latitude: 0,
    longitude: 0,
    isEnhanced: true,
    isPublished: false,
    translationItems: [] as ITranslationItem[],
    features: [] as IFeature[],
    checkinTimeFrom: {hours: 14, minutes: 0},
    checkinTimeTo: {hours: 0, minutes: 0},
    checkoutTimeFrom: {hours: 10, minutes: 0},
    checkoutTimeTo: {hours: 0, minutes: 0},
    starRating: 0,
    roomCount: 0
}

export interface IUploadMediaPayload {
    formData: FormData;
    mediaType: MediaTypeEnum;
}

export interface IDeleteMediaPayload {
    showToast: boolean
    mediaItems : IMediaItem[];
    mediaType: MediaTypeEnum;
}

export interface IUpdateMediaPayload {   
    mediaItems : IMediaItem[];
    mediaType: MediaTypeEnum;
}

export interface IValidateProfilePayload { 
    id: string;
    media: IMediaItem[];
    sectionsComplete: Dictionary<boolean>;
}

export interface IUpdateProfileDescriptionPayload {
    code: string;
    value: string;
}

export interface IUpdateProfileContact {
    type: string;
    name: string;
    email: string;
    address: string;
    telephone: string;
    skype: string;
    countryCode?: string;
    dialCode?: string;
}

export interface IUpdateProfileLink {
    id: string;
    type: string;
    url: string;
}

export interface IPin {
    latitude: number;
    longitude: number;
    drivingLatitude: number;
    drivingLongitude: number;
}

export interface ICreateReviewPayload {
    profileId: string;
}

export interface IPageProps {
    title: string;
    description: string;
    tabs: { [key:string]: IFormTab };
}

export interface IFormTab {
    title: string;
    description: string;
    sections: { [key:string]: ISection };
}

export interface ISection {
    title: string;
    description: string;
    selected: boolean;
    components: string[];
}

 export interface ITranslationItem {
    id: string;
    profileId: string;
    parentId: string;
    parentEntityType: EntityTypeEnum;
    entityType: EntityTypeEnum;
    type: TranslationItemTypeEnum;
    text: string;
    directionText: string[];
    isMachineTranslation: boolean;
    languageCode: string;
 }

 export interface DropzoneImage {
    file: File;
    isChecked: boolean;
  }

export interface DeleteBlob {
    blobNames: string[];
    azureContainerName: string;
}

export interface IGetByIdPayload {
    profileId: string;
    accountId: string;
}

export interface IBookingAffiliate {
    affiliate: BookingAffiliateEnum,
    description: string;
}

export interface IMultiSelectData {
    title: string;
    subtitle: string;
    key: string;
    isChecked: boolean;
}
//#endregion Interfaces