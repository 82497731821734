// Indexing starts 1, component id's will need to be aligned with this in the order of their placement
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, ProgressBar, Button, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './wizard.scss';
import { Step } from './types';
import { RootState } from 'app/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { create, update } from 'components/profile/profile.slice';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { EntityTypeEnum } from 'components/profile/types';

declare var Beacon: any;

interface IProps {
  navigateTo: string;
  finalNavigation?: string;
  startingStep?: number;
  step: Step;
  children: React.ReactNode;
}

export const Wizard = ({step, children, finalNavigation = '/home', startingStep = 1, navigateTo = ''}: IProps) => {
  const [stepCount, setStepCount] = useState<number>(1);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(startingStep);
  const [stepData, setStepData] = useState<Step[]>([]);
  const history = useHistory();
  const { profile } = useSelector((state: RootState) => state.profileSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentStepIndex(startingStep);
  }, [startingStep]);

  useEffect(() => {
    setStepCount(React.Children.count(children));
  }, [children]);

  useEffect(() => {
      if(React.Children.count(children) > 1) {
        React.Children.forEach(children, ((child: any, i: number) => {
            setStepData(stepData => [
              ...stepData,
              {
                id: i,
                completed: false,
                skip: false
              }
            ]);
          }));
      } else {
        setStepData(stepData => [
            ...stepData,
            {
              id: 0,
              completed: false,
              skip: false
            }
          ]);
      }
    
  }, [children]);

  useEffect(() => {
    const getStepData = (stepData: Step[]) => {
      let newStepDataArray = [...stepData];
      if(newStepDataArray && newStepDataArray.length > 0) {
        newStepDataArray[step.id].completed = step.completed;
        newStepDataArray[step.id].skip = step.skip;
      }
      return newStepDataArray;
    }
    setStepData(profile => profile = getStepData(profile));
  }, [step.id, step.completed, step.skip]);

  const nextStep = () => {
    history.push(navigateTo + (currentStepIndex + 1));
  }

  const previousStep = () => {
    history.push(navigateTo + (currentStepIndex - 1));
  }

  const RoomCancel = () => {
    if (step.pageCallBack2) {
      step.pageCallBack2();
    }
  }

  const RoomDone = () => {
    if (step.pageCallBack1) {
      step.pageCallBack1();
    }  
    history.push(navigateTo + 10);
  }

  const finalize = () => {

    if(currentStepIndex === 10)
    {      
      if(profile.id) {
        dispatch(update({
            id: profile.id,
            category: profile.category,
            isEnhanced: profile.isEnhanced,
            latitude: profile.pin.latitude,
            longitude: profile.pin.longitude,
            name: profile.name,
            type: profile.type,
            checkinTimeFrom: profile.checkinTimeFrom,
            checkoutTimeFrom: profile.checkoutTimeFrom,
            checkinTimeTo: profile.checkinTimeTo,
            checkoutTimeTo: profile.checkoutTimeTo,
            isPublished: profile.isPublished,
            entityType: EntityTypeEnum.profile,
            features: profile.features,
            searchAliases: profile.searchAliases,
            translationItems: profile.translationItems,
            shortDescription: profile.shortDescription,
            richDescription: profile.richDescription,
            availableLanguages: profile.availableLanguages,
            status: profile.status,
            qualityAssuranceBody: profile.qualityAssuranceBody,
            starRating: profile.starRating,
            alternativeRating: profile.alternativeRating,
            roomCount: profile.roomCount,
            timeFormat: profile.timeFormat,
            minimumChildAge: profile.minimumChildAge,
            sectionsComplete: profile.sectionsComplete,
            clientName: profile.clientName,
            affiliate: profile.affiliate,
            affiliateCode: profile.affiliateCode,
            gdsCodes: profile.gdsCodes,
            contacts: profile.contacts,
            links: profile.links    
        }));
    } else {
        dispatch(create({
            category: profile.category,
            isEnhanced: profile.isEnhanced,
            isPublished: profile.isPublished,
            checkinTimeFrom: profile.checkinTimeFrom,
            checkoutTimeFrom: profile.checkoutTimeFrom,
            checkinTimeTo: profile.checkinTimeTo,
            checkoutTimeTo: profile.checkoutTimeTo,
            latitude: profile.pin.latitude,
            longitude: profile.pin.longitude,
            name: profile.name,
            type: profile.type,
            entityType: EntityTypeEnum.profile,
            translationItems: profile.translationItems, 
            features: profile.features,
            roomCount: profile.roomCount,
            starRating: profile.starRating
        }));
    }
    
      nextStep();
    }else
    {
      history.push(finalNavigation);
    }
  
  }

  const preview = () => {
    //navigate to Wetu iBrochure; Open in a new browser tab.
      window.open('https://andrew.dev.wetu.com/iBrochure/1205/?profileId=1a149980-4a1e-44e8-a424-5282b8c00174', '_blank');
  }
  return (
    <Container id="wizard" className="padding-bottom-100" fluid>
      <Row>
        <Col>
          {
            (step.progressBarEnabled !== false) && <ProgressBar now={currentStepIndex - 1} max={stepCount} />
          }
            <Container className="margin-top-50">
                <Row>
                    <Col className="flex-center">
                        {
                            (React.Children.count(children) === 1) && children
                        }
                        {
                            (React.Children.count(children) > 1) && React.Children.toArray(children)[currentStepIndex - 1]
                        }
                    </Col>
                </Row>
            </Container>
            <Navbar fixed="bottom" bg="dark" variant="dark">
              <Col xs={{offset: 3, span: 2}}>
              <Nav.Item>
                { step.displayFooterNavigation && !step.displayRoomCancel &&
                    currentStepIndex > 1 &&
                    <Button variant="primary" onClick={() => previousStep()}><BsChevronLeft /> Back</Button>
                }

                { step.displayFooterNavigation &&  step.displayRoomCancel &&
                    <Button  variant="primary" onClick={() => RoomCancel()}><BsChevronLeft />Cancel</Button>
                }       
                </Nav.Item>
              </Col>
              <Col xs={{offset: 2, span: 2}}>
                <Nav.Item>
                  {
                    step.displayFooterNavigation && step.displayNext &&
                    <Button disabled={!(stepData && stepData[currentStepIndex] && stepData[currentStepIndex].completed)} variant="primary" onClick={() => nextStep()}>{step.skip ? "Skip for now" : "Next"} <BsChevronRight /></Button>
                  }
                  { step.displayFooterNavigation &&
                    step.displayPreview &&
                    <Button className="preview-button" variant="primary" onClick={() => preview()}>Preview profile</Button>
                  }
                  { step.displayFooterNavigation &&
                    step.displayFinalize &&
                    <Button  variant="primary" onClick={() => finalize()}>Finalize profile</Button>
                  } 
                  
                  { step.displayFooterNavigation &&
                    step.displayRoomDone &&
                    <Button variant="primary" disabled={step.disabled} onClick={() => RoomDone()}>Done <BsChevronRight /></Button>
                  }   

                </Nav.Item>
              </Col>
              <Col xs={{offset: 1, span: 2}}>
                <Nav.Item>
                  <Button className="margin-right-5" variant="primary" onClick={() => { Beacon('open'); }}>Need Help?</Button>
                </Nav.Item>
              </Col>
            </Navbar>
         
        </Col>
      </Row>
    </Container>
  );
}
