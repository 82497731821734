import React, { useState, useCallback } from 'react';
import { DragSortableCard } from './drag.sortable.card/drag.sortable.card';
import update from 'immutability-helper';
import { useEffect } from 'react';
import './drag.sortable.scss';

interface CardItem {
    id: number;
    child: any;
    groupId: string;
}

interface IProps {
    groupId: string;
    children: React.ReactNode;
    cardDropCallback: (newSequence: number[], groupId: string) => void;
}

export const DragSortable = ({groupId, children, cardDropCallback}: IProps) => {
    const [cards, setCards] = useState<CardItem[]>([]);

    useEffect(() => {
        let cardsTemp: CardItem[] = [];
        React.Children.toArray(children).map((child, index) => 
            cardsTemp.push({ id: index, groupId: groupId, child: child })
        )
        setCards(cardsTemp);
    }, [children, groupId])

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(update(cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        }));
    }, [cards]);

    const cardDropCallbackLocal = useCallback(() => {
        cardDropCallback(cards.map(x => x.id), groupId);
    }, [cardDropCallback, cards, groupId])

    return (
        <div className="dragsort-container">
            { cards && cards.length > 1 &&
                 cards.map((card, i) => 
                 <DragSortableCard key={card.id} index={i} id={card.id} moveCard={moveCard} groupId={groupId}
                  cardDropCallback={cardDropCallbackLocal} >
                     {card.child}
                 </DragSortableCard>
                 )
            }
            { cards && cards.length === 1 &&
                cards[0].child
            }
        </div>
    )
}