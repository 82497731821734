import { createAsyncThunk, createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { deleteProfileMedia_service, getMediaItems_service, updateProfileMedia_service, uploadProfileMedia_service, uploadProfileEmbedVideos } from "api/media.service";
import store from "app/store";
import { ProfileFormPageEnum, ProfileFormPageSectionEnum } from "pages/profile-form/profile.form.types";
import { minimumImages, minimumVideos, languageDictionary } from 'app/config';
import { IMediaItem, IDeleteMediaPayload, MediaTypeEnum, IUpdateMediaPayload, IMediaIEmbedApiPayload, IUploadMediaPayload, 
  getMediaTypeEnumAsLabel, EntityTypeEnum, DocumentTypeEnum, DocumentVisibilityEnum} from "../types";
import { showGlobalToast } from "components/toast/toast.slice";
import { logError } from 'utils/General';
import _ from 'lodash';

const initialState = {
  mediaItems: [] as IMediaItem[],
  isMediaItemSliceLoading: true,
  saving: false,
  submitting: false
};

export const isMediaComplete = (pageId: string): boolean => {
  const mediaItems = store.getState().mediaItemSlice.mediaItems;
  switch (pageId) {
    case ProfileFormPageEnum.media:
    case ProfileFormPageSectionEnum.images : {
     return mediaItems.filter(x => x.mediaType === MediaTypeEnum.image).length >= minimumImages;
    }
    case ProfileFormPageSectionEnum.videos : {
      return mediaItems.filter(x => x.mediaType === MediaTypeEnum.video || x.mediaType === MediaTypeEnum.embedVideo).length >= minimumVideos;      
    }
    case ProfileFormPageSectionEnum.virtualTours : {
      return mediaItems.filter(x => x.mediaType === MediaTypeEnum.virtualTour).length >= 1;
    }
    case ProfileFormPageSectionEnum.documents: {
      return mediaItems.filter(x => x.mediaType === MediaTypeEnum.documentation).length >= 1;
    }
    default: {
      return false;
    }
  }
}

export const getDefaultNewDocument = (documentsEnum: DocumentTypeEnum) : IMediaItem => {
  return {
    id: '',
    label: {enText: '', machineTranslations: {}, translations: {}},
    description: {enText: '', machineTranslations: {}, translations: {}},
    mediaType: MediaTypeEnum.documentation,
    entityType: EntityTypeEnum.media,
    azureContainerName: '',
    credit: '',
    filePath: '',
    fileSizeKb: 0,
    hasFileSizeWarning: false,
    height: 0,
    isChanged: false,
    isChecked: false,
    originalFileName: '',
    parentId: '',
    profileId: '',
    documentType: documentsEnum,
    sequence: 0,
    url: '',
    width: 0,
    visibility: DocumentVisibilityEnum.publicAndTrade
  };
}

export const getMediaItems = createAsyncThunk('profile/getMediaItems', async (profileId: string, { rejectWithValue }) => {
    try {   
      const response = await getMediaItems_service(profileId);
      const languageCodes = Object.keys(languageDictionary);
      //We need to initialize translations dictionaries, otherwise we get "Warning: A component is changing a controlled input of type undefined to be uncontrolled."
      if (response && response.data) {
        for (let i = 0; i < languageCodes?.length; i++) {
          for (let j = 0; j < response.data.length; j++) {
           const languageCode = languageCodes[i];
            if (!response.data[j].label.translations[languageCode]) {
              response.data[j].label.translations[languageCode] = '';
            }
            if (!response.data[j].description.translations[languageCode]) {
              response.data[j].description.translations[languageCode] = '';
            }
          }
        }
      }
      return response && response.data ? response.data : null;
    }
    catch(err) {
      logError(err);
      return rejectWithValue(false);
    }
  })

  export const updateChangedMedia = createAsyncThunk('profile/updateChangedMedia', async (payload: IMediaItem[], { dispatch, rejectWithValue }) => {
    try {
      const changedMedia: IMediaItem[] = payload.filter(x => x.isChanged);
      if (changedMedia && changedMedia.length > 0) {
        const response = await updateProfileMedia_service(changedMedia);
        dispatch(showGlobalToast({title: "Success", message: getMediaTypeEnumAsLabel(changedMedia[0].mediaType, payload.length > 1) + " saved."}));
        return response.data;
      }
      return null;
    }
    catch(err) {
      logError(err);
      return rejectWithValue(false);
    }
  })

  export const updateMedia = createAsyncThunk('profile/updateProfileMedia', async (payload: IMediaItem[], { rejectWithValue }) => {
    try {
      const response = await updateProfileMedia_service(payload);
      return response.data;
    }
    catch(err) {
      logError(err);
      return rejectWithValue(false);
    }
  })

  export const deleteMedia = createAsyncThunk('media/deleteProfileMedia', async (payload: IDeleteMediaPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await deleteProfileMedia_service(payload.mediaItems);
      if(payload.showToast) {
        dispatch(showGlobalToast({title: "Success", message: getMediaTypeEnumAsLabel(payload.mediaType, payload.mediaItems.length > 1) + " deleted"}));
      }
      return response.data;
    }
    catch(err) {
      logError(err);
      return rejectWithValue(false);
    }
  })

  export const uploadMedia = createAsyncThunk('media/uploadProfileMedia', async (payload: IUploadMediaPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadProfileMedia_service(payload.formData);
      var multipleMedia = payload.formData.getAll('files');
      dispatch(showGlobalToast({title: "Success", message: getMediaTypeEnumAsLabel(payload.mediaType, !multipleMedia || multipleMedia.length > 1) + " added"}));
      return response.data;
    }
    catch(err) {
      logError(err);
      return rejectWithValue(false);
    }
  })

  export const uploadEmbedVideos = createAsyncThunk('media/UploadProfileEmbedVideos', async (payload: IMediaIEmbedApiPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadProfileEmbedVideos(payload.IMediaIEmbed);  
      if (payload.enableToast) {
        dispatch(showGlobalToast({title: "Success", message: payload.toastMessage }));
      }    
      return response.data;
    }
    catch(err) {
      logError(err);
      return rejectWithValue(false);
    }
  })  

  const mediaItemSlice = createSlice({
    name: 'mediaItemSlice',
    initialState,
    reducers: {
      ResetMediaItems(state) {
        state.mediaItems = initialState.mediaItems;
      },
      UpdateMediaItems(state, action) {
        if (action.payload) {
            state.mediaItems = action.payload
        }
      },
      UpdateChangedMedia_Reducer(state, action: PayloadAction<IMediaItem[]>) {
          let mediaItemsClone: IMediaItem[] = _.cloneDeep(state.mediaItems);
          const changedItems = action.payload;
          for (let i = 0; i < changedItems.length; i++) {
            let index = mediaItemsClone.findIndex(x => x.id === changedItems[i].id);
            mediaItemsClone[index] = changedItems[i];
          }
          state.mediaItems = mediaItemsClone;
      },
      UpdateMedia_Reducer(state, action: PayloadAction<IUpdateMediaPayload>) {
        const currentMediaItems = current(state.mediaItems);
        let images: IMediaItem[] =currentMediaItems.filter(x => x.mediaType === MediaTypeEnum.image);
        let videos: IMediaItem[]= currentMediaItems.filter(x => x.mediaType === MediaTypeEnum.video || x.mediaType === MediaTypeEnum.embedVideo );
        let virtualTours: IMediaItem[]= currentMediaItems.filter(x => x.mediaType === MediaTypeEnum.virtualTour);
        let documents: IMediaItem[]= currentMediaItems.filter(x => x.mediaType === MediaTypeEnum.documentation);
        let mediaType = action.payload?.mediaType ? action.payload.mediaType : MediaTypeEnum.image;
 
        switch (mediaType) {
           case MediaTypeEnum.image: {
             images = action.payload.mediaItems;
             break;
           }
           case MediaTypeEnum.video:
           case MediaTypeEnum.embedVideo: {
             videos = action.payload.mediaItems;
             break;
           }
           case MediaTypeEnum.virtualTour: {
             virtualTours = action.payload.mediaItems;
             break;
           }
           case MediaTypeEnum.documentation: {
             documents = action.payload.mediaItems;
             break;
           }
           default: {
             break;
           }         
         }
       state.mediaItems = [ ...images , ...videos, ...virtualTours, ...documents];
      },
    },
    extraReducers: builder => {
      builder.addCase(getMediaItems.pending, (state) => {
        state.mediaItems = [];
      })
      builder.addCase(getMediaItems.fulfilled, (state, action) => {   
        state.isMediaItemSliceLoading = false;
        if (action.payload) {
          state.mediaItems  = action.payload;
        }
      })
      builder.addCase(deleteMedia.fulfilled, (state) => {
        state.isMediaItemSliceLoading = false;
      })
      builder.addCase(uploadEmbedVideos.fulfilled, (state, action: PayloadAction<IMediaItem[]>) => {
        let media: IMediaItem[] = state.mediaItems;
        let embedVideo = action.payload; 
       state.mediaItems = [ ...media , ...embedVideo];
      })
      builder.addCase(updateMedia.fulfilled, (state, action: PayloadAction<IMediaItem[]>) => {
        let images: IMediaItem[] = state.mediaItems.filter(x => x.mediaType === MediaTypeEnum.image);
        let videos: IMediaItem[]= state.mediaItems.filter(x => x.mediaType === MediaTypeEnum.video || x.mediaType === MediaTypeEnum.embedVideo );
        let virtualTour: IMediaItem[]= state.mediaItems.filter(x => x.mediaType === MediaTypeEnum.virtualTour);
        let mediaType = MediaTypeEnum.image;
 
        if(action.payload.length > 0 ) {
           mediaType = action.payload[0].mediaType;
        }
 
        switch(mediaType) {
           case MediaTypeEnum.image: {
             images = action.payload;
             break;
           }
           case MediaTypeEnum.video:
           case MediaTypeEnum.embedVideo: {
             videos = action.payload;
             break;
           }
           case MediaTypeEnum.virtualTour: {
             virtualTour = action.payload;
             break;
           }
           default : {
             break;
           }         
         }
       state.mediaItems = [ ...images , ...videos, ...virtualTour];
       })
       builder.addCase(uploadMedia.pending, (state) => {
        state.isMediaItemSliceLoading = false;
      })
      builder.addCase(uploadMedia.fulfilled, (state, action: PayloadAction<IMediaItem[]>) => {
        state.isMediaItemSliceLoading = false;
        state.mediaItems  = [...state.mediaItems, ...action.payload];
      })
      builder.addCase(updateChangedMedia.fulfilled, (state, action: PayloadAction<IMediaItem[] | null>) => {
        if (action.payload) {
          state.mediaItems.forEach((item) => {
            item.isChanged = false;
            return item;
          })
        }
       })
    },
});

export const {
  ResetMediaItems,
  UpdateMediaItems,
  UpdateChangedMedia_Reducer,
  UpdateMedia_Reducer
} = mediaItemSlice.actions;

export default mediaItemSlice.reducer;