import React, { useState } from 'react';
import {InputGroup, CloseButton, Container, Modal, Button, Form, Row, Col } from 'react-bootstrap';
import './embed.videos.modal.scss';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { EntityTypeEnum, IMediaIEmbed, MediaTypeEnum, IMediaIEmbedApiPayload } from 'components/profile/types';
import { uploadEmbedVideos } from 'components/profile/mediaitem/mediaitem.slice';

interface IProps {
    showModal?: boolean;   
    callBack: (outcome: string) => void; 
    closeCallback: (outcome: boolean) => void;
}

export const EmbedVideosModal = ({showModal = false, callBack, closeCallback }: IProps) => {
    const { id } = useSelector((state: RootState) => state.profileSlice.profile);
    const { mediaItems } = useSelector((state: RootState) => state.mediaItemSlice);
    const [ confirmed, setConfirmed ] = useState(false);
    const [ displayClearButton, setDisplayClearButton ] = useState(false);
    const [ embedVideoUrl, setEmbedVideoUrl ] = useState<string>('');
    const [ invalidUrl, setInvalidUrl ] = useState(false);
    const [ multipleEmbedUploads, setMultipleEmbedUploads ] = useState(false);
    const [ displayUploadUrlInfo, setDisplayUploadUrlInfo ] = useState('');
    const [ messageStyle, setMessageStyle ] = useState<string>('added-url');    
    const dispatch = useDispatch(); 

    const acceptButtonClick = () => {
        checkUrlValidation(false, true);       
    }

    const closeButtonClick = () => {
        setConfirmed(false);
        resetUrlInput();
        closeCallback(false);
        setMessageStyle('added-url');
        setDisplayUploadUrlInfo('');
        setMultipleEmbedUploads(false);
    }

    const onInputChange = (value: string) => {
        setMessageStyle('added-url')
        setDisplayClearButton(true);
        setEmbedVideoUrl(value)

        if(value === "" ) {
            setInvalidUrl(false);
            setEmbedVideoUrl("");
            setDisplayClearButton(false);
        }        
    }

    const confirmationEvent = (value : boolean) => {       
            setConfirmed(value);
            if(value) {            
              checkUrlValidation(value, false);
        }           
    }

    const checkUrlValidation = (value : any, submit: boolean) => {
        if (value || confirmed) {
            validate(embedVideoUrl, submit)
        }
    }

    const resetUrlInput = () => {
        setInvalidUrl(false)
        setEmbedVideoUrl("");
        setDisplayClearButton(false);       
    }

    const isValidURL = (str: string) => {
        try { return Boolean(new URL(str)); }
        catch (e) { return false; }
        }        

    const validate = (originalUrlName: string, submit: boolean) => {

        const videoUrl = originalUrlName.toLowerCase();
        setDisplayClearButton(true);

        if (isValidURL(videoUrl)) {          
            if(videoUrl.includes("youtu.be") || videoUrl.includes("youtube.") || videoUrl.includes("vimeo.")) {           
                if (displayUploadUrlInfo !== '') {
                    setMessageStyle('added-url-fade-in')
                }                

                setInvalidUrl(false);

                if (submit) {                      
                    const embedMediaItem : IMediaIEmbed = {
                        entityType: EntityTypeEnum.profile,   
                        label: "",
                        mediaType : MediaTypeEnum.embedVideo,
                        url: convertToEmbedLink(originalUrlName),  
                        profileId: id,   
                        azureContainerName: "profile-wizard-images",
                        originalFileName: originalUrlName,
                        sequence: mediaItems.length,
                        hasAcceptedUsagePolicy: true
                    }
                   const apiPayload : IMediaIEmbedApiPayload = {
                        toastMessage: "Virtual tour added",
                       IMediaIEmbed: embedMediaItem,     
                       enableToast : multipleEmbedUploads ? false:  true }
                    
                       dispatch(uploadEmbedVideos(apiPayload));

                    if (!multipleEmbedUploads) {
                        setConfirmed(false);
                        resetUrlInput();
                        closeCallback(false);
                        closeMessage();
                    } else { 
                        setDisplayUploadUrlInfo(originalUrlName);
                        setConfirmed(false);   
                        resetUrlInput();                 
                    } 

                }  

            } else { 
                setInvalidUrl(true);
            }

        } else {
            setInvalidUrl(true);
        }

        if(originalUrlName === "" ) {
            setInvalidUrl(false);
            setDisplayClearButton(false);
        }

    }
 
    const convertToEmbedLink = (url: string): string => {       
        let mediaUrl = getEmbedLinkAndVideoId(url);        
        return mediaUrl;      
    }      
      
    const getEmbedLinkAndVideoId = (originalLink: string) : string => {
          
        let videoId = '';      
        if (isValidURL(originalLink)) {   
            if (originalLink.includes('youtube')) {
                videoId =  getYoutubeVideoId(originalLink);                
                return 'https://www.youtube.com/embed/' + videoId;
                 
            } else if (originalLink.includes('vimeo')) {
                videoId =  getVimeoVideoId(originalLink);                 
                return 'https://player.vimeo.com/video/' + videoId;                 
            }
        }          
        return ""; 
    }      
      
    const getVimeoVideoId = (url: string) => {
        var regExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;
        var match = url.match(regExp);
        return (match && match[1].length > 0) ? match[1] : "";
    }      
      
    const  getYoutubeVideoId = (url: string) => {
        var regExp = /^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length === 11) ? match[1] : "";
    }


    const anotherEmbedUploadConfirmation = (value : boolean) => {         
        setMultipleEmbedUploads(value);
    }

    const closeMessage = () => {
        setDisplayUploadUrlInfo('')
        setMessageStyle('added-url');        
    }

    return (
        <Modal show={showModal} onHide={() => closeButtonClick()}>
             <Container fluid className="embed-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Embed video</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {displayUploadUrlInfo !== '' &&
                    <div className='display-message-box' >
                        <div  className={messageStyle} >
                            <Row>
                                <Col xs="1"> <BsFillExclamationCircleFill className="grey-icon"/>  </Col>
                                <Col xs="10"> <span className="wrap-message">Video '{displayUploadUrlInfo}' added</span> </Col>
                                <Col xs="1"> <CloseButton id="closeMessage" className="close-message-button" onClick={ () => closeMessage() } /> </Col>
                            </Row>
                        </div>
                    </div>
                    }
                    <p>Paste the URL of your Youtube or Vimeo video into the field below.</p>

                    <Form.Group className="padding-top-10">
                        <Form.Label>Youtube or Vimeo URL </Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control name="videoUrl" className={!invalidUrl ? "video-url" : "video-invalid-url"}  value={embedVideoUrl} onChange={ (event) => onInputChange(event.target.value)} />
                            <CloseButton className="close-button" onClick={ () => resetUrlInput() }  hidden={!displayClearButton} />
                        </InputGroup>
                            
                            <Form.Text  hidden={invalidUrl}>E.g https://www.youtube.com/watch123</Form.Text>
                            <div className="position-div" hidden={!invalidUrl}>
                            <BsFillExclamationCircleFill className="red-icon" />  <span className="red-text">URL not valid. Try again with a valid YouTube or Vimeo URL.</span>
                            </div>
                    </Form.Group>
                        <div className="flex-display-confirmation">
                            <Form.Check id="confirmation" checked={confirmed}  onChange={(e: any) => confirmationEvent(e.target.checked)} label="I confirm we have permission to use this video " /><span className="red-text">*</span>
                        </div>
                
                    <p></p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-display-confirmation">
                        <Form.Check id="embedConfirmation" checked={multipleEmbedUploads} label="Embed another" onChange={(e: any)  => anotherEmbedUploadConfirmation(e.target.checked)} />
                    </div>
                    <Button onClick={() => closeButtonClick()} variant="secondary">Cancel</Button>
                    <Button disabled={!confirmed || embedVideoUrl === "" } onClick={() => acceptButtonClick()} variant="primary">Embed video</Button>
                </Modal.Footer>
            </Container>
        </Modal>
    )
}