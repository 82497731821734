import { RootState } from 'app/root-reducer';
import React from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalToast } from './toast.slice';

export const Toaster = () => {
  const dispatch = useDispatch();
  const { show, title, message } = useSelector((state: RootState) => state.toast);

  const hide = () => {
    dispatch(hideGlobalToast());
  }

  return (
    <React.Fragment>
      {
        show && (
          <div aria-live="polite" aria-atomic="true" style={{zIndex: 10000, position: 'fixed', top: '60%', right: 0, margin: '10px', minWidth: '250px', minHeight: '200px'}}>
            <div  style={{ position: 'absolute', top: '100%', right: '0%',  minWidth: '300px', width: '100%' }}>
                <Toast onClose={hide} show={show} delay={3000} autohide>
                  <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                    <strong className="mr-auto">{title}</strong>
                  </Toast.Header>
                  <Toast.Body >{message}</Toast.Body>
                </Toast>
              </div>
            </div>
          )
      }
    </React.Fragment>
  )
};