import { IFeature, FeatureTypeEnum, IMenuItem, ISidebarMenuItem, BookingAffiliateEnum, IBookingAffiliate, IMultiSelectData, GDSEnum } from 'components/profile/types';
import { ChecklistSection, ProfileFormPageEnum, ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import { Dictionary } from "@reduxjs/toolkit"

declare interface DefinedDictionary<T> {
    [id: string]: T;
}

interface Card {
    name: string;
    icon: string;
    description: string;
    value: string;
}

export const typesArray: Card[] = [
    {name: "Stays", icon :"", description: "Hotels, B&Bs, boats etc", value: "Accommodation"},
    {name: "Things to do", icon :"", description: "", value: "Activity"},
    {name: "Venue", icon :"", description: "", value: "Venue"},
    {name: "Food & Beverage", icon :"", description: "", value: "Restaurant"},
    {name: "Person", icon :"", description: "", value: "Person"},
    {name: "Transportation", icon :"", description: "", value: "Vehicle"},
    {name: "Destination", icon :"", description: "", value: "Destination"},
    {name: "Infrastructure", icon :"", description: "", value: "Infrastructure"},
    {name: "Variant", icon :"", description: "", value: "Variant"},
]

export const categoryArray: Card[] = [
    {name: "Hotel", icon :"", description: "", value: "Hotel"},
    {name: "Guest House", icon :"", description: "", value: "Guest House"},
    {name: "Short Term Rental", icon :"", description: "", value: "Short Term Rental"},
    {name: "Villa", icon :"", description: "", value: "Villa"},
    {name: "Lodge", icon :"", description: "", value: "Lodge"},
    {name: "Motel", icon :"", description: "", value: "Motel"},
    {name: "Rail", icon :"", description: "", value: "Rail"},
    {name: "Boat", icon :"", description: "", value: "Boat"},
    {name: "Mobile Safari", icon :"", description: "", value: "Mobile Safari"},
    {name: "Camp", icon :"", description: "", value: "Camp"},
    {name: "Vehicle", icon :"", description: "", value: "Vehicle"},
]


export const RoomConfiguration: string[] = [
    "Select",
    "Single",
    "Double",
    "Twin",
    "Triple",
    "Quad/Family",
    "Queen",
    "King",
    "Suite",
    "Studio",
    "Connecting",
    "Murphy",
    "Accessible",
]

export const languages: IFeature[] = [
    {id: 1001, name: "English", type: FeatureTypeEnum.language},
    {id: 1002, name: "Chinese", type: FeatureTypeEnum.language},
    {id: 1003, name: "Dutch", type: FeatureTypeEnum.language},
    {id: 1004, name: "French", type: FeatureTypeEnum.language},
    {id: 1005, name: "German", type: FeatureTypeEnum.language},
    {id: 1006, name: "Indian", type: FeatureTypeEnum.language},
    {id: 1007, name: "Italian", type: FeatureTypeEnum.language},
    {id: 1008, name: "Japanese", type: FeatureTypeEnum.language},
    {id: 1009, name: "Portuguese", type: FeatureTypeEnum.language},
    {id: 1010, name: "Russian", type: FeatureTypeEnum.language},
    {id: 1011, name: "Spanish", type: FeatureTypeEnum.language},
    {id: 1012, name: "Afrikaans", type: FeatureTypeEnum.language},
    {id: 1013, name: "Flemish", type: FeatureTypeEnum.language},
    {id: 1014, name: "Swahili", type: FeatureTypeEnum.language},
    {id: 1015, name: "Xhosa", type: FeatureTypeEnum.language},
    {id: 1016, name: "Zulu", type: FeatureTypeEnum.language},

]

export const languageDictionary: Dictionary<string> = {
    en: "English",
    zh: "Chinese (Simplified)",
    "zh-TW": "Chinese (Traditional)",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    fi: "Finnish",
    fr: "French",
    de: "German",
    it: "Italian",
    no: "Norwegian",
    pl: "Polish",
    pt: "Portuguese",
    ru: "Russian",
    es: "Spanish",
    sv: "Swedish"
}

export const ProfileSummaryDropdownMenuItems: IMenuItem[] = [
    {key: "edit", label: "Edit profile", privacy: "N/A", tier: "Enhanced" },
    {key: "open", label: "Open iBrochure", privacy: "Public", tier: "Enhanced" },
    {key: "copy", label: "Copy link", privacy: "Public", tier: "Enhanced" },
    {key: "share", label: "Wetu Share", privacy: "Public", tier: "Enhanced" },
    {key: "embed", label: "Embed profile", privacy: "Public", tier: "Enhanced" },
    {key: "map", label: "Map view", privacy: "N/A", tier: "Enhanced" },
    {key: "disable", label: "Disable profile", privacy: "Public", tier: "N/A" },
]

export const ProfileSidebarMenuItems: ISidebarMenuItem[] = [
    {label: "Basic Info", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.basicInfo, isEnhancedOnly: false },
    {label: "Fast Facts", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.fastFacts, isEnhancedOnly: true },
    {label: "Media", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.media, isEnhancedOnly: false },
    {label: "Sleeping arrangements", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.sleepingArrangements, isEnhancedOnly: true },
    {label: "Facilities", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.facilities, isEnhancedOnly: false },
    {label: "Contact details", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.contactDetails, isEnhancedOnly: false },
    {label: "Location", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.location, isEnhancedOnly: false },
    {label: "Added services", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.addedServices, isEnhancedOnly: true },
    {label: "Rates", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.rates, isEnhancedOnly: true },
    {label: "Documents", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.documents, isEnhancedOnly: false },
    {label: "Integrations", isSelected: false, isCompleted: false, url: ProfileFormPageEnum.integrations, isEnhancedOnly: false}
]

export const ChecklistSections: ChecklistSection[] = [
    {label: "Images", id: ProfileFormPageSectionEnum.images, page: ProfileFormPageEnum.media, isEnhancedOnly: false, isCompleted: false},
    {label: "Videos", id: ProfileFormPageSectionEnum.videos, page: ProfileFormPageEnum.media, isEnhancedOnly: true, isCompleted: false},
    {label: "Virtual Tours", id: ProfileFormPageSectionEnum.virtualTours, page: ProfileFormPageEnum.media, isEnhancedOnly: true, isCompleted: false},
    {label: "Appearances", id: ProfileFormPageSectionEnum.appearances, page: ProfileFormPageEnum.media, isEnhancedOnly: true, isCompleted: false},
    {label: "Property", id: ProfileFormPageSectionEnum.facilitiesProperty, page: ProfileFormPageEnum.facilities, isEnhancedOnly: false, isCompleted: false},
    {label: "Sleeping Arrangements", id: ProfileFormPageSectionEnum.facilitiesSleepingArrangements, page: ProfileFormPageEnum.facilities, isEnhancedOnly: false, isCompleted: false},
    {label: "Services", id: ProfileFormPageSectionEnum.facilitiesServices, page: ProfileFormPageEnum.facilities, isEnhancedOnly: false, isCompleted: false},
    {label: "Activities on site", id: ProfileFormPageSectionEnum.facilitiesActivitiesOnSite, page: ProfileFormPageEnum.facilities, isEnhancedOnly: false, isCompleted: false},
    {label: "Activities nearby", id: ProfileFormPageSectionEnum.facilitiesActivitiesNearby, page: ProfileFormPageEnum.facilities, isEnhancedOnly: false, isCompleted: false},
    {label: "Enquiries", id: ProfileFormPageSectionEnum.enquiries, page: ProfileFormPageEnum.contactDetails, isEnhancedOnly: false, isCompleted: false},
    {label: "Contacts", id: ProfileFormPageSectionEnum.contacts, page: ProfileFormPageEnum.contactDetails, isEnhancedOnly: false, isCompleted: false},
    {label: "Social media", id: ProfileFormPageSectionEnum.socialMedia, page: ProfileFormPageEnum.contactDetails, isEnhancedOnly: false, isCompleted: false},
    {label: "Integrations", id: ProfileFormPageSectionEnum.integrations, page: ProfileFormPageEnum.integrations, isEnhancedOnly: false, isCompleted: false},
    {label: "GDS codes", id: ProfileFormPageSectionEnum.gdsCodes, page: ProfileFormPageEnum.integrations, isEnhancedOnly: false, isCompleted: false},
]

export const MediaAppearancesSections: string[] = [
    "Why stay here",
    "Room types", 
    "Unit types", 
    "Restaurant",
    "Activities",
    "Facilities",
    "Map",
    "Documents"
];

export const featuresData: DefinedDictionary<DefinedDictionary<IFeature[]>> = {
    "Property": {
        "General": [
                {id: 1, name: "Activity desk", type: FeatureTypeEnum.facility },
                {id: 2, name: "Bar", type: FeatureTypeEnum.facility },
                {id: 3, name: "Barbeque facilities", type: FeatureTypeEnum.facility},
                {id: 4, name: "Business centre", type: FeatureTypeEnum.facility },
                {id: 262, name: "Casino", type: FeatureTypeEnum.facility},
                {id: 5, name: "Conference centre", type: FeatureTypeEnum.facility},
                {id: 6, name: "Chapel", type: FeatureTypeEnum.facility },
                {id: 263, name: "Cinema", type: FeatureTypeEnum.facility},
                {id: 7, name: "Communal dining", type: FeatureTypeEnum.facility },
                {id: 8, name: "Eco friendly", type: FeatureTypeEnum.facility },
                {id: 264, name: "Gym / Fitness centre", type: FeatureTypeEnum.facility},
                {id: 9, name: "Gym on property", type: FeatureTypeEnum.facility },
                {id: 10, name: "Gym nearby", type: FeatureTypeEnum.facility },
                {id: 265, name: "Indoor pool", type: FeatureTypeEnum.facility},
                {id: 11, name: "Kiddies club", type: FeatureTypeEnum.facility},
                {id: 323, name: "Kiddies pool / Splash pool", type: FeatureTypeEnum.facility},
                {id: 12, name: "Library", type: FeatureTypeEnum.facility },
                {id: 322, name: "Lounges", type: FeatureTypeEnum.facility},
                {id: 13, name: "Malaria free", type: FeatureTypeEnum.facility },
                {id: 266, name: "Nightclubs", type: FeatureTypeEnum.facility },
                {id: 267, name: "Outdoor pool / Pool deck", type: FeatureTypeEnum.facility},
                {id: 14, name: "Pool", type: FeatureTypeEnum.facility },
                {id: 15, name: "Restaurant", type: FeatureTypeEnum.facility },
                {id: 16, name: "Secure parking", type: FeatureTypeEnum.facility},
                {id: 321, name: "Shops", type: FeatureTypeEnum.facility},
                {id: 17, name: "Spa", type: FeatureTypeEnum.facility},
                {id: 268, name: "Theatres", type: FeatureTypeEnum.facility},
                {id: 18, name: "TV room", type: FeatureTypeEnum.facility},
                {id: 269, name: "Wedding Chapel", type: FeatureTypeEnum.facility},
                {id: 270, name: "Wellness centre", type: FeatureTypeEnum.facility}
        ],
        "Connectivity": [
                {id: 19, name: "Battery charging facilities", type: FeatureTypeEnum.facility},
                {id: 271, name: "Internet access", type: FeatureTypeEnum.facility},
                {id: 20, name: "Internet access complimentary", type: FeatureTypeEnum.facility},
                {id: 21, name: "Internet access paid", type: FeatureTypeEnum.facility},
                {id: 22, name: "Mobile connectivity", type: FeatureTypeEnum.facility},
                {id: 23, name: "Skype phones", type: FeatureTypeEnum.facility},
        ]
    },
    "Rooms": {
        "General": [
            {id: 24, name: "Air conditioning", type: FeatureTypeEnum.facility},
            {id: 272, name: "Balcony", type: FeatureTypeEnum.facility},
            {id: 25, name: "Balcony / Deck", type: FeatureTypeEnum.facility},
            {id: 273, name: "Camp beds", type: FeatureTypeEnum.facility},
            {id: 26, name: "Cooking equipment", type: FeatureTypeEnum.facility},
            {id: 27, name: "Cooking facilities", type: FeatureTypeEnum.facility}, 
            {id: 28, name: "Complimentary Wi-Fi", type: FeatureTypeEnum.facility},
            {id: 274, name: "Cooking equipment", type: FeatureTypeEnum.facility},
            {id: 275, name: "Cooking facilities", type: FeatureTypeEnum.facility},
            {id: 29, name: "Disabled rooms", type: FeatureTypeEnum.facility},
            {id: 276, name: "Dome tent", type: FeatureTypeEnum.facility},
            {id: 277, name: "Driver's cabin", type: FeatureTypeEnum.facility},
            {id: 30, name: "Electrical outlets", type: FeatureTypeEnum.facility},
            {id: 278, name: "Family safari tent", type: FeatureTypeEnum.facility},
            {id: 31, name: "Fan", type: FeatureTypeEnum.facility},
            {id: 32, name: "Fireplace", type: FeatureTypeEnum.facility},
            {id: 315, name: "Foam mattress", type: FeatureTypeEnum.facility},
            {id: 33, name: "Fridge", type: FeatureTypeEnum.facility},
            {id: 34, name: "Heater", type: FeatureTypeEnum.facility},
            {id: 35, name: "Internet access complimentary", type: FeatureTypeEnum.facility},
            {id: 36, name: "Internet access paid", type: FeatureTypeEnum.facility},
            {id: 279, name: "LED camp lights", type: FeatureTypeEnum.facility},
            {id: 37, name: "Lounge", type: FeatureTypeEnum.facility},
            {id: 280, name: "Mini bar", type: FeatureTypeEnum.facility},
            {id: 38, name: "Mini bar complimentary", type: FeatureTypeEnum.facility},
            {id: 39, name: "Mini bar paid", type: FeatureTypeEnum.facility},
            {id: 40, name: "Mosquito nets", type: FeatureTypeEnum.facility},
            {id: 281, name: "Mosquito netting", type: FeatureTypeEnum.facility},
            {id: 282, name: "Oil / Paraffin lamps", type: FeatureTypeEnum.facility},
            {id: 41, name: "Outside shower", type: FeatureTypeEnum.facility},
            {id: 42, name: "Plunge pool", type: FeatureTypeEnum.facility},
            {id: 283, name: "Private lounge / Sitting area", type: FeatureTypeEnum.facility},
            {id: 43, name: "Private pool / Jacuzzi", type: FeatureTypeEnum.facility},
            {id: 44, name: "Pure cotton linen", type: FeatureTypeEnum.facility},
            {id: 284, name: "Reversing Camera", type: FeatureTypeEnum.facility},
            {id: 285, name: "Roof hatch", type: FeatureTypeEnum.facility},
            {id: 45, name: "Safe", type: FeatureTypeEnum.facility},
            {id: 286, name: "Sahara style tent", type: FeatureTypeEnum.facility},
            {id: 46, name: "Sala", type: FeatureTypeEnum.facility},
            {id: 47, name: "Satellite / Cable TV", type: FeatureTypeEnum.facility},
            {id: 287, name: "Smoke alarm", type: FeatureTypeEnum.facility},
            {id: 48, name: "Smoking rooms", type: FeatureTypeEnum.facility},
            {id: 288, name: "Solid base camp beds", type: FeatureTypeEnum.facility},
            {id: 289, name: "Stretcher beds", type: FeatureTypeEnum.facility},
            {id: 290, name: "Swivel front cabin seats", type: FeatureTypeEnum.facility},
            {id: 291, name: "Telephone", type: FeatureTypeEnum.facility},
            {id: 49, name: "Television", type: FeatureTypeEnum.facility},
            {id: 292, name: "Under seat storage", type: FeatureTypeEnum.facility},
            {id: 50, name: "Underfloor heating", type: FeatureTypeEnum.facility},
            {id: 293, name: "Upper dashboard refrigerated compartment", type: FeatureTypeEnum.facility},
            {id: 51, name: "Verandah", type: FeatureTypeEnum.facility},
            {id: 294, name: "Wardrobe", type: FeatureTypeEnum.facility},
            {id: 295, name: "Water storage tank", type: FeatureTypeEnum.facility},
            {id: 52, name: "Wheelchair friendly", type: FeatureTypeEnum.facility},          
               
        ],
        "Bath/Laundry": [
            {id: 53, name: "Bath", type: FeatureTypeEnum.facility},
            {id: 296, name: "Bath towels", type: FeatureTypeEnum.facility},
            {id: 297, name: "Bed linen", type: FeatureTypeEnum.facility},
            {id: 298, name: "Bucket shower", type: FeatureTypeEnum.facility},
            {id: 299, name: "Bunk bed", type: FeatureTypeEnum.facility},
            {id: 54, name: "Clothes press", type: FeatureTypeEnum.facility},
            {id: 55, name: "Detached private bathroom", type: FeatureTypeEnum.facility},
            {id: 56, name: "Dressing gowns", type: FeatureTypeEnum.facility},
            {id: 57, name: "Ensuite", type: FeatureTypeEnum.facility},
            {id: 300, name: "External shower", type: FeatureTypeEnum.facility},
            {id: 58, name: "Hairdryer", type: FeatureTypeEnum.facility}, 
            {id: 301, name: "Hand towels", type: FeatureTypeEnum.facility},        
            {id: 59, name: "Heated towel rails", type: FeatureTypeEnum.facility},
            {id: 60, name: "Iron / Ironing board", type: FeatureTypeEnum.facility},
            {id: 61, name: "Laundry facilities", type: FeatureTypeEnum.facility},
            {id: 62, name: "Laundry service available", type: FeatureTypeEnum.facility},
            {id: 63, name: "Laundry service complimentary", type: FeatureTypeEnum.facility},
            {id: 302, name: "Mid ensuite with separate shower / toilet", type: FeatureTypeEnum.facility},
            {id: 303, name: "Queen bed", type: FeatureTypeEnum.facility},
            {id: 64, name: "Shared ablutions", type: FeatureTypeEnum.facility},
            {id: 65, name: "Shower", type: FeatureTypeEnum.facility},
            {id: 304, name: "Twin bed", type: FeatureTypeEnum.facility},
            {id: 305, name: "Vanity cabinet", type: FeatureTypeEnum.facility}
        ],
        "Kitchen": [
            {id: 306, name: "Drinking water filtration system", type: FeatureTypeEnum.facility},
            {id: 307, name: "Fridge / Freezer", type: FeatureTypeEnum.facility},
            {id: 308, name: "Gas cooker", type: FeatureTypeEnum.facility},
            {id: 309, name: "Gas cylinders", type: FeatureTypeEnum.facility},
            {id: 310, name: "Gas hot water system", type: FeatureTypeEnum.facility},
            {id: 311, name: "Kitchenette", type: FeatureTypeEnum.facility},
            {id: 312, name: "Microwave", type: FeatureTypeEnum.facility},
            {id: 313, name: "Portable BBQ", type: FeatureTypeEnum.facility},
            {id: 314, name: "Table", type: FeatureTypeEnum.facility},
        ],
        "Entertainment": [
            {id: 316, name: "AM / FM / iPod / iPhone / USB Stereo", type: FeatureTypeEnum.facility},
            {id: 317, name: "External stereo", type: FeatureTypeEnum.facility},
            {id: 318, name: "Internal stereo", type: FeatureTypeEnum.facility},
            {id: 319, name: "iPod / iPhone / USB Dock", type: FeatureTypeEnum.facility},
            {id: 320, name: "LCD DVD / TV", type: FeatureTypeEnum.facility}
        ]
    },
    "Services": {
        "Available Services": [
            {id: 66, name: "24-hour security", type: FeatureTypeEnum.facility},
            {id: 67, name: "Airport shuttle available", type: FeatureTypeEnum.facility},
            {id: 68, name: "Airport shuttle complimentary", type: FeatureTypeEnum.facility},
            {id: 69, name: "ATM", type: FeatureTypeEnum.facility},         
            {id: 70, name: "Babysitting", type: FeatureTypeEnum.facility},
            {id: 71, name: "Chauffeur", type: FeatureTypeEnum.facility},
            {id: 72, name: "Credit card", type: FeatureTypeEnum.facility},
            {id: 73, name: "Disability", type: FeatureTypeEnum.facility},
            {id: 74, name: "Foreign exchange", type: FeatureTypeEnum.facility},
            {id: 75, name: "Laundry service available", type: FeatureTypeEnum.facility},
            {id: 76, name: "Laundry service complimentary", type: FeatureTypeEnum.facility},
            {id: 77, name: "Meal on request", type: FeatureTypeEnum.facility},
            {id: 78, name: "Private vehicle available", type: FeatureTypeEnum.facility},
            {id: 79, name: "Room service", type: FeatureTypeEnum.facility},
            {id: 80, name: "Room service 24 hours", type: FeatureTypeEnum.facility},
            {id: 81, name: "Travel desk", type: FeatureTypeEnum.facility},          
        ]
    },
    "Activities On Site": {
        "Water Sports": [
            {id: 82, name: "Boating", type: FeatureTypeEnum.facility},
            {id: 83, name: "Canoeing", type: FeatureTypeEnum.facility},
            {id: 84, name: "Catch and release fishing", type: FeatureTypeEnum.facility},
            {id: 85, name: "Fishing", type: FeatureTypeEnum.facility},
            {id: 86, name: "Jet skis", type: FeatureTypeEnum.facility},
            {id: 87, name: "Kayaking", type: FeatureTypeEnum.facility},
            {id: 88, name: "Kite surfing", type: FeatureTypeEnum.facility},
            {id: 89, name: "Mokoro excursion", type: FeatureTypeEnum.facility},
            {id: 90, name: "Para sailing", type: FeatureTypeEnum.facility},
            {id: 91, name: "Sailing", type: FeatureTypeEnum.facility},
            {id: 92, name: "Scuba diving", type: FeatureTypeEnum.facility},
            {id: 93, name: "Snorkelling", type: FeatureTypeEnum.facility},
            {id: 94, name: "Surfing", type: FeatureTypeEnum.facility},
            {id: 95, name: "Water skiing", type: FeatureTypeEnum.facility},
            {id: 96, name: "Windsurfing", type: FeatureTypeEnum.facility},        
        ],
        "Land Sports": [
            {id: 97, name: "Boules", type: FeatureTypeEnum.facility},
            {id: 98, name: "Bowls", type: FeatureTypeEnum.facility},
            {id: 99, name: "Cycling", type: FeatureTypeEnum.facility},
            {id: 100, name: "Golf", type: FeatureTypeEnum.facility},
            {id: 101, name: "Horse riding", type: FeatureTypeEnum.facility},
            {id: 102, name: "Practice green", type: FeatureTypeEnum.facility},
            {id: 103, name: "Quad biking", type: FeatureTypeEnum.facility},
            {id: 104, name: "Squash court", type: FeatureTypeEnum.facility},
            {id: 105, name: "Tennis court", type: FeatureTypeEnum.facility},       
        ],
        "Sightseeing / Excursions": [
            {id: 106, name: "Beach drop off", type: FeatureTypeEnum.facility},
            {id: 107, name: "Garden tours", type: FeatureTypeEnum.facility},
            {id: 108, name: "Massages", type: FeatureTypeEnum.facility},
            {id: 109, name: "Helicopter scenic flights", type: FeatureTypeEnum.facility},
            {id: 110, name: "Natural history tours", type: FeatureTypeEnum.facility},
            {id: 111, name: "Whale watching", type: FeatureTypeEnum.facility},
            {id: 112, name: "Scenic flights", type: FeatureTypeEnum.facility},
         
        ],
        "Wildlife and Nature": [
            {id: 113, name: "Big 5", type: FeatureTypeEnum.facility},
            {id: 114, name: "Bord watching", type: FeatureTypeEnum.facility},
            {id: 115, name: "Elephant interaction", type: FeatureTypeEnum.facility},
            {id: 116, name: "Elephant back safaris", type: FeatureTypeEnum.facility},
            {id: 117, name: "Game drives", type: FeatureTypeEnum.facility},
            {id: 118, name: "Game viewing", type: FeatureTypeEnum.facility},
            {id: 119, name: "Game viewing hide", type: FeatureTypeEnum.facility},
            {id: 120, name: "Game walks", type: FeatureTypeEnum.facility},
            {id: 121, name: "Gorilla tracking", type: FeatureTypeEnum.facility},
            {id: 122, name: "Horse back safari", type: FeatureTypeEnum.facility},
            {id: 123, name: "Night drives", type: FeatureTypeEnum.facility},
            {id: 124, name: "Rhino tracking", type: FeatureTypeEnum.facility},
            {id: 125, name: "Safaris", type: FeatureTypeEnum.facility},
            {id: 126, name: "Sleep outs", type: FeatureTypeEnum.facility},
            {id: 127, name: "Turtle tracking", type: FeatureTypeEnum.facility},      
            {id: 128, name: "Walks guided", type: FeatureTypeEnum.facility},
            {id: 129, name: "Walks self guided", type: FeatureTypeEnum.facility},
            {id: 130, name: "Wildlife tours", type: FeatureTypeEnum.facility},         
        ],
        "Food and Beverage": [
            {id: 131, name: "Cooking courses", type: FeatureTypeEnum.facility},
            {id: 132, name: "Fine dining", type: FeatureTypeEnum.facility},        
            {id: 133, name: "Gourmet picnic", type: FeatureTypeEnum.facility},
            {id: 134, name: "High tea", type: FeatureTypeEnum.facility},
        ]
    },
    "Activities Nearby": {
        "General": [           
            {id: 135, name: "Adrenaline activites", type: FeatureTypeEnum.facility},
            {id: 136, name: "Child friendly activities", type: FeatureTypeEnum.facility},
        ],
        "Water Sports": [
            {id: 137, name: "Beach", type: FeatureTypeEnum.facility},
            {id: 138, name: "Boating", type: FeatureTypeEnum.facility},
            {id: 139, name: "Canoeing", type: FeatureTypeEnum.facility},
            {id: 140, name: "Catch and release fishing", type: FeatureTypeEnum.facility},
            {id: 141, name: "Deep sea fishing", type: FeatureTypeEnum.facility},
            {id: 142, name: "Fishing", type: FeatureTypeEnum.facility},
            {id: 143, name: "Fly fishing", type: FeatureTypeEnum.facility},
            {id: 144, name: "Jet skis", type: FeatureTypeEnum.facility},
            {id: 145, name: "Kayaking / Padling", type: FeatureTypeEnum.facility},
            {id: 146, name: "Kite surfing", type: FeatureTypeEnum.facility},
            {id: 147, name: "Marine adventures", type: FeatureTypeEnum.facility},
            {id: 148, name: "Marine tidal pool", type: FeatureTypeEnum.facility},
            {id: 149, name: "Para sailing", type: FeatureTypeEnum.facility},
            {id: 150, name: "Sailing", type: FeatureTypeEnum.facility},
            {id: 151, name: "Scuba diving", type: FeatureTypeEnum.facility},
            {id: 152, name: "Sea kayaking", type: FeatureTypeEnum.facility},
            {id: 153, name: "Snorkelling", type: FeatureTypeEnum.facility},
            {id: 154, name: "Surfing", type: FeatureTypeEnum.facility},
            {id: 155, name: "Water skiing", type: FeatureTypeEnum.facility},
            {id: 156, name: "Windsurfing", type: FeatureTypeEnum.facility},
        ],
        "Land Sports": [
            {id: 157, name: "Boules", type: FeatureTypeEnum.facility},
            {id: 158, name: "Bowls", type: FeatureTypeEnum.facility},
            {id: 159, name: "Cycling", type: FeatureTypeEnum.facility},
            {id: 160, name: "Golf", type: FeatureTypeEnum.facility},
            {id: 161, name: "Health and fitness centre", type: FeatureTypeEnum.facility},
            {id: 162, name: "Hiking", type: FeatureTypeEnum.facility},
            {id: 163, name: "Horse riding", type: FeatureTypeEnum.facility},
            {id: 164, name: "Mini golf", type: FeatureTypeEnum.facility},
            {id: 165, name: "Mountain biking", type: FeatureTypeEnum.facility},         
            {id: 166, name: "Practice green", type: FeatureTypeEnum.facility},
            {id: 167, name: "Quad biking", type: FeatureTypeEnum.facility},
            {id: 168, name: "Rock climbing", type: FeatureTypeEnum.facility},
            {id: 169, name: "Squash court", type: FeatureTypeEnum.facility},
            {id: 170, name: "Tennis court", type: FeatureTypeEnum.facility},            
        ],
        "Unique Experiences": [
            {id: 171, name: "Helicopter rides", type: FeatureTypeEnum.facility},
            {id: 172, name: "Helicopter scenic flights", type: FeatureTypeEnum.facility},
            {id: 173, name: "Hot air ballooning", type: FeatureTypeEnum.facility},
            {id: 174, name: "Paragliding", type: FeatureTypeEnum.facility},
            {id: 175, name: "Sky diving", type: FeatureTypeEnum.facility},
            {id: 176, name: "Scenic flights", type: FeatureTypeEnum.facility},
        ],
        "Sightseeing / Excursions": [
            {id: 177, name: "Amusement park", type: FeatureTypeEnum.facility},
            {id: 178, name: "Art galleries", type: FeatureTypeEnum.facility},
            {id: 179, name: "Battlefield tours", type: FeatureTypeEnum.facility},
            {id: 180, name: "Boat cruises", type: FeatureTypeEnum.facility},
            {id: 181, name: "Cultural tours", type: FeatureTypeEnum.facility},
            {id: 182, name: "Garden tours", type: FeatureTypeEnum.facility},
            {id: 183, name: "General sightseeing", type: FeatureTypeEnum.facility},
            {id: 184, name: "Historical tours", type: FeatureTypeEnum.facility},
            {id: 185, name: "Museums", type: FeatureTypeEnum.facility},
            {id: 186, name: "Mokoro excursion", type: FeatureTypeEnum.facility},
            {id: 187, name: "Natural history tours", type: FeatureTypeEnum.facility},
            {id: 188, name: "River cruises", type: FeatureTypeEnum.facility},
            {id: 189, name: "Shark cage diving", type: FeatureTypeEnum.facility},
            {id: 190, name: "Treetop canopy tours", type: FeatureTypeEnum.facility},          
        ],
        "Wildlife and Nature": [
            {id: 191, name: "Animal encounters", type: FeatureTypeEnum.facility},
            {id: 192, name: "Big 5", type: FeatureTypeEnum.facility},
            {id: 193, name: "Bird watching", type: FeatureTypeEnum.facility},
            {id: 194, name: "Elephant interaction", type: FeatureTypeEnum.facility},
            {id: 195, name: "Elephant back safaris", type: FeatureTypeEnum.facility},
            {id: 196, name: "Game drives", type: FeatureTypeEnum.facility},
            {id: 197, name: "Game viewing", type: FeatureTypeEnum.facility},
            {id: 198, name: "Game walks", type: FeatureTypeEnum.facility},
            {id: 199, name: "Gorilla tracking", type: FeatureTypeEnum.facility},      
            {id: 200, name: "Horse back safari", type: FeatureTypeEnum.facility},
            {id: 201, name: "Night drives", type: FeatureTypeEnum.facility},
            {id: 202, name: "Rhino tracking", type: FeatureTypeEnum.facility},
            {id: 203, name: "Safaris", type: FeatureTypeEnum.facility},
            {id: 204, name: "Turtle tracking", type: FeatureTypeEnum.facility},
            {id: 205, name: "Walks guided", type: FeatureTypeEnum.facility},
            {id: 206, name: "Walks self guided", type: FeatureTypeEnum.facility},
            {id: 207, name: "Wildlife tours", type: FeatureTypeEnum.facility},           
        ],
        "Food and Beverage": [
            {id: 208, name: "Cooking courses", type: FeatureTypeEnum.facility},
            {id: 209, name: "Wine tasting", type: FeatureTypeEnum.facility},           
        ]
    },
    "Fast Facts": {
        "Friendly": [           
            {id: 210, name: "Business", type: FeatureTypeEnum.facility},
            {id: 211, name: "Children", type: FeatureTypeEnum.facility},
            {id: 212, name: "Disability", type: FeatureTypeEnum.facility},
            {id: 213, name: "Honeymoon", type: FeatureTypeEnum.facility},
            {id: 214, name: "Leisure", type: FeatureTypeEnum.facility},
            {id: 215, name: "Luxury", type: FeatureTypeEnum.facility},
            {id: 216, name: "Pet", type: FeatureTypeEnum.facility},
            {id: 217, name: "Romance", type: FeatureTypeEnum.facility},
            {id: 218, name: "Vegetarian", type: FeatureTypeEnum.facility},
            {id: 219, name: "Weddings", type: FeatureTypeEnum.facility}
        ],
        "Special Interest": [           
            {id: 221, name: "Adventure", type: FeatureTypeEnum.facility},
            {id: 222, name: "Battlefields", type: FeatureTypeEnum.facility},
            {id: 223, name: "Beach / Coastal", type: FeatureTypeEnum.facility},
            {id: 224, name: "Big 5", type: FeatureTypeEnum.facility},
            {id: 225, name: "Birding", type: FeatureTypeEnum.facility},
            {id: 226, name: "Cycling", type: FeatureTypeEnum.facility},
            {id: 227, name: "Fishing", type: FeatureTypeEnum.facility},
            {id: 228, name: "Flora", type: FeatureTypeEnum.facility},
            {id: 229, name: "Golf", type: FeatureTypeEnum.facility},
            {id: 230, name: "Gourmet", type: FeatureTypeEnum.facility},
            {id: 231, name: "Hiking", type: FeatureTypeEnum.facility},
            {id: 232, name: "History & Culture", type: FeatureTypeEnum.facility},
            {id: 233, name: "Indigenous Culture / Art", type: FeatureTypeEnum.facility},
            {id: 234, name: "Leisure", type: FeatureTypeEnum.facility},
            {id: 235, name: "Nature", type: FeatureTypeEnum.facility},
            {id: 236, name: "Relaxation", type: FeatureTypeEnum.facility},
            {id: 237, name: "Shopping", type: FeatureTypeEnum.facility},
            {id: 238, name: "Sports", type: FeatureTypeEnum.facility},
            {id: 239, name: "Star Gazing", type: FeatureTypeEnum.facility},
            {id: 240, name: "Watersports", type: FeatureTypeEnum.facility},
            {id: 241, name: "Wildlife", type: FeatureTypeEnum.facility},
            {id: 242, name: "Wine", type: FeatureTypeEnum.facility}
        ],
        "Tour Operator / Travel Agent Guidance": [           
            {id: 243, name: "BAR Pricing", type: FeatureTypeEnum.facility},
            {id: 244, name: "Cash Payment Only", type: FeatureTypeEnum.facility},
            {id: 245, name: "Disabled Access", type: FeatureTypeEnum.facility},
            {id: 246, name: "Elderly Friendly", type: FeatureTypeEnum.facility},
            {id: 247, name: "Game Drive (Max 10 pax)", type: FeatureTypeEnum.facility},
            {id: 248, name: "Game Drive (Max 12 pax)", type: FeatureTypeEnum.facility},
            {id: 249, name: "Game Drive (Max 4 pax with window seats)", type: FeatureTypeEnum.facility},
            {id: 250, name: "Game Drive (Max 6 pax with window seats)", type: FeatureTypeEnum.facility},
            {id: 251, name: "Game Drive (Max 9 pax)", type: FeatureTypeEnum.facility},
            {id: 252, name: "Honeymoon Suite Available", type: FeatureTypeEnum.facility},
            {id: 253, name: "Interleading Rooms Available", type: FeatureTypeEnum.facility},
            {id: 254, name: "Limited Electricity", type: FeatureTypeEnum.facility},
            {id: 255, name: "No Electricity", type: FeatureTypeEnum.facility},
            {id: 256, name: "Private Game Drive Vehicle Available", type: FeatureTypeEnum.facility},
            {id: 257, name: "Private Restaurant Dining", type: FeatureTypeEnum.facility},
            {id: 258, name: "Seasonal Activities", type: FeatureTypeEnum.facility},
            {id: 259, name: "Sole Use Available", type: FeatureTypeEnum.facility},
            {id: 260, name: "Triple for Adult Available", type: FeatureTypeEnum.facility},
            {id: 261, name: "Triple for Children Available", type: FeatureTypeEnum.facility}
        ]
    }
}

export const minimumImages = 6;
export const maximumImages = 30;
export const minimumVideos = 1;

export const affiliates: IBookingAffiliate[] = [
    {affiliate: BookingAffiliateEnum.none, description: ""}, 
    {affiliate: BookingAffiliateEnum.nightsbridge, description: "When you provide your NightsBridge ID, your room information will be automatically linked and populated from your Nightsbridge records."}, 
    {affiliate: BookingAffiliateEnum.resRequest, description: ""}, 
];

export const gdsData: IMultiSelectData[] = [
    {title: GDSEnum.amadeus, key: GDSEnum.amadeus, isChecked: false, subtitle: ""},
    {title: GDSEnum.galileo, key: GDSEnum.galileo, isChecked: false, subtitle: ""},
    {title: GDSEnum.pegasus, key: GDSEnum.pegasus, isChecked: false, subtitle: ""},
    {title: GDSEnum.sabre, key: GDSEnum.sabre, isChecked: false, subtitle: ""},
    {title: GDSEnum.travelPort, key: GDSEnum.travelPort, isChecked: false, subtitle: ""},
    {title: GDSEnum.worldSpan, key: GDSEnum.worldSpan, isChecked: false, subtitle: ""}
];

export const getApiBaseUrl = () => {
    if (window?.location?.hostname === 'localhost') {
        var envFileBaseUrl = process.env?.REACT_APP_API_BASE_URL;
        if (envFileBaseUrl) {
            if (envFileBaseUrl.slice(-1) !== '/') {
                envFileBaseUrl += '/';
            }
        }
        return envFileBaseUrl ? envFileBaseUrl : 'https://localhost:44386/api/v1/';
    } 
    else {
        return 'https://profile-management-api.dev.wetu.com/api/v1/';
    }
}