import React,  { useEffect } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Facilities } from 'components/facilities/facilities';
import { WizardCallback } from 'components/wizard/types';
import { useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { FeatureTypeEnum } from 'components/profile/types';

const WizardStep6Page = ({callBack}: WizardCallback) => {
  const { features } = useSelector((state: RootState) => state.profileSlice.profile);
  
  useEffect(() => {
    const featuresCount = features.filter(x => x.type === FeatureTypeEnum.facility).length;
    callBack({id: 6, completed: true, skip: featuresCount > 0 ? false : true, displayFooterNavigation: true, displayNext: true});
  }, [callBack, features]);

    return (
        <Container fluid>
          <Row className="margin-top-50">
            <Col xs={{offset: 2, span: 10}}>
              <Row>
                <Col xs="8">
                  <Card border="dark">
                    <Card.Body>
                      <Card.Title>What facilities can guests use at your hotel?</Card.Title>                    
                      <Form.Group>                      
                        <Facilities featureDataSectionName="Property"></Facilities> 
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="4">
                
                </Col>
              </Row>
            </Col>
          </Row>         
        </Container>
      );
    }
    
    export default WizardStep6Page
    
