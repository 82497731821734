import { minimumImages, minimumVideos } from 'app/config';
import { RootState } from 'app/root-reducer';
import { ProfileImagesCard } from 'components/profile.images.card/profile.images.card';
import { IDeleteMediaPayload, EntityTypeEnum, IMediaItem, MediaTypeEnum } from 'components/profile/types';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MediaAppearancesCard } from './features/appearances/media.appearances';
import { MediaVideosCard } from './features/videos/media.videos';
import { MediaVirtualToursCard } from './features/virtual.tours/media.virtual.tours';
import { useAppDispatch } from 'app/store';
import { deleteMedia, getMediaItems, uploadMedia, isMediaComplete, UpdateMedia_Reducer } from 'components/profile/mediaitem/mediaitem.slice';
import { updateProfileFromStore, UpdateSectionCompleteWithValue} from 'components/profile/profile.slice';
import { ProfileFormPageEnum } from 'pages/profile-form/profile.form.types';
import _ from 'lodash';

interface IProps {
    saveCallback: () => void;
}

export const MediaPage = ({saveCallback}: IProps) => {
    const { isEnhanced, availableLanguages, name, id } = useSelector((state: RootState) => state.profileSlice.profile);
    const { mediaItems, isMediaItemSliceLoading } = useSelector((state: RootState) => state.mediaItemSlice);
    const [ videos, setVideos ] = useState<IMediaItem[]>([]);
    const [ images, setImages ] = useState<IMediaItem[]>([]);
    const [ virtualTours, setVirtualTours ] = useState<IMediaItem[]>([]);
    const [ imagesComplete, setImagesComplete ] = useState<boolean>(false);
    const [ videosComplete, setVideosComplete ] = useState<boolean>(false);
    const [ virtualToursComplete, setVirtualToursComplete ] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const getImages = useCallback(() => {
        return mediaItems.filter(x => x.mediaType === MediaTypeEnum.image).sort((a, b) => a.sequence > b.sequence ? 1 : -1);
    }, [mediaItems])

    const getVideos = useCallback(() => {
        return mediaItems.filter(x => x.mediaType === MediaTypeEnum.video || x.mediaType === MediaTypeEnum.embedVideo).sort((a, b) => a.sequence > b.sequence ? 1 : -1);
    }, [mediaItems])

    const getVirtualTours = useCallback(() => {
        return mediaItems.filter(x => x.mediaType === MediaTypeEnum.virtualTour).sort((a, b) => a.sequence > b.sequence ? 1 : -1);
    }, [mediaItems])

    const getMediaCount = (getFunction: () => IMediaItem[]) => {
        return getFunction()?.length;
    }

    useEffect(() => {
        //Load MediaItems from API
        if (id) {
            dispatch(getMediaItems(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        //Set mediaItems (from API) into local component state.
        if (!isMediaItemSliceLoading) {
            //mediaItems has now been populated. 
            setImages(getImages());
            setVideos(getVideos());
            setVirtualTours(getVirtualTours());

            setImagesComplete(mediaItems.filter(x => x.mediaType === MediaTypeEnum.image).length >= minimumImages);
            setVideosComplete(mediaItems.filter(x => x.mediaType === MediaTypeEnum.video || x.mediaType === MediaTypeEnum.embedVideo).length >= minimumVideos);
            setVirtualToursComplete(mediaItems.filter(x => x.mediaType === MediaTypeEnum.virtualTour).length >= 1);
        }
    }, [mediaItems, isMediaItemSliceLoading, getImages, getVideos, getVirtualTours]);

    const uploadMediaCallBack = useCallback((newMediaItems: IMediaItem[], uploadedMedia: IMediaItem[], mediaType: MediaTypeEnum) => {
        const uploadMediaFormData = () => {
            const formData = new FormData();
            newMediaItems.forEach(mItem => {
                if (mItem.url === '' && mItem.file) {
                    formData.append("files", mItem.file);
                    formData.append("sequences", mItem.sequence.toString());
                }
            });

            formData.append("container", "profile-wizard-images");
            // id in this case is the profileid
            formData.append("profileId", id);
            formData.append("parentEntityType", EntityTypeEnum.profile);
            formData.append("mediaType", mediaType);
            formData.append("hasAcceptedUsagePolicy", "true");

            if (formData.get('files') != null) {
                //When uploading we should only update SectionsComplete on the Profile document if we have just hit the minimum required num images.
                Promise.all([
                    isMediaComplete(ProfileFormPageEnum.media)
                ]).then(returnValues => {
                    const isCompleteBefore = returnValues[0] as boolean;
                    dispatch(uploadMedia({mediaType: mediaType, formData: formData })).then(() => {
                        const isCompleteAfter = isMediaComplete(ProfileFormPageEnum.media);
                        if (!isCompleteBefore && isCompleteAfter) {
                            Promise.all([
                                dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.media, value: true })),
                                dispatch(updateProfileFromStore())
                            ])
                        }
                    })
                })
            }
        }
        if (id && newMediaItems.length > uploadedMedia.length) {
            uploadMediaFormData();
        }
    }, [dispatch, id]);

    const deleteMediaCallBack = useCallback((selectedMedia: IMediaItem[], mediaType: MediaTypeEnum) => {
        const payload: IDeleteMediaPayload = { mediaItems: selectedMedia, showToast: true, mediaType: mediaType };
        let mediaItemsTemp: IMediaItem[] = _.cloneDeep(mediaItems);
        selectedMedia.forEach(image => {
            const index = mediaItemsTemp.findIndex(x => x.id === image.id);
            mediaItemsTemp.splice(index, 1);
        });

        //If we are updating images, we need to update SectionsComplete. But we should only update the DB if there has been a change, ie. delete below the minimum required images.
        if (mediaType === MediaTypeEnum.image) {
            const wasComplete = isMediaComplete(ProfileFormPageEnum.media)
            Promise.all([
                dispatch(deleteMedia(payload)),
                dispatch(UpdateMedia_Reducer({mediaItems: mediaItemsTemp, mediaType: mediaType}))
            ]).then(() => {
                const isCompleteNow = isMediaComplete(ProfileFormPageEnum.media);
                if (wasComplete && !isCompleteNow) {
                    Promise.all([
                        dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.media, value: false })),
                        dispatch(updateProfileFromStore())
                    ])
                }
            });
        }
        else {
            dispatch(deleteMedia(payload));
            dispatch(UpdateMedia_Reducer({mediaItems: mediaItemsTemp, mediaType: mediaType}));
        }
    }, [dispatch, mediaItems])

    return (
        !isMediaItemSliceLoading ?
        <React.Fragment>
            <ProfileImagesCard isProfileForm={true} title={"Images"} mediaCount={getMediaCount(getImages)} uploadCallback={uploadMediaCallBack}
            subtitle={`Add at least ${minimumImages} images to publish your profile and drag to reorder however you like.`} 
            availableLanguages={availableLanguages} isEnhanced={isEnhanced} isImagesEditable={true} isCompleted={imagesComplete} 
            profileName={name} saveCallback={saveCallback} mediaPayload={images} deleteCallback={deleteMediaCallBack}/>
        {
            isEnhanced && 
            <React.Fragment>
            <MediaVideosCard title={"Videos"} subtitle={"Upload from your computer or embed video URLs from YouTube or Vimeo. Supported file types: .WEBM, .MP4, .MOV. The size limit is 500MB per video file."} 
                languageCodes={availableLanguages} isEnhanced={isEnhanced} saveCallback={saveCallback} mediaPayload={videos} 
                isImagesEditable={true} isCompleted={videosComplete} isProfileForm={true} deleteCallback={deleteMediaCallBack} 
                uploadCallback={uploadMediaCallBack} mediaCount={getMediaCount(getVideos)}
                />
            <MediaVirtualToursCard title={"Virtual Tours"} deleteCallback={deleteMediaCallBack}
                subtitle={"Virtual Tours should be a standalone HTML viewer (such as Google Street View or an HTML5 panorama). Avoid using older styles of panorama such as Flash videos which are not supported on mobile devices and modern browsers."} 
                languageCodes={availableLanguages} isEnhanced={isEnhanced} saveCallback={saveCallback} mediaPayload={virtualTours} 
                isMediaEditable={true} isCompleted={virtualToursComplete} isProfileForm={true} mediaCount={getMediaCount(getVirtualTours)} />
            <MediaAppearancesCard />
            </React.Fragment>
        }
        </React.Fragment>
        : null
    )
}