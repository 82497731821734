export enum ProfileFormPageEnum {
    none = "",
    basicInfo = "BasicInfo",
    fastFacts = "FastFacts",
    media = "Media",
    sleepingArrangements = "SleepingArrangements",
    facilities = "Facilities",
    contactDetails = "ContactDetails",
    location = "Location",
    addedServices = "AddedServices",
    rates = "Rates",
    documents = "Documents",
    integrations = "Integrations"
}

export interface ChecklistSection {
    label: string;
    id: ProfileFormPageSectionEnum;
    page: ProfileFormPageEnum;
    isEnhancedOnly: boolean;
    isCompleted: boolean;
}

export enum ProfileFormPageSectionEnum {
    images = "ProfileImages",
    videos = "ProfileVideos",
    virtualTours = "ProfileVirtualTours",
    appearances = "ProfileAppearances",
    facilitiesServices = "FacilitiesServices",
    facilitiesProperty = "FacilitiesProperty",
    facilitiesActivitiesOnSite = "FacilitiesActivitiesOnSite",
    facilitiesActivitiesNearby = "FacilitiesActivitiesNearby",
    facilitiesSleepingArrangements = "FacilitiesSleepingArrangements",
    integrations = "Integrations",
    gdsCodes = "GDSCodes",
    enquiries = "Enquiries",
    contacts = "Contacts",
    socialMedia = "SocialMedia",
    documents = "Documents"
}

export interface Insights {
    title: string;
    titleIcon: string;
    description: string[];
}

export enum ProfileFacilitiesSectionEnum {
    property = "Property",
    activitiesOnSite = "Activities On Site",
    activitiesNearby = "Activities Nearby",
    services = "Services",
    sleepingArrangments = "Rooms",
    fastFacts = "Fast Facts"
}
