import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CheckboxAccordion } from 'components/checkbox.accordion/checkbox.accordion';
import { featuresData } from 'app/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { IFeature } from 'components/profile/types';
import { UpdateProfileFeatures } from 'components/profile/profile.slice';
import './facilities.scss';

interface IProps {
    featureDataSectionName: string;
    labels?: string[];
}

export const Facilities = ({featureDataSectionName, labels}:IProps) =>{
    const { features } = useSelector((state: RootState) => state.profileSlice.profile);   
    const dispatch = useDispatch();
    const data = featuresData[featureDataSectionName];
    const accordionCallBack = (checked: boolean, facility: IFeature) => {
        let updatedFeatures = [];
      
        if(checked){          
            updatedFeatures = [...features, facility]
          } else {
            updatedFeatures = [...features];
            const index = updatedFeatures.findIndex(x => x.id === facility.id);
            if (index > -1) {   
              updatedFeatures.splice(index, 1);
            }
          }
          dispatch(UpdateProfileFeatures(updatedFeatures));
    }

    return (    
        <Container fluid className="padding-0">
            <Row className="margin-20">
              <Col>
                {
                  Object.entries(data).map((child, i) => ( 
                      <CheckboxAccordion title={child[0]} label={(labels && labels[i]) ? labels[i] : ''} content={child[1]} fixedCount={6} features={features} key={child[0]} accordionCallBack={accordionCallBack} />                    
                  ))
                }
              </Col>
            </Row>           
            <br />
        </Container>
      )
  }