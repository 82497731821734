import { getApiBaseUrl } from 'app/config';
import axios from 'axios';
import { IMediaIEmbed, IMediaItem } from 'components/profile/types';

const baseProfileUrl = getApiBaseUrl() + 'media/';

export async function uploadProfileEmbedVideos(payload: IMediaIEmbed) {
  return axios.post<IMediaItem[]>(baseProfileUrl + "UploadProfileEmbedVideos", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*'     
      }
  });
}

export async function uploadProfileMedia_service(payload: FormData) {
    return axios.post<IMediaItem[]>(baseProfileUrl + "UploadProfileMedia", payload, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'content-type': 'multipart/form-data'
        }
    });
  }
  
  export async function deleteProfileMedia_service(payload: IMediaItem[]) {
    return axios.post<any>(baseProfileUrl + "DeleteProfileMedia", payload, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
    });
  }

  export async function updateProfileMedia_service(payload: IMediaItem[]) {
    return axios.post<IMediaItem[]>(baseProfileUrl + "UpdateProfileMedia", payload, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
    });
  }

  export async function getMediaItems_service(profileId: string) {
    return axios.get<IMediaItem[]>(baseProfileUrl + `GetMediaItems?profileId=${profileId}`, { 
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
    }).catch(function (error) {
      if (error.response) {
        console.error('getMediaItems_service error.response.data: ', error.response.data);
        console.error('getMediaItems_service error.response.status: ', error.response.status);
      }
    });
  }