import React,  {useState, useEffect, useCallback} from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import './wizard.step-8.scss';
import { RootState } from 'app/root-reducer';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateProfileCheckinTimeFrom, UpdateProfileCheckinTimeTo, UpdateProfileCheckoutTimeFrom, UpdateProfileCheckoutTimeTo, UpdateProfileContact, UpdateProfileRoomCount, UpdateProfileStarRating } from 'components/profile/profile.slice';
import { WizardCallback } from 'components/wizard/types';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import { IContact, ContactTypeEnum, ITime } from '../../../../components/profile/types';
import { EmailInput } from "components/email-input/email-input";
import { ShowMore } from '../../../../components/show.more/show.more';
import { TimePicker } from '../../../../components/time.picker/time.picker';
import { NumberStepper } from '../../../../components/number.stepper/number.stepper';
import { StarRating } from '../../../../components/star.rating/star.rating';

const WizardStep8Page = ({callBack}: WizardCallback) => {
    const { contacts, checkinTimeFrom, checkinTimeTo, checkoutTimeFrom, checkoutTimeTo, roomCount, starRating } = useSelector((state: RootState) => state.profileSlice.profile);
    const dispatch = useDispatch();
    const getTelephone = (contactType: ContactTypeEnum) => {
      const existingContact = contacts.find(x => x.type === contactType);
      if (existingContact) {
        return existingContact.telephone;
      }
      return null;
    }
    const getCountryCode = (contactType: ContactTypeEnum) => {
      const existingContact = contacts.find(x => x.type === contactType);
      if (existingContact && existingContact.countryCode && parseInt(existingContact.countryCode) > 0) {
        return existingContact.countryCode;
      }
      return '';
    }
    const getEmail = () => {
      const existingContact = contacts.find(x => x.type === ContactTypeEnum.frontDesk);
      if (existingContact && existingContact.email) {
        return existingContact.email;
      }
      return '';
    }
    const getDialCode = (contactType: ContactTypeEnum) => {
      const existingContact = contacts.find(x => x.type === contactType);
      if (existingContact) {
        return existingContact.dialCode;
      }
      return null;
    }
    
    const [countryCode, setCountryCode] = useState<string | number | undefined>(getCountryCode(ContactTypeEnum.frontDesk));
    const [reservationCountryCode] = useState<string | number | undefined>(getCountryCode(ContactTypeEnum.reservation));
    const [telephone, setTelephone] = useState<string | null | undefined>(getTelephone(ContactTypeEnum.frontDesk));
    const [reservationTelephone, setReservationTelephone] = useState<string | null | undefined>(getTelephone(ContactTypeEnum.reservation));
    const [email, setEmail] = useState<string>(getEmail());
    const [showReservation, setShowReservation] = useState<boolean>(false);
    const [checkinTimeFromLocal, setCheckinTimeFromLocal] = useState<ITime>(checkinTimeFrom);
    const [checkinTimeToLocal, setCheckinTimeToLocal] = useState<ITime>(checkinTimeTo);
    const [checkoutTimeFromLocal, setCheckoutTimeFromLocal] = useState<ITime>(checkoutTimeFrom);
    const [checkoutTimeToLocal, setCheckoutTimeToLocal] = useState<ITime>(checkoutTimeTo);
    const [roomCountLocal, setRoomCountLocal] = useState(roomCount);
    const [rating, setRating] = useState(starRating);
    const [frontDeskDialCode, setFrontDeskDialCode] = useState<string | null | undefined>(getDialCode(ContactTypeEnum.frontDesk));
    const [reservationDialCode, setReservationDialCode] = useState<string | null | undefined>(getDialCode(ContactTypeEnum.reservation));

    const updatePhone = (cCode: string, phone: string, dialCode: string) => {
      const existingContact: IContact = contacts.find(x => x.type === ContactTypeEnum.frontDesk) as IContact;
      if (existingContact) {
        const contactCopy = {...existingContact, countryCode: cCode, dialCode: dialCode, telephone: phone};
          setTelephone(phone);
          setCountryCode(cCode);
          setFrontDeskDialCode(dialCode);
          dispatch(UpdateProfileContact(contactCopy));
      } 
      else {
        const contact: IContact = { type: ContactTypeEnum.frontDesk, countryCode: cCode, dialCode: dialCode, telephone: phone};
        if (contact.telephone) {
          setTelephone(contact.telephone);
          setFrontDeskDialCode(contact.dialCode);
        }
        if (contact.countryCode) {
          setCountryCode(contact.countryCode);
        }
        dispatch(UpdateProfileContact(contact));
      }
    }

    const updateResPhone = (cCode: string, phone: string, dialCode: string) => {
      const existingContact: IContact = contacts.find(x => x.type === ContactTypeEnum.reservation) as IContact;
      if (existingContact) {
          const contactCopy = {...existingContact, countryCode: cCode, dialCode: dialCode, telephone: phone};
          setReservationTelephone(phone);
          setReservationDialCode(dialCode);
          dispatch(UpdateProfileContact(contactCopy));
      } 
      else {
        const contact: IContact = {type: ContactTypeEnum.reservation, telephone: phone, dialCode: dialCode};
        if (contact.telephone) {
          setReservationTelephone(contact.telephone);
          setReservationDialCode(dialCode);
          
        }
        dispatch(UpdateProfileContact(contact));
      }
    }

    const updateEmail = (updateValue: string | boolean, name: string) => {
      const existingContact: IContact = contacts.find(x => x.type === ContactTypeEnum.frontDesk) as IContact;
      if (existingContact) {
        const contactCopy = {...existingContact, email: updateValue};
        dispatch(UpdateProfileContact(contactCopy));
      }
      else {
        const contact: IContact = {type: ContactTypeEnum.frontDesk, email: updateValue !== false ? updateValue.toString() : ''};
        setEmail(updateValue as string);
        dispatch(UpdateProfileContact(contact));
      }
    }

    const timePickerCallbackCheckinFrom = (time: ITime) => {
      dispatch(UpdateProfileCheckinTimeFrom(time));
      setCheckinTimeFromLocal(time);
    }
   
    const timePickerCallbackCheckinTo = (time: ITime) => {
      dispatch(UpdateProfileCheckinTimeTo(time));
      setCheckinTimeToLocal(time);
    }

    const timePickerCallbackCheckoutFrom = (time: ITime) => {
      dispatch(UpdateProfileCheckoutTimeFrom(time));
      setCheckoutTimeFromLocal(time);
    }
   
    const timePickerCallbackCheckoutTo = (time: ITime) => {
      dispatch(UpdateProfileCheckoutTimeTo(time));
      setCheckoutTimeToLocal(time);
    }

    const showRes = () => {
      setShowReservation(true);
    }

    const countCallback = useCallback((count: number) => {
      
      dispatch(UpdateProfileRoomCount(count));
      setRoomCountLocal(count);
    }, [dispatch]);
  
    const ratingCallback = useCallback((rating: number) => {
      dispatch(UpdateProfileStarRating(rating));
      setRating(rating);
    }, [dispatch]);

    const isPhoneValid = (phone: string | undefined, dialCode: string | null | undefined) => {
      return phone && dialCode && phone.length > dialCode?.length;
    }

    useEffect(() => {
      if (reservationTelephone) {
        setShowReservation(true);
      }
    }, [reservationTelephone] );

    useEffect(() => {
      const isCompleted = () => {
        const frontDeskContact: IContact = contacts.find(x => x.type === ContactTypeEnum.frontDesk) as IContact;
        const reservationContact: IContact = contacts.find(x => x.type === ContactTypeEnum.reservation) as IContact;
        if ((frontDeskContact && isPhoneValid(frontDeskContact?.telephone, frontDeskDialCode)) ||
          (reservationContact && isPhoneValid(reservationContact?.telephone, reservationDialCode))) {            
          return true;
        }
        return false;
      }
      callBack({id: 8, completed: isCompleted(), skip: false, displayFooterNavigation: true, displayNext: true});
    }, [callBack, contacts, roomCount, frontDeskDialCode, reservationDialCode]);

  return (
    <Container fluid>
      <Row className="padding-top-20">
        <Col xs="3">
        </Col>
        <Col xs="6">
          <Card border="dark">
            <Card.Body>
              <Card.Title>Property information</Card.Title>
              <Card.Subtitle className="padding-top-10">FRONT DESK</Card.Subtitle>
              <Form.Group>
                  <Form.Label><b>Telephone Number</b></Form.Label>
                  <PhoneInput
                      country={countryCode}
                      value={telephone}
                      onChange={(telephone, country) => { if ('countryCode' in country) updatePhone(country.countryCode, telephone, country.dialCode); }}
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      defaultMask=".. ... ...."
                      countryCodeEditable={true}
                  />
              </Form.Group>
              <Form.Group className="padding-top-10">
                  <Form.Label><b>Email address</b></Form.Label>
                  <EmailInput 
                    email={email}
                    validationEvent={"onBlur"}
                    callBack = {updateEmail}
                    showLabel = {false}
                  />
              </Form.Group>
              <div className="padding-top-10">
              <ShowMore label='+ Add Reservation number' callback={showRes} show={showReservation}>
                <Card.Subtitle className="padding-top-10">RESERVATION NUMBER</Card.Subtitle>

                <Form.Label><b>Telephone Number</b></Form.Label>
                  <PhoneInput
                      country={reservationCountryCode}
                      value={reservationTelephone}
                      onChange={(telephone, country) => { if ('countryCode' in country) updateResPhone(country.countryCode, telephone, country.dialCode); }}
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      defaultMask=".. ... ...."
                      countryCodeEditable={true}
                  />
                </ShowMore>
                </div>
                <Card.Subtitle className="padding-top-40">CHECK IN</Card.Subtitle>
                <Container fluid className="padding-top-10">
                  <Row>
                    <Col xs="5">
                      <Form.Group>
                        <Form.Label><b>From</b></Form.Label>
                        <TimePicker callback={ timePickerCallbackCheckinFrom } time={checkinTimeFromLocal} />
                      </Form.Group>
                    </Col>
                    <Col xs="1" className="separator-column"> - </Col>
                    <Col xs="5">
                      <Form.Group>
                        <Form.Label><b>Until</b></Form.Label>
                        <TimePicker callback={ timePickerCallbackCheckinTo } time={checkinTimeToLocal} />
                      </Form.Group>
                    </Col>
                  </Row>
                  </Container>
                  <Card.Subtitle className="padding-top-20">CHECK OUT</Card.Subtitle>
                  <Container fluid className="padding-top-10">
                  <Row className="">
                    <Col xs="5">
                      <Form.Group>
                        <Form.Label><b>From</b></Form.Label>
                        <TimePicker callback={ timePickerCallbackCheckoutFrom } time={checkoutTimeFromLocal} />
                      </Form.Group>
                    </Col>
                    <Col xs="1" className="separator-column"> - </Col>
                    <Col xs="5">
                      <Form.Group>
                        <Form.Label><b>Until</b></Form.Label>
                        <TimePicker callback={ timePickerCallbackCheckoutTo } time={checkoutTimeToLocal} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <Form.Group  className="padding-top-20">
                  <Form.Label><b>Number of rooms</b></Form.Label>
                  <NumberStepper title={""} callBack={countCallback} initialCount={roomCountLocal} />
              </Form.Group>
              <Form.Group className="padding-top-20">
                  <Form.Label><b>Star rating</b></Form.Label>
                  <StarRating starAmount={7} callBack={ratingCallback} starRating={rating} />
              </Form.Group>
              </Card.Body>
          </Card>
        </Col>
        <Col xs="3">
        </Col>
      </Row>
    </Container>
  )
}

export default WizardStep8Page