import { MultiSelect } from 'components/multi-select/multiselect';
import { IMultiSelectData } from 'components/profile/types';
import React from 'react';
import { Card } from 'react-bootstrap';
import './multi.select.repeater.scss';

interface IProps {
    id: string;
    title: string;
    dropDownDefaultText: string;
    multiSelectData: IMultiSelectData[];
    multiSelectCallback: (data: IMultiSelectData[]) => void;
    children: React.ReactNode;
    hasSelectAll?: boolean;
    
    //Parameters to prompt the user to confirm before de-selecting items which would otherwise be deleted:
    confirmDeselectCallback?: (data: IMultiSelectData[]) => void;
    getNumDeselectMediaItemsCallback?: (data: IMultiSelectData[]) => number;
    confirmDeselectOkText?: string;
    confirmDeselectCancelText?: string;
    confirmDeselectTitleText?: string;
    confirmDeselectBodyText?: string;       //"Delete {n} {document}?"  converted to eg. "Delete 3 documents"
}

export const MultiSelectRepeater = ({id, title, dropDownDefaultText, multiSelectData, multiSelectCallback, children, hasSelectAll,
    confirmDeselectCallback, confirmDeselectOkText, confirmDeselectCancelText, confirmDeselectTitleText, confirmDeselectBodyText,
    getNumDeselectMediaItemsCallback}: IProps) => {
    
    const multiSelectCallbackLocal = (data: IMultiSelectData[]) => {
        multiSelectCallback(data);
    }

    return(
        <Card id={id} border="dark" className="multi-section-card">
            <Card.Body>
                <div className="multi-section-card-title">
                <Card.Title>{title}</Card.Title>
                {
                    multiSelectData.length > 0 && 
                        <MultiSelect data={multiSelectData} title={dropDownDefaultText} multiSelectCallback={multiSelectCallbackLocal} 
                            hasSelectAll={hasSelectAll} confirmDeselectBodyText={confirmDeselectBodyText} 
                            confirmDeselectCallback={confirmDeselectCallback} confirmDeselectCancelText={confirmDeselectCancelText}
                            confirmDeselectOkText={confirmDeselectOkText} confirmDeselectTitleText={confirmDeselectTitleText}
                            getNumDeselectMediaItemsCallback={getNumDeselectMediaItemsCallback}
                        />
                }
                </div>
                {
                    children
                }
            </Card.Body>
        </Card>
    )
}