import { languageDictionary } from 'app/config';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';

interface IProps {
    showModal?: boolean;
    title?: string;
    body?: string;
    declineButtonText?: string;
    acceptButtonText?: string;
    languageValues: string[];
    callBack: (outcome: boolean, values: string[]) => void
}

export const LanguagesModal = ({languageValues, title = '', showModal = false, body = '', callBack, declineButtonText = 'Close', acceptButtonText = 'Save changes'}: IProps) => {
    
    const [show, setShow] = useState(showModal);
    const [languages, setLanguages] = useState<string[]>(languageValues);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    const acceptButtonClick = () => {
        setShow(false);
        callBack(true, languages);
    }

    const declineButtonClick = () => {
        setShow(false);
        setLanguages(languageValues);
        callBack(false, languages);
    }

    const handleLanguageClick = (checked: boolean, value: string) => {
        if (checked) {
            setLanguages(values => [...values, value]);
        } else {
            const remainingLanguages = languages.filter(x => x !== value);
            setLanguages(remainingLanguages);
        }
    }
    
    return (
        <Modal show={show} onHide={() => declineButtonClick()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                        {body}
                        </Col>
                    </Row>
                    <Row>
                    {
                        Object.keys(languageDictionary).map((languageCode, index) => 
                        <Col key={index} xs={6}>
                            <Form.Check id={languageCode} onChange={(event: any) => handleLanguageClick(event.target.checked, languageCode)} checked={languages.find(x => x === languageCode) ? true : false} disabled={languageCode === "en"} type={'checkbox'} label={languageDictionary[languageCode]} />
                        </Col>)
                    }
                    </Row>
                </Container>
                
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => declineButtonClick()} variant="secondary">{declineButtonText}</Button>
                <Button onClick={() => acceptButtonClick()} variant="primary">{acceptButtonText}</Button>
            </Modal.Footer>
        </Modal>
    )
}