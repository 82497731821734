import React from "react";
import { Button } from "react-bootstrap";
import { BsFillCaretDownFill } from 'react-icons/bs';
import './profile.status.dropdown.toggle.scss';

type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {};
    selectedValue: string;
  };

export const ProfileStatusDropdownToggle = React.forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button
      ref={ref}
      variant="light"
      onClick={e => {
        e.preventDefault();
        if (props.onClick) {
          props.onClick(e);
        };
      }}
    >
      <div className="profile-status-toggle-button">
      {props.selectedValue}<BsFillCaretDownFill />
      {props.children}
      </div>
    </Button>
));