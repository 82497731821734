import React from 'react';
import landingImage from './images/landingImage.png'; 
import { Button } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import './profile-empty.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { create } from 'components/profile/profile.slice';
import { ResetProfileItem } from 'components/profile/profileitem/profileitem.slice';
import {initialCreateProfile } from 'components/profile/types';

const ProfileEmpty = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createProfileClick = () => {
    dispatch(create(initialCreateProfile));
    dispatch(ResetProfileItem());
    history.push('/profiles/create/1');
  }

  return (
    <div className="profile-empty-page" >
      <Container fluid className="canvas">
          <div className="profile-empty-page-introduction">
            <img src={landingImage} alt=""></img>
            <h4>Add and manage your profiles</h4>
            <div>This is where you'll add profiles and manage your content</div>
            <br />
            <div className="display-flex">
              <Button className="button-margin" variant="primary" onClick={() => createProfileClick()}>Create Profile</Button>
            </div>
          </div>
        </Container>
    </div>
  )
}

export default ProfileEmpty