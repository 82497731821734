import { StarIcon } from '../star.icon/star.icon';
import React from 'react';

interface IProps {
    index: number,
    rating: number,
    hoverRating: number,
    onMouseEnter: (index: number) => void,
    onMouseLeave: () => void,
    onSaveRating: (index: number) => void
}
export const RatingIcon = ({index, rating, hoverRating, onMouseEnter, onMouseLeave, onSaveRating}: IProps) => {
    const fill = React.useMemo(() => {
        if(hoverRating >= index) {
            return '#FAD63D';
        } else if(!hoverRating && rating >= index) {
            return '#FAD63D';
        }
    }, [rating, hoverRating, index]);
    return (
        <div onMouseEnter={() => onMouseEnter(index)} onMouseLeave={() => onMouseLeave()} onClick={() => onSaveRating(index)} >
            <StarIcon color={fill} />
        </div>
    )
}