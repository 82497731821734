import React, { useState } from 'react'
import './profile-list.scss';
import { Button, Alert } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { IProfileSummary } from 'components/profile/types';
import { ProfileGrid } from 'components/profile.grid/profile.grid';
import { useDispatch } from 'react-redux';
import { create } from 'components/profile/profile.slice';
import { ResetProfileItem } from 'components/profile/profileitem/profileitem.slice';
import {initialCreateProfile } from 'components/profile/types';

interface ProfileListProps {
  name: string;
  profileSummaries: IProfileSummary[]
} 

export const ProfileList = ({name, profileSummaries}: ProfileListProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const createProfileClick = () => {
      dispatch(create(initialCreateProfile));
      dispatch(ResetProfileItem());
      history.push('/profiles/create/1');
    }
    
    return (
      <div className="profile-list-page" >
        <Container fluid className="canvas">
          <div className="profile-list-page-introduction">
            <h4>My profiles</h4>
            <Button className="button-margin" variant="primary" onClick={() => createProfileClick()}>Create Profile</Button>
          </div>
          <br/>
          { (show) &&
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
            <Alert.Heading className="alert-header-warning"><BsFillExclamationTriangleFill className="warning-icon"/>This profile is currently being reviewed:</Alert.Heading>
              <ul>
                <li>{name} is currently being reviewed.  The Supplier Success team will be in touch shortly.</li>
              </ul>
          </Alert>
          }
          <ProfileGrid profileSummaries={profileSummaries}></ProfileGrid>
        </Container>
      </div>
    )
}

export default ProfileList