import React, { useState, useCallback, useEffect } from 'react';
import { RootState } from 'app/root-reducer';
import { useSelector } from 'react-redux';
import { Container, Form, Card, Row, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { MdLens } from 'react-icons/md';
import { BsInfoCircleFill } from 'react-icons/bs';
import { MultiSelectRepeater } from 'components/multi.select.repeater/multi.select.repeater';
import { IMultiSelectData, DocumentTypeEnum, MediaTypeEnum, IMediaItem, EntityTypeEnum, IUpdateMediaPayload,
     IDeleteMediaPayload, DocumentVisibilityEnum} from 'components/profile/types';
import './documents.page.scss';
import _ from 'lodash';
import { deleteMedia, getDefaultNewDocument, getMediaItems, isMediaComplete, UpdateMedia_Reducer, 
    uploadMedia, UpdateChangedMedia_Reducer } from 'components/profile/mediaitem/mediaitem.slice';
import DropZone from 'components/dropzone/dropzone';
import { DocumentItem } from './features/document.item.feature';
import { ProfileFormPageEnum } from 'pages/profile-form/profile.form.types';
import { useAppDispatch } from 'app/store';
import { updateProfileFromStore, UpdateSectionCompleteWithValue } from 'components/profile/profile.slice';
import { DragSortable } from 'components/drag.sortable/drag.sortable';
import { Dictionary } from '@reduxjs/toolkit';
import { LanguagePicker } from 'components/language.picker/language.picker';
import { languageDictionary } from 'app/config';

interface IProps {
    isCompleted: boolean;
}

export const DocumentsPage = ({isCompleted}: IProps) => {
    const { profile } = useSelector((state: RootState) => state.profileSlice);
    const { mediaItems, isMediaItemSliceLoading } = useSelector((state: RootState) => state.mediaItemSlice);
    const [ multiSelectDataDocuments, setMultiSelectDataDocuments ] = useState<IMultiSelectData[]>([]);
    const [ uploadedImages ] = useState<IMediaItem[]>([]);
    const [ currentLanguageCode, setCurrentLanguageCode ] = useState<string>('en');
    const [ languageCompletionDots, setLanguageCompletionDots ] = useState<Dictionary<boolean>>({});    //includes 'en'
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isLoadingSection, setIsLoadingSection ] = useState<string>('');
    const dispatch = useAppDispatch();

    const getDocuments = useCallback(() => {
        return mediaItems.filter(x => x.mediaType === MediaTypeEnum.documentation);
    }, [mediaItems])

    const getDocumentCount = () => {
        return getDocuments()?.length;
    }

    const getDocumentsByType = (documentType: DocumentTypeEnum): IMediaItem[] => {
        return getDocuments()?.filter(x => x.documentType === documentType);
    }

    const updateLanguagePickerDots = useCallback((documents: IMediaItem[]) => {
        //(for document label) if all documents have eg. a German translation set the dot to blue. Otherwise grey.
        setLanguageCompletionDots(existingItems => {
            if (existingItems) {
                let itemsCopy = _.cloneDeep(existingItems);
                profile.availableLanguages?.forEach((languageCode) => {
                    let languageDocs = documents.filter(x => x.label.translations[languageCode]);
                    itemsCopy[languageCode] = documents.length === languageDocs.length;
                });
                let languageDocs = documents.filter(x => x.label.enText);
                itemsCopy['en'] = documents.length === languageDocs.length;
                return itemsCopy;
            }
            return {};
        });
    }, [profile.availableLanguages]);

    useEffect(() => {
        //Load MediaItems from API
        if (profile.id) {
            dispatch(getMediaItems(profile.id));
            setCurrentLanguageCode(existing => {
                if (!existing) {
                    return 'en';
                }
                return existing;
            });
        }
    }, [dispatch, profile.id]);

    useEffect(() => {
        //Set completion dots for the language tabs.
        if (!isMediaItemSliceLoading) {
            updateLanguagePickerDots(getDocuments());
        }
    }, [mediaItems, getDocuments, isMediaItemSliceLoading, updateLanguagePickerDots])

    useEffect(() => {
        //Set multiSelectDataDocuments, used by MultiSelectRepeater.
        const getInitMultiSelectData = () : IMultiSelectData[] => {
            const initMultiSelectData: IMultiSelectData[] = [];
            const docs = getDocuments();
            if (profile.isEnhanced) {
                for (let i = 0; i < Object.keys(DocumentTypeEnum).length; i++) {
                    if (Object.values(DocumentTypeEnum)[i] !== DocumentTypeEnum.none) {
                        initMultiSelectData.push({
                            title: getDocumentsEnumUiLabel(Object.values(DocumentTypeEnum)[i]),
                            subtitle: '',
                            isChecked: !!docs.find(x => x.documentType === Object.values(DocumentTypeEnum)[i].toString()),
                            key: Object.values(DocumentTypeEnum)[i],
                        });
                    }
                }
            }
            else {
                initMultiSelectData.push({
                    title: getDocumentsEnumUiLabel(DocumentTypeEnum.covidNotice),
                    subtitle: '',
                    isChecked: true,
                    key: DocumentTypeEnum.covidNotice
                })
            }
            return initMultiSelectData;
        }
        
        if (multiSelectDataDocuments.length === 0 && !isMediaItemSliceLoading) {
            setMultiSelectDataDocuments(getInitMultiSelectData());
        }
    }, [mediaItems, multiSelectDataDocuments.length, getDocuments, isMediaItemSliceLoading, profile.isEnhanced])
    
    const getDocumentsEnumUiLabel = (documentsEnum: DocumentTypeEnum) => {
        switch (documentsEnum) {
            case DocumentTypeEnum.covidNotice:
                return "COVID-19 notice";
            case DocumentTypeEnum.factSheet:
                return "Fact sheet";
            case DocumentTypeEnum.foodAndBeverage:
                return "Food and beverage";
            case DocumentTypeEnum.faqs:
                return "FAQs";
            case DocumentTypeEnum.otherGeneral:
                return "Other / General"
            default:
                return documentsEnum;
        }
    }

    const getExistingDocument = (documentType: string): IMediaItem | undefined => {
        return getDocuments().find(x => x.documentType === documentType);
    }

    const multiSelectCallback = (data: IMultiSelectData[]) => {
        //User clicked Done in the MultiSelect control.
        let deselectedItems: IMediaItem[] = [];
        multiSelectDataDocuments?.filter(x => x.isChecked)?.forEach((item) => {
            if (!data.find(x => x.key === item.key)?.isChecked) {
                deselectedItems.push(...getDocuments()?.filter(x => x.documentType === item.key));
            }
        })
        setMultiSelectDataDocuments(data);
    }

    const uploadCallBackLocal = useCallback((mediaItems: IMediaItem[]) => {
        const docType: DocumentTypeEnum = mediaItems?.length > 0 ? mediaItems[0].documentType : DocumentTypeEnum.covidNotice;
        setIsLoading(true);
        setIsLoadingSection(docType);
        let documentsCopy = _.cloneDeep(getDocuments());
        documentsCopy.push(getDefaultNewDocument(docType));
        const uploadMediaFormData = () => {
            const formData = new FormData();
            mediaItems.forEach(mItem => {
                if (mItem.url === '' && mItem.file) {
                    formData.append("files", mItem.file);
                    formData.append("sequences", mItem.sequence.toString());
                }
            });

            formData.append("container", "profile-wizard-images");
            formData.append("profileId", profile.id);
            formData.append("parentEntityType", EntityTypeEnum.profile);
            formData.append("mediaType", MediaTypeEnum.documentation);
            formData.append("documentType", docType);
            formData.append("hasAcceptedUsagePolicy", "true");

            if (formData.get('files') != null) {
                //When uploading we should only update SectionsComplete on the Profile document if we have just hit the minimum required num images.
                Promise.all([
                    isMediaComplete(ProfileFormPageEnum.documents)
                ]).then(returnValues => {
                    const isCompleteBefore = returnValues[0] as boolean;
                    dispatch(uploadMedia({mediaType: MediaTypeEnum.documentation, formData: formData })).then(() => {
                        const isCompleteAfter = isMediaComplete(ProfileFormPageEnum.documents);
                        if (!isCompleteBefore && isCompleteAfter) {
                            Promise.all([
                                dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.documents, value: true })),
                                dispatch(updateProfileFromStore())
                            ])
                        }
                    })
                })
            }
        }
        if (mediaItems.length > 0) {
            uploadMediaFormData();
        }
    }, [dispatch, profile.id, getDocuments]);

    const isDocumentsDifferent = (mediaItem1: IMediaItem, mediaItem2: IMediaItem): boolean => {
        return mediaItem1.documentType !== mediaItem2.documentType
        || JSON.stringify(mediaItem1.label) !== JSON.stringify(mediaItem2.label)
        || mediaItem1.visibility !== mediaItem2.visibility;
    }

    const documentItemUpdateCallback = useCallback((document: IMediaItem) => {
        let documentsCopy = _.cloneDeep(getDocuments());
        for (let i = 0; i < documentsCopy.length; i++) {
            if (documentsCopy[i].id === document.id) {
                if (isDocumentsDifferent(documentsCopy[i], document)) {
                    document.isChanged = true;
                }
                documentsCopy[i] = document;
            }
        }

        const updatePayload : IUpdateMediaPayload = {mediaItems: documentsCopy, mediaType: MediaTypeEnum.documentation };
        dispatch(UpdateMedia_Reducer(updatePayload));   //Just update the slice. We save the slice data to the DB when they hit Save.
        updateLanguagePickerDots(documentsCopy);

    }, [dispatch, getDocuments, updateLanguagePickerDots])

    const deleteMediaItems = useCallback((mItems: IMediaItem[]) => {
        let mediaItemsTemp: IMediaItem[] = _.cloneDeep(mediaItems);
        
        mItems.forEach(mItem => {
            const index = mediaItemsTemp.findIndex(x => x.id === mItem.id);
            mediaItemsTemp.splice(index, 1);
        });

        const payload: IDeleteMediaPayload = { mediaItems: mItems, showToast: true, mediaType: MediaTypeEnum.documentation };
        const isCompleteBefore = isMediaComplete(ProfileFormPageEnum.documents);
        Promise.all([
            dispatch(deleteMedia(payload)),
            dispatch(UpdateMedia_Reducer({mediaItems: mediaItemsTemp, mediaType: MediaTypeEnum.documentation}))
        ]).then(() => {
            const isCompleteNow = isMediaComplete(ProfileFormPageEnum.documents);
            if (isCompleteBefore && !isCompleteNow) {
                Promise.all([
                    dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.documents, value: false })),
                    dispatch(updateProfileFromStore())
                ])
            }
        });
        dispatch(deleteMedia(payload));
    }, [dispatch, mediaItems])

    const deleteCallback = useCallback((document: IMediaItem) => {
        //Callback from DocumentItem delete button
        deleteMediaItems([document]);
    }, [deleteMediaItems])

    const getDeselectedMediaItemForDelete = useCallback((data: IMultiSelectData[]): IMediaItem[] => {
        //When user de-selects items in the MultiSelectRepeater we need to confirm they want to delete, then delete objects associated with these list items (IMediaItem).
        let mediaItemsToDelete: IMediaItem[] = [];
        data.forEach((item) => {
            const mediaItemsTemp: IMediaItem[] = mediaItems.filter(x => x.documentType?.toString() === item.key);
            if (mediaItemsTemp) {
                mediaItemsToDelete.push(...mediaItemsTemp);
            }
        });
        return mediaItemsToDelete
    }, [mediaItems])

    const getNumDeselectMediaItemsCallback = useCallback((data: IMultiSelectData[]): number => {
        return getDeselectedMediaItemForDelete(data).length;
    }, [getDeselectedMediaItemForDelete]);

    const confirmDeselectCallback = useCallback((data: IMultiSelectData[]) => {
        //When user de-selects items in the MultiSelectRepeater we need to confirm they want to delete
        const mediaItemsToDelete = getDeselectedMediaItemForDelete(data);
        deleteMediaItems(mediaItemsToDelete);

        //Update local multiSelectDataDocuments, to hide the dropzones.
        setMultiSelectDataDocuments(items => {
            let copy = _.cloneDeep(items);
            data.forEach((item) => {
                let existing = copy.find(x => x.key === item.key);
                if (existing) {
                    existing.isChecked = false;
                }
            })
            return copy;
        });
    }, [deleteMediaItems, getDeselectedMediaItemForDelete]);

    const cardDropCallback = useCallback((newSequences: number[], documentType: string) => {
        //Used for DragSort of DocumentItems.
        let typeItems = _.cloneDeep(mediaItems.filter(x => x.mediaType === MediaTypeEnum.documentation)
            .filter(x => x.documentType === documentType).sort((a, b) => (a.sequence > b.sequence ? 1 : -1)));
        newSequences.map((item, i) => {
          typeItems[item].isChanged = typeItems[item].sequence !== i ? true : false;
          typeItems[item].sequence = i;
          return item;
        })
        let changedItems = typeItems.filter(x => x.isChanged);
        dispatch(UpdateChangedMedia_Reducer(changedItems));
      }, [dispatch, mediaItems])

    const fileBrowseClick = (files: FileList, documentType: string) => {
        //Adapted from DropZone code.
        let mediaItemsTemp: IMediaItem[] = [];
        let sequenceNumber = mediaItems.length;

        for (let i = 0; i < files.length; i++) {
            mediaItemsTemp.push({file:files[i], isChecked: false, url: '', hasFileSizeWarning: false, id: '', filePath: '', 
            label: {enText: '', translations: {}, machineTranslations: {}}, description: {enText: '', translations: {}, machineTranslations: {}}, 
            profileId: '', entityType: EntityTypeEnum.media, azureContainerName: '', mediaType: MediaTypeEnum.documentation, credit: '', 
            documentType: documentType as DocumentTypeEnum,
            parentId: '', fileSizeKb: 0, width: 0, height: 0, originalFileName: '', sequence: sequenceNumber, isChanged: false,
            visibility: DocumentVisibilityEnum.publicAndTrade});
            sequenceNumber++;
        }
        uploadCallBackLocal(mediaItemsTemp);
    }

    const loadedCallback = useCallback(() => {
        //Set isLoading flag for loading spinner.
        setIsLoading(false);
    }, [])

    const getContent = (item: IMultiSelectData, index: number) => {
        //For Enhanced: The root UI object (with the "Documents" title) is the MultiSelectRepeater.
        //For Basic: The root UI object is a <Card> with same id as the MultiSelectRepeater, so the CSS is scoped to the same top-level object.
        if ((item.isChecked && !getExistingDocument(item.key))) {
            return (
                <div key={`container${index}`} className='document-type-container'>
                    <Form.Label key={`label${index}`} className='document-type-label'>{item.title}</Form.Label>
                    <DropZone key={index} addButtonText={"Add documents"}  
                    dropMediaItems={uploadedImages} invalids={[]} uploadCallBack={uploadCallBackLocal} isEnhanced={profile.isEnhanced}
                    isItemsEditable={false} documentType={item.key as DocumentTypeEnum}
                    languageCodes={profile.availableLanguages} profileName={profile.name}  
                    mediaType={MediaTypeEnum.documentation} mediaCount={getDocumentCount()} confirmPermission={false} />
                </div>
            )
        }
        else if (item.isChecked || (!profile.isEnhanced && getExistingDocument(item.key))) {
            const documentsByType = getDocumentsByType(item.key as DocumentTypeEnum);
             var sectionDocuments = documentsByType.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

            return (
                <div className='document-type-container' key={`document-type-container${index}`}>
                <div className='header-row-2-col'>
                    <Form.Label key={`label${index}`} className='document-type-label'>{item.title}</Form.Label>
                    <Form.Label className='file-upload-link-active'>
                        <Form.File className="my-classname" id="form-file-1" 
                            onChange={(e: any) => fileBrowseClick(e.target.files, item.key)} multiple={true}></Form.File>
                        + Add documents
                    </Form.Label>
                </div>
                <Row className='document-section-header'>
                    <Col xs='7' className="name-of-file">Name of file
                    {profile.availableLanguages?.length > 1 && profile.isEnhanced &&
                     <span> ({languageDictionary[currentLanguageCode]})</span>
                    }
                    </Col>
                    <Col xs='4'>Visibility 
                        <span className='tooltip-container'><OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-document-visibility`} >
                        Public and trade<br />
                        Display documents on your iBrochure for travellers and trade partners to access.<br /><br />
                        Trade only<br />
                        To be used exclusively by the trade partners who access and use your content in Wetu.<br /><br />
                        Private<br />
                        Documents will not display anywhere else in the Wetu system, to ensure that they are kept private from the public and trade.
                            </Tooltip> }>
                            <BsInfoCircleFill className="inline" size={18} />
                        </OverlayTrigger>
                        </span>
                    </Col>
                    <Col xs='1'>
                    </Col>
                </Row>
                <DragSortable key={`ds-${index}`} groupId={item.key} cardDropCallback={cardDropCallback}>
                { sectionDocuments.length > 1 &&
                 sectionDocuments.map((item, index) => 
                    <DocumentItem deleteCallback={deleteCallback} updateCallback={documentItemUpdateCallback} document={item}
                        languageCode={currentLanguageCode} documentsCount={getDocuments()?.length} key={`document-item-${index}`} 
                        loadedCallback={loadedCallback}
                    />
                )}
                { sectionDocuments.length === 1 &&
                    <div className="draggable-container no-handle">
                    <DocumentItem deleteCallback={deleteCallback} updateCallback={documentItemUpdateCallback} document={sectionDocuments[0]}
                        languageCode={currentLanguageCode} documentsCount={getDocuments()?.length} key={`document-item-0`} 
                        loadedCallback={loadedCallback}
                    />
                    </div>
                }
                </DragSortable>
                { isLoading && isLoadingSection === item.key &&
                    <Spinner animation="border" variant="primary" className="center-content loading-spinner" />
                }
                </div>
            )
        }
        return null;
    }

    const setLanguageCodeCallback = (languageCode: string) => {
        setCurrentLanguageCode(languageCode);       //This will propagate down into the DocumentItem component.
    }

    return (
        <Container fluid className="documents-page section-border">
            <MdLens className={`section-dot ${isCompleted ? "complete-dot" : "incomplete-dot" }`} />
            { profile.isEnhanced &&
                <MultiSelectRepeater id="Documents" title="Documents" dropDownDefaultText="Select categories" hasSelectAll={true}
                    multiSelectData={ multiSelectDataDocuments } multiSelectCallback={ multiSelectCallback } 
                    confirmDeselectCallback={confirmDeselectCallback}
                    confirmDeselectTitleText="Remove {category|ies|NUMSECTIONS} and delete {its|their|NUMSECTIONS} {document|s|NUMOBJECTS}?"
                    confirmDeselectBodyText="Removing the {category|ies|NUMSECTIONS} will permanently delete {NUMOBJECTS} {document|s|NUMOBJECTS}. This can't be undone."
                    getNumDeselectMediaItemsCallback={getNumDeselectMediaItemsCallback}
                    confirmDeselectCancelText="Cancel"
                    confirmDeselectOkText="Remove"
                >
                    { getDocumentCount() > 0 && profile.availableLanguages?.length > 1 &&
                        <LanguagePicker languageCompletionDots={languageCompletionDots}
                        availableLanguages={profile.availableLanguages} setLanguageCodeCallback={setLanguageCodeCallback}
                    />    
                    }
                    { multiSelectDataDocuments.map((item, index) =>
                        getContent(item, index)    
                    )}
                </MultiSelectRepeater>
            }
            { !profile.isEnhanced &&
                <Card className="basic-container">
                    <Card.Body>
                        <Card.Title>Documents</Card.Title>
                        { multiSelectDataDocuments.map((item, index) =>
                            getContent(item, index)    
                        )}
                    </Card.Body>
                </Card>
            }
        </Container>
    )
}