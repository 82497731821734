import React, { useState, useCallback } from 'react';
import { RootState } from 'app/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import './fast-facts.page.scss';
import { PillTextInput } from 'components/pill.text.input/pill.text.input';
import { UpdateQualityAssuranceBody, UpdateProfileStarRating, UpdateAlternativeRating, UpdateProfileRoomCount, UpdateTimeFormat, UpdateProfileCheckinTimeFrom, 
  UpdateProfileCheckoutTimeFrom, UpdateProfileCheckinTimeTo, UpdateProfileCheckoutTimeTo, UpdateMinimumChildAge, UpdateProfileFeatures } from 'components/profile/profile.slice';
import { BsDot, BsTrash } from 'react-icons/bs';
import { StarRating } from 'components/star.rating/star.rating';
import { ShowMore } from 'components/show.more/show.more';
import { NumberStepper } from 'components/number.stepper/number.stepper';
import { TimeFormatEnum, ITime, FeatureTypeEnum, IFeature } from '../../../../components/profile/types';
import { TimePicker } from 'components/time.picker/time.picker';
import { CheckboxAccordion } from 'components/checkbox.accordion/checkbox.accordion';
import { languages } from 'app/config';
import { Facilities } from 'components/facilities/facilities';
import { initialState } from '../../../../components/profile/profile.slice';

interface IProps {
  isCompleted: boolean;
}

export const FastFactsPage = ({isCompleted}: IProps) => {
  const { profile } = useSelector((state: RootState) => state.profileSlice);
  const [ roomCountLocal, setRoomCountLocal ] = useState(profile.roomCount ? profile.roomCount : 0);
  const [ languagesMessage, setLanguagesMessage ] = useState("");
  const [ languageExists, setLanguageExists ] = useState(false);
  const otherLanguages = profile.features?.filter(x => x.id === 0 && x.type === FeatureTypeEnum.language);
  const otherLanguagesNames = otherLanguages?.map(x => x.name);
  const [ qualityAssuranceBody, setQualityAssuranceBody ] = useState(profile.qualityAssuranceBody ? profile.qualityAssuranceBody : '');
  const [ starRating, setStarRating ] = useState(profile.starRating ? profile.starRating : 0);
  const [ alternativeRating, setAlternativeRating ] = useState(profile.alternativeRating ? profile.alternativeRating : '');
  const [ timeFormat, setTimeFormat ] = useState(profile.timeFormat? profile.timeFormat : initialState.profile.timeFormat);
  const [ checkinTimeFrom, setCheckinTimeFrom ] = useState(profile.checkinTimeFrom ? profile.checkinTimeFrom : initialState.profile.checkinTimeFrom);
  const [ checkinTimeTo, setCheckinTimeTo ] = useState(profile.checkinTimeTo ? profile.checkinTimeTo : initialState.profile.checkinTimeTo);
  const [ checkoutTimeFrom, setCheckoutTimeFrom ] = useState(profile.checkoutTimeFrom ? profile.checkoutTimeFrom : initialState.profile.checkoutTimeFrom);
  const [ checkoutTimeTo, setCheckoutTimeTo ] = useState(profile.checkoutTimeTo ? profile.checkoutTimeTo : initialState.profile.checkoutTimeTo);
  const [ minimumChildAge, setMinimumChildAge ] = useState(profile.minimumChildAge ? profile.minimumChildAge : 0);
  const [ features, setFeatures ] = useState(profile.features);
  const [ showAltRating, setShowAltRating ] = useState(profile.alternativeRating?.length > 0);
  const dispatch = useDispatch();

  const facilityLabels: string[] = [
    "Use the Friendly options to highlight special capabilities of your property. Check options that are unusual for establishments in your area.",
    "Use the Special Interests options to showcase a few particular, outstanding benefits of your location that are sure to draw visitors.",
    ""
  ];
  const featuresSectionName = "Fast Facts";

  const timeFormats = Object.values(TimeFormatEnum);
  
  const getTimeFormatLabel = (timeFormat: TimeFormatEnum): string => {
    switch (timeFormat) {
      case TimeFormatEnum.Hours12:
        return "12 Hour";
      case TimeFormatEnum.Hours24:
        return "24 Hour";
      default:
        return '';
    }
  }
  
  const getTimeFormatLabel2 = (timeFormat: TimeFormatEnum): string => {
    switch (timeFormat) {
      case TimeFormatEnum.Hours12:
        return "12:00 AM - 11:59 PM";
      case TimeFormatEnum.Hours24:
        return "00:00 - 24:00";
      default:
        return '';
    }
  }

  const qualityAssuranceBodyChange = (inputString: string) => {
    setQualityAssuranceBody(inputString);
    dispatch(UpdateQualityAssuranceBody(inputString));
  }

  const altRatingChange = (altRating: string) => {
    setAlternativeRating(altRating);
    dispatch(UpdateAlternativeRating(altRating));
  }
  
  const deleteAltRating = () => {
    setShowAltRating(false);
    setAlternativeRating('');
    dispatch(UpdateAlternativeRating(''));
  }

  const addAlternativeRatingCallback = () => {
    setShowAltRating(true);
  }

  const ratingCallback = useCallback((rating: number) => {
    setStarRating(rating);
    dispatch(UpdateProfileStarRating(rating));
  }, [dispatch]);

  const roomCountCallback = useCallback((count: number) => {
    dispatch(UpdateProfileRoomCount(count));
    setRoomCountLocal(count);
  }, [dispatch]);

  const timeFormatChange = (newTimeFormat: TimeFormatEnum) => {
    setTimeFormat(newTimeFormat);
    dispatch(UpdateTimeFormat(newTimeFormat));
  }

  const timePickerCallbackCheckinFrom = (time: ITime) => {
    setCheckinTimeFrom(time);
    dispatch(UpdateProfileCheckinTimeFrom(time));
  }
 
  const timePickerCallbackCheckinTo = (time: ITime) => {
    setCheckinTimeTo(time);
    dispatch(UpdateProfileCheckinTimeTo(time));
  }

  const timePickerCallbackCheckoutFrom = (time: ITime) => {
    setCheckoutTimeFrom(time);
    dispatch(UpdateProfileCheckoutTimeFrom(time));
  }
 
  const timePickerCallbackCheckoutTo = (time: ITime) => {
    setCheckoutTimeTo(time);
    dispatch(UpdateProfileCheckoutTimeTo(time));
  }

  const minChildAgeChange = (event: any) => {
    if (event.target.value) {
      const num = Number(event.target.value);
      if (num >= 0) {
          setMinimumChildAge(num);
          dispatch(UpdateMinimumChildAge(num));
      }
    }
    else {
      setMinimumChildAge(0);
      dispatch(UpdateMinimumChildAge(0));
    }
  }
  
  const languageAccordionCallBack = (checked: boolean, language: IFeature) => {
    let updatedFeatures = [];
    if (checked) {
      updatedFeatures = [...profile.features, language];
    } 
    else {
      updatedFeatures = [...profile.features];
      const index = updatedFeatures.findIndex(x => x.id === language.id);
      if (index > -1) {   
        updatedFeatures.splice(index, 1);
      }
    }
    setFeatures(updatedFeatures);
    dispatch(UpdateProfileFeatures(updatedFeatures));
  }
  
  const addAdditionalLanguage = (name: string) => {
    const addedLanguage = {
      id: 0,
      type: FeatureTypeEnum.language,
      name: name
    } as IFeature;
    const updatedFeatures = [...profile.features, addedLanguage]
    setFeatures(updatedFeatures);
    dispatch(UpdateProfileFeatures(updatedFeatures));
  }
  
  const languageCallBack = (name: string) => {
    addAdditionalLanguage(name);
  }

  const checkLanguageExists = (value: string) =>{
    let updatedFeatures = [...profile.features];

    const index = updatedFeatures.findIndex(x => x.id === 0 && x.type === FeatureTypeEnum.language && x.name.toLowerCase() === value.toLowerCase());
    const checkBoxIndex = languages.findIndex(x => x.name.toLowerCase() === value.toLowerCase());
    
    if (index > -1 || checkBoxIndex > -1) {
      setLanguageExists(true);
      if (index > -1) {
        setLanguagesMessage(`${value} already added`);
      } 
      else {
        setLanguagesMessage(`${value} available in language list`);
      }
    } 
    else {
      setLanguageExists(false);
      setLanguagesMessage(value);
    }
  }

  const changeLanguageCallBack = (name: string) => {
    checkLanguageExists(name);
  }

  const onRemoveLanguage = (itemName: string) => {
    let updatedFeatures = [...profile.features];
    const index = updatedFeatures.findIndex(x => x.id === 0 && x.type === FeatureTypeEnum.language && x.name === itemName);
    if (index > -1) {   
      updatedFeatures.splice(index, 1);
    }
    setFeatures(updatedFeatures);
    dispatch(UpdateProfileFeatures(updatedFeatures));
  }

  return (
    <Card border="dark">
      <Card.Body>
        <Container fluid className="padding-right-0">
          <Row>
            <Col xs="12" className="padding-0">
              <Card.Title className="card-title-fast-facts">Fast Facts</Card.Title>
              <Form.Text className="text-label">The details you select will be displayed on your iBrochure and will also appear on digital itineraries featuring your listing that are built by Operators in Wetu.</Form.Text>
            </Col>
          </Row>
        </Container>
        <BsDot color={ isCompleted ? "#2596be" : "#aaaaaa"} size={85} className="completed-dot" />
        <Form.Group>
          <Form.Label>Quality Assurance Body</Form.Label>
          <Form.Control value={qualityAssuranceBody} onChange={(e) => qualityAssuranceBodyChange(e.target.value)} id="qab-input" type="text" />
          <Form.Text className="label-small">E.g. AAA, TGCSA, Hotelstars Union</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Star rating</Form.Label>
          <StarRating starAmount={7} callBack={ratingCallback} starRating={starRating} />
        </Form.Group>
        <ShowMore label='+ Add alternative rating' show={showAltRating} callback={addAlternativeRatingCallback}>
          <Form.Label>Rating</Form.Label>
          <div className="number-input-container">
          <Form.Control value={alternativeRating} onChange={(e) => altRatingChange(e.target.value)} id="alt-rating-input" type="text" className="alt-image-rating"/><BsTrash title="Delete" className="trash-button" onClick={ () => deleteAltRating()}/>
          </div>
          <Form.Text className="label-small">E.g. 5 Diamonds, 4.4/5 Excellent, 9/10</Form.Text>
        </ShowMore>
        <Form.Group className="padding-top-20">
          <Form.Label>Number of rooms</Form.Label>
          <NumberStepper title={""} callBack={roomCountCallback} initialCount={roomCountLocal} />
        </Form.Group>
        <Form.Group>
        <Form.Label>TIME FORMAT</Form.Label>
          {timeFormats.map((option, i) => {
              return <div key={'radio-container' + i} className="radio-container">
                        <input type="radio" checked={timeFormat === option? true: false} key={'radio' + i} id={'radio' + i}
                            onChange={event => timeFormatChange(event.target.value as TimeFormatEnum)} value={option} />
                        <label className="padding-left" key={'label' + i} htmlFor={'radio' + i}>
                            <div className="padding-left-8">{getTimeFormatLabel(option)}</div>
                            <div className="padding-left-8 label-small">{getTimeFormatLabel2(option)}</div>
                        </label>
                  </div>
          })}
        </Form.Group>
        <Form.Label>CHECK IN</Form.Label>
        <Container fluid className="padding-top-10">
          <Row>
            <Col xs="5">
              <Form.Group>
                <Form.Label>From</Form.Label>
                <TimePicker callback={ timePickerCallbackCheckinFrom } time={checkinTimeFrom} />
              </Form.Group>
            </Col>
            <Col xs="1" className="separator-column"> - </Col>
            <Col xs="5">
              <Form.Group>
                <Form.Label>Until</Form.Label>
                <TimePicker callback={ timePickerCallbackCheckinTo } time={checkinTimeTo} />
              </Form.Group>
            </Col>
          </Row>
          </Container>
          <Card.Subtitle className="padding-top-20">CHECK OUT</Card.Subtitle>
          <Container fluid className="padding-top-10">
          <Row className="">
            <Col xs="5">
              <Form.Group>
                <Form.Label>By</Form.Label>
                <TimePicker callback={ timePickerCallbackCheckoutFrom } time={checkoutTimeFrom} />
              </Form.Group>
            </Col>
            <Col xs="1" className="separator-column"> - </Col>
            <Col xs="5">
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <TimePicker callback={ timePickerCallbackCheckoutTo } time={checkoutTimeTo} />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Form.Group>
          <Form.Label>Minimum child age</Form.Label>
          <Form.Control value={minimumChildAge > 0 ? minimumChildAge : ''} onChange={(e) => minChildAgeChange(e)} id="min-age-input" type="number" placeholder="0"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>SPOKEN LANGUAGES</Form.Label>
          <Form.Text className="text-label">Select all the languages spoken by staff members at your establishment.</Form.Text>
          <Row className="accordion-container">
            {
              <CheckboxAccordion accordionCallBack={languageAccordionCallBack} key={1} title={""} content={languages} fixedCount={12} features={features}  />
            }
          </Row>
          <PillTextInput title="Add additional languages" message={languagesMessage} unique={!languageExists} items={otherLanguagesNames} addCallBack={languageCallBack} 
          removeCallBack={onRemoveLanguage} changeCallBack={changeLanguageCallBack}></PillTextInput>
        </Form.Group>
        <Form.Group className="facilities-container">
          <Facilities featureDataSectionName={ featuresSectionName } labels={facilityLabels}></Facilities> 
        </Form.Group>
      </Card.Body>
    </Card>
  )
}