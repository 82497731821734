import { useLocation } from "react-router-dom";
import React from 'react';
import { ProfileCreationHeader } from "../profile.creation.header/profile.creation.header";
import { ProfileGeneralHeader } from "../profile.general.header/profile.general.header";

export const HeaderFactory = () => {
    const location = useLocation();
    const isInProfiles = location.pathname.includes("profiles"); 
    const isOnProfileUpdatePage = location.pathname.includes("profiles/update");

    const getCurrentPathWithoutParams = (pathname: string) => {      
        let path: number = 0;
        path = Number(pathname.slice(pathname.lastIndexOf('/') + 1));
        if(isNaN(path)) {
            const sliceWithIdAndGuid = pathname.slice(pathname.lastIndexOf('/', pathname.lastIndexOf('/')-1));
            const sliceWithOnlyId = sliceWithIdAndGuid.slice(sliceWithIdAndGuid.indexOf('/') + 1, sliceWithIdAndGuid.lastIndexOf('/'));
            path = Number(sliceWithOnlyId);
        }
        if(isNaN(path)) {
            path = 0;
        }
        return path;
    }

    const position = getCurrentPathWithoutParams(location.pathname);   

	switch (true) {
        case (position === 0):
            return <ProfileGeneralHeader isProfiles={isInProfiles} isUpdateProfile={isOnProfileUpdatePage}/>;
        case (position > 4 && position < 11):            
            return <ProfileCreationHeader displaySaveAndExit={true}/>;       
        default:
            return null;
    }
}