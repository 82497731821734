import React from 'react';
import { Col, Nav , Form} from 'react-bootstrap';
import './profile.general.header.scss';
import { BsList } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import { ToggleUpdateProfileMenu } from 'app/app.slice';
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
import { RootState } from 'app/root-reducer';

interface IProps {
    isProfiles: boolean;
    isUpdateProfile: boolean;
  }

export const ProfileGeneralHeader = ({isProfiles, isUpdateProfile} : IProps) => {
    const { showUpdateProfileMenu } = useSelector((state: RootState) => state.applicationSlice);
    const dispatch = useDispatch();

    const links = [     
        {name: "Home", path: ""},
        {name: "Profiles", path: "/profiles/"},
        {name: "Page 3", path: "/page3/"},
        {name: "Page 4", path: "/page4/"},
        {name: "Page 5", path: "/page5/"}
      ];

    const toggleProfileUpdateMenu =() => {
        dispatch(ToggleUpdateProfileMenu(!showUpdateProfileMenu)); 
    }

    return (
        <React.Fragment>            
            <Col className="profile-sidebar-button-col" md={1}>                
                {isUpdateProfile &&
                <div className="profile-sidebar-button">
                    <BsList className="cursor-pointer" onClick={() => toggleProfileUpdateMenu()}  />
                </div>
                  
                }             
            </Col>
            {links.map((link,index) => (
                <Col md={1} key={index}>
                     {isProfiles && link.name === "Profiles" &&
                        <Nav.Item>                        
                        <NavLink className={"NavLink-profiles-bold"} key={index} to={link.path} exact activeClassName="current">
                        <li><span>{link.name}</span></li>
                        </NavLink>
                        </Nav.Item>
                    } 
                    {isProfiles && link.name !== "Profiles" &&
                         <Nav.Item>                        
                        <NavLink className={"no-decoration"} key={index} to={link.path} exact activeClassName="current">
                        <li><span>{link.name}</span></li>
                        </NavLink>
                        </Nav.Item>
                    }
                    {!isProfiles &&
                         <Nav.Item>                        
                        <NavLink className={"no-decoration"} key={index} to={link.path} exact activeClassName="current">
                        <li><span>{link.name}</span></li>
                        </NavLink>
                        </Nav.Item>
                    }                
                </Col>
            ))}
            <Col md={2} >
                <Nav.Item>
                    <input type="text" placeholder="Search.." name="placeHolderSearchBox" />
                </Nav.Item>
            </Col>  
             <Col md={1}>
                 {isUpdateProfile &&                   
                   
                <Form>
                    <div className="switch">
                    <Form.Label className="switch-label">AutoSave</Form.Label>
                    <Form.Check className="switch-toggle"
                        type="switch"
                        id="custom-switch"  />
                     </div>   

                </Form>
                } 
            </Col>    
            <Col md={2} >
                <Nav.Item className="profile-icon-flex-end">
                    <div className="placeholder-user-profile">
                        <svg height={24} width={30}><circle cx={11} cy={11} r={10} fillOpacity={0} strokeWidth={1} stroke="#555555" /></svg>Kelly Stower
                    </div>
                </Nav.Item>
            </Col>
        </React.Fragment>        
    )
}