import React from 'react';
import { GDSCard } from './features/gds.card/gds.card';
import { IntegrationsCard } from './features/integrations.card/integrations.card';

export const IntegrationsPage = () => {
    return(
        <React.Fragment>
            <IntegrationsCard />
            <GDSCard />
        </React.Fragment>
    )
}