import React, {useCallback, useState} from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';
import './wizard.section.card.scss';
import { BsCheck } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { IProfileItem, IProfileItemDeletePayload, ProfileItemTypeEnum, ProfileItemUnitOfAreaEnum } from 'components/profile/profileitem/types';
import { WizardRoomCard } from 'components/wizard.room.card/wizard.room.card';
import { deleteProfileItem } from 'components/profile/profileitem/profileitem.slice';
import { useDispatch, useSelector } from 'react-redux';
import { SleepingArrangementsOverlay } from 'pages/profile-form/pages/sleeping-arrangements/features/sleeping.arrangements.overlay/sleeping.arrangements.overlay';
import { EntityTypeEnum, ITranslationItem } from 'components/profile/types';
import { RootState } from 'app/root-reducer';

interface IProps {
    name: string;
    title: string;
    description: string;
    navLink: string;
    navText: string;
    checked: boolean;
    rooms?: IProfileItem[];
}

export const WizardSectionCard = ({name, title, description, navLink, navText, checked, rooms}:IProps) => {
    const { id } = useSelector((state: RootState) => state.profileSlice.profile);
    const dispatch = useDispatch();
    const [ showProfileItemModal, setShowProfileItemModal ] = useState<boolean>(false);
    const getDefaultProfileItem = (type: ProfileItemTypeEnum): IProfileItem => {
        return {
          id: "", 
          features: [], 
          name: "", 
          configuration: "", 
          description: {} as ITranslationItem, 
          entityType: EntityTypeEnum.profileItem, 
          isChanged: false, 
          media: [], 
          profileId: id, 
          sequence: 0, 
          type: type,
          count: 0,
          size: 0,
          units: ProfileItemUnitOfAreaEnum.SquareMeter
        };
    }
    const [ selectedProfileItem, setSelectedProfileItem ] = useState<IProfileItem>(getDefaultProfileItem(ProfileItemTypeEnum.Room));
    const history = useHistory();

    const onClickHandler = () => {
        if (rooms) {
            setShowProfileItemModal(true);
        } else {
            history.push(navLink);
        }
    }

    const DeleteProfileItemCallback = useCallback((room: IProfileItem) => { 
        const profileItemDeletePayload : IProfileItemDeletePayload = { id : room.id, profileId: room.profileId , media: room.media, translationItemId: room.description.id, type: room.type}       
        dispatch(deleteProfileItem(profileItemDeletePayload));
    }, [dispatch]);

    const profileItemOverlayCallback = () => {
        setShowProfileItemModal(false);
    }

    const editRoomCallback = (profileItem: IProfileItem) => {
        setSelectedProfileItem(profileItem);
        setShowProfileItemModal(true);
    }

    const addNewItemCallback = (type: ProfileItemTypeEnum) => {
        setSelectedProfileItem(getDefaultProfileItem(type));
        setShowProfileItemModal(true);
    }

    return (
        <Col className="wizard-section-card-wrap" lg="9" md="12">
            <Card className={`wizard-section-card ${checked?'selected':''}`} >
                <Card.Body className="card-body">
                    <Container fluid>
                        <Row>
                            <Col xs="2" className="circle-container">
                                <div className={`circle ${checked?'selected':''}`}>{checked && <BsCheck size={21} className="tick"/>}</div>
                            </Col>
                            <Col className="wizard-card-wrap" xs="8" >
                                <Card.Title className="card-title">{title}</Card.Title>
                                <Card.Subtitle className="card-subtitle">{name}</Card.Subtitle>
                                <Card.Text>
                                    {description}
                                </Card.Text>
                            </Col>
                            <Col xs="2">
                                <div>
                                     <Nav.Link className="wizard-link" onClick={onClickHandler} >{navText}</Nav.Link>
                                </div>
                            </Col>
                        </Row>
                        { (rooms) &&
                            <Row className="room-card-container">
                                {rooms.map( (item,index) => <WizardRoomCard editCallBack={editRoomCallback} deleteCallBack={DeleteProfileItemCallback} room={item} index={index} key={index} />)}
                                <SleepingArrangementsOverlay showModal={showProfileItemModal} callBack={profileItemOverlayCallback} item={selectedProfileItem} addNewItemCallback={addNewItemCallback}/>
                            </Row>
                        }    
                    </Container>
                </Card.Body>
            </Card>
        </Col>
    )
}