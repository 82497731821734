import React from 'react';
import { Col, Button } from 'react-bootstrap';
import './profile.creation.header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { create, update } from 'components/profile/profile.slice';
//import { createPItem, updatePItem } from 'components/profile/profileitem/profileitem.slice';
import { RootState } from 'app/root-reducer';
import { EntityTypeEnum } from 'components/profile/types';

interface IProps {
    displaySaveAndExit?: boolean;
  }

export const ProfileCreationHeader = ({displaySaveAndExit} : IProps) => {
    const { profile } = useSelector((state: RootState) => state.profileSlice);

    const dispatch = useDispatch();

    const updateProfile = () => {
        if(profile.id) {
            dispatch(update({
                id: profile.id,
                category: profile.category,
                isEnhanced: profile.isEnhanced,
                latitude: profile.pin.latitude,
                longitude: profile.pin.longitude,
                name: profile.name,
                type: profile.type,
                entityType: EntityTypeEnum.profile,
                checkinTimeFrom: profile.checkinTimeFrom,
                checkoutTimeFrom: profile.checkoutTimeFrom,
                checkinTimeTo: profile.checkinTimeTo,
                checkoutTimeTo: profile.checkoutTimeTo,
                isPublished: profile.isPublished,
                features: profile.features,
                searchAliases: profile.searchAliases,
                translationItems: profile.translationItems,
                shortDescription: profile.shortDescription,
                richDescription: profile.richDescription,
                availableLanguages: profile.availableLanguages,
                status: profile.status,
                qualityAssuranceBody: profile.qualityAssuranceBody,
                starRating: profile.starRating,
                alternativeRating: profile.alternativeRating,
                roomCount: profile.roomCount,
                timeFormat: profile.timeFormat,
                minimumChildAge: profile.minimumChildAge,
                sectionsComplete: profile.sectionsComplete,
                clientName: profile.clientName,
                affiliate: profile.affiliate,
                affiliateCode: profile.affiliateCode,
                gdsCodes: profile.gdsCodes,
                contacts: profile.contacts,
                links: profile.links        
            }));
        } else {
            dispatch(create({
                category: profile.category,
                isEnhanced: profile.isEnhanced,
                latitude: profile.pin.latitude,
                longitude: profile.pin.longitude,
                name: profile.name,
                type: profile.type,
                entityType: EntityTypeEnum.profile,
                checkinTimeFrom: profile.checkinTimeFrom,
                checkoutTimeFrom: profile.checkoutTimeFrom,
                checkinTimeTo: profile.checkinTimeTo,
                checkoutTimeTo: profile.checkoutTimeTo,    
                isPublished: profile.isPublished,
                translationItems: profile.translationItems,   
                features: profile.features,
                roomCount: profile.roomCount,
                starRating: profile.starRating
            }));
        }
    }
    
    return (
        <React.Fragment>
            <Col xs={{offset: 7, span: 2}} >
                {displaySaveAndExit &&
                    <Button onClick={() => updateProfile()} variant={'link'}>save and exit</Button>
                }
            </Col>
        </React.Fragment>
    )
}