import React, { useState } from 'react';
import { ChecklistSection } from 'pages/profile-form/profile.form.types';
import { Container, Row, Card, Col } from 'react-bootstrap';
import { BsDot } from 'react-icons/bs';
import './profile.checklist.scss';
import { useEffect } from 'react';
import { ProfileFormPageEnum } from '../../profile.form.types';

interface IProps {
    availableSections: ChecklistSection[];
}

export const ProfileChecklist = ({availableSections}: IProps) => {
    
    const [ sections, setSections ] = useState<ChecklistSection[]>([]);
    const [ currentSection, setCurrentSection ] = useState<string>("");
    const [ yAxisOffset ] = useState<number>(-60);

    useEffect(() => {
        setSections(availableSections);
    }, [availableSections]);

    const setCurrentSectionAndScroll = (section: ChecklistSection) => {
        setCurrentSection(section.id);      
        const element = document.getElementById(section.id);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset + yAxisOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
      
    return (
        <Card className="sticky-checklist" border="dark">
            <Container className="checklist-header">
            <Card.Title>Checklist</Card.Title>
            </Container>
            <Container>
            {
                sections.map((section, index) => 
                <Row onClick={() => setCurrentSectionAndScroll(section)} key={index} className={`checklist-item ${currentSection === section.id ? "checklist-item-active" : ""}`}>
                    <Col xs={9}>
                        <div className="checklist-item-text">{section.label}</div>
                    </Col>
                    <Col xs={3}>
                    <BsDot className={section.isCompleted ? "complete-dot" : 
                    ( section.page === ProfileFormPageEnum.media && section.label === "Images" ?  "incomplete-dot-media" : "incomplete-dot")} size={24} />
                    </Col>
                </Row>)
            }
            </Container>
        </Card>
    )
}