import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './suggestion.modal.scss';
import { useDispatch} from 'react-redux';
import { showGlobalToast } from 'components/toast/toast.slice';

interface IProps {
  title: string;
  callBack: (value:string) => void;
}


export const SuggestionModal =  ({title, callBack}:IProps) =>
{
    const [isOpen, setIsOpen] = useState(false); 
    const [noSuggestion, showSuggestionBox] = React.useState(false);
    const dispatch = useDispatch();
    const [text, setText] = useState(""); 

const showModal = () => {
  setIsOpen(true);
};

const hideModal = () => {
  setIsOpen(false);
  showSuggestionBox(false);
};

const SuggestionModal = () => {
  showSuggestionBox(true);
}

const submit = () => {
  hideModal();
  callBack(text);
  dispatch(showGlobalToast({title: "Success", message: "Thank you! Your suggestion was submitted"}));
}

  return(  
    <div>
      <Button variant={'link'} className="pointer" onClick={showModal}>I don't see my profile {title.toLowerCase()} on the list</Button>
      <Modal size={"lg"} show={isOpen} onHide={hideModal}>     
        <Modal.Body >
          <div className="suggestion-input" hidden={noSuggestion} >
            <h5><b>I don't see my profile {title.toLowerCase()} on the list</b></h5>
            <p>That's okay - try to choose a {title.toLowerCase()} that is most similiar to your property.<br/>
            Alternatively, please submit a suggestion to our Product team for future considerations.</p>          
          </div> 

          <div  hidden={!noSuggestion} >  
          <h5><b>Submit profile {title.toLowerCase()} suggestion</b></h5>
            <p>Thank you for taking the time to share suggestions regarding our Profiles.</p>
            <p>Your suggestion*</p>            
            <textarea value={text} onChange={ event => setText(event.target.value)} className="suggestion-input"> </textarea>
          </div>         
        </Modal.Body>
        <Modal.Footer>
          <Button className="pointer" variant={'link'} hidden={noSuggestion} onClick={() => SuggestionModal()} >Submit a suggestion</Button>
          <Button variant="primary"  onClick={hideModal}>Cancel</Button>
          <Button hidden={!noSuggestion} disabled={!text}  variant="primary" onClick={() => submit()}>Submit a suggestion</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )

}