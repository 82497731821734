import axios from 'axios';
import { DeleteBlob } from 'components/profile/types';
import { getApiBaseUrl } from '../app/config';

const baseProfileUrl =  getApiBaseUrl() + 'blob/';

export async function uploadBlob(payload: FormData) {
  return axios.post<any>(baseProfileUrl + "Upload", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'multipart/form-data'
      }
  });
} 

export async function deleteBlob(payload: DeleteBlob) {
  return axios.post<any>(baseProfileUrl + "Delete", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'content-type': 'multipart/form-data'
      }
  });
} 

