import { ProfileStatusEnum } from 'components/profile/types';
import React from 'react';
import { Container } from 'react-bootstrap';
import './label.pill.scss';

interface IProps {
    label: string;
}

export const LabelPill = ({label}:IProps) => {

    const pillClass:string = label.toLowerCase();
    let displayLabel:string = label;
    
    if(label === ProfileStatusEnum.inProgress){
        displayLabel = "In Progress";
    }
    
    if(label === ProfileStatusEnum.inReview){
        displayLabel = "In Review";
    }

    return (
        <Container>
            <div className={"pill " + pillClass}>{displayLabel}</div>
        </Container>
    )
}