import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap';
import './number.stepper.scss';

interface IProps {
    title?: string;
    callBack: (value:number) => void;
    initialCount?: number;
    min?: number;
    max?: number;
    className?: string;
}

export const NumberStepper = ({title = '', callBack, initialCount = 0, min = 0, max = 9999, className = ""}:IProps) => {
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
        callBack(count);
    }, [count, callBack]); 

    const increment = () => {
        if (count < max) {
            setCount(count => count + 1);
        }
    }

    const decrement = () => {
        if (count > min) {
            setCount(count => count === 0 ? count : count - 1);
        }
    }

    const keyChange = (event : any) => {
        if (event.target.value !== '') {
            const num = Number(event.target.value);
            if (num >= min && num <= max) {
                setCount(num);
            }
        }
        else {
            setCount(min);
        }   
    }
    return (
        <Container className="number-stepper">
            <Row>
                <Col>
                    <Form.Text>{title}</Form.Text>
                </Col>
            </Row>
            <Row>
                <Col className="number-stepper-col">
                <ButtonGroup className={className}>
                    <Form.Control type="number" value={count > min ? count : ''} onChange={(e: any) => keyChange(e)} placeholder={min.toString()}/>
                    <Button onClick={() => decrement()} variant="secondary">-</Button>
                    <Button onClick={() => increment()} variant="secondary">+</Button>
                </ButtonGroup>
                </Col>
            </Row>
        </Container>
    )
}