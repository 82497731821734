import React from 'react';
import { IconContext } from "react-icons";
import { BsStarFill, BsStar } from "react-icons/bs";
import './star.icon.scss';

interface IProps {
    color?: string;
}
export const StarIcon = ({color = '#7987A1'}: IProps) => {
    
    return (
        <IconContext.Provider value={{ color: color, className: "margin-4" }}>
            <div className="star-container">
                {
                    color === '#7987A1' &&
                    <BsStar size={24}/>
                }
                {
                    color !== '#7987A1' &&
                    <BsStarFill size={24}/>
                }
            </div>
        </IconContext.Provider>
    )
}