import { featuresData, RoomConfiguration } from 'app/config';
import { RootState } from 'app/root-reducer';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import { CheckboxAccordion } from 'components/checkbox.accordion/checkbox.accordion';
import DropZone from 'components/dropzone/dropzone';
import { IndeterminateCheckbox } from 'components/indeterminate.checkbox/indeterminate.checkbox';
import { IndeterminateCheckboxEnum } from 'components/indeterminate.checkbox/types';
import { NumberStepper } from 'components/number.stepper/number.stepper';
import { UpdateSectionCompleteWithValue, updateProfileFromStore } from 'components/profile/profile.slice';
import { deleteMedia, UpdateMedia_Reducer } from 'components/profile/mediaitem/mediaitem.slice';
import { createProfileItem, deleteProfileItem, getProfileItemCount, UpdateExistingProfileItemMedia, UpdateProfileItemMediaItems, updateProfileItemsDb, uploadRoomBlob, uploadRoomMedia } from 'components/profile/profileitem/profileitem.slice';
import { ProfileFormPageEnum } from 'pages/profile-form/profile.form.types';
import { IProfileItem, IProfileItemDeletePayload, ProfileItemTypeEnum, ProfileItemUnitOfAreaEnum } from 'components/profile/profileitem/types';
import { IDeleteMediaPayload, EntityTypeEnum, IFeature, IMediaItem, MediaTypeEnum, DocumentTypeEnum } from 'components/profile/types';
import { showGlobalToast } from 'components/toast/toast.slice';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Modal, Row, Col, Button, Card, Form, Alert } from 'react-bootstrap';
import { BsCheckCircle, BsFillExclamationCircleFill, BsTrash, BsX } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import './sleeping.arrangements.overlay.scss';
import { useAppDispatch } from 'app/store';

interface IProps {
    showModal: boolean;
    callBack: (showModal: boolean, didSave: boolean, profileItem: IProfileItem) => void;
    item: IProfileItem;
    addNewItemCallback: (type: ProfileItemTypeEnum) => void;
}

export const SleepingArrangementsOverlay = ({showModal, callBack, item, addNewItemCallback}: IProps) => {
    const { profile } = useSelector((state: RootState) => state.profileSlice);
    const { profileItems, mediaItems } = useSelector((state: RootState) => state.profileItemSlice);
    const [ showCloseConfirmModal, setShowCloseConfirmModal ] = useState<boolean>(false);
    const [ profileItem, setProfileItem ] = useState<IProfileItem>(item);
    const [ profileItemTypes ] = useState<string[]>(Object.values(ProfileItemTypeEnum).filter(x => x !== "Restaurant" && x !== "Activities" && x !== "Feature" && x !== "None"));
    const [ profileItemUnitOfAreaTypes ] = useState<string[]>(Object.values(ProfileItemUnitOfAreaEnum));
    const [ wordCount, setWordCount ] = useState(0);
    const [ displayInvalidFiles, setDisplayInvalidFiles ] = useState<boolean>(false);
    const [ selectedImages, setSelectedImages ] = useState<IMediaItem[]>([]);
    const [ invalidFiles, setInvalidFiles ] = useState<string[]>([]);
    const [ facilityData ] = useState(Object.entries(featuresData)[1]);
    const [ showImageModal, setShowImageModal ] = useState<boolean>(false);
    const [ clickedCancel, setClickedCancel ] = useState<boolean>(false);
    const [ initialProfile, setInitialProfile ] = useState<IProfileItem | null>(null);
    const [ originalName, setOriginalName ] = useState<string>(item.name);
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);
    const [ currentProfileItemIndex, setCurrentProfileItemIndex ] = useState<number>(0);
    const [ isValid, setIsValid ] = useState<boolean>(true);
    const [ showSectionalMessage, setShowSectionalMessage ] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
      setProfileItem(item);
      setInitialProfile(item);
      setWordCount(item.description.text ? item.description.text.trim().split(' ').length - 1 : 0);
      setOriginalName(item.name);
    }, [item]);

    useEffect(() => {
      setCurrentProfileItemIndex(profileItems.findIndex(x => x.id === item.id));
    }, [profileItems, item.id]);

    useEffect(() => {
      if (!initialProfile) {
        setInitialProfile(_.cloneDeep(item));
      }
    }, [item, initialProfile]);

    useEffect(() => {
      const combinedMedia = _.unionBy(mediaItems, item.media, 'id').sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
      setProfileItem(profileItem => profileItem = {...profileItem, media: combinedMedia });
    }, [mediaItems, item.media]);

    const closeButtonClick = () => {
        if (hasChanges()) {
            setShowCloseConfirmModal(true);
        } else {
            callBack(false, false, profileItem);
            setInitialProfile(null);
            setShowSectionalMessage(false);
        }
    }

    const closeConfirmCallBack = (didClose: boolean) => {
      if (didClose) {
        setShowCloseConfirmModal(false);
        setInitialProfile(null);
        setShowSectionalMessage(false);
        callBack(false, false, profileItem);
      } else {
        setShowCloseConfirmModal(false);
      }
    }

    const isValidForm = (): boolean => {
      return profileItem.name?.length > 0 && profileItem.description?.text?.length > 0;
    }

    const getNewSequenceNumber = (profileItemType: ProfileItemTypeEnum) : number => {
      //We can't just return the array length because if they delete the first item in the array then add a new item this would create a duplicate sequence number.
      //Also we need to use negative numbers to make the newest item appear at the top in our DragSortable list.
      //So we need to get the smallest existing sequence number, and subtract 1.
      //We will sometimes get a non-contiguous array (eg. -2,-4,-5) but that doesn't affect the functionality.
      //This negative number system will also be overwritten to positive (eg. 0...5) when they drag-sort but that also doesn't matter.
      return Math.min.apply(
        Math, profileItems?.filter(x => x.type === profileItemType)?.map(
          function (item: IProfileItem) {
            return item.sequence;
          }
        )
      ) - 1;
    }

    const onSaveClick = () => {
      let clonedProfileItem = _.cloneDeep(profileItem);
      clonedProfileItem.isChanged = hasChanges();
      clonedProfileItem.sequence = getNewSequenceNumber(profileItem.type);

      if (isValidForm()) {
        if (!clonedProfileItem.id) {
          dispatch(createProfileItem(clonedProfileItem)).then(
            (payloadAction) => {
              const newProfileItem = payloadAction.payload as IProfileItem;
              setProfileItem(newProfileItem);
              setInitialProfile(newProfileItem);

              //Only update Profile.sectionsComplete if the completion status of the SleepingArrangements section has changed.
              dispatch(getProfileItemCount()).then(
                (payloadAction) => {
                  const profileItemCount = payloadAction.payload as number;
                  if (profileItemCount === 1) {
                    Promise.all([
                      dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.sleepingArrangements, value: true})),
                      dispatch(updateProfileFromStore())
                    ]);
                  }
              })
            }
          )          
          setShowSectionalMessage(true);
        } 
        else {
          dispatch(updateProfileItemsDb([clonedProfileItem]));
        }
        setShowCloseConfirmModal(false);
        callBack(true, true, clonedProfileItem);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }

    const handleRoomNameChange = (value: string) => {        
        let cloneProfileItem = _.cloneDeep(profileItem);
        cloneProfileItem.name = value;
        setProfileItem(cloneProfileItem);
    }

    const countCallback = useCallback((count: number) => {
        setProfileItem(x => x = {...x,  count : count});
    }, []);

    const onChangeDescription = (value: string) => {
      const count: number = value.split(' ').length - 1;
      setWordCount(count);
      if (count <= 200) {
        let cloneProfileItem = _.cloneDeep(profileItem)
        cloneProfileItem.description.text = value;    
        setProfileItem(cloneProfileItem);
      }
    }

    const onChangeRoomType = (value: string) => {      
        let cloneProfileItem = _.cloneDeep(profileItem);
        cloneProfileItem.type = value as ProfileItemTypeEnum;
        setProfileItem(cloneProfileItem);
    }

    const onSizeChangeNumberOnly = (value: number) => {
        let cloneProfileItem = _.cloneDeep(profileItem);
        if (value) {        
            cloneProfileItem.size = Number(value);
        }
        else {        
            cloneProfileItem.size = 0;
        }
        setProfileItem(cloneProfileItem);    
    };

    const onChangeUnitOfArea = (value: string) => {      
        let cloneProfileItem = _.cloneDeep(profileItem);
        cloneProfileItem.units = value as ProfileItemUnitOfAreaEnum;
        setProfileItem(cloneProfileItem);
    }

    const onChangeRoomConfiguration = (value: string) => { 
        value = value !== "Select" ? value : "";
        let cloneProfileItem = _.cloneDeep(profileItem);
        cloneProfileItem.configuration = value;
        setProfileItem(cloneProfileItem);
    }

    const clearInvalidFiles = () => {
        setInvalidFiles([]);
    }

    const uploadImageCallBack = useCallback((images: IMediaItem[], invalidFiles: string[]) => {
        const selectedImages = images.filter(x => x.isChecked === true);
        setSelectedImages(selectedImages);
        setInvalidFiles(invalidFiles);
        if (invalidFiles.length > 0) {
            setDisplayInvalidFiles(true);
        }
    
        const uploadImages = () => {
            const formData = new FormData();
            images.forEach(pImage => {
                if (pImage.url === '' && pImage.file) {
                    formData.append("files", pImage.file);
                    formData.append("sequences", pImage.sequence.toString());
                }
            });
    
            if(formData.get("files") === null) {
              return;
            }
    
            formData.append("container", "profile-wizard-images");
            formData.append("profileId", profile.id);
            formData.append("profileItemId", profileItem.id);
            formData.append("parentEntityType", EntityTypeEnum.profileItem);
            formData.append("mediaType", MediaTypeEnum.image);
            formData.append("documentType", DocumentTypeEnum.none);

            if(!profileItem.id) {
              dispatch(uploadRoomBlob(formData));
            } else {           
               dispatch(uploadRoomMedia(formData));
            }        
    
        }
    
        if (profile.id && images.length > profileItem.media?.length) {
            uploadImages();
        }
    }, [dispatch, profile.id, profileItem.media, profileItem.id]);

    const deleteImagesCallBack = useCallback((deleteImages: boolean) => {
        setShowImageModal(false);
        if (deleteImages) {
            const localMediaItems: IMediaItem[] = _.cloneDeep(profileItem.media);
            selectedImages.forEach(image => {
                const index = localMediaItems.findIndex(x => x.id === image.id);
                localMediaItems.splice(index, 1);
            });
            let itemsToDeleteFromStorage = selectedImages.filter(x => x.parentId);
    
            if (itemsToDeleteFromStorage.length > 0) {
              const payload: IDeleteMediaPayload = { mediaItems: itemsToDeleteFromStorage, showToast: true, mediaType: MediaTypeEnum.image };
              dispatch(deleteMedia(payload));
            } else {
              dispatch(showGlobalToast({title: "Success", message: localMediaItems.length > 1 ? "Images deleted" : "Image deleted"}));
            }
    
            if (profileItem.id) {
              dispatch(UpdateExistingProfileItemMedia({id: profileItem.id, media: localMediaItems}));
            } else {
              dispatch(UpdateProfileItemMediaItems(localMediaItems));
            }
            
            setSelectedImages([]);
        }
    }, [dispatch, selectedImages, profileItem.media, profileItem.id])

    const deleteSelectedImages = () => {
        setShowImageModal(true);
    }

    const hasChanges = () => {
      return !!(initialProfile && profileItem && (
        initialProfile.name !== profileItem.name ||
        initialProfile.type !== profileItem.type ||
        initialProfile.configuration !== profileItem.configuration ||
        initialProfile.count !== profileItem.count ||
        initialProfile.size !== profileItem.size ||
        initialProfile.units !== profileItem.units ||
        initialProfile.description.text !== profileItem.description.text ||
        JSON.stringify(initialProfile.media) !== JSON.stringify(profileItem.media) ||
        JSON.stringify(initialProfile.features) !== JSON.stringify(profileItem.features)
      ));
    }  

    const setAllImagesState = useCallback((checked: boolean) => {
        const media = _.cloneDeep(profileItem.media);
        media.forEach(item => {
          item.isChecked = checked;
        });
        setProfileItem(profileItem => profileItem = {...profileItem, media: media});
      }, [profileItem.media]);

    const checkboxCallback = useCallback((value: number) => {
        switch(value) {
            case 0: {
                setAllImagesState(false);
                break;
            }
            case 1: {
                break;
            }
            case 2: {
                setAllImagesState(true);
                break;
            }
        }
    }, [setAllImagesState]);

    const cancelCallBack = useCallback((cancelPage: boolean) => {
        setClickedCancel(false);
        if (cancelPage) {
          const payload: IDeleteMediaPayload = { mediaItems: mediaItems, showToast: false, mediaType: MediaTypeEnum.image };
          dispatch(deleteMedia(payload));
          if (profileItem.id) {
            let items = _.cloneDeep(profileItem.media);
            mediaItems.forEach(image => {
              const index = items.findIndex(x => x.id === image.id);
              items.splice(index, 1)
            });
  
            dispatch(UpdateExistingProfileItemMedia({id: profileItem.id, media: items}));
          } else {
            dispatch(UpdateProfileItemMediaItems([]));
          }
        }

        callBack(false, false, profileItem);
    }, [dispatch, mediaItems, callBack, profileItem]);

    const deleteProfileItemCallback = (confirmed: boolean) => {
      setShowDeleteModal(false);

      if (confirmed) {
        const payload: IProfileItemDeletePayload = {
          media: profileItem.media,
          id: profileItem.id,
          profileId: profile.id,
          translationItemId: profileItem.description.id,
          type: profileItem.type
        }
        dispatch(deleteProfileItem(payload));
        callBack(false, true, profileItem);
      }
    }

    const accordionCallBack = (checked: boolean, facility: IFeature) => {   
        let cloneProfileItem = _.cloneDeep(profileItem);
        if (checked) {
            const index = cloneProfileItem.features.findIndex(x => x.id === facility.id);
            if (index === -1) {
                cloneProfileItem.features.push(facility);
            }
        } 
        else {
            const index = cloneProfileItem.features.findIndex(x => x.id === facility.id);
            if (index > -1) {   
                cloneProfileItem.features.splice(index, 1);
            }
        }
        setProfileItem(x => x = {...x, features : cloneProfileItem.features});
    }

    const addNewSleepingArrangement = () => {
      setShowSectionalMessage(false);
      addNewItemCallback(profileItem.type);
    }

    const selectCallback = useCallback((mediaItems: IMediaItem[]) => {
      const selectedMediaItems = mediaItems.filter(x => x.isChecked === true);
      setSelectedImages(selectedMediaItems);
  }, [])
    
  const invalidFilesCallback = useCallback((invalidFilesArray: string[]) => {
    setInvalidFiles(invalidFilesArray);
    if(invalidFilesArray.length > 0) {
        setDisplayInvalidFiles(true);
    }
  }, [])

  const imageDropCallback = (newMediaItems: IMediaItem[]) => {
    dispatch(UpdateMedia_Reducer({mediaItems: newMediaItems, mediaType: MediaTypeEnum.image}));
  }

  const getMediaCount = () => {
    return profileItem.media?.length;
  }

    return(
        <Modal className="profile-item-modal" show={showModal} onHide={() => closeButtonClick()}>
            <Modal.Header className="sticky-modal-header" closeButton closeLabel="Close">
                <Container className="header-container">
                    <Row>
                        <Col className="header-inner-container">
                            <AcceptDeclineModal showModal={showCloseConfirmModal} callBack={closeConfirmCallBack} title={`Unsaved changes`}
                                body={`If you leave this page, any unsaved changes will be lost.`} declineButtonText="Stay on page" acceptButtonText="Leave page" />
                            <div>
                            {profile.name} <br />
                            {
                              item.id !== '' && <span className="lower-text">Sleeping arrangement {currentProfileItemIndex + 1} of {profileItems.length}</span>
                            }
                            </div>
                            <div className="middle-button-container">
                                <Button className="middle-buttons save" onClick={() => onSaveClick()}>Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>
            <Container className="margin-top-24 overlay-body-container">
              <h3>{originalName.length > 0 ? originalName : "Add sleeping arrangements"}</h3>
              {
                showSectionalMessage && 
                <Alert variant={'success'}>
                  <div className="alert-header-container">
                    <div className="alert-header-text"><BsCheckCircle size={24} /> {originalName} created</div>
                    <div onClick={() => setShowSectionalMessage(false)} className="close-button"><BsX size={24} /></div>
                  </div>
                  <Button onClick={() => addNewSleepingArrangement()} variant={'link'}>Add another sleeping arrangement</Button>
                </Alert>
              }
              <Card className="overlay-card" border="dark">
                <Card.Body className="wizard-section-card-body wide-container">
                  <Card.Title>What does your room type look like?</Card.Title>
                  <Card.Subtitle>Add your various room types with enticing descriptions and images.</Card.Subtitle>
                  <Container fluid className="padding-top-10"></Container>
                  <Row>
                    <Col xs="6">
                      <Form.Group className="padding-top-10">
                        <Form.Label>Name <span className="red-text">*</span></Form.Label>
                        <Form.Control name="name" value={profileItem.name} onChange={(event) => handleRoomNameChange(event.target.value)} />
                        {
                          (!isValid && !profileItem.name) && <div className="red-text">Please enter a name</div>
                        }
                      </Form.Group>
                    </Col>
                    <Col xs="6">
                      <Form.Group className="padding-top-10">
                      <div><Form.Label>Type</Form.Label></div>
                        <Form.Control
                          as="select"
                          name="profile-item-type"
                          className="profile-item-type"                         
                          custom={true}
                          defaultValue={item.type}
                          onChange={event => onChangeRoomType(event.target.value)}  >
                          { profileItemTypes.map((x, index) => (
                            <option key={index} value={x}>{x}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col  xs="6">
                      <Form.Group className="padding-top-10">
                      <div><Form.Label>Configuration</Form.Label></div>
                        <Form.Control
                          as="select"
                          name="configuation"
                          className="input form-control"
                          custom
                          defaultValue={item.configuration !== "" ? item.configuration : "Select"}
                          onChange={event => onChangeRoomConfiguration(event.target.value)}  >
                          {RoomConfiguration.map((x, index) => (
                            <option key={index}  value={x}>{x}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="6">
                      <Form.Group  className="padding-top-10">
                        <Form.Label>How many of this type do you have?</Form.Label>
                        <NumberStepper className="number-stepper" title={""} callBack={countCallback} initialCount={item.count} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <Form.Group >
                        <Form.Label>Size</Form.Label>
                        <div className="room-size-container">
                        <Form.Control className="room-size-input"   value={profileItem.size}  onChange={event => onSizeChangeNumberOnly(parseInt(event.target.value)) }  />
                        {profileItemUnitOfAreaTypes.map((option,i)=>{
                        return <label className="padding-left radio-label" key={i}>
                                  <input
                                      type="radio"
                                      checked={profileItem.units === option? true: false}
                                      onChange={event => onChangeUnitOfArea(event.target.value)}
                                      value={option}
                                      />
                                  <span className="radio-label-span">{option}</span>
                            </label>
                      })}
                      </div>
                      </Form.Group>
                    </Col>
                     <Col  xs="6">
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <Form.Group controlId="formDescription">
                        <Form.Label>Description <span className="red-text">*</span></Form.Label>
                        <Form.Control className={wordCount > 200 ? 'warning-border' : ''} as="textarea" rows={5}  name="description-input" value={profileItem.description.text}  onChange={event => onChangeDescription(event.target.value)} />

                        { (wordCount > 200) &&
                          <Form.Label className="font-12 float-right warning-text">{wordCount - 200} words over limit</Form.Label>
                        }
                        {
                          (wordCount <= 200) &&
                          <Form.Label className="font-12 float-right"> {200 - wordCount} words remaining</Form.Label>
                        }
                        {
                          (!isValid && !profileItem.description.text) && <div className="red-text">Please enter a description</div>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="overlay-card" border="dark">
                <Card.Body>                  
                  <Card.Subtitle>Images</Card.Subtitle>
                    {
                        (invalidFiles.length > 0 && displayInvalidFiles) &&
                        <Card border="dark margin-top-10">
                            <Card.Body>
                            <Card.Title className="invalid-file"><div><BsFillExclamationCircleFill /> &nbsp; These images couldn't be added</div><div><Button variant="clear" onClick={() => clearInvalidFiles()}><BsX /></Button></div></Card.Title>
                            <ul>
                                {invalidFiles.map((item, index) => <li key={index}>{item}</li>)}
                            </ul> 
                            </Card.Body>
                        </Card>
                    }
                    {
                      selectedImages.length > 0 &&
                      <div className="images-selected sleeping-arrangements-images-selected">
                            <Form.Label className="display-flex"><IndeterminateCheckbox value={selectedImages.length === profileItem.media?.length ? IndeterminateCheckboxEnum.checked : IndeterminateCheckboxEnum.indeterminate} callBack={checkboxCallback}></IndeterminateCheckbox> {selectedImages.length} {selectedImages.length === 1 ? "image" : "images"} selected</Form.Label>
                            <Button onClick={() => deleteSelectedImages()} variant="link">delete images</Button>
                            <AcceptDeclineModal showModal={showImageModal} callBack={deleteImagesCallBack} title={`Delete ${selectedImages.length} of ${profileItem.media.length} images`} body={"This can't be undone."} declineButtonText={selectedImages.length > 1 ? 'Keep images' : 'Keep image'} acceptButtonText={selectedImages.length > 1 ? 'Delete images' : 'Delete image'} />
                      </div>
                    }                    
                    <DropZone addButtonText={"Add images"} acceptedFileTypes={['image/jpeg', 'image/jpg', 'image/png']} 
                    dropMediaItems={profileItem.media} invalids={invalidFiles} uploadCallBack={uploadImageCallBack} 
                    isEnhanced={profile.isEnhanced} selectCallback={selectCallback} invalidFilesCallback={invalidFilesCallback}
                    itemDropCallback={imageDropCallback} mediaCount={getMediaCount()} 
                    profileName={profile.name} languageCodes={profile.availableLanguages} mediaType={MediaTypeEnum.image} />
                </Card.Body>
              </Card>
              <Container fluid className="padding-top-20"></Container>
              <Card className="section-card-outer" border="dark">
                <Card.Body className="wizard-section-card-body wide-container">
                  <Card.Subtitle>Facilities</Card.Subtitle>
                  <Row>
                    <Col xs="12">  
                        {Object.entries(facilityData[1]).map((child) => ( 
                              <CheckboxAccordion title={child[0]} content={child[1]} fixedCount={6} features={profileItem.features} key={child[0]} accordionCallBack={accordionCallBack} />                    
                        ))}
                        <AcceptDeclineModal showModal={clickedCancel} callBack={cancelCallBack} title={`Unsaved changes`} body={"If you leave now, any changes you've made to this room type will be lost."} declineButtonText='Stay on page' acceptButtonText='Leave page' />
                     </Col>
                  </Row>
                </Card.Body>
              </Card>
              {
                item.id && <Button onClick={() => setShowDeleteModal(true)} className="delete-arrangement-button" variant="outline-secondary"><BsTrash /> Delete sleeping arrangement</Button>
              }
              <AcceptDeclineModal showModal={showDeleteModal} callBack={deleteProfileItemCallback} title={`Delete ${originalName}`} body={`Are you sure you want to delete ${originalName}? This can't be undone.`} declineButtonText='Cancel' acceptButtonText='Delete sleeping arrangement' />
        </Container>
            </Modal.Body>
        </Modal>
    )
}