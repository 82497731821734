import React, { useEffect, useState } from 'react';
import './wizard.step-4.scss';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { GooglePlacesSearch } from 'components/google.places.search/google.places.search';
import { InsightsCard } from 'components/insights.card/insights.card';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { IsStepComplete } from 'utils/Validation';
import { UpdateProfileCity, UpdateProfileCountry, UpdateProfilePin, UpdateProfilePostalCode, UpdateProfileStreetAddress } from 'components/profile/profile.slice';
import { getAddress, getCity, getCountry, getPostalCode } from 'utils/GoogleMapsUtils';
import { WizardCallback } from 'components/wizard/types';

const WizardStep4Page = ({callBack}: WizardCallback) => {

  const { streetAddress, city, postalCode, country } = useSelector((state: RootState) => state.profileSlice.profile);
  const [ lAddress, setAddress ] = useState(streetAddress ? streetAddress : '');
  const [ lCity, setCity ] = useState(city ? city : '');
  const [ lPostalCode, setPostalCode ] = useState(postalCode ? postalCode : '');
  const [ lCountry, setCountry ] = useState(country ? country : '');
  const [ hasError, setHasError ] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const stepComplete = IsStepComplete([streetAddress, city, country]);
    callBack({id: 4, completed: stepComplete, skip: false, displayFooterNavigation: true, displayNext: true});
  }, [callBack, city, hasError, postalCode, streetAddress, country]);

  const googlePlacesCallback = (googlePlaceResult: google.maps.places.PlaceResult, hasGoogleError: boolean) => {
    setHasError(hasGoogleError);
    if(!hasGoogleError && googlePlaceResult.name) {
      const googleStreetAddress = getAddress(googlePlaceResult);
      const freeTextStreetAddress = googlePlaceResult.name;
      const country = getCountry(googlePlaceResult);
      const city = getCity(googlePlaceResult);
      const postalCode = getPostalCode(googlePlaceResult);
      handleCountryChange(country, true);
      handleCityChange(city, true);
      handlePostalCodeChange(postalCode);
      handleAddressChange(freeTextStreetAddress, googleStreetAddress);

      const pin =  {
        latitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location ? googlePlaceResult.geometry.location.lat() : 0,
        longitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location && googlePlaceResult.geometry ? googlePlaceResult.geometry.location.lng() : 0,
        drivingLatitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location && googlePlaceResult.geometry ? googlePlaceResult.geometry.location.lat() : 0,
        drivingLongitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location && googlePlaceResult.geometry ? googlePlaceResult.geometry.location.lng() : 0,
      }

      dispatch(UpdateProfilePin(pin));
    } else {
      handleAddressChange('');
    }
  }

  const handleAddressChange = (freeTextStreetAddress: string, googleStreetAddress: string = '') => {
    const saveAddress = googleStreetAddress ? googleStreetAddress : freeTextStreetAddress;
    setAddress(saveAddress);
    dispatch(UpdateProfileStreetAddress(saveAddress));
  } 

  const handleCountryChange = (value: string, isFromGoogleLookup: boolean = false) => {
    setCountry(value);
    dispatch(UpdateProfileCountry({value: value, resetPin: false}));
  }
  
  const handleCityChange = (value: string, isFromGoogleLookup: boolean = false) => {
    setCity(value);
    dispatch(UpdateProfileCity({value: value, resetPin: false}));
  }

  const handlePostalCodeChange = (value: string) => {
    setPostalCode(value);
    dispatch(UpdateProfilePostalCode(value));
  }

  return (
    <Container fluid>
    <Row className="margin-top-20">
      <Col xs="2">
      </Col>
      <Col xs="6">
        <Card border="dark">
          <Card.Body>
            <Card.Title>Where's your property located?</Card.Title>
            <Form.Group>
              <GooglePlacesSearch label={'Street address'} value={lAddress} callBack={googlePlacesCallback} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Country/region</Form.Label>
              <Form.Control value={lCountry} onChange={event => handleCountryChange(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control value={lCity} onChange={event => handleCityChange(event.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Postal code</Form.Label>
              <Form.Control value={lPostalCode} onChange={event => handlePostalCodeChange(event.target.value)} />
            </Form.Group>  
          </Card.Body>
        </Card>
      </Col>
      <Col xs="2"></Col>
      <Col xs="2" className="insights-display">
        <InsightsCard title={"testing subtitle"} titleIcon={""} />
      </Col>
    </Row>
  </Container>
  )
}

export default WizardStep4Page;