export const ItemTypes = {
    IMAGE: 'image',
    VIDEO: 'video'
}

export interface DragItem {
  index: number;
  id: string;
  type: string;
  groupId: string;
}
