import axios from 'axios';
import { IProfile, IUpdateProfilePayload, ICreateProfilePayload, IProfileSummary, IValidateProfilePayload } from 'components/profile/types';
import { getApiBaseUrl } from '../app/config';

const baseProfileUrl = getApiBaseUrl() + 'profile/';

export async function getProfileById(profileId: string, accountId: string) {
  return axios.get<IProfile>(baseProfileUrl + `GetById?profileId=${profileId}&accountId=${accountId}`, { 
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  }).catch(function (error) {
    if (error.response) {
      console.error('getProfileById error.response.data: ', error.response.data);
      console.error('getProfileById error.response.status: ', error.response.status);
    }
  });
}

export async function updateProfile(payload: IUpdateProfilePayload) {
  return axios.post<IProfile>(baseProfileUrl + "Update", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  });
}

export async function createProfile(payload: ICreateProfilePayload) {
  return axios.post<IProfile>(baseProfileUrl + "Create", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  });
}

export async function validateProfile(payload: IValidateProfilePayload) {
  return axios.post<IProfile>(baseProfileUrl + "Validate", payload, { 
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  });
}

export async function getProfileSummaries(id: string) {
  return axios.get<IProfileSummary[]>(baseProfileUrl + `GetProfileSummaries?accountId=${id}`, { 
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  });
}