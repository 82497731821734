import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Accordion, Card, Button, Spinner } from 'react-bootstrap';
import './dropzone.scss';
import { DropZoneImage } from './features/image/dropzone.image';
import { DropZoneVideo } from './features/video/dropzone.video';
import { DropZoneVirtualTour } from './features/virtualTour/dropzone.virtualTour';
import { ImageEditModal } from './features/image.edit.modal/image.edit.modal';
import update from 'immutability-helper';
import _ from 'lodash';
import { DocumentTypeEnum, DocumentVisibilityEnum, EntityTypeEnum, IMediaItem, MediaTypeEnum } from 'components/profile/types';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import { Dictionary } from '@reduxjs/toolkit';
import { pluralise } from 'utils/General';

interface DropzoneIProps {
    uploadCallBack: (items: IMediaItem[], invalidFiles: string[]) => void;
    itemDropCallback?: (items: IMediaItem[]) => void;
    modalPopupCallback?: (value: Boolean) => void;
    saveCallback?: () => void;
    selectCallback?: (mediaItems: IMediaItem[]) => void;
    invalidFilesCallback?: (invalidFiles: string[]) => void;
    profileName?: string
    dropMediaItems: IMediaItem[];
    itemLimit?: number;
    invalids?: string[];
    isEnhanced: boolean;
    isItemsEditable?: boolean;
    languageCodes: string[];
    acceptedFileTypes?: string[];
    addButtonText: string;  
    mediaType:  MediaTypeEnum;
    mediaCount: number;
    documentType?: DocumentTypeEnum;
    confirmPermission?: boolean;
  }

const DropZone = ({uploadCallBack, itemDropCallback, saveCallback, modalPopupCallback, selectCallback, invalidFilesCallback, 
    dropMediaItems, itemLimit = 30, invalids = [], acceptedFileTypes, addButtonText, profileName, isEnhanced, mediaType, 
    isItemsEditable = false, languageCodes, mediaCount, documentType, confirmPermission = true}: DropzoneIProps) => {

    const getMediaName = (isPlural: boolean): string => {
        const mediaName = mediaType === MediaTypeEnum.image ? "image" : mediaType === MediaTypeEnum.documentation ? "document" : "video";
        return pluralise(mediaName, isPlural);
    }

    const [ showEditModal, setShowEditModal ] = useState<boolean>(false);
    const [ currentItemIndex, setCurrentItemIndex ] = useState<number>(0);
    const [ mediaItems, setMediaItems ] = useState<IMediaItem[]>(dropMediaItems);
    const [ invalidFiles, setInvalidFiles ] = useState<string[]>(invalids);
    const [ hasDragOver, setHasDragOver ] = useState<boolean>(false);
    const [ hasSelectedItems, setHasSelectedItems ] = useState<boolean>(false);
    const [ showingMoreItems, setShowingMoreItems ] = useState<boolean>(false);
    const [ showUsagePolicy, setShowUsagePolicy ] = useState<boolean>(false);
    const [ usagePolicyFiles, setUsagePolicyFiles ] = useState<File[]>([]);
    const [ confirmationLabel, setConfirmationLabel ] = useState<string>(`I confirm we have permission to use these ${getMediaName(true)}.`);
    const [ itemDisplayAmount, setItemDisplayAmount] = useState<number>(4); //The number of media items that will display in the dropzone without a 'Show more' button appearing.
    const [ permissionConfirmationBody ] = useState<string>(`Before you upload ${getMediaName(true)}, please confirm you have permission to use them.`);
    const [ originalSequenceNumbers, setOriginalSequenceNumbers ] = useState<Dictionary<number>>();

    const isMediaLoaded = useCallback(() => {
        //mediaItems comes from the local state of MediaPage. But setting local state is async and we don't know when it is finished updating.
        //So initially mediaItems will be empty, then populate. This was causing an unwanted re-render.
        //mediaCount is set directly from the mediaItems on MediaPage once mediaItems have loaded (without going via any local state).
        //So mediaCount can be trusted and used to compare with mediaItems.
        //TODO: we could into something like this: https://github.com/the-road-to-learn-react/use-state-with-callback#usage
        return mediaCount === mediaItems?.length;
    }, [mediaCount, mediaItems])

    //useEffect: set local mediaItems from dropMediaItems passed from the parent component (ultimately from the API)
    useEffect(() => {
        if (dropMediaItems?.length === mediaCount) {
            setMediaItems(dropMediaItems);
            //If originalSequenceNumbers has not been set, set it from dropMediaItems (from the DB).
            setOriginalSequenceNumbers(items => {
                const isNewSavedSequence = !dropMediaItems.find(x => x.isChanged);
                return !items || isNewSavedSequence ? Object.assign({}, ...dropMediaItems.map((x) => ({[x.id]: x.sequence}))) : items;
            })
        }
    }, [dropMediaItems, mediaCount]);

    //useEffect: Set local invalidFiles from invalids passed from the parent component.
    useEffect(() => {
        if (isMediaLoaded()) {
            setInvalidFiles(invalids);
        }
    }, [invalids, isMediaLoaded]);

    //useEffect: update the show more component when media are added/removed.
    useEffect(() => {
        if (isMediaLoaded()) {
            const forceShowCheckbox = (): boolean => {
                const anyItemSetToTrue: IMediaItem[] = mediaItems?.filter(x => x.isChecked);
                if (anyItemSetToTrue?.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
          
            if (mediaItems?.length <= 4 && itemDisplayAmount !== 4 && showingMoreItems) {
                setItemDisplayAmount(4);
                setShowingMoreItems(false);
            }
    
            const isShowCheckbox = forceShowCheckbox();
            if (isShowCheckbox !== hasSelectedItems) {
                setHasSelectedItems(forceShowCheckbox());
            }
        }
        
    }, [mediaItems, showingMoreItems, itemDisplayAmount, isMediaLoaded, hasSelectedItems]);

    const dragOver = (e: any) => {
        if (mediaType !== MediaTypeEnum.virtualTour) {
            e.stopPropagation();
            e.preventDefault();
            if (e.dataTransfer.items[0].kind === 'file') {
                setHasDragOver(true);
            }
        }
    }
    
    const dragEnter = (e: any) => {
        if (mediaType !== MediaTypeEnum.virtualTour) {
            e.preventDefault();
        }
    }
    
    const dragLeave = (e: any) => {
        if (mediaType !== MediaTypeEnum.virtualTour) {
            e.preventDefault();
            if (e.dataTransfer.items[0].kind === 'file') {
                setHasDragOver(false);
            }        
        }
    }

    const dragEnd = (e: any) => {
        if (mediaType !== MediaTypeEnum.virtualTour) {
            e.preventDefault();
            setHasDragOver(false);
        }
    }
    
    const fileDrop = (e: any) => {
        if (mediaType !== MediaTypeEnum.virtualTour) {
            e.stopPropagation();
            e.preventDefault();
            if (mediaItems?.length === itemLimit) {
                if (e.dataTransfer.items[0].kind === 'file') {
                    setHasDragOver(false);
                }
                return;
            }
            const files: File[] = Array.from(e.dataTransfer.files);
            if (files.length) {
                setConfirmationLabel(files.length > 1 ?  `I confirm we have permission to use these ${getMediaName(true)}.` : 
                `I confirm we have permission to use this ${getMediaName(false)}.`);
                if (confirmPermission) {
                    setShowUsagePolicy(true);
                    setUsagePolicyFiles(files);
                }
                else {
                    handleFiles(files);
                }
            }
        }
    }

    const handleFiles = useCallback((files: File[]) => {
        const validateFile = (file: File) => {
            return !acceptedFileTypes || acceptedFileTypes.indexOf(file.type) !== -1;
        }

        let mediaItemsTemp: IMediaItem[] = [];
        let invalidFileTemp: string[] = invalidFiles;
        let sequenceNumber = mediaItems.length;
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                mediaItemsTemp.push({file:files[i], isChecked: false, url: '', hasFileSizeWarning: false, id: '', filePath: '', 
                label: {enText: '', translations: {}, machineTranslations: {}}, description: {enText: '', translations: {}, machineTranslations: {}}, 
                profileId: '', entityType: EntityTypeEnum.media, azureContainerName: '', mediaType: mediaType, credit: '', documentType: documentType ? documentType : DocumentTypeEnum.covidNotice,
                parentId: '', fileSizeKb: 0, width: 0, height: 0, originalFileName: '', sequence: sequenceNumber, isChanged: false,
                visibility: DocumentVisibilityEnum.publicAndTrade});
                sequenceNumber++;
            } else {
                const supportedValidFilesMessage = `is not a supported file type. Upload files ending in ${mediaType === MediaTypeEnum.image ? ".jpg, .png." : "webm, mp4, mov"}`;
                invalidFileTemp.push(files[i].name + supportedValidFilesMessage);
            }
        }
        if (invalidFileTemp.length > 0) {
            if (invalidFilesCallback) {
                invalidFilesCallback(invalidFileTemp);
            }
        }
        if (mediaItemsTemp.length > 0) {
            const newArray = mediaItems.concat(mediaItemsTemp);
            setMediaItems(newArray);
            uploadCallBack(newArray, invalidFileTemp);
        }
        
    }, [acceptedFileTypes, mediaType, mediaItems, invalidFiles, documentType, uploadCallBack, invalidFilesCallback])

    const acceptUsagePolicyCallback = useCallback((isAccepted: boolean) => {
        setShowUsagePolicy(false);
        if (isAccepted) {
            handleFiles(usagePolicyFiles);
        }
        setUsagePolicyFiles([]);
        setHasDragOver(false);
    }, [handleFiles, usagePolicyFiles])

    const handleChange = (filesInput: FileList) => {
        const files: File[] = Array.from(filesInput);
        if (mediaType === MediaTypeEnum.documentation) {
            handleFiles(files);
        }
        else if (mediaType !== MediaTypeEnum.virtualTour) {
            if (files.length && confirmPermission) {         
                setConfirmationLabel(files.length > 1 ? `I confirm we have permission to use these ${mediaType === MediaTypeEnum.image ? "images" : "videos"}.` : 
                `I confirm we have permission to use this ${mediaType === MediaTypeEnum.image ? "image" : "video"}.`);            
                setShowUsagePolicy(true);
                setUsagePolicyFiles(files);
            }
            else {
                handleFiles(files);
                setHasDragOver(false);
            }
        }
    }

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        const dragItem = mediaItems[dragIndex];
        setMediaItems(
            update(mediaItems, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragItem],
              ],
            }),
        )
    }, [mediaItems])

    const itemDrop = useCallback(() => {
        let mediaItemsCopy: IMediaItem[] = _.cloneDeep(mediaItems);
        for (let i = 0; i < mediaItemsCopy.length; i++) {
            mediaItemsCopy[i].sequence = i;
            if (originalSequenceNumbers && mediaItemsCopy[i].sequence !== originalSequenceNumbers[mediaItemsCopy[i].id]) {
                mediaItemsCopy[i].isChanged = true;
            }
            else {
                mediaItemsCopy[i].isChanged = false;
            }
        }
        setMediaItems(mediaItemsCopy);
        if (itemDropCallback) {
            itemDropCallback(mediaItemsCopy);
        }
    }, [mediaItems, itemDropCallback, originalSequenceNumbers]);

    const onItemSelected = (isChecked: boolean, url: string) => {
        const itemCollection: IMediaItem[] = _.cloneDeep(mediaItems);
        const index = itemCollection.findIndex(x => x.url === url);
        itemCollection[index].isChecked = isChecked;
        setMediaItems(itemCollection);
        if (selectCallback) {
            selectCallback(itemCollection);
        }
    }

    const onEditClickCallback = (itemIndex: number) => {
        setCurrentItemIndex(itemIndex);
        setShowEditModal(true);
    }
    
    const onEditVirtualTourClickCallback = (itemIndex: number) => {
        setCurrentItemIndex(itemIndex);       
    }

    const closeCallBack = () => {
        setShowEditModal(false);
    }

    const deleteCallback = (newIndex: number) => {
        setCurrentItemIndex(newIndex);
    }

    const saveCallbackLocal = (newItems: IMediaItem[], newItemIndex: number) => {
        setMediaItems(newItems);
        setCurrentItemIndex(newItemIndex);
        if (saveCallback) {
            saveCallback();
        }
    }

    const openModalPopup = useCallback(() => {
        if (modalPopupCallback) {
            modalPopupCallback(true)
        }
    }, [modalPopupCallback]);  

    const accordianToggle = () => {
        if (showingMoreItems){
            setItemDisplayAmount(4);
        } else  {
            if (mediaItems?.length >= 5){
                setItemDisplayAmount(5);
            } else {
                setItemDisplayAmount(4);
            } 
        }
        setShowingMoreItems(!showingMoreItems);  
    }

    const fileInputOnClick = (e: any) => {
        //Clear the existing file input value so that if they browse for the same file again (after clicking cancel the first time) the onChange event handler for the file input fires. 
        //From this example: https://newbedev.com/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        e.target.value = '';
    }

    const renderAddItemsButton = () => {
        return (
        <div className="add-items">
            <div className="align-content-center">
                <Form.Label className={`${mediaItems?.length !== itemLimit ? "custom-file-upload-link-active" : "custom-file-upload-link-inactive" }`}>
                    <Form.File disabled={mediaItems?.length === itemLimit}
                    onClick={(e: any) => fileInputOnClick(e)}
                    onChange={(e: any) => handleChange(e.target.files)} multiple={true}></Form.File>
                    {addButtonText}
                </Form.Label>
            </div>
            <div className="align-content-center">
                <p>or drag to upload</p>
            </div>
        </div>
        )
    }

    const renderVideoDropZone = (item: IMediaItem, index: number) => {
        return (
            <DropZoneVideo imageDropCallback={itemDrop} forceShowCheckbox={hasSelectedItems} hasFileSizeWarning={item.hasFileSizeWarning} 
            imageSelected={item.isChecked} imageSelectedCallBack={onItemSelected} url={item.url} 
            id={item.file?.name ? item.file.name : ''} index={index} moveImage={moveItem} 
            onEditClickCallback={onEditClickCallback} isImagesEditable={isItemsEditable}
            imageClass={`gallery__image`} src={item.file ? item.file : undefined} mediaType={item.mediaType} />
        )
    }

    const renderImageDropZone = (item: IMediaItem, index: number) => {
        return (
            <DropZoneImage imageDropCallback={itemDrop} forceShowCheckbox={hasSelectedItems} hasFileSizeWarning={item.hasFileSizeWarning} 
            imageSelected={item.isChecked} imageSelectedCallBack={onItemSelected} url={item.url} 
            id={item.file?.name ? item.file.name : ''} index={index} moveImage={moveItem} 
            onEditClickCallback={onEditClickCallback} isImagesEditable={isItemsEditable}
            imageClass={`gallery__image`} src={item.file ? item.file : undefined} />
        )
    }

    const renderVirtualTourDropzone = (item: IMediaItem, index: number) => {
        return (
            <DropZoneVirtualTour label={item.label} mediaDropCallback={itemDrop} forceShowCheckbox={hasSelectedItems} 
            hasFileSizeWarning={item.hasFileSizeWarning} mediaSelected={item.isChecked} mediaSelectedCallBack={onItemSelected} 
            url={item.url} id={item.file?.name ? item.file.name : ''} index={index} moveMedia={moveItem} 
            onEditClickCallback={onEditVirtualTourClickCallback} isMediaEditable={isItemsEditable}
            src={item.file ? item.file : undefined} />
        )
    }

    return (
                <Col className="dropzone-container">
                    <div id="dropzone" className={`dropzone ${mediaItems?.length < 1 ? "dashed-border" : ""} 
                    ${(hasDragOver && mediaItems?.length === 30) ? "dashed-border-warning dropzone-warning-background" : ""} 
                    ${(hasDragOver && (mediaItems?.length > 0 && mediaItems?.length < 30)) ? "dashed-border-interact" : ""} 
                    ${hasDragOver && mediaItems?.length < 30 ? "dropzone-interact-background" : ""}`}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDragEnd={dragEnd}
                    onDrop={fileDrop}>
                        {
                            (mediaType === MediaTypeEnum.virtualTour) && (dropMediaItems.length === 0) &&
                            <Container className="align-content-center">
                                <Row>
                                    <Col xs="12" >
                                        <Button onClick={() => openModalPopup()}> {addButtonText}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            (!hasDragOver && mediaItems?.length === 0)  && (mediaType !== MediaTypeEnum.virtualTour) &&
                            <Container className="align-content-center">
                                <Row>
                                    <Col xs="12" >
                                        <Form.Label className="custom-file-upload-button">
                                              <Form.File className="my-classname" id="form-file-1" 
                                                onChange={(e: any) => handleChange(e.target.files)} multiple={true}></Form.File>
                                            {addButtonText}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" >                                        
                                        <p>or drop files to upload</p>                                          
                                    </Col>
                                </Row>
                                { acceptedFileTypes &&
                                    <Row>
                                        <Col xs="12" className="filetype-text">
                                            <p> Supported file types <span>{acceptedFileTypes.map((filetype, index) => <React.Fragment key={index}>.{filetype.split("/")[1].toUpperCase()} </React.Fragment>)}</span></p>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                        }
                        {
                            (hasDragOver && mediaItems?.length >= 0 && mediaItems?.length < itemLimit) && (mediaType !== MediaTypeEnum.virtualTour) &&
                            <Container className="align-content-center">
                                <Row>
                                    <Col xs="12" className="align-content-center">
                                        <p>drop files to upload</p>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            (hasDragOver && mediaItems?.length === itemLimit) &&
                            <Container className="align-content-center">
                                <Row>
                                    <Col xs="12" className="align-content-center">
                                        <p>You can upload a maximum of {itemLimit} {mediaType}</p>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        {
                            (!hasDragOver && mediaItems?.length > 0 && mediaItems?.length <= itemLimit && mediaType !== MediaTypeEnum.documentation) &&
                            <React.Fragment>
                            <div className="gallery-container">
                            <div className="gallery">
                                {(mediaType === MediaTypeEnum.video) && 
                                    mediaItems?.slice(0, itemDisplayAmount).map((mediaItem, index) => <div className={`${index === 0 ? "gallery_video__item--1" : "gallery__item"}`} key={index}>
                                    {renderVideoDropZone(mediaItem, index)}
                                    </div>)
                                }
                                {(mediaType === MediaTypeEnum.image) &&
                                    mediaItems?.slice(0, itemDisplayAmount).map((mediaItem, index) => <div className={`${index === 0 ? "gallery__item--1" : "gallery__item"}`} key={index}>
                                    {renderImageDropZone(mediaItem, index)} </div>)
                                }
                                {(mediaType === MediaTypeEnum.virtualTour) &&
                                    mediaItems?.slice(0, itemDisplayAmount).map((mediaItem, index) => <div className={`${index === 0 ? "gallery__item--1" : "gallery__item"}`} key={index}>
                                    {renderVirtualTourDropzone(mediaItem, index)}</div>)
                                }

                                { !showingMoreItems && (mediaType !== MediaTypeEnum.virtualTour) &&
                                     renderAddItemsButton() 
                                }
                                { !showingMoreItems && (mediaType === MediaTypeEnum.virtualTour) &&
                                <div className="add-images">
                                    <div className="align-content-center">
                                       <Button onClick={() => openModalPopup()} variant="link"> {addButtonText}</Button>
                                    </div>                                       
                                </div>
                                }
                                
                            </div>
                            {
                                mediaItems?.length >= itemDisplayAmount && mediaItems?.length > 4 &&
                                <Accordion className="margin-top-10">
                                    <Card className="no-border">
                                    <Accordion.Collapse eventKey="0">
                                            <Card.Body className="padding-none">
                                            <div className="show-more-gallery">
                                            {mediaItems?.map((mediaItem, index) => index >= itemDisplayAmount ? 
                                            <div className="gallery__item" key={index}>                                                
                                                {(mediaType === MediaTypeEnum.video) && 
                                                    renderVideoDropZone(mediaItem, index)
                                                }
                                                {(mediaType === MediaTypeEnum.image) &&
                                                    renderImageDropZone(mediaItem, index)
                                                }
                                                {(mediaType === MediaTypeEnum.virtualTour) &&
                                                    renderVirtualTourDropzone(mediaItem, index)
                                                }
                                            </div>
                                            : 
                                            null)} 
                                                {
                                                    mediaItems?.length >= itemDisplayAmount && (mediaType === MediaTypeEnum.virtualTour || mediaType === MediaTypeEnum.image) &&
                                                    renderAddItemsButton()
                                                }
                                                { (mediaType === MediaTypeEnum.virtualTour) &&
                                                    <div className="add-images">
                                                        <div className="align-content-center">
                                                        <Button onClick={() => openModalPopup()} variant="link"> {addButtonText}</Button>
                                                        </div>                                       
                                                    </div>
                                                }
                                            </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle onClick={() => accordianToggle()} className="margin-top-none cursor-pointer" as={Card.Header} eventKey="0">
                                            {(!showingMoreItems) && <span className="show-more-less-toggle"> Show more <BsChevronDown className="show-more-less-icon" /></span> }
                                            {(showingMoreItems) &&  <span className="show-more-less-toggle"> Show less <BsChevronUp className="show-more-less-icon" /></span>}  
                                        </Accordion.Toggle>
                                    </Card>
                                </Accordion>
                            }
                            </div>
                            </React.Fragment>
                        }
                        {
                            (!hasDragOver && mediaItems?.length > 0 && mediaItems?.length <= itemLimit && mediaType === MediaTypeEnum.documentation ) &&
                            <Spinner animation="border" variant="primary" className="center-content" />
                        }
                    </div>
                    <ImageEditModal showModal={showEditModal} profileName={profileName} closeCallBack={closeCallBack} images={mediaItems} 
                    currentImageIndex={currentItemIndex} isEnhanced={isEnhanced} languageCodes={languageCodes} deleteCallback={deleteCallback} saveCallback={saveCallbackLocal} />
                    <AcceptDeclineModal confirmationRequired={true} confirmationLabel={confirmationLabel} title={"Confirm usage rights"}
                    body={permissionConfirmationBody} showModal={showUsagePolicy} 
                    callBack={acceptUsagePolicyCallback} acceptButtonText={"Continue upload"} declineButtonText={"Cancel"} />
                </Col>
    );
}

export default DropZone;