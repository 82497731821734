import DropZone from 'components/dropzone/dropzone';
import { ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import { IMediaItem, MediaTypeEnum } from 'components/profile/types';
import React, { useCallback, useEffect, useState }  from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { MdLens } from 'react-icons/md';
import "./media.virtual.tours.scss";
import { UpdateMedia_Reducer } from 'components/profile/mediaitem/mediaitem.slice';
import { EmbedVirtualToursModal } from 'components/embed.virtualtours.modal/embed.virtualtours.modal';
import { IndeterminateCheckbox } from 'components/indeterminate.checkbox/indeterminate.checkbox';
import { IndeterminateCheckboxEnum } from 'components/indeterminate.checkbox/types';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import { useAppDispatch } from 'app/store';
import _ from 'lodash';

export interface IProps {
    title: string;
    subtitle: string;
    isEnhanced: boolean;
    languageCodes: string[];
    mediaPayload: IMediaItem[];
    isMediaEditable: boolean;
    isCompleted: boolean;
    isProfileForm?: boolean;
    mediaCount: number;
    saveCallback?: () => void;
    deleteCallback: (selectedMedia: IMediaItem[], mediaType: MediaTypeEnum) => void;
}

export const MediaVirtualToursCard = ({title, subtitle, isEnhanced, languageCodes, mediaPayload, isMediaEditable, isCompleted, isProfileForm,
    mediaCount, saveCallback, deleteCallback }: IProps) => {
    const [ addButtonText] = useState<string>("Embed virtual tour");
    const [ acceptedFileTypes] = useState<string[]>(['']);
    const [ uploadedMedia, setUploadedMedia ] = useState<IMediaItem[]>([]);
    const [ selectedMedia, setSelectedMedia ] = useState<IMediaItem[]>([]);
    const [ invalidFiles, setInvalidFiles ] = useState<string[]>([]);  
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ showEmbedModal, setShowEmbedModal ] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setUploadedMedia(mediaPayload);
    }, [mediaPayload]);

    const deleteSelectedMedia = () => {
        setShowModal(true);
    }

    const deleteMediaCallBack = useCallback((deleteImages: boolean) => {
        setShowModal(false);
        if (deleteImages) {
            setSelectedMedia([]);
            deleteCallback(selectedMedia, MediaTypeEnum.virtualTour);
        }
    }, [selectedMedia, deleteCallback])

    const setAllImagesState = useCallback((checked: boolean) => {
        const images: IMediaItem[] = _.cloneDeep(uploadedMedia);
        images.forEach(image => {
            image.isChecked = checked;
        });
        setUploadedMedia(images);
    }, [uploadedMedia]);

    const checkboxCallback = useCallback((value: number) => {
        switch(value) {
            case 0: {
                setAllImagesState(false);
                break;
            }
            case 1: {
                break;
            }
            case 2: {
                setAllImagesState(true);
                break;
            }
        }
    }, [setAllImagesState]);
      
    const saveCallbackLocal = () => {
        if (saveCallback) {
            saveCallback();
        }
    }

    const uploadMediaCallBack = useCallback((videos: IMediaItem[], invalidFiles: string[]) => {      
        const selectedMedia = videos.filter(x => x.isChecked === true);
        setSelectedMedia(selectedMedia);
         setInvalidFiles(invalidFiles);
    }, []);

    const selectCallback = useCallback((mediaItems: IMediaItem[]) => {
        const selectedMediaItems = mediaItems.filter(x => x.isChecked === true);
        setSelectedMedia(selectedMediaItems);
    }, [])

    const embedPopupModalOpenCallBack = useCallback(() => {
        embedPopupModalOpen();
    }, []);

    const embedPopupModalOpen = () => {
        setShowEmbedModal(true);
    }    

    const embedVideosCloseCallBack = useCallback(() => {
        setShowEmbedModal(false);
    }, []);

    const embedVideosCallBack = useCallback(() => {
    }, []);    

    const virtualTourDropCallback = (newMediaItems: IMediaItem[]) => {
        dispatch(UpdateMedia_Reducer({mediaItems: newMediaItems, mediaType: MediaTypeEnum.virtualTour}));
    }

    return(
        <Card id={ProfileFormPageSectionEnum.virtualTours} border="dark" className="videos-card">
            <Card.Body>
                <Card.Title className="card-section-dot card-title-section">
                    <div>
                        {isProfileForm && <MdLens className={`section-dot ${isCompleted ? "complete-dot" : "incomplete-dot" }`} />}                      
                        {title}                    
                    </div>
                </Card.Title>
                <Card.Subtitle>
                    {subtitle}
                </Card.Subtitle>
                <EmbedVirtualToursModal  showModal={showEmbedModal} callBack={embedVideosCallBack} closeCallback={embedVideosCloseCallBack}  />
                {
                    (selectedMedia.length > 0 && mediaPayload.length !== 0 ) &&                   
                    <div className="images-selected">
                        <Form.Label className="display-flex"><IndeterminateCheckbox 
                            value={selectedMedia.length === uploadedMedia.length ? IndeterminateCheckboxEnum.checked : IndeterminateCheckboxEnum.indeterminate} 
                            callBack={checkboxCallback}></IndeterminateCheckbox> {selectedMedia.length} {selectedMedia.length === 1 ? "virtual tour" : "virtual tours"} selected</Form.Label>
                        <Button onClick={() => deleteSelectedMedia()} variant="link">delete virtual tours</Button>
                        <AcceptDeclineModal showModal={showModal} callBack={deleteMediaCallBack} title={`Delete ${selectedMedia.length} of ${uploadedMedia.length} virtual tours`}
                            body={"This can't be undone."} declineButtonText={selectedMedia.length > 1 ? 'Keep virtual tours' : 'Keep virtual tour'} 
                            acceptButtonText={selectedMedia.length > 1 ? 'Delete virtual tours' : 'Delete virtual tour'} />
                    </div>
                }

                <DropZone addButtonText={addButtonText} acceptedFileTypes={acceptedFileTypes} dropMediaItems={uploadedMedia} invalids={invalidFiles} 
                uploadCallBack={uploadMediaCallBack} isEnhanced={isEnhanced} languageCodes={languageCodes} isItemsEditable={isMediaEditable} 
                saveCallback={saveCallbackLocal} itemDropCallback={virtualTourDropCallback} mediaType={MediaTypeEnum.virtualTour}
                modalPopupCallback={embedPopupModalOpenCallBack} mediaCount={mediaCount} selectCallback={selectCallback} /> 
            </Card.Body>
        </Card>
    )
}