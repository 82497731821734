import { GridRow } from 'components/grid.row/grid.row';
import { IProfileSummary } from 'components/profile/types';
import { SortLabel } from 'components/sort.label/sort.label';
import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Nav, Navbar, NavDropdown, Table } from 'react-bootstrap';
import './profile.grid.scss';
import _ from 'lodash';
import { BsLayoutThreeColumns, BsSearch} from 'react-icons/bs';
import { typesArray } from 'app/config';
import { ProfileFormPageEnum } from '../../pages/profile-form/profile.form.types';

interface IProfileGridProps {
    profileSummaries: IProfileSummary[];
} 

interface IColumnHeader {
  label: string;
  key: ColumnHeaderEnum;
}

interface IStatus {
  label: string;
  key: StatusEnum;
}

enum StatusEnum {
  all = "All",
  active = "Active",
  inProgress = "InProgress",
  inReview = "InReview",
  disabled = "Disabled"
}

enum ColumnHeaderEnum {
  name = "name",
  status = "status",
  visibility = "isPublished",
  rating = "contentRating",
  tier = "isEnhanced",
  type = "type",
  lastModified = "lastModified"
}

interface IType {
  label: string;
  value: string;
}

export const ProfileGrid = ({profileSummaries}: IProfileGridProps) => {
    const [sortedProfiles, setSortedProfiles] = useState<IProfileSummary[]>(_.orderBy(profileSummaries, ColumnHeaderEnum.lastModified, "desc"));
    const [order, setOrder] = useState<string>("desc");
    const [currentSortColumn, setCurrentSortColumn] = useState<string>(ColumnHeaderEnum.lastModified);
    const columnHeaders: IColumnHeader[] = [{label:"Name", key: ColumnHeaderEnum.name},
                                           {label:"Status", key: ColumnHeaderEnum.status},
                                           {label:"To Do", key: ColumnHeaderEnum.status},
                                           {label:"Visibility", key: ColumnHeaderEnum.visibility},
                                           {label:"Rating", key: ColumnHeaderEnum.rating},
                                           {label:"Tier", key: ColumnHeaderEnum.tier},
                                           {label:"Type", key: ColumnHeaderEnum.type},
                                           {label:"Last Modified", key: ColumnHeaderEnum.lastModified} ];
    const statuses: IStatus[] = [{label: "All", key: StatusEnum.all},
                                {label: "Active", key: StatusEnum.active},
                                {label: "In Progress", key: StatusEnum.inProgress},
                                {label: "In Review", key: StatusEnum.inReview},
                                {label: "Disabled", key: StatusEnum.disabled} ];                                  
    const [currentStatus, setCurrentStatus] = useState<string>("All");
    const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
    const [currentTier, setCurrentTier] = useState<string>("");
    const [currentType, setCurrentType] = useState<IType>({label: "", value: ""});

    const sortProfilesByDirectionAndColumn = (sortValue: string, sortDirection: string) => {
        const sort = sortDirection === "asc" ? "asc" : "desc"; 
        setCurrentSortColumn(sortValue);
        setOrder(sort);
    }
    
    useEffect(() => {
      const sortProfilesByFilterValues = () => {
        const profiles = _.filter(profileSummaries, (summary) => {
          return summary.name?.toLowerCase().includes(currentSearchValue.toLowerCase()) &&
          (currentTier !== "" && currentTier === "Enhanced" ? summary.isEnhanced : currentTier !== "" && currentTier === "Basic" ? !summary.isEnhanced : true) &&
          (currentStatus !== "All" ? summary.status.toString() === currentStatus : true) &&
          (currentType.value !== "" ? summary.type === currentType.value : true);
       });
       const sort = order === "asc" ? "asc" : "desc";
        setSortedProfiles(_.orderBy(profiles, [currentSortColumn], [sort]));
      }

      sortProfilesByFilterValues();
    }, [currentSearchValue, profileSummaries, currentTier, currentStatus, currentType, currentSortColumn, order]);

    const handleSearchValueChange = (value: string) => {
      setCurrentSearchValue(value);
    }

    return (
        <div>
            <Navbar className="filter-bar-border">
              <Nav>
                {statuses.map((status, index) => <Nav.Link key={index} className={`margin-right-10 ${currentStatus === status.key ? "nav-link-border-bottom" : ""}`} onClick={() => setCurrentStatus(status.key)}>{status.label}</Nav.Link>)}
              </Nav>
              <Nav>
              <InputGroup id="search-filter">
                <InputGroup.Text id="search-filter-icon"><BsSearch /></InputGroup.Text>
                <Form.Control
                  placeholder="Filter profiles"
                  aria-label="Filter profiles"
                  aria-describedby="search-filter-icon"
                  onChange={event => handleSearchValueChange(event.target.value)}
                />
              </InputGroup>
              <NavDropdown id="filter-type" title={currentType.value === "" ? "Type" : currentType.label}>
                {
                  typesArray.map((type, index) => <NavDropdown.Item key={index} onClick={() => setCurrentType({label: type.name, value: type.value})}>{type.name}</NavDropdown.Item>)
                }
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => setCurrentType({label: "", value: ""})}>Clear</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown id="filter-tier" title={currentTier === "" ? "Tier" : currentTier}>
                <NavDropdown.Item onClick={() => setCurrentTier("Basic")}>Basic</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setCurrentTier("Enhanced")}>Enhanced</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => setCurrentTier("")}>Clear</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown className="flex-center" id="more-filters" title="More filters">
              </NavDropdown>
              <Button variant="outline-dark"><BsLayoutThreeColumns /></Button>
              </Nav>
            </Navbar>
            <Table responsive bordered>
            <thead>
              <tr>
                {
                  columnHeaders.map((columnHeader, index) => <th key={index}><SortLabel label={columnHeader.label} callBack={sortProfilesByDirectionAndColumn} sortValue={columnHeader.key} currentSortColumn={currentSortColumn}/></th>)
                }
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                sortedProfiles.map((profileSummary,index) => <GridRow profileSummary={profileSummary} key={index} defaultPageUrl={`/profiles/update/${profileSummary.profileId}/${ProfileFormPageEnum.basicInfo}`}></GridRow>)
              }
            </tbody>
          </Table>
      </div>
    )
}