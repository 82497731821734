import React, {useRef, useEffect} from 'react';
import './map.scss';
import { IMapProps } from './types';

let map: google.maps.Map;
let markers: google.maps.Marker[] = [];

export const Map = (props: IMapProps) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const initialCenter: google.maps.LatLngLiteral = {lat: 0, lng: 0};
    const { setCoordinates, pin } = props;

    useEffect(() => {
        if(mapRef.current){
            map = new google.maps.Map(mapRef.current,{
                center: initialCenter,
                zoom: 12,
                draggableCursor: 'default'
            });
        }
    }, [ initialCenter, setCoordinates ]);

    useEffect(() => {
        const addMarker = (location:google.maps.LatLng) => {            
            const marker = new google.maps.Marker({
              position: location,
              draggable: true,
              map: map
            });
            
            if (location.lat() === 0 && location.lng() === 0) {
                map.setZoom(3);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Drag to position pin'
                  });
    
                google.maps.event.addListener(map, 'tilesloaded', function() {
                    infowindow.open(map,marker);
                  });
            }
            markers.push(marker);
            google.maps.event.addListener(marker, 'dragend', (evt) =>{
                setCoordinates( evt.latLng );
            });
            map.setCenter(location);
        }
        
        if (pin){
            const location = new google.maps.LatLng(pin.latitude, pin.longitude);
            addMarker(location)
        }
    }, [pin, initialCenter, setCoordinates]);

    return ( 
        <React.Fragment>
            <div ref={mapRef} className="map-container"></div>
        </React.Fragment>
    )
}
