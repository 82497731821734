import React, { useEffect, useState } from 'react';
import { RootState } from 'app/root-reducer';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import './profile.form.container.scss';
import { Sidebar }  from './features/sidebar/profile.form.sidebar';
import { getById, update, isFeaturesSelected, isEnquiriesComplete, isContactsComplete, isSocialMediaComplete,
   UpdateIsProfileLoaded } from 'components/profile/profile.slice';
import { isMediaComplete, updateChangedMedia } from 'components/profile/mediaitem/mediaitem.slice';
import { updateProfileItemsDb } from 'components/profile/profileitem/profileitem.slice';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { ProfileStatusCard } from 'components/profile.status.card/profile.status.card';
import { useCallback } from 'react';
import { ChecklistSection, Insights, ProfileFacilitiesSectionEnum, ProfileFormPageEnum, ProfileFormPageSectionEnum } from './profile.form.types';
import { BasicInfoPage } from './pages/basic-info/basic.info.page';
import { SleepingArrangementsPage  } from './pages/sleeping-arrangements/sleeping.arrangements.page';
import { BookingAffiliateEnum, EntityTypeEnum } from 'components/profile/types';
import { InsightsCard } from 'components/insights.card/insights.card';
import { ISidebarMenuItem } from 'components/profile/types';
import { ChecklistSections } from 'app/config';
import { MediaPage } from './pages/media/media.page';
import { ProfileChecklist } from './features/checklist/profile.checklist';
import { FastFactsPage } from './pages/fast-facts/fast-facts.page';
import { FacilitiesPage } from './pages/facilities/facilities.page';
import { ContactDetailsPage } from './pages/contact-details/contact-details.page';
import { IntegrationsPage } from './pages/integrations/integrations.page';
import { DocumentsPage } from './pages/documents/documents.page';

declare var Beacon: any;

interface ParamType {
    id: string;
    pageId: string;
}

const ProfileFormContainerTemp = () => {
  const { profileSummaries, profile, loading } = useSelector((state: RootState) => state.profileSlice);
  const { profileItems } = useSelector((state: RootState) => state.profileItemSlice);
  const { mediaItems } = useSelector((state: RootState) => state.mediaItemSlice);
  const { showUpdateProfileMenu } = useSelector((state: RootState) => state.applicationSlice);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id, pageId } = useParams<ParamType>();
  const [ displayName, setDisplayName ] = useState<string>("");
  const [ menuItems, setMenuItems ] = useState<ISidebarMenuItem[]>([]);
  const [ profileIdTemp, setProfileIdTemp ] = useState<string>("");
  const [ sections, setSections ] = useState<ChecklistSection[]>([]);
  const [ insights, setInsights ] = useState<Insights>({ title: "", titleIcon: "", description: [""]});
  const [ initialProfileJson, setInitialProfileJson ] = useState<string>('');

  useEffect(() => {
    if (id) {
      dispatch(getById({profileId: id, accountId: 'e6e02556-8724-4592-bbeb-fd4c0c4130b1'})).then(() => {
        dispatch(UpdateIsProfileLoaded(true));
      });
    }
  }, [dispatch, id]);
  
  if ((!displayName && profile?.name) || (profile?.id && profileIdTemp && profile.id !== profileIdTemp)) {
    setDisplayName(profile.name);
    setProfileIdTemp(profile.id);
  }

  // menu item use effect
  useEffect(() => {
    if (!initialProfileJson && profile.isLoaded) {
      setInitialProfileJson(JSON.stringify(profile));
    }
  }, [profile, initialProfileJson]);

  // checklist sections use effect
  useEffect(() => {
    if (profile.isLoaded) {
      const checklistSections = ChecklistSections.filter(x => {
      return x.page === pageId && (profile.isEnhanced ? true : x.isEnhancedOnly === false);
    });

    switch (pageId) {
      case ProfileFormPageEnum.basicInfo:
          break;
      case ProfileFormPageEnum.fastFacts:
        break;
      case ProfileFormPageEnum.media:        
        checklistSections.forEach(x => {
          if (x.id === ProfileFormPageSectionEnum.images) {
            x.isCompleted = isMediaComplete(ProfileFormPageSectionEnum.images);
          }
          if (x.id === ProfileFormPageSectionEnum.videos) {            
            x.isCompleted = isMediaComplete(ProfileFormPageSectionEnum.videos);
          }
          if (x.id === ProfileFormPageSectionEnum.virtualTours) {            
            x.isCompleted = isMediaComplete(ProfileFormPageSectionEnum.virtualTours);
          }
        });
        break;
      case ProfileFormPageEnum.sleepingArrangements:
        break;
      case ProfileFormPageEnum.facilities:
        checklistSections.forEach(x => {
          if (x.id === ProfileFormPageSectionEnum.facilitiesProperty) {
            x.isCompleted = isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.property);
          }
          if (x.id === ProfileFormPageSectionEnum.facilitiesServices) {
            x.isCompleted = isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.services);
          }
          if (x.id === ProfileFormPageSectionEnum.facilitiesActivitiesNearby) {
            x.isCompleted = isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.activitiesNearby);
          }
          if (x.id === ProfileFormPageSectionEnum.facilitiesActivitiesOnSite) {
            x.isCompleted = isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.activitiesOnSite);
          }
          if (x.id === ProfileFormPageSectionEnum.facilitiesSleepingArrangements) {
            x.isCompleted = isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.sleepingArrangments);
          }
        });
        break;
      case ProfileFormPageEnum.contactDetails:
        checklistSections.forEach(x => {
          if (x.id === ProfileFormPageSectionEnum.enquiries) {
            x.isCompleted = isEnquiriesComplete(profile);
          }
          if (x.id === ProfileFormPageSectionEnum.contacts) {
            x.isCompleted = isContactsComplete(profile);
          }
          if (x.id === ProfileFormPageSectionEnum.socialMedia) {
            x.isCompleted = isSocialMediaComplete(profile);
          }
        });
        break;
      case ProfileFormPageEnum.integrations:
          checklistSections.forEach(x => {
            if (x.id === ProfileFormPageSectionEnum.integrations) {
              if (profile.affiliate !== BookingAffiliateEnum.none) {
                if (profile.affiliate === BookingAffiliateEnum.nightsbridge) {
                  x.isCompleted = profile.affiliateCode?.length > 0;
                }
                if (profile.affiliate === BookingAffiliateEnum.resRequest) {
                  x.isCompleted = profile.affiliateCode?.length > 0 && profile.clientName?.length > 0;
                }
              }
            }
            if (x.id === ProfileFormPageSectionEnum.gdsCodes) {
              x.isCompleted = profile.gdsCodes && Object.keys(profile.gdsCodes).length > 0 && Object.keys(profile.gdsCodes).filter(x => profile.gdsCodes[x]).length > 0;
            }
          });
          break;
      default:
        break;
    }
    
    setSections(checklistSections);
  }
  }, [pageId, profile, mediaItems]);

  // insights use effect
  useEffect(() => {
    if (profile.isLoaded) {
      let insight: Insights = { title: "", titleIcon: "", description: [""]};
      switch (pageId) {
        case ProfileFormPageEnum.basicInfo:
          break;
        case ProfileFormPageEnum.fastFacts:
          break;
        case ProfileFormPageEnum.media:
          insight = { title: "Image recommendations", titleIcon: "", description: ["JPG or PNG images", "Landscape images display better than portrait", "Images larger than 1MB"]};
          break;
        case ProfileFormPageEnum.sleepingArrangements:
          break;
        case ProfileFormPageEnum.facilities:
          break;
        default:
          break;
      }
      setInsights(insight);
    }
  }, [pageId, profile.isLoaded]);

  const navigateToProfile = (isForward: boolean) => {
    const index = profileSummaries.findIndex(x => x.profileId === id);
    if(isForward) {
      const summary = profileSummaries[index + 1];
      if(summary) {
        history.push(`/profiles/update/${summary.profileId}/${pageId}`);
      }
    } else {
      const summary = profileSummaries[index - 1];
      if(summary) {
        history.push(`/profiles/update/${summary.profileId}/${pageId}`);
      }
    }
  }

  const viewIBrochure = () => {
    window.open('https://andrew.dev.wetu.com/'+ profile.id, '_blank');
  }

  const profileStatusCallBack = useCallback(() => {
    //dispatch changes here
  }, [])

  const renderPage = () => {
    let index: number = menuItems.findIndex(x => x.url === pageId);
    let isCompleted: boolean = (index >= 0) ? menuItems[index].isCompleted : false;
    switch (pageId) {
        case ProfileFormPageEnum.basicInfo:
            return <BasicInfoPage isCompleted={isCompleted} />
        case ProfileFormPageEnum.fastFacts:
            return <FastFactsPage isCompleted={isCompleted} />
        case ProfileFormPageEnum.media:
            return <MediaPage saveCallback={savePage} />
        case ProfileFormPageEnum.sleepingArrangements:
            return <SleepingArrangementsPage isCompleted={isCompleted} />
        case ProfileFormPageEnum.facilities:
            return <FacilitiesPage />
        case ProfileFormPageEnum.contactDetails:
            return <ContactDetailsPage sections={sections} />
        case ProfileFormPageEnum.documents:
            return <DocumentsPage isCompleted={isCompleted} />
        case ProfileFormPageEnum.integrations:
            return <IntegrationsPage />
        default:
            return <div>default</div>
    }
  }

  const renderStatusCard = () => {
    switch (pageId) {
      case ProfileFormPageEnum.basicInfo:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Basic Info", contentRating: 5 }} />
      case ProfileFormPageEnum.fastFacts:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Fast Facts", contentRating: 0 }} />
      case ProfileFormPageEnum.facilities:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Facilities", contentRating: 0}} />
      case ProfileFormPageEnum.sleepingArrangements:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Sleeping arrangements", contentRating: 0 }} />
      case ProfileFormPageEnum.contactDetails:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Contact Details", contentRating: 10 }} />
        case ProfileFormPageEnum.documents:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Documents", contentRating: 10 }} />
      default:
          return <ProfileStatusCard callBack={profileStatusCallBack} profile={profile} pageInformation={{ label: "Default", contentRating: 0 }} />
      }
  }

  const savePage = () => {
    if (pageId === ProfileFormPageEnum.sleepingArrangements) {
        dispatch(updateProfileItemsDb(profileItems));
    }
    if (pageId !== ProfileFormPageEnum.media && pageId !== ProfileFormPageEnum.documents) {
      dispatch(update({
        id: profile.id,
        category: profile.category,
        isEnhanced: profile.isEnhanced,
        latitude: profile.pin.latitude,
        longitude: profile.pin.longitude,
        name: profile.name,
        type: profile.type,
        entityType: EntityTypeEnum.profile,
        checkinTimeFrom: profile.checkinTimeFrom,
        checkoutTimeFrom: profile.checkoutTimeFrom,
        checkinTimeTo: profile.checkinTimeTo,
        checkoutTimeTo: profile.checkoutTimeTo,
        isPublished: profile.isPublished,
        translationItems: profile.translationItems,
        features: profile.features,
        searchAliases: profile.searchAliases,
        shortDescription: profile.shortDescription,
        richDescription: profile.richDescription,
        availableLanguages: profile.availableLanguages,
        status: profile.status,
        qualityAssuranceBody: profile.qualityAssuranceBody,
        starRating: profile.starRating,
        alternativeRating: profile.alternativeRating,
        roomCount: profile.roomCount,
        timeFormat: profile.timeFormat,
        minimumChildAge: profile.minimumChildAge,
        clientName: profile.clientName,
        affiliate: profile.affiliate,
        affiliateCode: profile.affiliateCode,
        gdsCodes: profile.gdsCodes,
        sectionsComplete: profile.sectionsComplete,
        contacts: profile.contacts,
        links: profile.links
      }));
      setDisplayName(profile.name);
    }
    else {
      dispatch(updateChangedMedia(mediaItems));
    }
  }

  const getCurrentProfileSummaryIndex = (): number => {
    return profileSummaries.findIndex(x => x.profileId === id);
  }

  const setCompletedCallback = useCallback((items: ISidebarMenuItem[]) => {
    setMenuItems(items);
  }, []);

  const menuItemCallBack = useCallback((items: ISidebarMenuItem[], url: string) => {
    setMenuItems(items);
    history.push(url);
  }, [history]);

  return (
     profile && profile.isLoaded ?
      <Container className="margin-top-50 height-100 profile-container" fluid>
        <Row className="height-100">
          <Col xs={2} className="sidebar-col">
            <Sidebar backButtonText={'Back to Profiles'} url={'/profiles'} callBack={menuItemCallBack} setCompletedCallback={setCompletedCallback} expanded={showUpdateProfileMenu} />
          </Col>
          <Col xs={10}>
            <Container fluid>
              <Row>
                <Col className="profile-form-display">
                {
                  loading && !profile && <Spinner className="spinner" animation="border" variant="primary"></Spinner>
                }
                {
                  (!loading && profile) &&
                  <Container className="margin-top-50" fluid>
                    <Row>
                      <Col className="container-title" xs={{span: 6, offset: 2}}>
                        <h2>{displayName}</h2>
                      </Col>
                      <Col xs={1} className="save-container">
                        <Button className="sticky-buttons" onClick={() => savePage()} variant="outline-secondary">Save</Button>
                      </Col>
                      <Col xs={2} className="view-ibrochure-container">
                        {
                          profile.isEnhanced && 
                          <Button onClick={() => viewIBrochure()} variant="outline-secondary" className="float-right">View iBrochure</Button>
                        }
                      </Col>
                      <Col xs={1} className="profile-navigation-buttons-container">
                        {
                          profileSummaries.length > 1 &&
                          <React.Fragment>
                            <Button disabled={getCurrentProfileSummaryIndex() === 0} className="border-radius-none" 
                            variant="outline-secondary" onClick={() => navigateToProfile(false)}><BsChevronLeft /></Button>
                            <Button disabled={getCurrentProfileSummaryIndex() === profileSummaries.length - 1} 
                            onClick={() => navigateToProfile(true)} className="border-radius-none" variant="outline-secondary"><BsChevronRight />
                            </Button>
                          </React.Fragment>
                        }
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={2}>
                        { sections.length >= 2 && <ProfileChecklist availableSections={sections} /> }
                      </Col>
                      <Col xs={6} className="central-content-section">
                        { renderPage() }
                      </Col>
                      <Col xs={4}>
                        { renderStatusCard() }
                        <InsightsCard title={insights.title} titleIcon={insights.titleIcon} description={insights.description} />
                        <Button className="help-button" variant="primary" onClick={() => { Beacon('open'); }}>Need Help?</Button>
                      </Col>
                      <Col xs={1}></Col>
                    </Row>
                  </Container>
                }
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    : null
    )
}

export const ProfileFormContainer = React.memo(ProfileFormContainerTemp);