import React from 'react';
import { Col, Image, Navbar, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router';
import logo from './wetuLogo.png';
import './header.scss';
import { HeaderFactory } from './features/header.factory/header.factory';

export const Header = () => {

    const history = useHistory();

    return (
        <Navbar id="header" fixed="top" bg="light-grey" variant="dark">
                <Col md={1}>
                    <Nav.Item>
                        <Image onClick={() => history.push('')} className="logo" src={logo}/>
                    </Nav.Item>
                </Col>
                <HeaderFactory />
        </Navbar>
    )
}