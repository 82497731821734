import React, { useEffect, useCallback } from 'react'
import { WizardCallback } from 'components/wizard/types';
import { Container, Row, Col } from 'react-bootstrap';
import { InsightsCard } from 'components/insights.card/insights.card';
import './wizard.step-9.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { ProfileImagesCard } from 'components/profile.images.card/profile.images.card';
import { EntityTypeEnum, IDeleteMediaPayload, IMediaItem, MediaTypeEnum } from 'components/profile/types';
import { deleteMedia, UpdateMedia_Reducer, uploadMedia } from 'components/profile/mediaitem/mediaitem.slice';
import { useAppDispatch } from 'app/store';
import _ from 'lodash';

const WizardStep9Page = ({callBack}: WizardCallback) => {

    const { availableLanguages, isEnhanced, id } = useSelector((state: RootState) => state.profileSlice.profile);
    const { mediaItems } = useSelector((state: RootState) => state.mediaItemSlice);
    const dispatch = useAppDispatch();

    const stepComplete = useCallback((): boolean => {
        return mediaItems.length > 0;
    }, [mediaItems.length]);
    
    useEffect(() => {
        callBack({id: 9, completed: stepComplete(), skip: false, displayFooterNavigation: true, displayNext: true});
    }, [callBack, mediaItems, stepComplete]);

    const uploadMediaCallBack = useCallback((newMediaItems: IMediaItem[], uploadedMedia: IMediaItem[], mediaType: MediaTypeEnum) => {
        const uploadMediaFormData = () => {
            const formData = new FormData();
            newMediaItems.forEach(mItem => {
                if (mItem.url === '' && mItem.file) {
                    formData.append("files", mItem.file);
                    formData.append("sequences", mItem.sequence.toString());
                }
            });

            formData.append("container", "profile-wizard-images");
            // id in this case is the profileid
            formData.append("profileId", id);
            formData.append("parentEntityType", EntityTypeEnum.profile);
            formData.append("mediaType", mediaType);
            formData.append("hasAcceptedUsagePolicy", "true");
            
            if (formData.get('files') != null) {
                dispatch(uploadMedia({mediaType: mediaType, formData: formData }));
            }
        }
        if (id && newMediaItems.length > uploadedMedia.length) {
            uploadMediaFormData();
        }
    }, [dispatch, id]);

    const deleteMediaCallBack = useCallback((selectedMedia: IMediaItem[], mediaType: MediaTypeEnum) => {
        const payload: IDeleteMediaPayload = { mediaItems: selectedMedia, showToast: true, mediaType: mediaType };
        let mediaItemsTemp: IMediaItem[] = _.cloneDeep(mediaItems);
        selectedMedia.forEach(image => {
            const index = mediaItemsTemp.findIndex(x => x.id === image.id);
            mediaItemsTemp.splice(index, 1);
        });

        dispatch(deleteMedia(payload));
        dispatch(UpdateMedia_Reducer({mediaItems: mediaItemsTemp, mediaType: mediaType}));
 
    }, [dispatch, mediaItems])

    const getImages = useCallback(() => {
        return mediaItems.filter(x => x.mediaType === MediaTypeEnum.image).sort(x => x.sequence);
    }, [mediaItems])
    
    const getMediaCount = (getFunction: () => IMediaItem[]) => {
        return getFunction()?.length;
    }

    return (
        <Container fluid>
            <Row>
                <Col xs={12} lg={{ offset: 2, span: 8}}>
                    <ProfileImagesCard title={"What does your property look like?"} 
                    subtitle={"Add at least one photo now. You can always add more later."} 
                        availableLanguages={availableLanguages} isEnhanced={isEnhanced} isImagesEditable={false} isCompleted={stepComplete()} 
                        mediaPayload={mediaItems} uploadCallback={uploadMediaCallBack} deleteCallback={deleteMediaCallBack} 
                        mediaCount={getMediaCount(getImages)} />
                </Col>
                <Col xs={12} lg={2}>
                    <InsightsCard titleIcon={'BiBulb'} title={'Image recommendations'} description={['JPG or PNG images', 'Landscape images display better than portrait.', 'Images larger than 1MB']} />
                </Col>
            </Row>
        </Container>
    );
}

export default WizardStep9Page;