import React, { useCallback, useState } from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import './wizard.room.card.scss';
import landingImage from './images/landingImage.png'; 
import { IProfileItem } from 'components/profile/profileitem/types';
import { useHistory } from "react-router-dom";
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';

interface IProps {
    index: number,
    room: IProfileItem,
    deleteCallBack: (room: IProfileItem) => void;
    editCallBack?: (room: IProfileItem) => void;
}

export const WizardRoomCard = ({room, deleteCallBack, editCallBack, index }: IProps) => {
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const history = useHistory();

    const deleteRoomModalOnClick = (room : IProfileItem) => {
        setShowModal(true);
    };

    const deleteRoomConfirmationOnClick = useCallback((deleteRoom: boolean) => {  
        if (deleteRoom) {
            deleteCallBack(room);
        }
        setShowModal(false);
    }, [deleteCallBack, room]);

    const editClick = () => {
        if (editCallBack) {
            editCallBack(room);
        }
        else {
            history.push("14/"+room.id);
        }
    }

    return (
        <Col className="wizard-room-card-wrap" lg="12" md="12">
            <Card>
                <Card.Body className="card-room-body">
                    <Container fluid>
                        <Row>
                            <Col xs="2" className="room-image-container">
                            <Image src={room.media.length > 0 ? room.media[0].url : landingImage} className="room-image"></Image>
                            </Col>
                            <Col xs="10" className="room-detail">
                                <Row className="room-header-row">
                                    <span>{room.type}</span>
                                    <div>
                                        <Button variant="nav-link" onClick={() => deleteRoomModalOnClick(room)}>Delete</Button>
                                        <AcceptDeclineModal showModal={showModal} callBack={deleteRoomConfirmationOnClick} title={`Delete ${room.name}`}  body={`Are you sure you want to delete ${room.name}? This can’t be undone.`} declineButtonText={"Cancel"} acceptButtonText={"Delete"} />
                                        <Button variant="nav-link" onClick={() => editClick()} >Edit</Button>
                                    </div>
                                </Row>
                                <Row className="room-detail-row">
                                    <h6>{room.name}</h6>
                                </Row>
                                <Row className="room-detail-row">
                                    <div className="room-description">{room.description?.text}</div>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Col>
    )
}