import { MediaAppearancesSections } from 'app/config';
import { ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import React, { useState } from 'react';
import { Card, Form, Container, Row, Col } from 'react-bootstrap';
import { BsImage, BsThreeDots } from 'react-icons/bs';
import { MdLens } from 'react-icons/md';
import "./media.appearances.scss";

export const MediaAppearancesCard = () => {
    const [ coverImageSections ] = useState<string[]>(MediaAppearancesSections);
    return(
        <Card id={ProfileFormPageSectionEnum.appearances} border="dark" className="appearances-card">
            <Card.Body>
                <Card.Title  className="card-title-section">
                    <div><MdLens className="section-dot" color={"lightgrey"} />Appearances</div>
                </Card.Title>
                <Card.Subtitle>
                    LANDING PAGE IMAGE
                </Card.Subtitle>
                <Card.Text className="card-section">
                    The picture you provide here will be shown on the landing of your iBrochure. <br />
                    If you do not provide one, your first image in the Gallery will be used instead.
                </Card.Text>
                <Form.Check id="showNameOnBrochure" type="checkbox" label="Show name on iBrochure" />
                <Container className="appearances-container-margin">
                    <Row>
                        <Col>
                        <div className="appearances-menu-icon-wrapper">
                            <div className="three-dot-container">
                                <BsThreeDots className="appearances-menu-icon" size={40} />
                            </div>
                        </div>
                        <div className="landing-page-section">
                            <BsImage className="placeholder-icon" size={40} />
                        </div>
                        </Col>
                    </Row>
                </Container>
                <Card.Subtitle>
                    SLIDER OVERVIEW IMAGES
                </Card.Subtitle>
                <Card.Text className="card-section">
                    Use this only if you want to take full control of the images shown in the slider gallery on the 
                    overview page. If you do not provide any, the first three images you have uploaded in the Gallery will be selected.
                </Card.Text>
                <Container className="appearances-container-margin">
                    <Row>
                        <Col>
                        <div className="appearances-menu-icon-wrapper">
                            <div className="three-dot-container">
                                <BsThreeDots className="appearances-menu-icon" size={40} />
                            </div>
                        </div>
                        <div className="landing-page-section">
                            <BsImage className="placeholder-icon" size={40} />
                        </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col>
                        <div className="appearances-menu-icon-wrapper">
                            <div className="three-dot-container">
                                <BsThreeDots className="appearances-menu-icon" size={40} />
                            </div>
                        </div>
                        <div className="landing-page-section">
                            <BsImage className="placeholder-icon" size={40} />
                        </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="appearances-container-margin">
                    <Row>
                        <Col>
                        <div className="appearances-menu-icon-wrapper">
                            <div className="three-dot-container">
                                <BsThreeDots className="appearances-menu-icon" size={40} />
                            </div>
                        </div>
                        <div className="landing-page-section">
                            <BsImage className="placeholder-icon" size={40} />
                        </div>
                        </Col>
                    </Row>
                </Container>
                <Card.Subtitle>
                    COVER IMAGES
                </Card.Subtitle>
                <Card.Text className="card-section">
                    The image(s) you provide here will be shown to visitors on your iBrochure. Use this only if you
                    want to take full control of the images shown on the overview page. If you do not provide any, cover images will automatically be generated.
                </Card.Text>
                <Container className="appearances-container-margin">
                    <Row>
                        {
                            coverImageSections.map((section, index) => <Col key={index} xs={4} className="col-padding">
                            <div className="cover-image-block-headers">{section}</div>
                            <div className="appearances-menu-icon-wrapper">
                                <div>
                                    <BsThreeDots className="appearances-menu-icon-cover-images" size={40} />
                                </div>
                            </div>
                            <div className="landing-page-section">
                                <BsImage className="placeholder-icon" size={40} />
                            </div>
                            </Col>)
                        }
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}