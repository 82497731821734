import React, { useRef, useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip, Spinner, Button, Form } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, ItemTypes } from '../types';
import { BsFillExclamationTriangleFill, BsPencil } from "react-icons/bs";
import { GrDrag } from "react-icons/gr";
import './dropzone.video.scss';
import { MediaTypeEnum } from 'components/profile/types';

interface DropzoneVideoIProps {
  src?: File;
  imageClass: string;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  id: string;
  index: number;
  imageSelectedCallBack: (selected: boolean, url: string, fileSizeWarning: boolean, file?: File) => void;
  imageSelected?: boolean;
  forceShowCheckbox?: boolean;
  url: string;
  hasFileSizeWarning: boolean;
  isImagesEditable: boolean;
  mediaType: MediaTypeEnum;
  onEditClickCallback: (index: number) => void;
  imageDropCallback: () => void;
}

export const DropZoneVideo = ({url, id, index, src, imageClass, moveImage, imageSelectedCallBack, imageSelected = false, forceShowCheckbox = false, hasFileSizeWarning, 
  onEditClickCallback, imageDropCallback, isImagesEditable = false, mediaType}: DropzoneVideoIProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mouseEntered, setMouseEntered] = useState<boolean>(forceShowCheckbox);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.VIDEO,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.VIDEO,
    item: () => {     
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    end: () => {
      imageDropCallback();
    }
  })

  drag(drop(ref));

  const onImageMouseEnter = () => {
    setMouseEntered(true);
  }

  const onImageMouseLeave = () => {
    if(!forceShowCheckbox) {
      setMouseEntered(false);
    }
  }

  const onImageSelected = (e: any) => {
    const selectionState: boolean = e.target.checked;
    imageSelectedCallBack(selectionState, url, hasFileSizeWarning, src);
  }

  const onEditClick = (e: any) => {
    onEditClickCallback(index);
  }

  useEffect(() => {
    setMouseEntered(forceShowCheckbox);
  }, [forceShowCheckbox]);

  return (
    <React.Fragment>
      {
        (url && url.length > 0) &&
        <div onMouseEnter={() => onImageMouseEnter()} onMouseLeave={() => onImageMouseLeave()} ref={ref} 
          className={`${isDragging ? "image-drag-active" : (isImagesEditable ? "image-drag-inactive-editable" : "image-drag-inactive")}`} data-handler-id={handlerId}>
          <div className="image-tools-container">
            <div className="image-flex-items">
              <div className="image-left-items">
            {
              mouseEntered &&
              <Form.Check checked={imageSelected} onChange={(e: any) => onImageSelected(e)} />
            }
            {
              hasFileSizeWarning &&
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-${src?.name}`}>
                    low resolution
                  </Tooltip>
                }
                >
                <BsFillExclamationTriangleFill className="image-warning-icon" size={24} />
              </OverlayTrigger>
            }
            </div>
            { isImagesEditable && mouseEntered && 
                <Button className="edit-button" onClick={(e: any) => onEditClick(e)}>
                  <BsPencil className="pen-icon" />
                </Button>
            }
            { !isImagesEditable && mouseEntered &&
              <div className="drag-icon-container">
                <GrDrag className="drag-icon" />
              </div>
            }
          </div>
        </div>
        <div  className={`video_box image-container ${isImagesEditable ? "edit-item" : "drag-item"}`}> 
          { (mediaType === MediaTypeEnum.video) &&
            <video src={url}  className={`${imageClass}`} >            
            Your browser does not support the video tag.
            </video> 
          }
          {
            (mediaType === MediaTypeEnum.embedVideo) && 
             <iframe title={url} width="178" height="170" src={url}></iframe>
          }
        </div>
    </div>
      }
      {
        (!url) &&
        <Spinner animation="border" variant="primary" />
      }
    </React.Fragment>
  )
};