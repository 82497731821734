import React, { useRef, useState, useEffect} from 'react';
import { OverlayTrigger, Tooltip, Spinner, Button, Form } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, ItemTypes } from '../types';
import { BsImage, BsFillExclamationTriangleFill, BsPencil } from "react-icons/bs";
import { GrDrag } from "react-icons/gr";
import './dropzone.virtualTour.scss';
import { ITranslatableText } from 'components/profile/types';

interface DropzoneVirtualTourIProps {
  src?: File; 
  id: string;
  label: ITranslatableText;
  index: number;
  mediaSelected?: boolean;
  forceShowCheckbox?: boolean;
  url: string;
  hasFileSizeWarning: boolean;
  isMediaEditable: boolean;
  moveMedia: (dragIndex: number, hoverIndex: number) => void;
  mediaSelectedCallBack: (selected: boolean, url: string, fileSizeWarning: boolean, file?: File) => void;
  onEditClickCallback: (index: number) => void;
  mediaDropCallback: () => void;
}

export const DropZoneVirtualTour = ({url, id, label, index, src, mediaSelected = false, forceShowCheckbox = false, hasFileSizeWarning, 
  isMediaEditable = false, moveMedia, mediaSelectedCallBack, onEditClickCallback, mediaDropCallback}: DropzoneVirtualTourIProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mouseEntered, setMouseEntered] = useState<boolean>(forceShowCheckbox);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.VIDEO,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveMedia(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.VIDEO,
    item: () => {     
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    end: () => {
      mediaDropCallback();
    }
  })

  drag(drop(ref));

  const onMediaMouseEnter = () => {
    setMouseEntered(true);
  }

  const onMediaMouseLeave = () => {
    if(!forceShowCheckbox) {
      setMouseEntered(false);
    }
  }

  const onMediaSelected = (e: any) => {
    const selectionState: boolean = e.target.checked;
    mediaSelectedCallBack(selectionState, url, hasFileSizeWarning, src);
  }

  const onEditClick = (e: any) => {
    onEditClickCallback(index);
  }

  useEffect(() => {
    setMouseEntered(forceShowCheckbox);
  }, [forceShowCheckbox]);

  return (
    <React.Fragment>
      {
        (url && url.length > 0) &&
        <div onMouseEnter={() => onMediaMouseEnter()} onMouseLeave={() => onMediaMouseLeave()} ref={ref} 
          className={`${isDragging ? "image-drag-active" : (isMediaEditable ? "image-drag-inactive-editable" : "image-drag-inactive")}`} data-handler-id={handlerId}>
          <div className="image-tools-container">
            <div className="image-flex-items">
              <div className="image-left-items">
            {
              mouseEntered &&
              <Form.Check checked={mediaSelected} onChange={(e: any) => onMediaSelected(e)} />
            }
            {
              hasFileSizeWarning &&
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-${src?.name}`}>
                    low resolution
                  </Tooltip>
                }
                >
                <BsFillExclamationTriangleFill className="image-warning-icon" size={24} />
              </OverlayTrigger>
            }
            </div>
            { isMediaEditable && mouseEntered && 
                <Button className="edit-button" onClick={(e: any) => onEditClick(e)}>
                  <BsPencil className="pen-icon" />
                </Button>
            }
            { !isMediaEditable && mouseEntered &&
              <div className="drag-icon-container">
                <GrDrag className="drag-icon" />
              </div>
            }
          </div>
        </div>
        <div className={`iframe-container ${isMediaEditable ? "edit-item" : "drag-item"}`}>   
          <BsImage    size={40} />
          <div className="overlay-title">{label.enText}</div>          
        </div>       
    </div>
      }
      {
        (!url) &&
        <Spinner animation="border" variant="primary" />
      }
    </React.Fragment>
  )
};