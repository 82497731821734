import { combineReducers } from '@reduxjs/toolkit';
import profileSlice from 'components/profile/profile.slice';
import profileItemSlice from 'components/profile/profileitem/profileitem.slice';
import mediaItemSlice from 'components/profile/mediaitem/mediaitem.slice';
import applicationSlice from 'app/app.slice';
import toast from 'components/toast/toast.slice';

const rootReducer = combineReducers({
	profileSlice,
	profileItemSlice,
	mediaItemSlice,
	applicationSlice,
	toast
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;