import { Dictionary } from '@reduxjs/toolkit';
import { gdsData } from 'app/config';
import { RootState } from 'app/root-reducer';
import { MultiSelect } from 'components/multi-select/multiselect';
import { UpdateGDSCodes } from 'components/profile/profile.slice';
import { IMultiSelectData } from 'components/profile/types';
import _ from 'lodash';
import { ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { BsFillTrashFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import './gds.card.scss';

export const GDSCard = () => {
    const { gdsCodes } = useSelector((state: RootState) => state.profileSlice.profile);
    const [ modifiedGDSData, setModifiedGDSData ] = useState<IMultiSelectData[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        let clone = _.cloneDeep(gdsData);
        clone.forEach(x => {
            if (gdsCodes && x.key in gdsCodes) {
                x.isChecked = true;
            } else {
                x.isChecked = false;
            }
        });
        setModifiedGDSData(clone);
    }, [gdsCodes]);

    const gdsCodeSelectCallback = (data: IMultiSelectData[]) => {
        const filteredData = data.filter(x => x.isChecked);
        dispatch(UpdateGDSCodes(Object.assign({}, ...filteredData.map((x) => ({[x.key]: gdsCodes && gdsCodes[x.key] ? gdsCodes[x.key] : ""})))));
    }

    const updateGDSCodes = (key: string, value: string) => {
        let gdsClone: Dictionary<string> = _.cloneDeep(gdsCodes);
        gdsClone[key] = value;
        dispatch(UpdateGDSCodes(gdsClone));
    }

    const removeGDSCode = (key: string) => {
        let gdsClone: Dictionary<string> = _.cloneDeep(gdsCodes);
        delete gdsClone[key];
        dispatch(UpdateGDSCodes(gdsClone));
    }

    return(
        <Card id={ProfileFormPageSectionEnum.gdsCodes} border="dark" className="gds-card">
            <Card.Body>
                <div className="gds-card-title-section">
                <Card.Title>Add your GDS codes</Card.Title>
                {
                    modifiedGDSData.length > 0 && <MultiSelect data={modifiedGDSData} title={"Select GDS system"} multiSelectCallback={gdsCodeSelectCallback} />
                }
                </div>
                {
                    gdsCodes && Object.keys(gdsCodes).map((gds: string, index: number) => 
                    <div className="gds-input" key={index}>
                        {gds}
                        <div className="gds-input-display">
                            <Form.Control value={gdsCodes[gds]} onChange={(event: any) => updateGDSCodes(gds, event.target.value)} type="search"></Form.Control>
                            <div onClick={() => removeGDSCode(gds)} className="gds-trash"><BsFillTrashFill className="trash-icon" size={24} /></div>
                        </div>
                    </div>)
                }
            </Card.Body>
        </Card>
    )
}