import { Facilities } from 'components/facilities/facilities';
import React from 'react';
import { Card } from "react-bootstrap"
import { MdLens } from 'react-icons/md';
import { FacilitiesProps } from '../../types';

export const FacilitiesSectionCard = ({isCompleted = false, featureDataSectionName, labels, sectionId, subtitle, title}: FacilitiesProps) => {

    return(
        <Card border="dark" id={sectionId}>
            <Card.Body>
                <Card.Title>
                <MdLens className={`section-dot ${isCompleted ? "complete-dot" : "incomplete-dot" }`} />
                {title}
                </Card.Title>
                <Card.Subtitle>
                    {subtitle}
                </Card.Subtitle>
                <Facilities featureDataSectionName={featureDataSectionName} labels={labels}></Facilities>
            </Card.Body>
        </Card>
    )
}