import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, Navbar } from 'react-bootstrap';
import './profile.form.sidebar.scss';
import { BsDot } from 'react-icons/bs';
import _ from 'lodash';
import { ISidebarMenuItem } from 'components/profile/types';
import { useHistory, useParams } from 'react-router-dom';
import { BsChevronLeft, BsCircle } from 'react-icons/bs';
import { ProfileFormPageEnum } from '../../profile.form.types';
import { ProfileSidebarMenuItems } from 'app/config';
import { RootState } from 'app/root-reducer';

interface IProps {
    expanded: boolean;
    callBack: (menuItems: ISidebarMenuItem[], url: string) => void;
    url: string;
    backButtonText: string;
    setCompletedCallback: (menuItems: ISidebarMenuItem[]) => void;
}

interface ParamType {
    id: string;
    pageId: string;
}

export const Sidebar = ({expanded, callBack, setCompletedCallback, url, backButtonText}: IProps) => {
    const { profile } = useSelector((state: RootState) => state.profileSlice);
    const [ menuItems, setMenuItems ] = useState<ISidebarMenuItem[]>([]);
    const { pageId } = useParams<ParamType>();
    const history = useHistory();

    //useEffect: updates menuItems when profile.sectionsComplete changes.
    useEffect(() => {
        const getInitialMenuItemState = (): ISidebarMenuItem[] => {
            let items = _.cloneDeep(ProfileSidebarMenuItems);
            if (!profile.isEnhanced) {
                items = items.filter(x => !x.isEnhancedOnly);
            }
            return items;
        }
        
        if (profile.id) {
            let itemsTemp: ISidebarMenuItem[] = [];
            setMenuItems(items => {
                let isInitialLoad = items?.length === 0;
                itemsTemp = (isInitialLoad && profile.id) ? getInitialMenuItemState() : _.cloneDeep(items);
                for (let i = 0; i < itemsTemp.length; i++) {
                    itemsTemp[i].isCompleted = !!(profile.sectionsComplete[itemsTemp[i].url]);
                    itemsTemp[i].isSelected = (itemsTemp[i].url === pageId);
                }
                return itemsTemp;
            });
        }
    }, [pageId, profile.id, profile.isEnhanced, profile.sectionsComplete]);

    //useEffect: When menuItems changes push these changes to the parent so the new completion dots can propagate to the child pages (so they can set their dots).
    useEffect(() => {
        if (menuItems && menuItems.length) {
            setCompletedCallback(menuItems);
        }
    }, [menuItems, setCompletedCallback])

    const setSelectedMenuItem = (index: number) => {
        let menuItemClone = _.cloneDeep(menuItems);
        let currentSelection = menuItemClone.find(x => x.isSelected === true);
        if(currentSelection) {
            currentSelection.isSelected = false;
        }
        let menuItem = menuItemClone[index];
        menuItem.isSelected = true;
        callBack(menuItemClone, menuItem.url);
    }

    const onBackClick = () => {
        history.push(url);
    }

    return (
        <Navbar className={`${expanded ? "expanded-sidebar" : "collapsed-sidebar"}`}>
            <Nav className="flex-column width-100 sticky-menu">
            <Nav.Link onClick={() => onBackClick()}><BsChevronLeft /> {expanded && backButtonText}</Nav.Link>
            {
                expanded &&
                menuItems.map((item, index) => <div className="flex-space-between" key={index}>
                    <Nav.Link className={item.isSelected ? "nav-link-border-left" : ""} onClick={() => setSelectedMenuItem(index)}><BsCircle /> {item.label}</Nav.Link>
                        <BsDot className={item.isCompleted ? "complete-dot" : (item.url === ProfileFormPageEnum.media ?  "incomplete-dot-media" : "incomplete-dot")} size={36} /></div>)            
            }
            {
                !expanded &&
                menuItems.map((item, index) => <div key={index}><Nav.Link className={item.isSelected ? "nav-link-border-left" : ""} onClick={() => setSelectedMenuItem(index)}><BsCircle /></Nav.Link></div>)
            }
            </Nav>
        </Navbar>
    );
}
