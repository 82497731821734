import { ContactTypeEnum } from 'components/profile/types';
import React, { useState, FocusEvent } from 'react';
import { Form, Spinner, Image } from 'react-bootstrap';
import './email-input.scss';
import warningImage from './images/icon_warning.svg';

interface Props {
    email: string;
    validated?: (isValid: boolean) => void;
    showLabel?: boolean;
    validationEvent?: string;
    callBack: (updateValue: string | boolean, name: string, contactType?: ContactTypeEnum) => void;
    contactType?: ContactTypeEnum;
}

const initialInputState = {
    ErrorMessage: "",
    HasError: false,
    IsValid: false,
    ShowErrorLink: false,
    IsLoading: false
}

enum Reason {
    Availability,
    Format,
    Error,
    None
}

export const EmailInput = ({callBack, email, validated, showLabel = true, validationEvent = "onChange", contactType }: Props) => {
    const [validationState, setValidationState] = useState(initialInputState);

    const updateStateValues = (state: string, value: string | boolean) => {
        setValidationState(prev => ({
            ...prev,
            [state]: value,
        }));
    };

    const validateEmail_Event = (e: FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;

        updateStateValues("HasError", false);
        updateStateValues("IsValid", false);

        if (value) {
            // eslint-disable-next-line
            let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            let isValidFormat = regexp.test(value);
           
            if (isValidFormat) {
                 callBack(value, "email", contactType);
            } else {
                validationHandler(Reason.Format);
            }
        } else {
            validationHandler(Reason.None);
            callBack(value, "email", contactType);
        } 

        if (validated) {
            validated(initialInputState.IsValid);
        }
    }

    const validationHandler = ( reason: Reason) => {
        let message = "";
        let hasError = false;
        let showLink = false;

        switch (reason) {
            case Reason.Format: {
                message = "Please enter a valid email address in the format name@email.com.";
                hasError = true;
                showLink = false;
                break;
            }
            case Reason.Availability: {
                message = "This email is already registered. Please check inbox or ";
                hasError = true;
                showLink = true;
                break;
            }
            case Reason.Error: {
                message = "Something went wrong. Please try again.";
                hasError = true;
                showLink = false;
                break;
            }
            case Reason.None: {
                message = "";
                hasError = false;
                showLink = false;
                break;
            }
            default: {
                message = "";
                hasError = false;
                showLink = false;
                break;
            }
        }       

        updateStateValues("ErrorMessage", message);
        updateStateValues("HasError", hasError);
        updateStateValues("ShowErrorLink", showLink);
    }

    return (
        <div className="email-input-wrap">
            <Form.Group>

                { showLabel &&
                    <Form.Label><b>Email*</b></Form.Label>
                }

                <div className="email-input">

                    {validationEvent === "onChange" &&
                        <Form.Control
                            isInvalid={validationState.HasError}
                            isValid={validationState.IsValid}
                            type="email"
                            defaultValue={email}
                            onChange={validateEmail_Event} />
                    }

                    {validationEvent === "onBlur" &&
                        <Form.Control
                            isInvalid={validationState.HasError}
                            isValid={validationState.IsValid}
                            type="email"
                            defaultValue={email}
                            onBlur={validateEmail_Event} />
                    }

                    {validationState.IsLoading &&
                        <Spinner className="spinner" animation="border" role="status" size="sm" variant="primary"></Spinner>
                    }

                    <Form.Control.Feedback type="invalid">
                        <div>
                            <Image src={warningImage} />
                            {validationState.ErrorMessage} {validationState.ShowErrorLink && <a href="https://wetu.com">Log in?</a>}
                        </div>
                    </Form.Control.Feedback>

                </div>
                                             
            </Form.Group>               
        </div>
    )
}