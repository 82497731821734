import { RatingIcon } from 'components/star.rating/features/rating.icon/rating.icon';
import React, { useEffect, useState } from 'react';
import './star.rating.scss';

interface IProps {
    starAmount?: number;
    starRating?: number;
    callBack: (rating: number) => void
}
export const StarRating = ({starAmount = 5, starRating = 0, callBack}: IProps) => {
    const [rating, setRating] = React.useState(starRating);
    const [hoverRating, setHoverRating] = React.useState(0);
    const [amount, setAmount] = useState<number[]>([]);

    useEffect(() => {
        for(let i: number = 1; i <= starAmount; i++) {
            setAmount(amount => [...amount, i]);
        }
    }, [starAmount]);

    useEffect(() => {
        callBack(rating);
    }, [callBack, rating]);

    const onMouseEnter = (index: number) => {
        setHoverRating(index);
    };
    const onMouseLeave = () => {
        setHoverRating(0);
    };
    const onSaveRating = (index: number) => {
        if (index === rating) { 
            index = 0;
        }
        setRating(index);
    };

    return(
        <div className="display-flex-justify-content-left">
        {amount.map((index) => {
            return (
            <RatingIcon
                key={index}
                index={index} 
                rating={rating} 
                hoverRating={hoverRating} 
                onMouseEnter={onMouseEnter} 
                onMouseLeave={onMouseLeave} 
                onSaveRating={onSaveRating} />
            )
        })}
        </div>
    );
}