import React, { useState } from "react";

type CustomMenuProps = {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    labeledBy?: string;
};

export const MenuItems = React.forwardRef(
(props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
    const [value] = useState('');

    return (
    <div
        ref={ref}
        style={props.style}
        className={props.className}
        aria-labelledby={props.labeledBy}
    >
        <ul className="list-unstyled">
        {React.Children.toArray(props.children).filter(
            (child: any) =>
            !value || child.props.children.toLowerCase().startsWith(value)
        )}
        </ul>
    </div>
    );
}
);