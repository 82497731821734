import React, { useEffect } from 'react'
import './wizard.step-11.scss';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { WizardCallback } from 'components/wizard/types';
import { ProfileFormPageEnum } from '../../../profile-form/profile.form.types';
import { isFormPageProfileComplete, UpdateSectionsComplete } from 'components/profile/profile.slice';
import { isFormPageProfileItemsComplete } from 'components/profile/profileitem/profileitem.slice';
import { Dictionary } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';

const WizardStep11Page = ({callBack}: WizardCallback) =>  {
  const history = useHistory();
  const { profileItems } = useSelector((state: RootState) => state.profileItemSlice);
  const { profile } = useSelector((state: RootState) => state.profileSlice);
  const dispatch = useDispatch();

  useEffect(() => {
      callBack({id: 11, completed: true, displayFooterNavigation: false, skip: false});
  }, [callBack] );

  const editProfile = () => {
    history.goBack();   
  } 

  const getSectionsComplete = (): Dictionary<boolean> => {
    //Once all changes have been made in the wizard and before publishing, generate the sectionsComplete dictionary which is used for the completion dots in the Profile Manager.
    //This is needed so that when a profile is opened in the Manager, the sidebar dots are already set (without having to make extra ProfileItem API calls, or having to visit each page to load the dot).
    //Better to do here than on the API so all completion dot logic is in one place.
    let sectionsComplete: Dictionary<boolean> = {};
    Object.values(ProfileFormPageEnum).forEach((item, i) => {
        if (item === ProfileFormPageEnum.sleepingArrangements) {
            sectionsComplete[item] = isFormPageProfileItemsComplete(profileItems, item);
        }
        else if (item) {  //Don't save none ""
            sectionsComplete[item] = isFormPageProfileComplete(profile, item);
        }
    })
    return sectionsComplete;
  }

  const publishProfile = () => {
    const sectionsComplete: Dictionary<boolean> = getSectionsComplete();
    dispatch(UpdateSectionsComplete(sectionsComplete));
    history.push('/profiles/create/12')
  } 

  return (
      <Container fluid>
        <Row className="margin-top-20">
          <Col xs="10">
            <Card className="card-page11-container" border="dark">
              <Card.Body >
                <Card.Title className="padding-bottom-20">You're ready to publish!</Card.Title>
                <Card.Subtitle className="padding-bottom-20">If you’d like to update your content, you can easily do so after you click “Publish profile”</Card.Subtitle>
                  <div>
                      <div>
                          <div>
                              <span><Button variant="primary" onClick={() => publishProfile()}>Publish profile</Button></span>
                              <span><Button onClick={() => editProfile()} variant="primary" >Edit profile</Button></span> 
                          </div>
                      </div>
                  </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )   
}  
export default WizardStep11Page