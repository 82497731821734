
import { createAsyncThunk, createSlice, Dictionary, PayloadAction, current } from "@reduxjs/toolkit";
import { createProfile, getProfileById, getProfileSummaries, updateProfile, validateProfile } from "api/profile.service";
import { showGlobalToast } from "components/toast/toast.slice";
import { IProfile, IUpdateProfileNameAddressLocation, IFeature, IUpdateProfileContact, IContact,
   ContactTypeEnum, IUpdateProfileLocation, ICreateProfilePayload, ITranslatableText, ITranslationItem, EntityTypeEnum, IUpdateProfilePayload,
    IProfileSummary, IValidateProfilePayload, IGetByIdPayload, TimeFormatEnum, BookingAffiliateEnum } from "./types";
import { ProfileFacilitiesSectionEnum, ProfileFormPageEnum } from 'pages/profile-form/profile.form.types';
import { featuresData } from 'app/config';
import { FeatureTypeEnum } from 'components/profile/types';
import _ from 'lodash';
import store from "app/store";
import { logError } from 'utils/General';

export const initialState = {
  profile: {
    id: "",
    pin: {latitude: 0, longitude: 0, drivingLatitude: 0, drivingLongitude: 0},
    contacts: [] as IContact[],
    entityType: EntityTypeEnum.profile,
    isEnhanced: true,
    isPublished: false,
    shortDescription: {enText: '', translations: {} } as ITranslatableText,
    richDescription: {enText: '', translations: {} } as ITranslatableText,
    translationItems: [] as ITranslationItem[],
    features: [] as IFeature[],
    checkinTimeFrom: {hours: 14, minutes: 0},
    checkinTimeTo: {hours: 0, minutes: 0},
    checkoutTimeFrom: {hours: 10, minutes: 0},
    checkoutTimeTo: {hours: 0, minutes: 0},
    searchAliases: [] as string[],
    availableLanguages: [] as string[],
    qualityAssuranceBody: "",
    starRating: 0,
    alternativeRating: '',
    roomCount: 0,
    timeFormat: TimeFormatEnum.Hours24,
    minimumChildAge: 0,
    affiliate: BookingAffiliateEnum.none,
    affiliateCode: "",
    clientName: "",
    gdsCodes: {} as Dictionary<string>,
    links: {} as Dictionary<string>,
    isLoaded: false
  } as IProfile,
  
  profileSummaries: [] as IProfileSummary[],
  isValidated: false,
  loading: true,
  saving: false,
  submitting: false
};

export const getDefaultContact = (contactType: ContactTypeEnum) => {
  let contact: IContact = {
    type: contactType,
    skype: '',
    address: '',
    countryCode: '',
    dialCode: '',
    email: '',
    name: '',
    telephone: '',
    website: '',
    onlineReservationUrl: ''
  };
  return contact;
}

export const isFeaturesSelected = (profile: IProfile, sectionName: string) => {
  if (profile.features) {
    const data = featuresData[sectionName];
    for (const subSectionName in data) {
      const featureArray: IFeature[] = data[subSectionName];
      for (let i = 0; i < featureArray.length; i++) {
        if (profile.features.find(x => x.id === featureArray[i].id)) {
          return true;
        }
      }
    }
  }
  return false;
}

export const isEnquiriesComplete = (profile: IProfile) : boolean => {
  const frontDesk: IContact = profile.contacts.find(x => x.type === ContactTypeEnum.frontDesk) as IContact;
  const reservation: IContact = profile.contacts.find(x => x.type === ContactTypeEnum.reservation) as IContact;
  return !!((frontDesk && frontDesk.telephone) || (reservation && reservation.telephone));
}

export const isContactsComplete = (profile: IProfile) : boolean => {
  const contacts = profile.contacts.filter(x => x.type === ContactTypeEnum.marketing || x.type === ContactTypeEnum.sales || 
      x.type === ContactTypeEnum.accounts);
  for (let i = 0; i < contacts?.length; i++) {
    if (isContactComplete(contacts[i])) {
      return true;
    }
  }
  return false;
}

export const isSocialMediaComplete = (profile: IProfile) : boolean => {
  const items = Object.values(profile.links);
  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i]) {
        return true;
      }
    }
  }
  return false;
}

export const isContactComplete = (contact: IContact) : boolean => {
  return !!(contact.address || contact.email || contact.name || contact.onlineReservationUrl || contact.skype || contact.telephone
    || contact.website);
}

export const getSectionsComplete = (profile: IProfile, pageId: string): Dictionary<boolean> => {
  let sectionsCompleteCopy: Dictionary<boolean> = {};
  if (profile.sectionsComplete) {
    sectionsCompleteCopy = _.cloneDeep(profile.sectionsComplete);
  }
  sectionsCompleteCopy[pageId] = isFormPageProfileComplete(profile, pageId);
  return sectionsCompleteCopy;
}

export const isFormPageProfileComplete = (profile: IProfile, pageId: string): boolean => {
  const isLanguagesSelected = () => {
    return profile.features?.findIndex(x => x.type === FeatureTypeEnum.language) >= 0;
  }

  const isGDSCodesCompleted = (profile: IProfile) => {
    return profile.gdsCodes && Object.keys(profile.gdsCodes).length > 0 && Object.keys(profile.gdsCodes).filter(x => profile.gdsCodes[x]).length > 0;
  }

  switch (pageId) {
    case ProfileFormPageEnum.basicInfo: {
      return !!(profile.name && profile.name.length > 0 && profile.shortDescription?.enText?.trim().length > 0);
    }
    case ProfileFormPageEnum.fastFacts: {
      return profile.qualityAssuranceBody?.length > 0 ||
      (profile.starRating && profile.starRating > 0) ||
      (profile.alternativeRating?.length > 0) ||
      (profile.roomCount && profile.roomCount > 0) ||
      (profile.timeFormat && profile.timeFormat !== initialState.profile.timeFormat) ||
      (profile.checkinTimeFrom && profile.checkinTimeFrom.hours !== initialState.profile.checkinTimeFrom?.hours) ||
      (profile.checkinTimeFrom && profile.checkinTimeFrom.minutes !== initialState.profile.checkinTimeFrom?.minutes) ||
      (profile.checkinTimeTo && profile.checkinTimeTo.hours !== initialState.profile.checkinTimeTo?.hours) ||
      (profile.checkinTimeTo && profile.checkinTimeTo.minutes !== initialState.profile.checkinTimeTo?.minutes) ||
      (profile.checkoutTimeFrom && profile.checkoutTimeFrom.hours !== initialState.profile.checkoutTimeFrom?.hours) ||
      (profile.checkoutTimeFrom && profile.checkoutTimeFrom.minutes !== initialState.profile.checkoutTimeFrom?.minutes) ||
      (profile.checkoutTimeTo && profile.checkoutTimeTo.hours !== initialState.profile.checkoutTimeTo?.hours) ||
      (profile.checkoutTimeTo && profile.checkoutTimeTo.minutes !== initialState.profile.checkoutTimeTo?.minutes) ||
      (profile.minimumChildAge && profile.minimumChildAge > 0) ||
      isLanguagesSelected() ||
      isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.fastFacts);
    }  
    case ProfileFormPageEnum.facilities: {
      return isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.property) && 
      isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.sleepingArrangments) && 
      isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.services) && 
      isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.activitiesOnSite) && 
      isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.activitiesNearby);
    }
    case ProfileFormPageEnum.integrations: {
      if (profile.affiliate === BookingAffiliateEnum.none) {
        return false;
      }

      if (profile.affiliate === BookingAffiliateEnum.nightsbridge && profile.affiliateCode?.length > 0 && isGDSCodesCompleted(profile)) {
        return true;
      }

      if (profile.affiliate === BookingAffiliateEnum.resRequest && profile.affiliateCode?.length > 0 && profile.clientName?.length > 0 && isGDSCodesCompleted(profile)) {
        return true;
      }

      return false;
    }
    case ProfileFormPageEnum.contactDetails: {
      return isEnquiriesComplete(profile) && isContactsComplete(profile) && isSocialMediaComplete(profile);
    }
    default: {
      return false;
    }
  }
}

export const getById = createAsyncThunk('profile/getById', async (payload: IGetByIdPayload, { rejectWithValue }) => {
  try {
    const response = await getProfileById(payload.profileId, payload.accountId);
    //This is needed because a zero-length array is removed from the object which gives "features is not iterable" error. You can test by initializing it after the mapper on the API side.
    if (response && response.data) {
      if (!response.data.features) {
        response.data.features = [];
      }
      if (!response.data.contacts) {
        response.data.contacts = [];
        //Again, this is needed to avoid the React error (changing an input from uncontrolled to controlled or vice versa).
        response.data.contacts.push(getDefaultContact(ContactTypeEnum.frontDesk));
        response.data.contacts.push(getDefaultContact(ContactTypeEnum.reservation));
        response.data.contacts.push(getDefaultContact(ContactTypeEnum.marketing));
        response.data.contacts.push(getDefaultContact(ContactTypeEnum.sales));
        response.data.contacts.push(getDefaultContact(ContactTypeEnum.physicalAddress));
      }
      if (!response.data.links) {
        response.data.links = {};
        response.data.links['Facebook'] = '';
        response.data.links['Twitter'] = '';
        response.data.links['Instagram'] = '';
        response.data.links['Pinterest'] = '';
      }
      return response.data;
    }
    return null;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

export const updateProfileFromStore = createAsyncThunk('profile/updateProfileFromStore', async (unusedPayload: string | void, { dispatch, rejectWithValue }) => {
  try
  {
    let profile = store.getState().profileSlice.profile;
    const response = await updateProfile({
     id: profile.id,
     category: profile.category,
     isEnhanced: profile.isEnhanced,
     latitude: profile.pin.latitude,
     longitude: profile.pin.longitude,
     name: profile.name,
     type: profile.type,
     entityType: EntityTypeEnum.profile,
     checkinTimeFrom: profile.checkinTimeFrom,
     checkoutTimeFrom: profile.checkoutTimeFrom,
     checkinTimeTo: profile.checkinTimeTo,
     checkoutTimeTo: profile.checkoutTimeTo,
     isPublished: profile.isPublished,
     translationItems: profile.translationItems,
     features: profile.features,
     searchAliases: profile.searchAliases,
     shortDescription: profile.shortDescription,
     richDescription: profile.richDescription,
     availableLanguages: profile.availableLanguages,
     status: profile.status,
     qualityAssuranceBody: profile.qualityAssuranceBody,
     starRating: profile.starRating,
     alternativeRating: profile.alternativeRating,
     roomCount: profile.roomCount,
     timeFormat: profile.timeFormat,
     minimumChildAge: profile.minimumChildAge,
     clientName: profile.clientName,
     affiliate: profile.affiliate,
     affiliateCode: profile.affiliateCode,
     gdsCodes: profile.gdsCodes,
     sectionsComplete: profile.sectionsComplete,
     contacts: profile.contacts,
     links: profile.links
   });
   return response.data;
  }
  catch(err) {
    logError(err);
    dispatch(showGlobalToast({title: "Error", message: "Your changes were not saved."}));
    return rejectWithValue(false);
  }
})

export const update = createAsyncThunk('profile/update', async (payload: IUpdateProfilePayload, { dispatch, rejectWithValue }) => {
  try {
    const response = await updateProfile(payload);
    dispatch(showGlobalToast({title: "Success", message: "Profile saved"}));
    return response.data;
  }
  catch(err) {
    logError(err);
    dispatch(showGlobalToast({title: "Error", message: "Your changes were not saved."}));
    return rejectWithValue(false);
  }
})

export const create = createAsyncThunk('profile/create', async (payload: ICreateProfilePayload, { dispatch, rejectWithValue }) => {
  try {
    const response = await createProfile(payload);
    dispatch(showGlobalToast({title: "Success", message: "Profile saved"}));
    return response.data;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

export const validate = createAsyncThunk('profile/validate', async (payload: IValidateProfilePayload, { rejectWithValue }) => {
  try {
    const response = await validateProfile(payload);
    return response.data;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

export const getSummaries = createAsyncThunk('profile/getProfileSummaries', async (id: string, { rejectWithValue }) => {
  try {
    const response = await getProfileSummaries(id);
    return response.data;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

const profileSlice = createSlice({
    name: 'profileSlice',
    initialState,
    reducers: {
      ResetProfile(state) {
        state.profile = initialState.profile;
      },
      UpdateSectionComplete(state, action) {
        state.profile.sectionsComplete = getSectionsComplete(state.profile, action.payload);
      },
      UpdateSectionCompleteWithValue(state, action) {
        let sectionsCompleteCopy: Dictionary<boolean> = {};
        if (state.profile.sectionsComplete) {
          //'current' is needed to fix 'Proxy' state: https://stackoverflow.com/questions/64586207/redux-toolkit-state-showing-as-proxy-undefined-within-reducer
          sectionsCompleteCopy = _.cloneDeep(current(state.profile.sectionsComplete));
        }
        sectionsCompleteCopy[action.payload.pageId] = action.payload.value;
        state.profile.sectionsComplete = sectionsCompleteCopy;
      },
      UpdateSectionsComplete(state, action) {
        state.profile.sectionsComplete = action.payload;
      },
      UpdateProfileLanguages(state, action: PayloadAction<string[]>) {
        state.profile.availableLanguages = action.payload;
      },
      UpdateProfileName(state, action) {
        state.profile.name = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.basicInfo, type: ''} );
      },
      UpdateProfileType(state, action) {
        state.profile.type = action.payload;
      },  
      UpdateProfileCategory(state, action) {
        state.profile.category = action.payload;
      }, 
      UpdateProfileStreetAddress(state, action) {
        state.profile.streetAddress = action.payload;
      }, 
      UpdateProfilePostalCode(state, action) {
        state.profile.postalCode = action.payload;
      },
      UpdateProfileCountry(state, action) {
        state.profile.country = action.payload.value;
        if (action.payload.resetPin) {
          profileSlice.caseReducers.ResetProfilePin(state);
        }
      },
      UpdateProfileCity(state, action) {
        state.profile.city = action.payload.value;
        if (action.payload.resetPin) {
          profileSlice.caseReducers.ResetProfilePin(state);
        }
      },
      UpdateProfilePin(state, action) {
        state.profile.pin = action.payload;
      },
      UpdateProfileCheckinTimeFrom(state, action) {
        state.profile.checkinTimeFrom = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateProfileCheckinTimeTo(state, action) {
        state.profile.checkinTimeTo = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateProfileCheckoutTimeFrom(state, action) {
        state.profile.checkoutTimeFrom = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateProfileCheckoutTimeTo(state, action) {
        state.profile.checkoutTimeTo = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateProfileRoomCount(state, action) {
        state.profile.roomCount = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateProfileStarRating(state, action) {
        state.profile.starRating = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateProfileFeatures(state, action) {
        state.profile.features = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.facilities, type: ''} );
      },
      ResetProfilePin(state) {
        state.profile.pin = {latitude: 0, longitude: 0, drivingLatitude: 0, drivingLongitude: 0};
      },
      UpdateProfileNameAddressLocation(state, action) {
        const payload = action.payload as IUpdateProfileNameAddressLocation;  

        state.profile.pin = payload.pin;
        state.profile.name = payload.name;
        state.profile.streetAddress = payload.address;
        state.profile.postalCode = payload.postalCode;
        state.profile.country = payload.country;
        state.profile.city = payload.city;
                
        let addressContact: IContact = state.profile.contacts.find(i => i.type === ContactTypeEnum.physicalAddress) as IContact;
        let index = state.profile.contacts.indexOf(addressContact);

        if (!addressContact) {
          addressContact = { type: ContactTypeEnum.physicalAddress, address: payload.address as string, name: "", "email": "", "telephone": "","skype": "", "countryCode": "" };
          state.profile.contacts.push(addressContact);
        }
        else {
          addressContact = { type: ContactTypeEnum.physicalAddress, address: payload.address as string, name: "", "email": "", "telephone": "","skype": "", "countryCode": "" };
          state.profile.contacts[index] = addressContact;
        }
      },
      UpdateProfileShortDescription(state, action: PayloadAction<ITranslatableText>) {
        state.profile.shortDescription = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.basicInfo, type: ''} );
      },
      UpdateProfileLocation(state, action){
        const payload = action.payload as IUpdateProfileLocation
        let pin = {
          latitude:  payload.latitude,
          longitude: payload.longitude,
          drivingLatitude: payload.latitude,
          drivingLongitude: payload.longitude,
        };

        pin.drivingLatitude = payload.latitude;
        pin.drivingLongitude = payload.longitude;
        state.profile.pin = pin;
      },
      UpdateProfileSearchAliases(state, action) {
        state.profile.searchAliases = action.payload;
      },
      UpdateProfileRichDescription(state, action: PayloadAction<ITranslatableText>) {
        state.profile.richDescription = action.payload;
      },
      UpdateProfileLinks(state, action) {
        state.profile.links = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.contactDetails, type: ''} );
      },
      UpdateProfileContact(state, action) {
        const payload = action.payload as IUpdateProfileContact;
        let contacts: IContact[] = [];
        if(!state.profile.contacts) {
          state.profile.contacts = contacts;
          contacts = [...state.profile.contacts, payload]
        } else {
          contacts = [...state.profile.contacts];
          var doesItemExist = contacts.find(x => x.type === payload.type);
          if(doesItemExist) {
            contacts = state.profile.contacts.map((item, index) => {
              if(item.type === payload.type) {
                return {
                  ...item,
                  ...payload
                }
              }
              return item;
            });
          } else {
            contacts = [...state.profile.contacts, payload];
          }
        }
        state.profile.contacts = contacts;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.contactDetails, type: ''} );
      },    
      UpdateQualityAssuranceBody(state, action) {
        state.profile.qualityAssuranceBody = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateAlternativeRating(state, action) {
        state.profile.alternativeRating = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateTimeFormat(state, action) {
        state.profile.timeFormat = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateMinimumChildAge(state, action) {
        state.profile.minimumChildAge = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.fastFacts, type: ''} );
      },
      UpdateAffiliate(state, action: PayloadAction<BookingAffiliateEnum>) {
        state.profile.affiliate = action.payload;
        switch (action.payload) {
          case BookingAffiliateEnum.none: {
            state.profile.affiliateCode = "";
            state.profile.clientName = "";
            break;
          }
          case BookingAffiliateEnum.nightsbridge: {
            state.profile.clientName = "";
            break;
          }
        }

        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.integrations, type: ''} );
      },
      UpdateAffiliateCode(state, action: PayloadAction<string>) {
        state.profile.affiliateCode = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.integrations, type: ''} );
      },
      UpdateClientName(state, action: PayloadAction<string>) {
        state.profile.clientName = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.integrations, type: ''} );
      },
      UpdateGDSCodes(state, action: PayloadAction<Dictionary<string>>) {
        state.profile.gdsCodes = action.payload;
        profileSlice.caseReducers.UpdateSectionComplete(state, { payload: ProfileFormPageEnum.integrations, type: ''} );
      },
      UpdateIsProfileLoaded(state, action: PayloadAction<boolean>) {
        state.profile.isLoaded = action.payload;
      }
    },
    extraReducers: builder => {
      builder.addCase(getById.pending, (state) => {
        state.loading = true;
      }) 
      builder.addCase(getById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.profile  = action.payload;
        }
      })
      builder.addCase(create.pending, (state) => {
        state.profile = initialState.profile;
        state.isValidated = false;
        state.loading = true;
      }) 
      builder.addCase(create.fulfilled, (state, action) => {
        state.loading = false;
        state.profile.id = action.payload.id;
      })
      builder.addCase(validate.fulfilled, (state, action) => {
        state.profile.isPublished = action.payload.isPublished;
        state.isValidated = true;
      })
      builder.addCase(getSummaries.pending, (state, action) => {
        state.profileSummaries = [];
        state.loading = true;
      })
      builder.addCase(getSummaries.fulfilled, (state, action) => {
        state.profileSummaries = action.payload;
        state.loading = false;
      })
      builder.addCase(update.pending, (state) => {
        state.saving = true;
      }) 
      builder.addCase(update.fulfilled, (state) => {
        state.saving = false;
      })
    },
});

export const {
  UpdateSectionComplete,
  UpdateSectionCompleteWithValue,
  UpdateSectionsComplete,
  UpdateProfileName,
  UpdateProfileType,
  UpdateProfileCategory,
  UpdateProfileNameAddressLocation,
  UpdateProfileSearchAliases,
  UpdateProfileFeatures,
  UpdateProfileShortDescription,
  UpdateProfileRichDescription,
  UpdateProfileContact,
  UpdateProfileLinks,
  UpdateProfileLocation,
  UpdateProfileStreetAddress,
  UpdateProfileCity,
  UpdateProfileCountry,
  UpdateProfilePostalCode,
  UpdateProfilePin,
  UpdateProfileCheckinTimeFrom,
  UpdateProfileCheckinTimeTo,
  UpdateProfileCheckoutTimeFrom,
  UpdateProfileCheckoutTimeTo,
  ResetProfilePin,
  UpdateProfileRoomCount,
  UpdateProfileStarRating,
  ResetProfile,
  UpdateProfileLanguages,
  UpdateQualityAssuranceBody,
  UpdateAlternativeRating,
  UpdateTimeFormat,
  UpdateMinimumChildAge,
  UpdateAffiliate,
  UpdateAffiliateCode,
  UpdateClientName,
  UpdateGDSCodes,
  UpdateIsProfileLoaded
} = profileSlice.actions;

export default profileSlice.reducer;