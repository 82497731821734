import { IProfile, ProfileStatusEnum } from 'components/profile/types';
import React, { useState } from 'react';
import { Button, Card, Col, Container, Dropdown, ProgressBar, Row } from 'react-bootstrap';
import { ProfileStatusDropdownToggle } from './features/profile.status.dropdown.toggle/profile.status.dropdown.toggle';
import './profile.status.card.scss';
import { BsCheck } from 'react-icons/bs';

interface IProps {
    profile: IProfile;
    pageInformation: PageInformation;
    callBack: (status: string) => void;
    stickyStyling?: boolean;
}

interface PageInformation {
    label: string;
    contentRating: number;
}

export const ProfileStatusCard = ({profile, pageInformation, callBack, stickyStyling = false}: IProps) => {

    const getCurrentStatus = (): string => {
        switch(profile.status) {
            case ProfileStatusEnum.active: {
                return 'Active';
            }
            case ProfileStatusEnum.disabled: {
                return 'Disabled';
            }
            case ProfileStatusEnum.closed: {
                return 'Closed';
            }
            default: {
                return 'Active';
            }
        }
    }

    const [selectedValue, setSelectedValue] = useState<string>(getCurrentStatus());

    const handleSelect = (value: string) => {
        setSelectedValue(value);
        callBack(value);
    }

    return (
        <Card id="profile-status-card" className={`border-dark ${stickyStyling ? "sticky-profile-status" : ""}`}>
            <Card.Body className="padding-bottom-none">
                <Container>
                    <Row>
                        <Col className="flex-col">
                            <Card.Title className="profile-status-title">
                                Profile status
                            </Card.Title>
                        </Col>
                        <Col className="breakdown-link">
                            <Button className="breakdown-button" variant="link">breakdown</Button>
                        </Col>
                    </Row>
                    <Row className="row-center-align">
                        <Col className="col-no-padding" xs={10}>
                            <ProgressBar now={profile.contentRating} />
                        </Col>
                        <Col className="col-no-padding progress-percentage-display" xs={2}>
                            { profile.contentRating ? profile.contentRating : 0 }%
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Dropdown id="profile-status-dropdown">
                                <Dropdown.Toggle selectedValue={selectedValue} className="menu-dropdown" as={ProfileStatusDropdownToggle} id="dropdown-custom-components">
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className={selectedValue === ProfileStatusEnum.active ? "selected-item-background" : ""} onClick={() => handleSelect("Active")} eventKey={"Active"}>
                                        <Container>
                                            <Row>
                                                <Col xs={9}>
                                                    <Row>
                                                        <b>Active</b>
                                                    </Row>
                                                    <Row>
                                                        Your profile is live and searchable <br /> in the Wetu system
                                                    </Row>
                                                </Col>
                                                <Col xs={3} className="selected-icon-col">
                                                    {
                                                        selectedValue === ProfileStatusEnum.active && <BsCheck />
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                    <Dropdown.Item className={selectedValue === ProfileStatusEnum.disabled ? "selected-item-background" : ""} onClick={() => handleSelect("Disabled")} eventKey={"Disabled"}>
                                        <Container>
                                            <Row>
                                                <Col xs={9}>
                                                    <Row>
                                                        <b>Temporarily closed (disabled)</b>
                                                    </Row>
                                                    <Row>
                                                        Hide your profile from <br /> search results
                                                    </Row>
                                                </Col>
                                                <Col xs={3} className="selected-icon-col">
                                                    {
                                                        selectedValue === ProfileStatusEnum.disabled && <BsCheck />
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <Dropdown.Item className={selectedValue === ProfileStatusEnum.closed ? "selected-item-background" : ""} onClick={() => handleSelect("Closed")} eventKey={"Closed"}>
                                        <Container>
                                            <Row>
                                                <Col xs={9}>
                                                    <Row>
                                                        <b>Permanently closed</b>
                                                    </Row>
                                                    <Row>
                                                        Deactivate your profile and<br />initiate the removing process
                                                    </Row>
                                                </Col>
                                                <Col xs={3} className="selected-icon-col">
                                                    {
                                                        selectedValue === ProfileStatusEnum.closed && <BsCheck />
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { profile.isEnhanced ? "Enhanced" : "Basic"} profile
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { profile.category }
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <hr />
            <Card.Body className="padding-top-none">
                <Container>
                    <Row>
                        <Col>
                            { pageInformation.label }
                        </Col>
                    </Row>
                    <Row className="row-center-align">
                        <Col className="col-no-padding" xs={10}>
                            <ProgressBar now={100} />
                        </Col>
                        <Col className="col-no-padding progress-percentage-display" xs={2}>
                            { pageInformation.contentRating }%
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}