import React, { useRef, useState } from 'react';
import { Tab, Nav, Button } from 'react-bootstrap';
import { languageDictionary } from 'app/config';
import './language.picker.scss';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { MdLens } from 'react-icons/md';
import { useEffect } from 'react';
import { Dictionary } from '@reduxjs/toolkit';

interface ILanguagePicker {
    availableLanguages: string[];
    languageCompletionDots: Dictionary<boolean>;        //includes 'en'
    setLanguageCodeCallback: (languageCode: string) => void;
 }

export const LanguagePicker = ({availableLanguages, languageCompletionDots, setLanguageCodeCallback}: ILanguagePicker) => {
    //This component was adapted from TranslatableTextEditor, the textbox replaced with callbacks so the language tabs can be connected to any UI element.
    const [ currentLanguageCode, setCurrentLanguageCode ] = useState<string>("en");
    const [ showNavButtons, setShowNavButtons ] = useState<boolean>(false);
    const [ languageCodes, setLanguageCodes ] = useState<string[]>(availableLanguages);
    const languageNavElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLanguageCodes(availableLanguages);
    }, [availableLanguages]);
    
    const setCurrentLanguageCodeLocal = (langCode: string) => {
        setCurrentLanguageCode(langCode);
        setLanguageCodeCallback(langCode);
    }

    const getLanguageCodeHasValue = (languageCode: string): boolean => {
        return !!languageCompletionDots[languageCode];
    }

    const scrollNavbar = (goForward: boolean) => {
        if (languageNavElement.current !== null) {
            if (goForward) {
                languageNavElement.current.scrollLeft += 150;
            } else {
                languageNavElement.current.scrollLeft -= 150;
            }
        }
    }

    useEffect(() => {
        if (languageNavElement.current && languageCodes.length > 1) {
            const hasOverflow = languageNavElement.current.offsetWidth < languageNavElement.current.scrollWidth;
            setShowNavButtons(hasOverflow);
        }
    }, [languageCodes]);

    return (
        <Tab.Container defaultActiveKey="en" activeKey={currentLanguageCode}>
            <div className="language-wrapper">
            { showNavButtons &&
                <Button className="carousel-button" variant="outline-dark" onClick={() => scrollNavbar(false)}><BsChevronLeft /></Button>
            }
                <Nav ref={languageNavElement} className="language-nav">
                    {languageCodes.map((languageCode) => (
                        (languageDictionary[languageCode]) &&
                            <Nav.Item key={languageCode}>
                                <Nav.Link className={currentLanguageCode === languageCode ? 'nav-link-active' : 'nav-link-inactive'} 
                                eventKey={languageCode} onClick={() => setCurrentLanguageCodeLocal(languageCode)}>
                                    <MdLens className="language-icon-margin-right" color={ getLanguageCodeHasValue(languageCode) ? '#2596be' : 'lightgrey' } size={8} />{languageDictionary[languageCode]}
                                </Nav.Link>
                            </Nav.Item> 
                        )
                    )}
                </Nav>
            { showNavButtons &&
                <Button className="carousel-button" variant="outline-dark" onClick={() => scrollNavbar(true)}><BsChevronRight /></Button>
            }
            </div>
        </Tab.Container>
    )
}