import React, { useRef, useState } from 'react';
import { Tab, Nav, Form, Button } from 'react-bootstrap';
import { languageDictionary } from 'app/config';
import { ITranslatableText } from 'components/profile/types';
import _ from 'lodash';
import './translatable.text.editor.scss';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { MdLens } from 'react-icons/md';
import { useEffect } from 'react';

interface ITranslatableTextEditor {
    enhanced: boolean;
    size: number;
    translatableText: ITranslatableText;
    setTranslationText(translateableText: ITranslatableText, languageCode: string): void;
    availableLanguages: string[];
    applyWordCount: boolean;
    useMultiLineTextArea: boolean;
 }

export const TranslatableTextEditor = ({setTranslationText, enhanced, size, translatableText, availableLanguages, applyWordCount, useMultiLineTextArea}: ITranslatableTextEditor) => {
    const getWordCount = (text: string) => {
        return text ? text.trim().split(' ').length : 0;
    }
    const [ currentLanguageCode, setCurrentLanguageCode ] = useState<string>("en");
    const [ showNavButtons, setShowNavButtons ] = useState<boolean>(false);
    const languageNavElement = useRef<HTMLDivElement>(null);
    const [ languageCodes, setLanguageCodes ] = useState<string[]>(availableLanguages);
    const [ wordCount, setWordCount ] = useState(getWordCount(translatableText?.enText));

    useEffect(() => {
        setLanguageCodes(availableLanguages);
    }, [availableLanguages]);
    
    const taText_onChange = (e: any, languageCode: string) => {
        let copy = _.cloneDeep(translatableText);
        if (copy) {
            if (languageCode === 'en') {
                copy.enText = e.target.value;
                setWordCount(getWordCount(copy.enText));
             } else {
                 copy.translations[languageCode] = e.target.value;
            }
            setTranslationText(copy, currentLanguageCode);
        }

       setTranslationText(copy, currentLanguageCode);
    }

    const getLanguageCodeHasValue = (languageCode: string): boolean => {
        if (languageCode === "en") {
            return translatableText?.enText?.length > 0;
        } else {
            let translation = translatableText?.translations[languageCode];
            if (translation) {
                return translation.length > 0;
            } else {
                return false;
            }
        }
    }

    const scrollNavbar = (goForward: boolean) => {
        if (languageNavElement.current !== null) {
            if (goForward) {
                languageNavElement.current.scrollLeft += 150;
            } else {
                languageNavElement.current.scrollLeft -= 150;
            }
        }
    }

    useEffect(() => {
        if (languageNavElement.current && languageCodes.length > 1) {
            const hasOverflow = languageNavElement.current.offsetWidth < languageNavElement.current.scrollWidth;
            setShowNavButtons(hasOverflow);
        }
    }, [languageCodes]);

    return (
        <React.Fragment>
            <Tab.Container defaultActiveKey="en" activeKey={currentLanguageCode}>
                { (enhanced) &&
                    <div className="language-wrapper">
                    {
                        showNavButtons &&
                        <Button className="carousel-button" variant="outline-dark" onClick={() => scrollNavbar(false)}><BsChevronLeft /></Button>
                    }
                    <Nav ref={languageNavElement} className="language-nav">
                        {languageCodes.map((languageCode) => (
                            (languageDictionary[languageCode]) &&
                                <Nav.Item key={languageCode}>
                                    <Nav.Link className={currentLanguageCode === languageCode ? 'nav-link-active' : 'nav-link-inactive'} eventKey={languageCode} onClick={() => setCurrentLanguageCode(languageCode)}>
                                        <MdLens className="language-icon-margin-right" color={ getLanguageCodeHasValue(languageCode) ? '#2596be' : 'lightgrey' } size={8} />{languageDictionary[languageCode]}
                                    </Nav.Link>
                                </Nav.Item> 
                            )
                        )}
                    </Nav>
                    {showNavButtons &&
                    <Button className="carousel-button" variant="outline-dark" onClick={() => scrollNavbar(true)}><BsChevronRight /></Button>
                    }
                    </div>
                }
                <Tab.Content>
                    {languageCodes.map((languageCode) => (
                        (languageDictionary[languageCode]) &&
                            <Tab.Pane key={languageCode} eventKey={languageCode}> 
                            {useMultiLineTextArea && 
                                <Form.Control value={languageCode === "en" ? translatableText?.enText : translatableText?.translations[languageCode]} as="textarea" rows={size} 
                                onChange={(e) => taText_onChange(e, languageCode)}/>
                            }
                            {!useMultiLineTextArea && 
                                <Form.Control value={languageCode === "en" ? translatableText?.enText : translatableText?.translations[languageCode]}
                                onChange={(e) => taText_onChange(e, languageCode)}/>
                            }
                                
                            </Tab.Pane>
                        )
                    )}
                </Tab.Content>
            </Tab.Container>
            {
                (applyWordCount && wordCount > 200 && currentLanguageCode === "en") &&
                <Form.Label className="font-12 float-right warning-text">{wordCount - 200} words over limit</Form.Label>
            }
            {
                (applyWordCount && wordCount <= 200 && currentLanguageCode === "en") && 
                <Form.Label className="font-12 float-right">Word Count: {wordCount} of 200</Form.Label>
            }
        </React.Fragment>
    )
}