import React, { useCallback, useState } from 'react';
import { RootState } from 'app/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import './basic.info.page.scss';
import { UpdateProfileLanguages, UpdateProfileRichDescription, UpdateProfileShortDescription, UpdateProfileName, 
  UpdateProfileSearchAliases } from "components/profile/profile.slice";
import { ITranslatableText } from 'components/profile/types';
import { PillTextInput } from 'components/pill.text.input/pill.text.input';
import { BsDot } from 'react-icons/bs';
import { LanguagesModal } from 'components/languages.modal/languages.modal';
import { TranslatableTextEditor } from 'components/translatable.text.editor/translatable.text.editor';

interface IProps {
  isCompleted: boolean;
}

export const BasicInfoPage = ({isCompleted}: IProps) => {

  const { profile } = useSelector((state: RootState) => state.profileSlice);
  const [ profileName, setProfileName ] = useState<string>(profile.name);
  const [ profileAliases, setProfileAliases ] = useState<string[]>(profile.searchAliases);
  const [ aliasMessage, setAliasMessage ] = useState<string>("");
  const [ aliasExists, setAliasExists ] = useState<boolean>(false);
  const [ showModal, setShowModal ] = useState<boolean>(false);
  const [ languages ] = useState<string[]>(profile.availableLanguages);
  const [ shortDescriptions, setShortDescriptions] = useState<ITranslatableText>(profile.shortDescription);
  const [ richDescriptions, setRichDescriptions] = useState<ITranslatableText>(profile.richDescription);

  const dispatch = useDispatch();

  const name_onChange = (pName: string) => {
    setProfileName(pName);
    dispatch(UpdateProfileName(pName));
  }

  const addAlias = (alias: string) => {
    let updatedAliases = profile.searchAliases ? [...profile.searchAliases, alias] : [alias];
    setProfileAliases(updatedAliases);
    dispatch(UpdateProfileSearchAliases(updatedAliases));
  }

  const removeAlias = (alias: string) => {
    let updatedAliases = [...profile.searchAliases];
    const index = updatedAliases.findIndex(x => x === alias);
    if (index > -1) {   
      updatedAliases.splice(index, 1);
    }
    setProfileAliases(updatedAliases);
    dispatch(UpdateProfileSearchAliases(profileAliases));
  }

  const checkChangeAlias = (alias: string) => {
    let updatedAliases = profile.searchAliases ? [...profile.searchAliases] : [];
    const index = updatedAliases.findIndex(x => x.toLowerCase() === alias.toLowerCase());
    if (index > -1) {
      setAliasExists(true);
      setAliasMessage(`'${alias}' already added`);
    } 
    else {
      setAliasExists(false);
      setAliasMessage(alias);
    }
  }

  const handleLanguages = (accepted: boolean, languages: string[]) => {
    if (accepted) {
      dispatch(UpdateProfileLanguages(languages));
    }
    setShowModal(false);
  }

  const setShortTranslationText = useCallback((translatableText: ITranslatableText) => {
    setShortDescriptions(translatableText);
    dispatch(UpdateProfileShortDescription(translatableText));
  }, [dispatch]);

  const setRichTranslationText = useCallback((translatableText: ITranslatableText) => {
    setRichDescriptions(translatableText);
    dispatch(UpdateProfileRichDescription(translatableText));
  }, [dispatch]);

  return (
          <Card border="dark">
            <Card.Body>
              <Container fluid className="padding-right-0">
                <Row>
                  <Col xs="5">
                    <Card.Title>Basic info</Card.Title>
                  </Col>
                  <Col xs="7" className="padding-right-0">
                  { profile.isEnhanced &&
                  <React.Fragment>
                    <Button onClick={() => setShowModal(true)} variant="link" className="btn btn-link float-right font-size-12">+ Add iBrochure languages</Button>
                    <LanguagesModal
                      languageValues={languages}
                      showModal={showModal} 
                      callBack={handleLanguages} 
                      title={"Add iBrochure languages"}  
                      body={"Your iBrochure can be presented in a local language to your clients - selecting a language option here will allow you to add translations for your content."} 
                      declineButtonText={"Cancel"} 
                      acceptButtonText={"Done"} 
                    />
                  </React.Fragment>
                  }
                  </Col>
                </Row>
              </Container>
              <BsDot color={ isCompleted ? "#2596be" : "#aaaaaa"} size={85} className="completed-dot" />
              <Form.Group>
                <Form.Label>Profile name</Form.Label>
                <Form.Control value={profileName} onChange={(e) => name_onChange(e.target.value)} id="name-input" type="text" />
              </Form.Group>
              <Form.Group>
                <Form.Label>GENERAL DESCRIPTION</Form.Label>
                <Form.Text className='field-descriptor'>The description will be used exclusively by the trade partners who access and use your content in Wetu. This description will display in their various Wetu outputs, including itineraries.</Form.Text>
                <TranslatableTextEditor setTranslationText={setShortTranslationText} enhanced={profile.isEnhanced} translatableText={shortDescriptions} size={5} 
                applyWordCount={true} availableLanguages={profile.availableLanguages} useMultiLineTextArea={true} />
              </Form.Group>
              { profile.isEnhanced &&
                <Form.Group>
                <Form.Label>EXTENDED DESCRIPTION</Form.Label>
                <Form.Text className='field-descriptor'>The iBrochure description will only appear on your iBrochure, and not on any of the other Wetu outputs. Here you have more range to write about your product.</Form.Text>
                <TranslatableTextEditor setTranslationText={setRichTranslationText} enhanced={profile.isEnhanced} translatableText={richDescriptions} size={5} 
                applyWordCount={true} availableLanguages={profile.availableLanguages} useMultiLineTextArea={true} />
                </Form.Group>
              }
              <Form.Group className="alias-container">
                <Form.Label>ALIAS</Form.Label>
                <Form.Text className='field-descriptor'>Add an alias for your location to help with searching for this point. The Alias can be a number, a reference code or a nickname that you use to distinguish your listings.</Form.Text>
                <PillTextInput addCallBack={addAlias} unique={!aliasExists} removeCallBack={removeAlias} changeCallBack={checkChangeAlias} message={aliasMessage} items={profile.searchAliases} />
              </Form.Group>
            </Card.Body>
          </Card>
  )
}