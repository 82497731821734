import DropZone from 'components/dropzone/dropzone';
import { ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import React, { useCallback, useEffect, useState }  from 'react';
import { UpdateMedia_Reducer } from 'components/profile/mediaitem/mediaitem.slice';
import { IMediaItem, MediaTypeEnum } from 'components/profile/types';
import { IndeterminateCheckbox } from 'components/indeterminate.checkbox/indeterminate.checkbox';
import { IndeterminateCheckboxEnum } from 'components/indeterminate.checkbox/types';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import { Card, Form, Button } from 'react-bootstrap';
import { MdLens } from 'react-icons/md';
import "./media.videos.scss";
import { BsFillExclamationCircleFill, BsX } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { EmbedVideosModal } from 'components/embed.videos.modal/embed.videos.modal';

export interface IProps {
    title: string;
    subtitle: string;
    isEnhanced: boolean;
    languageCodes: string[];
    mediaPayload: IMediaItem[];
    isImagesEditable: boolean;
    isCompleted: boolean;
    isProfileForm?: boolean;
    mediaCount: number;
    saveCallback?: () => void;
    uploadCallback?: (mediaItems: IMediaItem[], uploadedMedia: IMediaItem[], mediaType: MediaTypeEnum) => void;
    deleteCallback: (selectedMedia: IMediaItem[], mediaType: MediaTypeEnum) => void;
}

export const MediaVideosCard = ({title, subtitle, isEnhanced, languageCodes, mediaPayload, isImagesEditable, isCompleted, isProfileForm,
    mediaCount, saveCallback, uploadCallback, deleteCallback }: IProps) => {
    const [ addButtonText ] = useState<string>("Add Videos");
    const [ acceptedFileTypes ] = useState<string[]>(['video/webm', 'video/mp4', 'video/mov']);
    const [ uploadedVideos, setUploadedVideos ] = useState<IMediaItem[]>([]);
    const [ selectedVideos, setSelectedVideos ] = useState<IMediaItem[]>([]);
    const [ invalidFiles, setInvalidFiles ] = useState<string[]>([]);
    const [ displayInvalidFiles, setDisplayInvalidFiles ] = useState<boolean>(false); 
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ showEmbedModal, setShowEmbedModal ] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setUploadedVideos(mediaPayload);
    }, [mediaPayload]);

    const deleteSelectedVideos = () => {
        setShowModal(true);
    }

    const deleteVideosCallBack = useCallback((deleteVideos: boolean) => {
        setShowModal(false);
        if (deleteVideos) {
            deleteCallback(selectedVideos, MediaTypeEnum.video);
            setSelectedVideos([]);
            setUploadedVideos([]);
        }
    }, [selectedVideos, deleteCallback])

    const setAllVideosState = useCallback((checked: boolean) => {
        const videos: IMediaItem[] = _.cloneDeep(uploadedVideos);
        videos.forEach(video => {
            video.isChecked = checked;
        });
        setUploadedVideos(videos);
    }, [uploadedVideos]);

    const checkboxCallback = useCallback((value: number) => {
        switch(value) {
            case 0: {
                setAllVideosState(false);
                break;
            }
            case 1: {
                break;
            }
            case 2: {
                setAllVideosState(true);
                break;
            }
        }
    }, [setAllVideosState]);

    const clearInvalidFiles = () => {
        setInvalidFiles([]);
    }
  
    const saveCallbackLocal = () => {
        if (saveCallback) {
            saveCallback();
        }
    }

    const uploadVideoCallBack = useCallback((videos: IMediaItem[], invalidFiles: string[]) => {
        if (uploadCallback) {
            uploadCallback(videos, uploadedVideos, MediaTypeEnum.video);
        }
    }, [uploadedVideos, uploadCallback]);

    const invalidFilesCallback = useCallback((invalidFilesArray: string[]) => {
        setInvalidFiles(invalidFilesArray);
        if(invalidFilesArray.length > 0) {
            setDisplayInvalidFiles(true);
        }
    }, [])
    
    const selectCallback = useCallback((mediaItems: IMediaItem[]) => {
        const selectedMediaItems = mediaItems.filter(x => x.isChecked === true);
        setSelectedVideos(selectedMediaItems);
    }, [])

    const videoDropCallback = (newMediaItems: IMediaItem[]) => {
        dispatch(UpdateMedia_Reducer({mediaItems: newMediaItems, mediaType: MediaTypeEnum.video}));
    }

    const embedPopupModalOpen = () => {
        setShowEmbedModal(true);
    }

    const embedVideosCloseCallBack = useCallback(() => {
        setShowEmbedModal(false);
    }, []);

    const embedVideosCallBack = useCallback(() => {
    }, []);

    return(
        <Card id={ProfileFormPageSectionEnum.videos} border="dark" className="videos-card">
            <Card.Body>
                <Card.Title className="card-section-dot card-title-section">
                    <div>
                        {isProfileForm && <MdLens className={`section-dot ${isCompleted ? "complete-dot" : "incomplete-dot" }`} />}                      
                        {title}                    
                    </div>                     
                    <Button onClick={() => embedPopupModalOpen()} variant="link">Embed videos</Button>
                </Card.Title>
                <Card.Subtitle>
                    {subtitle}
                </Card.Subtitle>
                <EmbedVideosModal showModal={showEmbedModal} callBack={embedVideosCallBack} closeCallback={embedVideosCloseCallBack}  />
                {
                    (invalidFiles.length > 0 && displayInvalidFiles) &&
                    <Card border="dark margin-top-10">
                        <Card.Body>
                        <Card.Title className="invalid-file"><div><BsFillExclamationCircleFill /> &nbsp; These files couldn't be added</div><div><Button variant="clear" onClick={() => clearInvalidFiles()}><BsX /></Button></div></Card.Title>
                        <ul>
                            {invalidFiles.map((item, index) => <li key={index}>{item}</li>)}
                        </ul> 
                        </Card.Body>
                    </Card>
                }
                {
                    (uploadedVideos && selectedVideos.length === 0) &&
                    <Form.Text>  {mediaPayload.length === 1 ? "Video" : "Videos"} {mediaPayload.length} </Form.Text>
                }
                {
                    (selectedVideos.length > 0 && mediaPayload.length !== 0 ) &&
                    <div className="images-selected">
                        <Form.Label className="display-flex"><IndeterminateCheckbox 
                            value={selectedVideos.length === uploadedVideos.length ? IndeterminateCheckboxEnum.checked : IndeterminateCheckboxEnum.indeterminate} 
                            callBack={checkboxCallback}></IndeterminateCheckbox> {selectedVideos.length} {selectedVideos.length === 1 ? "video" : "videos"} selected</Form.Label>
                        <Button onClick={() => deleteSelectedVideos()} variant="link">delete videos</Button>
                        <AcceptDeclineModal showModal={showModal} callBack={deleteVideosCallBack} title={`Delete ${selectedVideos.length} of ${uploadedVideos.length} videos`}
                            body={"This can't be undone."} declineButtonText={selectedVideos.length > 1 ? 'Keep videos' : 'Keep video'} 
                            acceptButtonText={selectedVideos.length > 1 ? 'Delete videos' : 'Delete video'} />
                    </div>
                }

                <DropZone addButtonText={addButtonText} acceptedFileTypes={acceptedFileTypes} dropMediaItems={uploadedVideos} invalids={invalidFiles} 
                uploadCallBack={uploadVideoCallBack} isEnhanced={isEnhanced} languageCodes={languageCodes} 
                itemDropCallback={videoDropCallback} selectCallback={selectCallback} mediaCount={mediaCount}
                isItemsEditable={isImagesEditable} saveCallback={saveCallbackLocal} mediaType={MediaTypeEnum.video} invalidFilesCallback={invalidFilesCallback} /> 
            </Card.Body>
        </Card>
    )
}