import React, { useState, useCallback} from 'react';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { IndeterminateCheckboxEnum } from './types';
import './indeterminate.checkbox.scss';

interface IProps {
    callBack: (value: number) => void;
    value: IndeterminateCheckboxEnum;
}

export const IndeterminateCheckbox = ({callBack, value}: IProps) => {
    const [state, setState] = useState<IndeterminateCheckboxEnum>(value);

    useEffect(() => {
      setState(value);
    }, [value]);

    const indetSetter = useCallback((el: HTMLInputElement) => {
      if (el) {
        if (state === IndeterminateCheckboxEnum.indeterminate) {
          el.indeterminate = true;
        } else if (state === IndeterminateCheckboxEnum.checked) {
          el.indeterminate = false;
        } else if (state === IndeterminateCheckboxEnum.unchecked) {
          el.indeterminate = false;
        }
      }
      
    }, [state]);

    const advance = () => {
      setState(prev => (prev + 1) % 3);
      callBack((state + 1) % 3);
    }
    
    return <Form.Check className="indeterminate-checkbox" type="checkbox"
                  checked={state === IndeterminateCheckboxEnum.checked}
                  ref={indetSetter}
                  onChange={advance} />;
  }