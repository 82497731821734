import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './accept.decline.modal.scss';

interface IProps {
    showModal?: boolean;
    title?: string;
    body?: string;
    declineButtonText?: string;
    acceptButtonText?: string;
    callBack: (outcome: boolean) => void;
    confirmationRequired?: boolean;
    confirmationLabel?: string;
}

export const AcceptDeclineModal = ({title = '', showModal = false, body = '', callBack, declineButtonText = 'Close', acceptButtonText = 'Save changes', confirmationRequired = false, confirmationLabel = ''}: IProps) => {
    const [ confirmed, setConfirmed ] = useState(false);

    const acceptButtonClick = () => {
        setConfirmed(false);
        callBack(true);
    }

    const declineButtonClick = () => {
        setConfirmed(false);
        callBack(false);
    }

    return (
        <Modal show={showModal} onHide={() => declineButtonClick()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{body}</p>
                {
                    confirmationRequired &&
                    <div className="flex-display-confirmation">
                        <Form.Check id="confirmation" checked={confirmed} onChange={(e: any) => setConfirmed(e.target.checked)} label={confirmationLabel} /><span className="red-text">*</span>
                    </div>
                }
                <p></p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => declineButtonClick()} variant="secondary">{declineButtonText}</Button>
                <Button disabled={confirmationRequired && !confirmed} onClick={() => acceptButtonClick()} variant="primary">{acceptButtonText}</Button>
            </Modal.Footer>
        </Modal>
    )
}