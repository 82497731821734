import React, { useEffect, useState } from 'react';
import './facilities.page.scss';
import { FacilitiesSectionCard } from './features/facilities.section.card/facilities.section.card';
import { ProfileFacilitiesSectionEnum, ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import { useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { isFeaturesSelected } from 'components/profile/profile.slice';

export const FacilitiesPage = () => {

    const { profile } = useSelector((state: RootState) => state.profileSlice);
    const [ propertyCompleted, setPropertyCompleted ] = useState<boolean>(false);
    const [ roomsCompleted, setRoomsCompleted ] = useState<boolean>(false);
    const [ servicesCompleted, setServicesCompleted ] = useState<boolean>(false);
    const [ activitiesOnSiteCompleted, setActivitiesOnSiteCompleted ] = useState<boolean>(false);
    const [ activitiesNearbyCompleted, setActivitiesNearbyCompleted ] = useState<boolean>(false);
    
    useEffect(() => {
        setPropertyCompleted(isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.property));
        setRoomsCompleted(isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.sleepingArrangments));
        setServicesCompleted(isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.services));
        setActivitiesOnSiteCompleted(isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.activitiesOnSite));
        setActivitiesNearbyCompleted(isFeaturesSelected(profile, ProfileFacilitiesSectionEnum.activitiesNearby));
    }, [profile]);

    return(
        <div className="facilities-page">
            <FacilitiesSectionCard isCompleted={propertyCompleted} featureDataSectionName={ProfileFacilitiesSectionEnum.property} title={"Property"} sectionId={ProfileFormPageSectionEnum.facilitiesProperty} labels={[]} subtitle={"The details you select will be displayed on your iBrochure and will also appear on digital iterinaries featuring your listing that are built by Operators in Wetu."} />
            <FacilitiesSectionCard isCompleted={roomsCompleted} featureDataSectionName={ProfileFacilitiesSectionEnum.sleepingArrangments} title={"Sleeping arrangements"} sectionId={ProfileFormPageSectionEnum.facilitiesSleepingArrangements} labels={[]} subtitle={""} />
            <FacilitiesSectionCard isCompleted={servicesCompleted} featureDataSectionName={ProfileFacilitiesSectionEnum.services} title={"Services"} sectionId={ProfileFormPageSectionEnum.facilitiesServices} labels={[]} subtitle={""} />
            <FacilitiesSectionCard isCompleted={activitiesOnSiteCompleted} featureDataSectionName={ProfileFacilitiesSectionEnum.activitiesOnSite} title={"Activities on site"} sectionId={ProfileFormPageSectionEnum.facilitiesActivitiesOnSite} labels={[]} subtitle={""} />
            <FacilitiesSectionCard isCompleted={activitiesNearbyCompleted} featureDataSectionName={ProfileFacilitiesSectionEnum.activitiesNearby} title={"Activities nearby"} sectionId={ProfileFormPageSectionEnum.facilitiesActivitiesNearby} labels={[]} subtitle={""} />
        </div>
    )
}