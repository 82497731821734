import React, { useEffect, useState } from 'react';
import './sort.label.scss';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

interface ISortLabelProps {
    label: string;
    sortValue: string;
    currentSortColumn: string;
    callBack: (sortValue: string, sortDirection: string) => void;
}

export const SortLabel = ({label, sortValue, currentSortColumn, callBack}:ISortLabelProps) => {
    const [sortDirection, setSortDirection] = useState<string>("unSorted");

    useEffect(() => {   
        if(currentSortColumn !== "" && currentSortColumn !== sortValue){
            setSortDirection("unSorted");
        }
    }, [currentSortColumn, sortValue] );

    const sortOnClick= () => {
        if(sortDirection === "unSorted"){
            setSortDirection("asc");
        }

        if(sortDirection === "asc"){
            setSortDirection("desc");
        }

        if(sortDirection === "desc"){
            setSortDirection("asc");
        }
        callBack(sortValue, sortDirection);
    }

    return (
        <div className="sort-label" onClick={() => sortOnClick()}>
            {label}
            <div className="arrows">
                { (sortDirection === "unSorted") &&
                    <FaSort/>
                }
                { (sortDirection === "asc") &&
                    <FaSortDown/>
                }
                { (sortDirection === "desc") &&
                    <FaSortUp/>
                }
            </div>
        </div>     
    )
}