import React, { useState, useEffect } from 'react';
import { RootState } from 'app/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {  UpdateProfileContact, UpdateProfileLinks, getDefaultContact } from 'components/profile/profile.slice';
import { BsDot, BsInfoCircleFill, BsFillExclamationCircleFill, BsFillTrashFill } from 'react-icons/bs';
import { ContactTypeEnum, IContact, IMultiSelectData } from 'components/profile/types';
import { ChecklistSection, ProfileFormPageSectionEnum } from '../../profile.form.types';
import './contact-details.page.scss';
import PhoneInput from "react-phone-input-2";
import { EmailInput } from "components/email-input/email-input";
import { ShowMore } from 'components/show.more/show.more';
import { MultiSelectRepeater } from 'components/multi.select.repeater/multi.select.repeater';
import { Dictionary } from '@reduxjs/toolkit';
import _ from 'lodash';
import { LinkTypeEnum }from 'components/profile/types';

interface IProps {
  sections: ChecklistSection[];
}

enum UrlTypeEnum
{
    website = "Website",
    onlineReservation = "OnlineReservation",
    facebook = "Facebook",
    instagram = "Instagram",
    twitter = "Twitter",
    pinterest = "Pinterest"
}

export const ContactDetailsPage = ({sections}: IProps) => {
    const { profile } = useSelector((state: RootState) => state.profileSlice);

    const getContact = (contactType: ContactTypeEnum) : IContact => {
        let existingContact: IContact = _.cloneDeep(profile.contacts.find(x => x.type === contactType)) as IContact;
        if (!existingContact) {
            existingContact = getDefaultContact(contactType);
        }
        return existingContact;
    }

    const getContactField = (contact: IContact): string => {
        let savedContact: IContact = _.cloneDeep(profile.contacts.find(x => x.type === contact.type)) as IContact;
        if (!savedContact) {
            savedContact = {};
        }
        const returnValue = contact.address ? savedContact.address?.toString() :
            contact.countryCode ? savedContact.countryCode?.toString() :
            contact.dialCode ? savedContact.dialCode?.toString() :
            contact.email ? savedContact.email?.toString() :
            contact.name ? savedContact.name?.toString() :
            contact.skype ? savedContact.skype?.toString() :
            contact.telephone ? savedContact.telephone?.toString() :
            contact.website ? savedContact.website?.toString() :
            contact.onlineReservationUrl ? savedContact.onlineReservationUrl?.toString() : '';
        return returnValue ? returnValue : '';
    }

    const isValidURL = (str: string | undefined) : boolean => {
        if (!str) {
            //If the URL field is empty, do not show red validation formatting.
            return true;
        }
        try
        {
            return Boolean(new URL(str)); 
        }
        catch (e)
        {
            return false;
        }
    }

/*
We need 2 boolean values for each phone to determine whether to show red validation text/formatting because:
 - [A] The user is required to enter EITHER a front desk OR reservations number, and...
 - [B] Validation text/formatting should not show on page load (just when they click out of the field).
*/
    const [ isFrontDeskPhoneComplete, setIsFrontDeskPhoneComplete ] = useState<boolean>(false);
    const [ isReservationsPhoneComplete, setIsReservationsPhoneComplete ] = useState<boolean>(false);
    const [ showFrontDeskPhoneValidation, setShowFrontDeskPhoneValidation ] = useState<boolean>(false);
    const [ showReservationsPhoneValidation, setShowReservationsPhoneValidation ] = useState<boolean>(false);
    
    const [ website, setWebsite ] = useState<string>(getContactField({type: ContactTypeEnum.reservation, website: ' '}));
    const [ onlineResUrl, setOnlineResUrl ] = useState<string>(getContactField({type: ContactTypeEnum.reservation, onlineReservationUrl: ' '}));
    const [ reservationContact ] = useState<IContact>(getContact(ContactTypeEnum.reservation));
    const [ socialLinks, setSocialLinks ] = useState<Dictionary<string>>({});
    const [ isInvalidUrl_website, setIsInvalidUrl_website]  = useState<boolean>(!isValidURL(reservationContact.website));
    const [ isInvalidUrl_onlineRes, setIsInvalidUrl_onlineRes]  = useState<boolean>(!isValidURL(reservationContact.onlineReservationUrl));
    const [ isInvalidUrls, setIsInvalidUrls] = useState<Dictionary<boolean>>({});
    const [ showReservation, setShowReservation ] = useState<boolean>(!!(reservationContact.telephone));
    const [ multiSelectDataContacts, setMultiSelectDataContacts ] = useState<IMultiSelectData[]>([]);
    const [ multiSelectDataLinks, setMultiSelectDataLinks ] = useState<IMultiSelectData[]>([]);
    const dispatch = useDispatch();

    const updateEmail = (updateValue: string | boolean, name: string, contactType?: ContactTypeEnum) => {
        let existingContact: IContact = _.cloneDeep(profile.contacts.find(x => x.type === contactType)) as IContact;
        existingContact.email = updateValue as string;
        dispatch(UpdateProfileContact(existingContact));
    }

    useEffect(() => {
        if (Object.keys(socialLinks).length === 0) {
            setSocialLinks(profile.links);
        }
    }, [setSocialLinks, profile.links, socialLinks])

    //Set the isInvalidUrls flags which are used by the UI to set validation formatting and messages.
    useEffect(() => {
        if (Object.keys(isInvalidUrls).length === 0) {
            Object.values(UrlTypeEnum).forEach((item) => {
                isInvalidUrls[item] = false;
            })
        }
    }, [isInvalidUrls])

    //Set the IMultiSelectData for social media links. IMultiSelectData is used by the MultiSelectRepeater.
    useEffect(() => {
        const getInitMultiSelectData = () : IMultiSelectData[] => {
            const initMultiSelectData: IMultiSelectData[] = [];
            for (let i = 0; i < Object.keys(profile.links).length; i++) {
                initMultiSelectData.push({
                    title: Object.keys(profile.links)[i],
                    subtitle: '',
                    isChecked: !!(profile.links[Object.keys(profile.links)[i]]),
                    key: Object.keys(profile.links)[i]
                });
            }
           return initMultiSelectData;
        }
        if (multiSelectDataLinks.length === 0) {
            setMultiSelectDataLinks(getInitMultiSelectData());
        }
    }, [profile.links, multiSelectDataLinks.length])

    //Initialise MultiSelectData used for the Contacts MultiSelectRepeater.
    useEffect(() => {
        const getInitMultiSelectData = () : IMultiSelectData[] => {
            const initMultiSelectData: IMultiSelectData[] = [{
                title: "Marketing contact person", 
                subtitle: "These contact details will display on your iBrochure.",
                isChecked: isContactSection(ContactTypeEnum.marketing),
                key: ContactTypeEnum.marketing
               },
               {
                   title: "Sales person",
                   subtitle: "Internal and Wetu purposes and will not show on any output.",
                   isChecked: isContactSection(ContactTypeEnum.sales),
                   key: ContactTypeEnum.sales
               },
               {
                   title: "Accounts contact person",
                   subtitle: "Contact information for your Agents and Tour Operators to see in the Wetu System.",
                   isChecked: isContactSection(ContactTypeEnum.accounts),
                   key: ContactTypeEnum.accounts
               }
           ];
           return initMultiSelectData;
        }
        const isContactSection = (contactType: ContactTypeEnum): boolean => {
            if (profile.contacts) {
                const contact = profile.contacts.find(x => x.type === contactType);
                if (contact) {
                    for (let i = 0; i < Object.keys(contact).length; i++) {
                        if (Object.keys(contact)[i] !== 'type') {
                            if (Object.values(contact)[i]) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }

        if (multiSelectDataContacts.length === 0) {
            setMultiSelectDataContacts(getInitMultiSelectData());
        }
    }, [setMultiSelectDataContacts, multiSelectDataContacts.length, profile.contacts])

    const showRes = () => {
        setShowReservation(true);
    }

    const getLinkValue = (key: string): string => {
        if (socialLinks[key]) {
            return socialLinks[key] as string;
        }
        return '';
    }

    const updateLinkLocal = (key: string, value: string) => {
        const copy = _.cloneDeep(socialLinks);
        copy[key] = value;
        setSocialLinks(copy);
    }

    const updateLinkValue = (key: string, value: string) => {
        let copy = _.cloneDeep(profile.links);
        copy[key] = value;
        dispatch(UpdateProfileLinks(copy));
    }

    const updateContactName = (contactType: ContactTypeEnum, newValue: string) => {
        let existingContact = getContact(contactType);
        existingContact.name = newValue;
        dispatch(UpdateProfileContact(existingContact));
    }

    const updateContactPhone = (contactType: ContactTypeEnum, countryCode: string, telephone: string, dialCode: string) => {
        let existingContact = getContact(contactType);
        existingContact.telephone = telephone;
        existingContact.countryCode = countryCode;
        existingContact.dialCode = dialCode;
        dispatch(UpdateProfileContact(existingContact));
    }

    const updateFrontDeskPhone = (countryCode: string, telephone: string, dialCode: string) => {
        let existingContact = getContact(ContactTypeEnum.frontDesk);
        existingContact.telephone = telephone;
        existingContact.countryCode = countryCode;
        existingContact.dialCode = dialCode;
        setIsFrontDeskPhoneComplete(!!telephone);
        setShowFrontDeskPhoneValidation(!telephone);
        setShowReservationsPhoneValidation(false);
        dispatch(UpdateProfileContact(existingContact));
    }

    const updateReservationsPhone = (countryCode: string, telephone: string, dialCode: string) => {
        let existingContact = getContact(ContactTypeEnum.reservation);
        existingContact.telephone = telephone;
        existingContact.countryCode = countryCode;
        existingContact.dialCode = dialCode;
        setIsReservationsPhoneComplete(!!existingContact.telephone);
        setShowFrontDeskPhoneValidation(false);
        setShowReservationsPhoneValidation(!existingContact.telephone);
        dispatch(UpdateProfileContact(existingContact));
    }

    const updateContactSkype = (contactType: ContactTypeEnum, newValue: string) => {
        let existingContact = getContact(contactType);
        existingContact.skype = newValue;
        dispatch(UpdateProfileContact(existingContact));
    }

    const getIsInvalidSocialUrl = (socialMediaLink: LinkTypeEnum): boolean => {
        return !!isInvalidUrls[socialMediaLink];
    }

    const updateWebsite = (value: string) => {
        setWebsite(value);
    }

    const updateOnlineResUrl = (value: string) => {
        setOnlineResUrl(value);
    }

    const saveWebsite = () => {
        const isValid = isValidURL(website);
        setIsInvalidUrl_website(!isValid);
        if (isValid) {
            let existingContact = getContact(ContactTypeEnum.reservation);
            existingContact.website = website;
            dispatch(UpdateProfileContact(existingContact));
        }
    }

    const saveOnlineResUrl = () => {
        const isValid = isValidURL(onlineResUrl);
        setIsInvalidUrl_onlineRes(!isValid);
        if (isValid) {
            let existingContact = getContact(ContactTypeEnum.reservation);
            existingContact.onlineReservationUrl = onlineResUrl;
            dispatch(UpdateProfileContact(existingContact));
        }
    }

    const validateLinkUrlAndSave = (url: string, socialMediaLink: LinkTypeEnum) => {
        const isInValid = socialMediaLink === LinkTypeEnum.twitter ? false : !isValidURL(url);
        setIsInvalidUrls(items => {
            let itemsCopy = _.cloneDeep(items);
            let isInvalid = isInValid;
            itemsCopy[socialMediaLink] = isInvalid;
            return itemsCopy;
        });
        if (!isInValid) {
            updateLinkValue(socialMediaLink, url);
        }
    }
    
    const selectCallbackContacts = (data: IMultiSelectData[]) => {
        setMultiSelectDataContacts(data);
        data.forEach((item) => {
            let savedContact: IContact = _.cloneDeep(profile.contacts.find(x => x.type === item.key)) as IContact;
            if (savedContact && !item.isChecked) {
                dispatch(UpdateProfileContact(getDefaultContact(savedContact.type as ContactTypeEnum)));
            }
        });
    }

    const selectCallbackLinks = (data: IMultiSelectData[]) => {
        setMultiSelectDataLinks(data);
        let linksTemp = _.cloneDeep(profile.links);
        let isChanges = false;
        data.forEach((item) => {
            if (!item.isChecked && linksTemp[item.key]) {
                linksTemp[item.key] = '';
                isChanges = true;
            }
        });
        if (isChanges) {
            setSocialLinks(linksTemp);
            dispatch(UpdateProfileLinks(linksTemp));
        }
    }

    const removeContact = (contactType: ContactTypeEnum) => {
        setMultiSelectDataContacts(items => {
            let itemsCopy = _.cloneDeep(items);
            itemsCopy.forEach(item => {
                if (item.key === contactType) {
                    item.isChecked = false;
                }
            });
            return itemsCopy;
        });
        dispatch(UpdateProfileContact(getDefaultContact(contactType)));
    }

    const removeLink = (linkType: LinkTypeEnum) => {
        setMultiSelectDataLinks(items => {
            let itemsCopy = _.cloneDeep(items);
            itemsCopy.forEach(item => {
                if (item.key === linkType) {
                    item.isChecked = false;
                }
            });
            return itemsCopy;
        })
        let linksCopy = _.cloneDeep(profile.links);
        linksCopy[linkType] = '';
        dispatch(UpdateProfileLinks(linksCopy));
    }

    const validateFrontDeskTelephone = () => {
        let existingContact = getContact(ContactTypeEnum.frontDesk);
        setIsFrontDeskPhoneComplete(!!existingContact.telephone);
        setShowFrontDeskPhoneValidation(!existingContact.telephone);
    }

    const validateReservationsTelephone = () => {
        let existingContact = getContact(ContactTypeEnum.reservation);
        setIsReservationsPhoneComplete(!!existingContact.telephone);
        setShowReservationsPhoneValidation(!existingContact.telephone);
    }

    const getSectionComplete = (sectionId: ProfileFormPageSectionEnum) : boolean => {
        return !!sections.find(x => x.id === sectionId)?.isCompleted;
    }

    const isFrontDeskPhoneValidation = () : boolean => {
        return !isFrontDeskPhoneComplete && showFrontDeskPhoneValidation && !isReservationsPhoneComplete;
    }

    const isReservationsPhoneValidation = () : boolean => {
        return !isReservationsPhoneComplete && showReservationsPhoneValidation && !isFrontDeskPhoneComplete;
    }

    return (
        <div className="contact-details">
            <Card id="Enquiries" border="dark" className="enquiries">
                <Card.Body>
                    <Card.Title className="card-title-fast-facts">Enquiries</Card.Title>
                    <Card.Subtitle className="">FRONT DESK</Card.Subtitle>
                    <Container fluid className="two-col-content">
                        <Row>
                            <Col xs="6" className="padding-0">
                                <Form.Group>
                                    <Form.Label>Telephone Number <span className="red-text">*</span></Form.Label>
                                    <div className={isFrontDeskPhoneValidation() ? "is-required" : "" }>
                                        <PhoneInput country={getContactField({type: ContactTypeEnum.frontDesk, countryCode: ' ' })} 
                                            value={getContactField({type: ContactTypeEnum.frontDesk, telephone: ' ' })}
                                            onChange={(telephone, country) => { if ('countryCode' in country) 
                                            updateFrontDeskPhone(country.countryCode, telephone, country.dialCode)}}
                                            enableSearch={true} inputStyle={{ width: "100%" }} defaultMask=".. ... ...."  countryCodeEditable={true}
                                            onBlur={() => validateFrontDeskTelephone()}
                                        />
                                    </div>
                                    <div className="position-div" hidden={ !isFrontDeskPhoneValidation() }>
                                        <BsFillExclamationCircleFill className="red-icon" />  <span className="red-text-small">Please enter a Front desk or Reservations number.</span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs="6" className="padding-right-0">
                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <EmailInput email={getContactField({type: ContactTypeEnum.frontDesk, email: ' ' })} validationEvent={"onBlur"} 
                                        contactType={ContactTypeEnum.frontDesk} callBack = {updateEmail} showLabel = {false} 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                    <BsDot color={ getSectionComplete(ProfileFormPageSectionEnum.enquiries) ? "#2596be" : "#aaaaaa"} size={85} className="completed-dot" />
                    <Row>
                        <Col xs="6" className="left-col">
                            <Form.Label>RESERVATIONS</Form.Label>
                            <Form.Group className="reservation-section">
                                <ShowMore label='+ Add Reservation number' callback={showRes} show={showReservation}>
                                    <Form.Label>Telephone Number <span className="red-text">*</span></Form.Label>
                                    <div className={isReservationsPhoneValidation() ? "is-required" : "" }>
                                        <PhoneInput
                                            country={getContactField({type: ContactTypeEnum.reservation, countryCode: ' ' })} 
                                            value={getContactField({type: ContactTypeEnum.reservation, telephone: ' ' })}
                                            onChange={(telephone, country) => { if ('countryCode' in country) 
                                            updateReservationsPhone(country.countryCode, telephone, country.dialCode)}}
                                            enableSearch={true} inputStyle={{ width: "100%" }} defaultMask=".. ... ...." countryCodeEditable={true}
                                            onBlur={() => validateReservationsTelephone()}
                                        />
                                    </div>
                                    <div className="position-div" hidden={ !isReservationsPhoneValidation() }>
                                        <BsFillExclamationCircleFill className="red-icon" />  <span className="red-text-small">Please enter a Front desk or Reservations number.</span>
                                    </div>
                                </ShowMore>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Skype username</Form.Label>
                                <Form.Control value={getContactField({type: ContactTypeEnum.reservation, skype: ' '})} 
                                    onChange={event => updateContactSkype(ContactTypeEnum.reservation, event.target.value)}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Website</Form.Label>
                                <Form.Control value={website} className={!isInvalidUrl_website ? "" : "invalid-url"} 
                                    onChange={event => updateWebsite(event.target.value)} onBlur={() => saveWebsite() }
                                />
                                <div className="position-div" hidden={!isInvalidUrl_website }>
                                    <BsFillExclamationCircleFill className="red-icon" />  <span className="red-text-small">Please enter a valid URL</span>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="tooltip-label">URL to online reservation system
                                    <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-url-to-res-system`} >Send clients to a specific web page by adding a URL to the Book Now button of this listing.</Tooltip> }>
                                        <BsInfoCircleFill className="inline" size={18} />
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control value={ onlineResUrl } 
                                    className={!isInvalidUrl_onlineRes ? "" : "invalid-url"} 
                                    onChange={event => updateOnlineResUrl(event.target.value)} onBlur={() => saveOnlineResUrl() }
                                />
                                { isInvalidUrl_onlineRes &&
                                    <><BsFillExclamationCircleFill className="red-icon" />  <span className="red-text-small">Please enter a valid URL</span></>
                                }
                                { !isInvalidUrl_onlineRes &&
                                    <Form.Text>Example https://book.reservations.com/123</Form.Text>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <MultiSelectRepeater id="Contacts" title="Contacts" dropDownDefaultText="Select contacts" 
                multiSelectData={ multiSelectDataContacts } multiSelectCallback={ selectCallbackContacts } >
                <BsDot color={ getSectionComplete(ProfileFormPageSectionEnum.contacts) ? "#2596be" : "#aaaaaa"} size={85} className="completed-dot" />
            {
                multiSelectDataContacts.map((item, index) => 
                item.isChecked &&
                    <Row key={`row-contacts${index}`} className="contact-section">
                        <Col xs="6" className="left-col">
                            <Form.Group>
                                <div className="title-wrapper-div">
                                    <Form.Label className="upper-case">{item.title}</Form.Label>
                                    <div onClick={() => removeContact(item.key as ContactTypeEnum)} className="gds-trash">
                                        <BsFillTrashFill className="trash-icon" size={20} title="Delete" />
                                    </div>
                                </div>
                                <Form.Label>Contact name</Form.Label>
                                <Form.Control value={getContactField({type: item.key, name: ' '})}
                                    onChange={event => updateContactName(item.key as ContactTypeEnum, event.target.value)} />
                                <Form.Label className="padding-top-15">Telephone number</Form.Label>
                                <PhoneInput country={getContactField({type: item.key, countryCode: ' ' })} 
                                    value={getContactField({type: item.key, telephone: ' ' })}
                                    onChange={(telephone, country) => { if ('countryCode' in country) 
                                    updateContactPhone(item.key as ContactTypeEnum, country.countryCode, telephone, country.dialCode)}}
                                    enableSearch={true} inputStyle={{ width: "100%" }} defaultMask=".. ... ...."  countryCodeEditable={true}
                                />
                                <Form.Label className="padding-top-15">Email address</Form.Label>
                                <EmailInput email={getContactField({type: item.key, email: ' ' })} validationEvent={"onBlur"} 
                                    contactType={item.key as ContactTypeEnum} callBack = {updateEmail} showLabel = {false} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )
            }
            </MultiSelectRepeater>

            <MultiSelectRepeater id="SocialMedia" title="Social media" dropDownDefaultText="Select social media accounts" 
                multiSelectData={ multiSelectDataLinks } multiSelectCallback={ selectCallbackLinks } >
                <BsDot color={ getSectionComplete(ProfileFormPageSectionEnum.socialMedia) ? "#2596be" : "#aaaaaa"} size={85} className="completed-dot" />
            {
                multiSelectDataLinks.map((item, index) => 
                item.isChecked &&
                    <Row key={`row-links${index}`}>
                        <Col xs="6" className="left-col">
                            <Form.Group>
                                <Form.Label>{item.title}</Form.Label>
                                <div className="input-wrapper">
                                    <Form.Control value={getLinkValue(item.key)} onChange={event => updateLinkLocal(item.key, event.target.value)}
                                        className={!getIsInvalidSocialUrl(item.key as LinkTypeEnum) ? "" : "invalid-url"} type="search"
                                        onBlur={(event: React.FocusEvent) => validateLinkUrlAndSave((event.target as HTMLInputElement).value, item.key as LinkTypeEnum)} 
                                    />
                                    <div onClick={() => removeLink(item.key as LinkTypeEnum)} className="gds-trash">
                                        <BsFillTrashFill className="trash-icon" size={20} title="Delete" />
                                    </div>
                                </div>
                                <div className="position-div" hidden={!getIsInvalidSocialUrl(item.key as LinkTypeEnum)}>
                                    <BsFillExclamationCircleFill className="red-icon" />  <span className="red-text-small">Please enter a valid URL</span>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                )
            }
            </MultiSelectRepeater>
        </div>
    )
}