import React, { useState } from 'react';
import {InputGroup, CloseButton, Container, Modal, Button, Form, Row, Col } from 'react-bootstrap';
import './embed.virtualtours.modal.scss';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';
import { EntityTypeEnum, MediaTypeEnum, IMediaIEmbed, IMediaIEmbedApiPayload } from 'components/profile/types';
import { uploadEmbedVideos } from 'components/profile/mediaitem/mediaitem.slice';

interface IProps {
    showModal?: boolean;   
    callBack: (outcome: string) => void; 
    closeCallback: (outcome: boolean) => void;
}

export const EmbedVirtualToursModal = ({showModal = false, callBack, closeCallback }: IProps) => {
    const { id } = useSelector((state: RootState) => state.profileSlice.profile);
    const { mediaItems } = useSelector((state: RootState) => state.mediaItemSlice);
    const [ confirmed, setConfirmed ] = useState(false);
    const [ displayClearButton, setDisplayClearButton ] = useState(false);
    const [ displayTitleClearButton, setDisplayTitleClearButton ] = useState(false);   
    const [ embedVideoUrl, setEmbedVideoUrl ] = useState<string>('');
    const [ virtualTourTitle, setVirtualTourTitle ] = useState<string>('');
    const [ invalidUrl, setInvalidUrl ] = useState(false);
    const [ multipleEmbedUploads, setMultipleEmbedUploads ] = useState(false);
    const [ displayUploadUrlInfo, setDisplayUploadUrlInfo ] = useState('');
    const [ messageStyle, setMessageStyle ] = useState<string>('added-url');    
    const dispatch = useDispatch(); 

    const acceptButtonClick = () => {
        checkUrlValidation(false, true);       
    }

    const closeButtonClick = () => {
        setConfirmed(false);
        resetUrlInput();
        resetTitleInput();
        closeCallback(false);
        setMessageStyle('added-url');
        setDisplayUploadUrlInfo('');
        setMultipleEmbedUploads(false);
    }

    const onTitleInputChange = (title: string) => {     
      setVirtualTourTitle(title);
      setDisplayTitleClearButton(true);
       
    }

    const resetTitleInput = () => {        
        setVirtualTourTitle("");
        setDisplayTitleClearButton(false);   
    }

    const onInputChange = (url: string) => { 
        setMessageStyle('added-url')
        setDisplayClearButton(true);
        setEmbedVideoUrl(url)

        if(url === "" ) {
            setInvalidUrl(false);
            setEmbedVideoUrl("");
            setDisplayClearButton(false);
        }        
    }

    const confirmationEvent = (value : boolean) => {       
            setConfirmed(value);
            if(value) {            
              checkUrlValidation(value, false);
        }           
    }

    const checkUrlValidation = (value : any, submit: boolean) => {
        if (value || confirmed) {
            validate(embedVideoUrl, submit)
        }
    }

    const resetUrlInput = () => {
        setInvalidUrl(false)
        setEmbedVideoUrl("");
        setDisplayClearButton(false);       
    }

    const isValidURL = (str: string) => {
        try { return Boolean(new URL(str)); }
        catch (e) { return false; }
    }        

    const validate = (originalUrlName: string, submit: boolean) => {

        const videoUrl = originalUrlName.toLowerCase();
        setDisplayClearButton(true);

        if (isValidURL(videoUrl) ) {          
                 
                if (displayUploadUrlInfo !== '') {
                    setMessageStyle('added-url-fade-in')
                }                

                setInvalidUrl(false);

                if (submit) {                      
                    const embedMediaItem : IMediaIEmbed = {
                        entityType: EntityTypeEnum.profile,   
                        label: virtualTourTitle,
                        mediaType : MediaTypeEnum.virtualTour,
                        url: originalUrlName, 
                        profileId: id,   
                        azureContainerName: "profile-wizard-images",
                        originalFileName: originalUrlName,
                        sequence: mediaItems.length,
                        hasAcceptedUsagePolicy: true
                    }
                   const apiPayload : IMediaIEmbedApiPayload = {
                       toastMessage: "Virtual tour added",
                       IMediaIEmbed: embedMediaItem,     
                       enableToast : multipleEmbedUploads ? false:  true }                       

                       dispatch(uploadEmbedVideos(apiPayload));

                    if (!multipleEmbedUploads) {
                        setConfirmed(false);
                        resetUrlInput();
                        resetTitleInput();
                        closeCallback(false);
                        closeMessage();
                    } else { 
                        setDisplayUploadUrlInfo(originalUrlName) 
                        resetUrlInput();
                        resetTitleInput();          
                        setConfirmed(false);             
                    } 

                }

        } else {
            setInvalidUrl(true);
        }

        if(originalUrlName === "") {
            setInvalidUrl(false);
            setDisplayClearButton(false);
        }
    } 

    const anotherEmbedUploadConfirmation = (value : boolean) => {         
        setMultipleEmbedUploads(value);
    }

    const closeMessage = () => {
        setDisplayUploadUrlInfo('')
        setMessageStyle('added-url');        
    }

    return (
        <Modal show={showModal} onHide={() => closeButtonClick()}>
             <Container fluid className="embed-virtual-media-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Embed virtual tour</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {displayUploadUrlInfo !== '' &&
                    <div className='display-message-box' >
                        <div  className={messageStyle} >
                            <Row>
                                <Col xs="1"> <BsFillExclamationCircleFill className="grey-icon"/>  </Col>
                                <Col xs="10"> <span className="wrap-message">Virtual tour '{displayUploadUrlInfo}' added</span> </Col>
                                <Col xs="1"> <CloseButton id="closeMessage" className="close-message-button" onClick={ () => closeMessage() } /> </Col>
                            </Row>
                        </div>
                    </div>
                    }
                    <p>Paste the link of your standalone HTML viewer (such as Google Street View or an HTML5 panorama) into the field below.</p>
                    
                    <Form.Group className="padding-top-10">
                    <Form.Label>Title</Form.Label><span className={(virtualTourTitle === "") ? "red-text" : ""}>*</span>
                        <InputGroup className="mb-3">
                            <Form.Control name="virtual-tour-title"  value={virtualTourTitle} onChange={ (event) => onTitleInputChange(event.target.value)} />
                            
                            <CloseButton className="close-button" onClick={ () => resetTitleInput() }  hidden={!displayTitleClearButton} />
                        </InputGroup>

                        <Form.Label>Embed link</Form.Label><span className={(embedVideoUrl === "") ? "red-text" : ""}>*</span>
                        <InputGroup className="mb-3">
                            <Form.Control name="videoUrl" className={!invalidUrl ? "video-url" : "video-invalid-url"}  value={embedVideoUrl} onChange={ (event) => onInputChange(event.target.value)} />
                            <CloseButton className="close-button" onClick={ () => resetUrlInput() }  hidden={!displayClearButton} />
                        </InputGroup>
                            
                            <Form.Text  hidden={invalidUrl}>Example https://goo.gl/maps/tour123</Form.Text>
                            <div className="position-div" hidden={!invalidUrl}>
                            <BsFillExclamationCircleFill className="red-icon" />  <span className="red-text">Link not valid. Please try again. Example https://goo.gl/maps/tour123</span>
                            </div>
                    </Form.Group>
                        <div className="flex-display-confirmation">
                            <Form.Check id="confirmation" checked={confirmed}  onChange={(e: any) => confirmationEvent(e.target.checked)} label="I confirm we have permission to use this virtual tour " /><span className="red-text">*</span>
                        </div>
                
                    <p></p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-display-confirmation">
                        <Form.Check id="embedConfirmation" checked={multipleEmbedUploads} label="Embed another" onChange={(e: any)  => anotherEmbedUploadConfirmation(e.target.checked)} />
                    </div>
                    <Button onClick={() => closeButtonClick()} variant="secondary">Cancel</Button>
                    <Button disabled={!confirmed || embedVideoUrl === "" || (virtualTourTitle === "") } onClick={() => acceptButtonClick()} variant="primary">Embed virtual tour</Button>
                </Modal.Footer>
            </Container>
        </Modal>
    )
}