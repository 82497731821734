import React, { useCallback, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router';
import { Wizard } from 'components/wizard/wizard';
import WizardStep1Page from './features/wizard.step-1/wizard.step-1';
import WizardStep2Page from './features/wizard.step-2/wizard.step-2';
import WizardStep3Page from './features/wizard.step-3/wizard.step-3';
import WizardStep4Page from './features/wizard.step-4/wizard.step-4';
import WizardStep5Page from './features/wizard.step-5/wizard.step-5';
import WizardStep6Page from './features/wizard.step-6/wizard.step-6';
import WizardStep7Page from './features/wizard.step-7/wizard.step-7';
import WizardStep8Page from './features/wizard.step-8/wizard.step-8';
import WizardStep9Page from './features/wizard.step-9/wizard.step-9';
import WizardStep10Page from './features/wizard.step-10/wizard.step-10';
import WizardStep11Page from './features/wizard.step-11/wizard.step-11';
import WizardStep12Page from './features/wizard.step-12/wizard.step-12';
import WizardStep13Page from './features/wizard.step-13/wizard.step-13';
import { Step } from 'components/wizard/types';

interface ParamType {
    id: string;
}

export const ProfileWizard = () => {
  const { id } = useParams<ParamType>();
  const [step, setStep] = useState<Step>({id: 0, completed: false, displayFooterNavigation: true, skip: false});

  const callBack = useCallback((step: Step) => {
    setStep(step);
  }, []);
  
  return (
    <Wizard navigateTo="/profiles/create/" startingStep={Number(id)} step={step}>
      <WizardStep1Page callBack={callBack} />
      <WizardStep2Page callBack={callBack} />
      <WizardStep3Page callBack={callBack} />
      <WizardStep4Page callBack={callBack} />
      <WizardStep5Page callBack={callBack} />
      <WizardStep6Page callBack={callBack} />
      <WizardStep7Page callBack={callBack} />
      <WizardStep8Page callBack={callBack} />
      <WizardStep9Page callBack={callBack} />
      <WizardStep10Page callBack={callBack}/>
      <WizardStep11Page callBack={callBack}/>
      <WizardStep12Page />
      <WizardStep13Page callBack={callBack}/>
    </Wizard>
  );
}