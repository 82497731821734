import './App.scss';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { HomePage } from 'pages/home/home.page';
import { Page3 } from 'pages/page-3/page-3.page';
import { Page4 } from 'pages/page-4/page-4.page';
import { Page5 } from 'pages/page5/page-5.page';
import { Header }  from 'components/header/header';
import { ProfileWizard } from 'pages/profile-wizard/profile.wizard.page';
import { Profiles } from 'pages/profiles/profiles.page';
import { Toaster } from 'components/toast/toast';
import { ProfileFormContainer } from 'pages/profile-form/profile.form.container';

export const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
    <Router>
        <Header />
        <Toaster />
        <Switch>
          <Route path="/" exact><HomePage /></Route>
          <Route path="/home" exact><HomePage /></Route>
          <Route path="/profiles" exact> <Profiles /> </Route>
          <Route path="/profiles/create/:id"><ProfileWizard /></Route>
          <Route path="/profiles/update/:id/:pageId"><ProfileFormContainer /></Route>
          <Route path="/page3" exact> <Page3 /> </Route>
          <Route path="/page4" exact> <Page4 /> </Route>
          <Route path="/page5" exact> <Page5 /> </Route>
        </Switch>
    </Router>
    </DndProvider>
  );
}