import React, { useState, useCallback, useEffect } from 'react';
import './sleeping.arrangements.page.scss';
import { DragSortable } from 'components/drag.sortable/drag.sortable';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { RootState } from 'app/root-reducer';
import { WizardRoomCard } from 'components/wizard.room.card/wizard.room.card';
import { IProfileItem, IProfileItemDeletePayload, ProfileItemTypeEnum, ProfileItemUnitOfAreaEnum } from 'components/profile/profileitem/types';
import { MdLens } from 'react-icons/md';
import { getProfileItemsById, UpdateProfileItemMediaItems, UpdateProfileItems, deleteProfileItem, getProfileItemCount } 
  from 'components/profile/profileitem/profileitem.slice';
import { SleepingArrangementsOverlay } from './features/sleeping.arrangements.overlay/sleeping.arrangements.overlay';
import _ from 'lodash';
import { EntityTypeEnum, ITranslationItem } from 'components/profile/types';
import landingImage from '../../../profiles/features/profile-empty/images/landingImage.png';
import { UpdateSectionCompleteWithValue, updateProfileFromStore } from 'components/profile/profile.slice';
import { ProfileFormPageEnum } from 'pages/profile-form/profile.form.types';

interface IProps {
  isCompleted: boolean;
}

export const SleepingArrangementsPage = ({isCompleted}: IProps) => {
  const { profileItems, loading } = useSelector((state: RootState) => state.profileItemSlice);
  const { id } = useSelector((state: RootState) => state.profileSlice.profile);
  const [ showProfileItemModal, setShowProfileItemModal ] = useState<boolean>(false);

  const getDefaultProfileItem = (type: ProfileItemTypeEnum): IProfileItem => {
    return {
      id: "", 
      features: [], 
      name: "", 
      configuration: "", 
      description: { text: "", profileId: id } as ITranslationItem, 
      entityType: EntityTypeEnum.profileItem, 
      isChanged: false, 
      media: [], 
      profileId: id, 
      sequence: 0, 
      type: type,
      count: 0,
      size: 0,
      units: ProfileItemUnitOfAreaEnum.SquareMeter
    };
  }
  const [ selectedProfileItem, setSelectedProfileItem ] = useState<IProfileItem>(getDefaultProfileItem(ProfileItemTypeEnum.Room));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getProfileItemsById(id));
    }
  }, [dispatch, id]);

  const addProfileItemClick = (type: ProfileItemTypeEnum) => {
    setSelectedProfileItem(getDefaultProfileItem(type));
    setShowProfileItemModal(true);
  }
  
  const deleteCallback = (profileItem: IProfileItem) => {
    const payload: IProfileItemDeletePayload = {
      media: profileItem.media,
      id: profileItem.id,
      profileId: id,
      translationItemId: profileItem.description.id,
      type: profileItem.type
    }
    
    //References example at: https://github.com/reduxjs/redux-thunk
    dispatch(deleteProfileItem(payload)).then(() => {
      dispatch(getProfileItemCount()).then(
        (payloadAction) => {
          const isProfileItems = (payloadAction.payload as number) > 0;
          if (!isProfileItems) {
            Promise.all([
              dispatch(UpdateSectionCompleteWithValue({ pageId: ProfileFormPageEnum.sleepingArrangements, value: false })),
              dispatch(updateProfileFromStore())
            ])
          }
        }
      )
    })
  }

  const editCallBack = (profileItem: IProfileItem) => {
    setSelectedProfileItem(profileItem);
    setShowProfileItemModal(true);
  }

  const cardDropCallback = useCallback((newSequences: number[], accommType: string) => {
    let typeItems = _.cloneDeep(profileItems.filter(x => x.type === accommType).sort((a, b) => (a.sequence > b.sequence ? 1 : -1)));
    newSequences.map((item, i) => {
      typeItems[item].isChanged = typeItems[item].sequence !== i ? true : false;
      typeItems[item].sequence = i;
      return item;
    })
    let changedItems = typeItems.filter(x => x.isChanged);
    dispatch(UpdateProfileItems(changedItems));
  }, [dispatch, profileItems])

  const profileItemOverlayCallback = (showModal: boolean, didSave: boolean, profileItem: IProfileItem) => {
    setShowProfileItemModal(showModal);
    dispatch(UpdateProfileItemMediaItems([]))
    if (didSave) {
      setSelectedProfileItem(profileItem);
    } else {
      setSelectedProfileItem(getDefaultProfileItem(ProfileItemTypeEnum.Room));
    }
  }

  const addNewItemCallback = (type: ProfileItemTypeEnum) => {
    setSelectedProfileItem(getDefaultProfileItem(type));
    setShowProfileItemModal(true);
    dispatch(UpdateProfileItemMediaItems([]));
  }

  const renderSection = (accommType: string, index: number) => {
    const items: IProfileItem[] = profileItems.filter(x => x.type === accommType).sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
    if (items && items.length && items.length > 0) {
      return (
        <Col xs="12" className="sa-section" key={`col${index}`}>
          <div className="header-row-2-col">
            <Card.Title>{accommType}s</Card.Title>
            <Button onClick={() => addProfileItemClick(items[0].type)} variant={"link"} className="wizard-link">+ Add another</Button>
          </div>
          <DragSortable key={`ds-${index}`} groupId={accommType} cardDropCallback={cardDropCallback}>
            {items.map((item, i) => 
                <WizardRoomCard deleteCallBack={deleteCallback} editCallBack={editCallBack} room={item} index={i} key={`${index}-${i}`}/>
            )}
          </DragSortable>
        </Col>
      )
    }
    return null;
  }

  return (
    <React.Fragment>
      {
        loading && <Container className="loading-container"><Spinner className="spinner" animation="border" variant="primary" /></Container>
      }
      {
        !loading && (profileItems && profileItems.length > 0) &&
        <Container fluid className="sleeping-arrangements">
          <Row className="intro-section">
            <Col xs="12">
              <Card.Title className="card-title-section">
                <MdLens className={`section-dot ${isCompleted ? "complete-dot" : "incomplete-dot" }`} />
                Sleeping Arrangements
                <Button onClick={() => addProfileItemClick(ProfileItemTypeEnum.Room)} variant="outline-secondary">Add sleeping arrangement</Button>
              </Card.Title>
              <Form.Text className="text-label">Add your various sleeping arrangements, like rooms and units, with enticing descriptions and images. These are required if you want to add rates, even if you only have one sleeping arrangement.</Form.Text>
            </Col>
          </Row>
          <Row className="sa-section-container">
            { Object.values(ProfileItemTypeEnum).map((accommType, index) => (
                renderSection(accommType, index)
            ))}
          </Row>
      </Container>
      }
      {
        !loading && profileItems?.length === 0 && 
        <Card border="dark" className="sleeping-arrangements-empty">
          <Card.Body>
          <img src={landingImage} alt="" />
          <Card.Title>
          Sleeping arrangements
          </Card.Title>
          <Card.Text>
          Your sleeping arrangements are major selling points for your property and are shown in all Wetu products and outputs.
          </Card.Text>
          <Button onClick={() => addProfileItemClick(ProfileItemTypeEnum.Room)} variant="primary">Add sleeping arrangement</Button>
          </Card.Body>
        </Card>
      }
      <SleepingArrangementsOverlay showModal={showProfileItemModal} callBack={profileItemOverlayCallback} item={selectedProfileItem} addNewItemCallback={addNewItemCallback}/>
   </React.Fragment>    
  )
}