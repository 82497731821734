export const GetDate = (dateString: string): string => {

    const currentDate: Date = new Date(new Date().toDateString());
    const date: Date = new Date(new Date(dateString).toDateString()); 
    const days = (currentDate.getTime() - date.getTime())/ (1000 * 60 * 60 * 24);
    const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let message = date.getDate() + " " + months[date.getMonth()] + " " +  date.getFullYear();
    
    if(days === 0) {
        message = "< 1 day ago"
    } else if(days === 1) {
        message = " 1 day ago"
    } else if( days < 8) {
        message  = days + " days ago";
    }
    return message;
}