import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container, Row, Col, InputGroup, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-popper/dist/index.umd';
import 'bootstrap/dist/js/bootstrap.bundle';
import {ITime} from '../../components/profile/types';
import './time.picker.scss';

interface IProps {
    label?: string;
    callback: (time: ITime) => void;
    time: ITime;
}

export const TimePicker: FunctionComponent<IProps> = ({callback, time = { hours: -1, minutes: -1 } }: IProps) => {
  const [checkInTime, setCheckInTime] = useState<string>('Select time');  
  useEffect(() => {
    const getTimeString = (time: ITime) => {
      return padNumber(time.hours) + ':' + padNumber(time.minutes);
    }
    if (time.hours >= 0) {
        setCheckInTime(getTimeString(time));
    }

  }, [setCheckInTime, time]);

  const padNumber = (num: number) => {
      let numStr = num.toString();
      if (numStr.length < 2) {
        numStr = '0' + numStr;
      }
      return numStr;
  }

  const handleTime = (h: number, m: number) => {
    setCheckInTime(padNumber(h) + ':' + padNumber(m));
    let time = {} as ITime;
    time.hours = h;
    time.minutes = m;
    callback(time);
  }

  const createSelectOptions = () => {
    let items = [];
    let id = 1;
    for (let h = 0; h <= 23; h++) {
        for (let m = 0; m <= 30; m += 30) {
         items.push(<Dropdown.Item key={"container-" + id.toString()} href="#" onClick={() => handleTime(h, m)}>{padNumber(h)} : {padNumber(m)}</Dropdown.Item>);
         id++;
        }
    }
    return items;

  }
  return (
    <Container fluid>
      <Row>
        <Col>
        <InputGroup className="time-picker-dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            {checkInTime}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                  createSelectOptions()
              }
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
        </Col>
      </Row>
    </Container>
  );
}
