import React, { useEffect } from 'react'
import './wizard.step-13.scss';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { WizardCallback } from 'components/wizard/types';
import { ResetProfile } from "components/profile/profile.slice";
import {useDispatch } from 'react-redux';
import { ResetProfileItem } from 'components/profile/profileitem/profileitem.slice';

const WizardStep13Page = ({callBack}: WizardCallback) =>  {
  const history = useHistory();  
  const firstProfile = false; //later database hookup from profiles.slice(not build yet), check an array if array more then one we assign false to this 
  const dispatch = useDispatch();

  useEffect(() => {
      callBack({id: 13, completed: true, displayFooterNavigation: false, skip: false, progressBarEnabled: false});
  }, [callBack] );


  const createNewProfile = () => {
    dispatch(ResetProfile());
    dispatch(ResetProfileItem());
    history.push('/profiles/create/1')
  }

  const myProfiles = () => {
    history.push('/profiles/')
  }

  const getMessageBody = (firstProfile: boolean): string => {
    if(firstProfile)
    {
      return "Congratulations! You published your first profile.";

    }else
    {
      return "Success! Your profile was published.";   
    }  
  }

    return (
        <Container fluid>          
          <Row className="margin-top-20">
            <Col xs="10">
              <Card className="card-page13-container" border="dark">
                <Card.Body >
                
                <div> 
                  <div className="placeholder"></div>                 
                    <Card.Title className="padding-bottom-20">{getMessageBody(firstProfile)}</Card.Title>
                    <Card.Subtitle className="padding-bottom-20">What would you like to do next?</Card.Subtitle>                  
                  </div>
                    <div>
                        <div>
                            <div className="padding-top-20">
                                <span><Button variant="primary" onClick={() => createNewProfile()}>Create another profile</Button></span>
                                <span><Button variant={'link'}  onClick={() => myProfiles()} >Go to My profiles</Button></span> 
                            </div>
                        </div>
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )   
}  


export default WizardStep13Page