import axios from 'axios';
import { IProfileItemDeletePayload, IProfileItem } from 'components/profile/profileitem/types';
import { getApiBaseUrl } from '../app/config';

const baseProfileUrl = getApiBaseUrl() + 'profileitem/';

export async function updateProfileItemsDb_service(payload: IProfileItem[]) { 
  return axios.post<IProfileItem[]>(baseProfileUrl + "updateProfileItems", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  }).catch(function (error) {
    if (error.response) {
      console.error('updateProfileItemsDb_service error.response.data: ', error.response.data);
      console.error('updateProfileItemsDb_service error.response.status: ', error.response.status);
    }
  });
}

export async function getProfileItems(id: string) { 
  return axios.get<IProfileItem[]>(baseProfileUrl + `GetProfileItems?profileId=${id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  }).catch(function (error) {
    if (error.response) {
      console.error('getProfileItems error.response.data: ', error.response.data);
      console.error('getProfileItems error.response.status: ', error.response.status);
    }
  });
};

export async function deleteProfileItemDb_service(payload: IProfileItemDeletePayload) {  
  return axios.post<IProfileItem>(baseProfileUrl + "DeleteProfileItem", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  });
}

export async function createProfileItemDb_service(payload: IProfileItem) { 
  return axios.post<IProfileItem>(baseProfileUrl + "Create", payload, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      }
  })
}



