import React from 'react';
import { Button } from 'react-bootstrap';
import './to.do.button.scss';
import { BsDash } from "react-icons/bs";

interface IToDoButtonProps {
    status: string;
}

export const ToDoButton = ({status}:IToDoButtonProps) => {
    let buttonLabel: string = ""
    let noBorder: boolean = true

    if(status === "Disabled"){
        buttonLabel = "Relist"
        noBorder = false;
    }

    if(status === "InProgress"){
        buttonLabel = "Finish"
        noBorder = false;
    }
    
    return (
        <div className="menu-button-container" >
            <Button className={noBorder ? "no-border" : "to-do-button"} variant="outline-dark">{buttonLabel}
            { (noBorder) &&
                <BsDash/>
            }
           </Button>
        </div>     
    )
}