import { maximumImages, minimumImages } from 'app/config';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import DropZone from 'components/dropzone/dropzone';
import { IndeterminateCheckbox } from 'components/indeterminate.checkbox/indeterminate.checkbox';
import { IndeterminateCheckboxEnum } from 'components/indeterminate.checkbox/types';
import { UpdateMedia_Reducer } from 'components/profile/mediaitem/mediaitem.slice';
import { IMediaItem , MediaTypeEnum} from 'components/profile/types';
import _ from 'lodash';
import { ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { BsFillExclamationCircleFill, BsX } from 'react-icons/bs';
import { MdLens } from 'react-icons/md';
import "./profile.images.card.scss";
import { useAppDispatch } from 'app/store';

interface IProps {
    profileName?: string;
    title: string;
    subtitle: string;
    isProfileForm?: boolean;
    availableLanguages: string[];
    isEnhanced: boolean;
    isImagesEditable: boolean;
    isCompleted: boolean;
    mediaPayload: IMediaItem[];
    saveCallback?: () => void;
    deleteCallback?: (selectedMedia: IMediaItem[], mediaType: MediaTypeEnum) => void;
    uploadCallback?: (mediaItems: IMediaItem[], uploadedMedia: IMediaItem[], mediaType: MediaTypeEnum) => void;
    mediaCount: number;
}

export const ProfileImagesCard = ({profileName, title, subtitle, isProfileForm = false, availableLanguages, isEnhanced, 
    isImagesEditable, isCompleted, mediaPayload, mediaCount, saveCallback, deleteCallback, uploadCallback}: IProps) => {
    const [ uploadedImages, setUploadedImages ] = useState<IMediaItem[]>([]);
    const [ selectedImages, setSelectedImages ] = useState<IMediaItem[]>([]);
    const [ invalidFiles, setInvalidFiles ] = useState<string[]>([]);
    const [ displayInvalidFiles, setDisplayInvalidFiles ] = useState<boolean>(false); 
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const dispatch = useAppDispatch(); 
    const [ maxImages ] = useState<number>(isEnhanced ? maximumImages : minimumImages); 

    //When MediaItems are loaded from the DB, update our local copy
    useEffect(() => {
        setUploadedImages(mediaPayload);
    }, [mediaPayload]);

    const uploadImageCallBackLocal = useCallback((mediaItems: IMediaItem[], invalidFiles: string[]) => {
        if (uploadCallback) {
            uploadCallback(mediaItems, uploadedImages, MediaTypeEnum.image);
        }
    }, [uploadedImages, uploadCallback]);

    const invalidFilesCallback = useCallback((invalidFilesArray: string[]) => {
        setInvalidFiles(invalidFilesArray);
        if(invalidFilesArray.length > 0) {
            setDisplayInvalidFiles(true);
        }
    }, [])

    const selectCallback = useCallback((mediaItems: IMediaItem[]) => {
        const selectedMediaItems = mediaItems.filter(x => x.isChecked === true);
        setSelectedImages(selectedMediaItems);
    }, [])
    
    const deleteSelectedImages = () => {
        setShowModal(true);
    }

    const deleteImagesCallBack = useCallback((deleteImages: boolean) => {
        setShowModal(false);
        if (deleteImages) {
            setSelectedImages([]);
            if (deleteCallback) {
                deleteCallback(selectedImages, MediaTypeEnum.image);
            }
        }
    }, [selectedImages, deleteCallback])

    const setAllImagesState = useCallback((checked: boolean) => {
        const images: IMediaItem[] = _.cloneDeep(uploadedImages);
        images.forEach(image => {
            image.isChecked = checked;
        });
        setUploadedImages(images);
    }, [uploadedImages]);

    const checkboxCallback = useCallback((value: number) => {
        switch(value) {
            case 0: {
                setAllImagesState(false);
                break;
            }
            case 1: {
                break;
            }
            case 2: {
                setAllImagesState(true);
                break;
            }
        }
    }, [setAllImagesState]);

    const clearInvalidFiles = () => {
        setInvalidFiles([]);
    }

    const imageDropCallback = (newMediaItems: IMediaItem[]) => {
        dispatch(UpdateMedia_Reducer({mediaItems: newMediaItems, mediaType: MediaTypeEnum.image}));
    }

    const saveCallbackLocal = () => {
        if (saveCallback) {
            saveCallback();
        }
    }

    return (
        <Card id={ProfileFormPageSectionEnum.images} border="dark" className="media-card">
            <Card.Body>
                <Card.Title  className="card-title-section">
                    {
                        isProfileForm && <MdLens className={`section-dot ${isCompleted ? "complete-dot" : "incomplete-dot-media" }`} />
                    }
                    {title}
                </Card.Title>
                <Card.Subtitle>
                    {subtitle}
                </Card.Subtitle>
                {
                    (invalidFiles.length > 0 && displayInvalidFiles) &&
                    <Card border="dark margin-top-10">
                        <Card.Body>
                        <Card.Title className="invalid-file">
                            <div><BsFillExclamationCircleFill /> &nbsp; These images couldn't be added</div>
                            <div>
                                <Button variant="clear" onClick={() => clearInvalidFiles()}><BsX /></Button>
                                </div>
                        </Card.Title>
                        <ul>
                            {invalidFiles.map((item, index) => <li key={index}>{item}</li>)}
                        </ul> 
                        </Card.Body>
                    </Card>
                }
                {
                    (uploadedImages && selectedImages.length === 0) &&
                    <Form.Text> Images {uploadedImages.length} / {maxImages} - You can add up to {maxImages} photos.</Form.Text>
                }
                {
                    selectedImages.length > 0 &&
                    <div className="images-selected">
                        <Form.Label className="display-flex"><IndeterminateCheckbox 
                            value={selectedImages.length === uploadedImages.length ? IndeterminateCheckboxEnum.checked : IndeterminateCheckboxEnum.indeterminate} 
                            callBack={checkboxCallback}></IndeterminateCheckbox> {selectedImages.length} {selectedImages.length === 1 ? "image" : "images"} selected</Form.Label>
                        <Button onClick={() => deleteSelectedImages()} variant="link">delete images</Button>
                        <AcceptDeclineModal showModal={showModal} callBack={deleteImagesCallBack} title={`Delete ${selectedImages.length} of ${uploadedImages.length} images`}
                            body={"This can't be undone."} declineButtonText={selectedImages.length > 1 ? 'Keep images' : 'Keep image'} 
                            acceptButtonText={selectedImages.length > 1 ? 'Delete images' : 'Delete image'} />
                    </div>
                }
                <DropZone itemDropCallback={imageDropCallback} addButtonText={"Add images"} acceptedFileTypes={['image/jpeg', 'image/jpg', 'image/png']} 
                    dropMediaItems={uploadedImages} invalids={invalidFiles} uploadCallBack={uploadImageCallBackLocal} isEnhanced={isEnhanced}
                     isItemsEditable={isImagesEditable} selectCallback={selectCallback} invalidFilesCallback={invalidFilesCallback}
                    languageCodes={availableLanguages} profileName={profileName} saveCallback={saveCallbackLocal} 
                    mediaType={MediaTypeEnum.image} mediaCount={mediaCount} />
            </Card.Body>
        </Card>
    )
}