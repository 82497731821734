import { ProfileSummaryDropdownMenuItems } from 'app/config';
import { IMenuItem } from 'components/profile/types';
import React, { useState } from 'react';
import { Dropdown, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MenuItems } from './features/menu.items/menu.items';
import { MenuToggle } from './features/menu.toggle/menu.toggle';
import './menu.button.scss';

interface IProps {
    url: string;
}

export const MenuButton = ({url}: IProps) => {
    const history = useHistory();
    const [menuItems] = useState<IMenuItem[]>(ProfileSummaryDropdownMenuItems);

    const itemOnClick = (key: string) => {
        switch(key) {
            case "edit": {
                history.push(url);
                break;
            }
            default: {
                break;
            }
        }
    }

  return(
    <Container className="menu-button">
        <Row>
            <Col className="dropdown-col">
            <Dropdown>
            <Dropdown.Toggle className="menu-dropdown" as={MenuToggle} id="dropdown-custom-components">
            </Dropdown.Toggle>
        
            <Dropdown.Menu as={MenuItems}>
                {
                    menuItems.map((item, index) => {
                        if(item.key === "delete" || item.key === "disable") {
                            return <React.Fragment key={index}><Dropdown.Divider /> <Dropdown.Item onClick={() => itemOnClick(item.key)} eventKey={item.key}>{item.label}</Dropdown.Item></React.Fragment>
                        }

                        return <Dropdown.Item key={index} onClick={() => itemOnClick(item.key)} eventKey={item.key}>{item.label}</Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
            </Dropdown>
            </Col>
        </Row>
    </Container>
    
  );
}