import { DocumentVisibilityEnum, IMediaItem, ITranslatableText } from 'components/profile/types';
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Dropdown, Button } from 'react-bootstrap';
import { BsFileEarmarkText, BsTrash, BsFillExclamationCircleFill } from 'react-icons/bs';
import _ from 'lodash';
import './document.item.feature.scss';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import axios, { AxiosRequestConfig } from 'axios';

interface IProps {
    document: IMediaItem;
    languageCode: string;
    documentsCount: number;
    updateCallback: (newDocument: IMediaItem) => void;
    deleteCallback: (newDocument: IMediaItem) => void;
    loadedCallback: () => void;     //To set isLoaded flag for Spinner.
}

export const DocumentItem = ({document, languageCode, documentsCount, updateCallback, deleteCallback, loadedCallback}: IProps) => {
    const [ documentLocal, setDocumentLocal ] = useState<IMediaItem>(_.cloneDeep(document));
    const [ showConfirmationModal, setShowConfirmationModal ] = useState<boolean>(false);
    const [ hasEditedName, setHasEditName ] = useState<boolean>(false);
    const [ currentLanguageCode, setCurrentLanguageCode ] = useState<string>(languageCode);     //We want to force a re-render through the useEffect when they change languageCode, so create a local copy here.

    const getDocumentLabel = useCallback((): string => {
        const translationText = documentLocal.label?.translations[currentLanguageCode];
        return currentLanguageCode === 'en' && documentLocal.label.enText ? documentLocal.label.enText : translationText ? translationText : '';
    }, [currentLanguageCode, documentLocal.label]);
    
    useEffect(() => {
        setDocumentLocal(_.cloneDeep(document));
    }, [document])

    useEffect(() => {
        setCurrentLanguageCode(languageCode);
    }, [languageCode, getDocumentLabel])

    useEffect(() => {
        loadedCallback();
    }, [loadedCallback])

    const updateTranslatableTextLabel = (translatableText: ITranslatableText, value: string) => {
        let copy = _.cloneDeep(translatableText);
        if (currentLanguageCode === 'en') {
            copy.enText = value;
        }
        else {
            copy.translations[currentLanguageCode] = value;
        }
        return copy;
    }

    const updateDocumentNameLocal = (userInput: string) => {
        //Update local state so user input is seen in the text box.
        const newLabel: ITranslatableText = updateTranslatableTextLabel(documentLocal.label, userInput);
        setDocumentLocal({...documentLocal, label: newLabel} );
        setHasEditName(true);
    }

    const updateDocumentNameGlobal = (userInput: string) => {
        //Update Redux (but not DB, that is updated on Save).
        const newLabel: ITranslatableText = updateTranslatableTextLabel(documentLocal.label, userInput);
        updateCallback( {...documentLocal, label: newLabel} );
    }

    const getVisibilityUiString = (visibility: string) => {
        //We are separating out the enum value from UI labels, so that future UI changes do not causes data retrieval problems.
        return visibility === DocumentVisibilityEnum.private ? 'Private'
            : visibility === DocumentVisibilityEnum.tradeOnly ? 'Trade only'
            : 'Public and trade';
    }

    const updateDocumentVisibility = (enumAsString: string) => {
        //Update local state and also global (redux).
        const newDocument: IMediaItem = {...documentLocal, visibility: enumAsString as DocumentVisibilityEnum};
        setDocumentLocal(newDocument);
        updateCallback(newDocument);
    }

    const deleteCallbackLocal = useCallback((doDelete: boolean) => {
        //Called from confirmation modal.
        setShowConfirmationModal(false);
        if (doDelete) {
            deleteCallback(documentLocal);
        }
        setShowConfirmationModal(false);
    }, [deleteCallback, documentLocal]);

    const deleteOnClick = () => {
        setShowConfirmationModal(true);
    }

    const getFileExtension = (url: string): string => {
        const fileExtension = url.split(/[#?]/)[0].split('.')?.pop()?.trim()?.toLowerCase();
        return fileExtension ? fileExtension : '';
    }

    const getViewableContentType = (fileExtensionLower: string) => {
        //Return the content type for file types that can be viewed in a new browser tab.
        return fileExtensionLower === 'pdf' ? 'application/pdf'
        : fileExtensionLower === 'jpg' ? 'image/jpeg'
        : fileExtensionLower === 'jpeg' ? 'image/jpeg'
        : fileExtensionLower === 'png' ? 'image/png'
        : fileExtensionLower === 'gif' ? 'image/gif'
        : fileExtensionLower === 'svg' ? 'image/svg+xml'
        : '';
    }

    async function openLink(url: string) {
        //PDFs and images should be opened in new browser tab by getting data in bytes and setting content type: //https://stackoverflow.com/questions/61135269/displaying-pdf-file-from-azure-blob-storage-container-in-new-tab
        //By default window.open(url, "_blank") downloads the file.
        const config: AxiosRequestConfig = {
            responseType: "blob"
        };
        try {
            const fileExtension = getFileExtension(url);
            const contentType = getViewableContentType(fileExtension);
            if (contentType) {
                const response = await axios.get(url, config);
                const fileBytes = new Blob([response.data], { type: contentType });
                url = URL.createObjectURL(fileBytes);
            }
            window.open(url, "_blank");
        }
        catch (e) {
            console.error('Error opening file', e);
            window.open(url, "_blank");
        }
    }
    
    const documentOpenClick = () => {
        openLink(documentLocal.url);
    }

    const getDeleteConfirmationModalTitle = () => {
        return `Delete 1 of ${documentsCount} documents?`;
    }

    const showInvalidNameWarning = (): boolean => {
        return currentLanguageCode === 'en' && hasEditedName && !getDocumentLabel();
    }

    const getPlaceholderText = (): string => {
        return documentLocal.label?.enText;
    }

    return (
        <Row className='document-item'>
            <Col xs='1'>
                <Button className='edit-button' onClick={() => documentOpenClick()}>
                    <BsFileEarmarkText />
                </Button>
            </Col>
            <Col xs='6'>
                <Form.Control value={getDocumentLabel()}
                    type='search' className={showInvalidNameWarning() ? 'invalid-text-input' : 'document-label'}
                    onChange={(event) => updateDocumentNameLocal((event.target.value))}
                    onBlur={(event: React.FocusEvent) => updateDocumentNameGlobal((event.target as HTMLInputElement).value)} 
                    placeholder={getPlaceholderText()}
                />
                 <div className='position-div' hidden={!showInvalidNameWarning() }>
                    <BsFillExclamationCircleFill className='red-icon' />  <span className='red-text-small'>Name is required</span>
                </div>
            </Col>
            <Col xs='4'>
                <Dropdown>
                    <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        {getVisibilityUiString(document.visibility)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    { Object.values(DocumentVisibilityEnum).map((item: string) => 
                        <Dropdown.Item key={item} href='#' onClick={() => updateDocumentVisibility(item)}>
                            {getVisibilityUiString(item)}
                        </Dropdown.Item>
                        )
                    }
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs='1'>
                <Button className='edit-button' onClick={() => deleteOnClick()}>
                    <BsTrash />
                </Button>
                <AcceptDeclineModal showModal={showConfirmationModal} callBack={deleteCallbackLocal} 
                    title={getDeleteConfirmationModalTitle()} body={"This can't be undone."} 
                    declineButtonText='Keep document' acceptButtonText='Delete document' 
                />
            </Col>
        </Row>
    )
}