import React, { useEffect } from 'react'
import './wizard.step-12.scss';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import landingImage from './images/landingImage.png'; 
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'components/profile/profile.slice';
import { RootState } from 'app/root-reducer';
import { useHistory } from "react-router-dom";

const WizardStep12Page = () =>  {
    const dispatch = useDispatch();
    const { profile, isValidated } = useSelector((state: RootState) => state.profileSlice);
    const { mediaItems } = useSelector((state: RootState) => state.mediaItemSlice);
    const history = useHistory();
   
    useEffect(() => {
        if(!isValidated){
            setTimeout(() => dispatch(validate({
                id: profile.id,
                media: mediaItems,
                sectionsComplete: profile.sectionsComplete
            })), 5000) 
        }
        
        if(isValidated && profile.isPublished) {
            history.push('/profiles/create/13')
        } 

        if(isValidated && !profile.isPublished ) {
            history.push('/profiles/')
        } 
    }, [isValidated, profile.id, profile.isPublished, dispatch, history, mediaItems, profile.sectionsComplete]);

    return (
        <Container fluid>
            <Row className="margin-top-20">
                <Col xs="10">
                    <Card className="card-page12-container" border="dark">
                    <Card.Body >
                    <img src={landingImage} alt=""></img>
                        <Card.Title className="padding-bottom-20">Creating your profile</Card.Title>
                        <Card.Subtitle className="padding-bottom-20">You're almost there!</Card.Subtitle>
                        <Spinner className="spinner" animation="border" />
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )   
}  

export default WizardStep12Page