import React, { useEffect } from 'react'
import './wizard.step-10.scss';
import { WizardSectionCard } from 'components/wizard.section.card/wizard.section.card';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { WizardCallback } from 'components/wizard/types';
import { RootState } from 'app/root-reducer';
import { useSelector } from 'react-redux';

const WizardStep10Page  = ({callBack}: WizardCallback) => {
  const { profileItems } = useSelector((state: RootState) => state.profileItemSlice);

  useEffect(() => {
      callBack({id: 10, completed: true, displayFooterNavigation: true, displayPreview: true, displayFinalize: true});
  }, [callBack] );

  return (
    <Container fluid>
      <Row className="margin-top-20">
        <Col xs="10">
          <Card className="section-card-outer" border="dark">
            <Card.Body className="wizard-section-card-body wide-container">
              <Card.Title></Card.Title>
              <Card.Subtitle></Card.Subtitle>
              <div className="section-card-container">
                <WizardSectionCard title="Step 1" checked={true} name="Basic information" description="Add your profile name, description and contact details." navLink="/profiles/create/3" navText="Edit" key="1"/>
                <WizardSectionCard title="Step 2" checked={true} name="Images" description="Tell a story with your images representing all aspects of your property." navLink="/profiles/create/9" navText="Edit" key="2"/>
                <WizardSectionCard title="Step 3" checked={profileItems.length > 0 ? true : false } name="Room Setup" description="Tell us about your rooms." navLink="/profiles/create/14" navText="Add rooms" key="3" rooms={profileItems}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default WizardStep10Page