import React,  {useState, useEffect} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './wizard.step-5.scss';
import { RootState } from 'app/root-reducer';
import { useSelector, useDispatch } from 'react-redux';
import { loadMapApi } from 'utils/GoogleMapsUtils';
import { Map } from 'components/map/map';
import { UpdateProfileLocation } from 'components/profile/profile.slice';
import { WizardCallback } from 'components/wizard/types';

const WizardStep5Page = ({callBack}: WizardCallback) => {
    const { pin } = useSelector((state: RootState) => state.profileSlice.profile);
    const [googleMapLoaded, setMapLoaded] = useState(false);
    const dispatch = useDispatch();
    
    const setNewCoordinates = (latLong: google.maps.LatLng) => {
      dispatch(UpdateProfileLocation( {latitude: latLong.lat(), longitude: latLong.lng()} ));
    }

    useEffect(() => {
      callBack({id: 5, completed: pin ? true : false, skip: false, displayFooterNavigation: true, displayNext: true});
    }, [callBack, pin]);

    useEffect(() => {
      const googleMapScript = loadMapApi();
        googleMapScript.addEventListener('load', function () {
            setMapLoaded(true);
        });
    }, [] );

  return (
    <Container fluid>
      <Row className="margin-top-20">
        <Col xs="3">
        </Col>
        <Col xs="6">
          <Card border="dark">
            <Card.Body>
              <Card.Title>Pin the location of your property</Card.Title>
              <Card.Subtitle>This is the location we'll show to guests on our site.  Drag the pin so that it matches the exact location of your property.</Card.Subtitle>
              {(googleMapLoaded || typeof google !== 'undefined') && (
                <Map pin={pin} setCoordinates={setNewCoordinates} />
              )}
              </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default WizardStep5Page