import React, { useEffect } from 'react'
import './profiles.page.scss';
import ProfileEmpty from './features/profile-empty/profile-empty';
import ProfileList from './features/profile-list/profile-list';
import { RootState } from 'app/root-reducer';
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
import { getSummaries } from 'components/profile/profile.slice';
import { Spinner } from 'react-bootstrap';

export const Profiles = () => {
  const { profile, profileSummaries, loading } = useSelector((state: RootState) => state.profileSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSummaries("e6e02556-8724-4592-bbeb-fd4c0c4130b1"));
  }, [dispatch] );
  
    return (
      <div>
      { loading &&
        <div className='loading-msg'>
          <Spinner className="spinner" animation="border" />
        </div>
      }
      { ( profileSummaries.length === 0 && !loading) &&
        <ProfileEmpty />
      }
      { 
      ( profileSummaries.length > 0 ) &&
        <ProfileList name={profile.name} profileSummaries={profileSummaries} />
      }
      </div>
    )
}