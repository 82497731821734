import React, { useEffect, useState } from 'react'
import './wizard.step-3.scss';
import { RootState } from 'app/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfileNameAddressLocation, UpdateProfileShortDescription } from "components/profile/profile.slice";
import { GooglePlacesSearch } from 'components/google.places.search/google.places.search';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { InsightsCard } from 'components/insights.card/insights.card';
import { IUpdateProfileNameAddressLocation } from 'components/profile/types';
import { IsStepComplete } from 'utils/Validation';
import { getAddress, getCity, getCountry, getPostalCode } from 'utils/GoogleMapsUtils';
import { WizardCallback } from 'components/wizard/types';

const WizardStep3Page = ({callBack}: WizardCallback) => {

  const getWordCount = (text: string) => {
    return text ? text.trim().split(' ').length : 0;
  }

  const { shortDescription, name } = useSelector((state: RootState) => state.profileSlice.profile);
  
  const [ profileName, setProfileName ] = useState<string>(name);

  const [ count, setCount ] = useState(getWordCount(shortDescription?.enText));
  const dispatch = useDispatch();

  useEffect(() => {
    const stepComplete = IsStepComplete([profileName, shortDescription.enText]);
    callBack({id: 3, completed: stepComplete, skip: false, displayFooterNavigation: true, displayNext: true});
  }, [callBack, profileName, shortDescription.enText]);

  const description_onChange = (value: string) => {
    setCount(getWordCount(value));
    dispatch(UpdateProfileShortDescription({ enText: value, translations: {}, machineTranslations: {}}))
  }

  const googlePlacesCallback = (googlePlaceResult: google.maps.places.PlaceResult) => {
    if (typeof googlePlaceResult !== 'undefined') {
      if (typeof googlePlaceResult.name !== 'undefined') {
        setProfileName(googlePlaceResult.name.toString());
      }

      const payload: IUpdateProfileNameAddressLocation = {
        name: typeof googlePlaceResult.name !== 'undefined' ? googlePlaceResult.name : '',
        pin: {
          latitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location ? googlePlaceResult.geometry.location.lat() : 0,
          longitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location ? googlePlaceResult.geometry.location.lng() : 0,
          drivingLatitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location ? googlePlaceResult.geometry.location.lat() : 0,
          drivingLongitude: googlePlaceResult && googlePlaceResult.geometry && googlePlaceResult?.geometry?.location ? googlePlaceResult.geometry.location.lng() : 0,
        },
          address: getAddress(googlePlaceResult),
          city: getCity(googlePlaceResult),
          country: getCountry(googlePlaceResult),
          postalCode: getPostalCode(googlePlaceResult)
      }
      dispatch(UpdateProfileNameAddressLocation(payload));
    }
  }  
  
  return (
    <Container fluid>
      <Row className="margin-top-20">
        <Col xs="2"></Col>
            <Col xs="6">
              <Card border="dark">
                <Card.Body>
                  <Card.Title>Describe your property</Card.Title>
                  <Card.Subtitle>Start by telling us the name and description of your property.</Card.Subtitle>
                  <GooglePlacesSearch label="Profile name" value={profileName} type={'name'} callBack={googlePlacesCallback} />
                  <Form.Group className="center-text">
                    <Form.Label className="float-left">Description</Form.Label>
                    <Form.Control className={count > 200 ? 'warning-border' : ''} value={shortDescription?.enText} onChange={(e) => description_onChange(e.target.value)} id="description-input" as="textarea" rows={3}/>
                    {
                      (count > 200) &&
                      <Form.Label className="font-12 float-right warning-text">{count - 200} words over limit</Form.Label>
                    }
                    {
                      (count <= 200) && 
                      <Form.Label className="font-12 float-right">Word Count: {count} of 200</Form.Label>
                    }
                  </Form.Group>
                  </Card.Body>
              </Card>
            </Col>
            <Col xs="2"></Col>
            <Col lg="2" className="insights-display d-none d-sm-block">
              <InsightsCard title={"insights"} titleIcon={"placeholder icon"} />
            </Col>
          </Row>
    </Container>
  );
}

export default WizardStep3Page
