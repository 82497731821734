import './home.page.scss'
import React from 'react';


export const HomePage = () => {
    return (
      <div className="home-page">
        <h1>Home page</h1>
      </div>
    )
}