import React from 'react';
import { Card, Col } from 'react-bootstrap';
import './wizard.card.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'app/root-reducer';

interface IProps {
    name: string;
    description: string;
    value: string;
    callBack: (value:string) => void;
}

export const WizardCard = ({name, description, value, callBack}:IProps) => {
    const { type, category } = useSelector((state: RootState) => state.profileSlice.profile);

    const card_onClick = () => {
        callBack(value);
    }

    return (
        <Col className="wizard-card-wrap" lg="3" md="4">
        <Card className={`wizard-card ${type === value || category === value?'selected':''}`} onClick={() => card_onClick()}>
            <Card.Body >
                <div className={`circle ${type === value || category === value?'selected':''}`}></div>
                <Card.Subtitle>{name}</Card.Subtitle>
                <Card.Text>
                  {description}
                </Card.Text>
              </Card.Body>
        </Card>
        </Col>
    )
}