import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, ItemTypes } from '../../dropzone/features/types';
import './drag.sortable.card.scss';
import { GrDrag } from "react-icons/gr";

interface IProps {
    id: number;
    index: number;
    groupId: string;    //This prevents problems if you have more than one DragSortable on the same page: dragging an element from one list into the other can affect the other list order.
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    children: React.ReactNode;
    cardDropCallback: () => void;
}

export const DragSortableCard = ({ id, children, index, moveCard, groupId, cardDropCallback }: IProps) => {
    //Code adapted from https://codesandbox.io/s/itdrm?file=/src/Container.jsx
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.IMAGE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            
            if (ref && ref.current) {
                const divElement = ref.current as unknown as HTMLDivElement;
                if (divElement) {
                    const type = divElement.getAttribute('data-groupid');
                    if (type !== item.groupId) {
                        return;
                    }
                }
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.IMAGE,
        item: () => {
            return { id, index, groupId };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => {
            cardDropCallback();
        }
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className="draggable-container" data-groupid={groupId}>
            <GrDrag className="drag-icon" />
			{children}
		</div>
    )
}