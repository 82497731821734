import React from 'react';
import { Card } from 'react-bootstrap';
import './insights.card.scss';
import { BiBulb } from 'react-icons/bi';

interface IProps {
    title?: string;
    titleIcon?: string
    description?: string[];
    stickyStyling?: boolean;
}

export const InsightsCard = ({titleIcon = '', description = [], title = '', stickyStyling = false}:IProps) => {
    const getIcon = () => {
        switch(titleIcon) {
            case 'BiBulb': {
                return <BiBulb />
            }
            default: {
                return null;
            }
        }
    }
    return (
        <Card border="dark" className={`insights-card ${stickyStyling ? "sticky-insights-card" : ""}`} >
            <Card.Body>
                <Card.Subtitle>{getIcon()} {title}</Card.Subtitle>
                {description.map((text, index) => <Card.Text key={index}>{text}</Card.Text>)}
            </Card.Body>
        </Card>
    )
}