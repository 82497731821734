import React from "react";
import { Button } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";

type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {};
  };

export const MenuToggle = React.forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <Button
      ref={ref}
      variant="light"
      className="dropdown-menu-button"
      onClick={e => {
        e.preventDefault();
        if (props.onClick) props.onClick(e);
      }}
    >
      <BsThreeDots />
      {props.children}
    </Button>
));