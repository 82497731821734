import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './content.rating.bar.scss';

interface IContentRatingBarProps {
    rating: number;
}

export const ContentRatingBar = ({rating}:IContentRatingBarProps) => {

    let variant: string = "success" ;

    if(rating < 80){
        variant = "warning";
    }

    if(rating < 50){
        variant = "danger";
    } 

     return (
        <div className="content-bar" >
            <ProgressBar variant={variant} now={rating} />
            {rating + "%"}
        </div>     
    )
}