import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import './wizard.step-7.scss';
import { WizardCallback } from 'components/wizard/types';
import { languages } from 'app/config';
import { CheckboxAccordion } from 'components/checkbox.accordion/checkbox.accordion';
import { IFeature } from 'components/profile/types';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfileFeatures } from 'components/profile/profile.slice';
import { RootState } from 'app/root-reducer';
import { FeatureTypeEnum } from 'components/profile/types';
import { PillTextInput } from 'components/pill.text.input/pill.text.input';

const WizardStep7Page = ({callBack}: WizardCallback) => {

  const { features } = useSelector((state: RootState) => state.profileSlice.profile);
  const dispatch = useDispatch();
  const otherLanguages = features.filter(x => x.id === 0 && x.type === FeatureTypeEnum.language);
  const items = otherLanguages.map(x => x.name);
  const [ languageExists, setLanguageExists ] = useState(false);
  const [ message, setMessage ] = useState("");
    
  useEffect(() => {
    const featuresCount = features.filter(x => x.type === FeatureTypeEnum.language).length;
    callBack({id: 7, completed: true, skip: featuresCount > 0 ? false : true, displayFooterNavigation: true, displayNext: true});
  }, [callBack, features]);
   
  const accordionCallBack = (checked: boolean, language: IFeature) => {
    let updatedFeatures = [];
    if(checked){
      updatedFeatures = [...features, language]
    } else {
      updatedFeatures = [...features];
      const index = updatedFeatures.findIndex(x => x.id === language.id);
      if (index > -1) {   
        updatedFeatures.splice(index, 1);
      }
    }
    dispatch(UpdateProfileFeatures(updatedFeatures));
  }

  const languageCallBack = (name: string) => {
      addAdditionalLanguage(name);
  }

  const addAdditionalLanguage = (name: string) => {
    const addedLanguage: IFeature = {
      id: 0,
      type: FeatureTypeEnum.language,
      name:  name,
    };
    const updatedFeatures = [...features, addedLanguage]
    dispatch(UpdateProfileFeatures(updatedFeatures));
  }

  const onRemove = (itemName: string) => {
    let updatedFeatures = [...features];
    const index = updatedFeatures.findIndex(x => x.id === 0 && x.type === FeatureTypeEnum.language && x.name === itemName);
    if (index > -1) {   
      updatedFeatures.splice(index, 1);
    }
    dispatch(UpdateProfileFeatures(updatedFeatures));
  }

  const changeCallBack = (name: string) => {
    checkLanguageExists(name);
  }
    
  const checkLanguageExists = (value: string) =>{
    let updatedFeatures = [...features];

    const index = updatedFeatures.findIndex(x => x.id === 0 && x.type === FeatureTypeEnum.language && x.name.toLowerCase() === value.toLowerCase());
    const checkBoxIndex = languages.findIndex(x => x.name.toLowerCase() === value.toLowerCase());
    
    if (index > -1 || checkBoxIndex > -1) {
      setLanguageExists(true);
      if (index > -1) {
        setMessage("'" + value + "' already added");
      } else {
        setMessage("'" + value + "' available in language list");
      }
    } else {
      setLanguageExists(false);
      setMessage(value);
    }
  }
  
  return (
    <Container fluid >
      <Row className="margin-top-20">
        <Col xs="3">
        </Col>
        <Col xs="6">
          <Card border="dark">
            <Card.Body>

              <Card.Title>What languages do you or your staff speak?</Card.Title>
              <br/>
              <Card.Subtitle>SELECT LANGUAGES</Card.Subtitle>
              <br/>
              <Row>
                {
                  <CheckboxAccordion accordionCallBack={accordionCallBack} key={1} title={""} content={languages} fixedCount={12} features={features}  />                    
                }                       
              </Row>
              <PillTextInput title="Add additional languages" message={message} unique={!languageExists} items={items} addCallBack={languageCallBack} removeCallBack={onRemove} changeCallBack={changeCallBack}></PillTextInput>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="3">
        </Col>
      </Row>
    </Container>
  )
}

export default WizardStep7Page
