import { affiliates } from 'app/config';
import { RootState } from 'app/root-reducer';
import { UpdateAffiliate, UpdateAffiliateCode, UpdateClientName } from 'components/profile/profile.slice';
import { IBookingAffiliate, BookingAffiliateEnum } from 'components/profile/types';
import { ProfileFormPageSectionEnum } from 'pages/profile-form/profile.form.types';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './integrations.scss';

export const IntegrationsCard = () => {
    const { affiliate, affiliateCode, clientName } = useSelector((state: RootState) => state.profileSlice.profile);
    const dispatch = useDispatch();

    return(
        <Card id={ProfileFormPageSectionEnum.integrations} border="dark" className="integrations-card">
            <Card.Body>
            <Card.Title>Integrations</Card.Title>
            <Card.Text>
                If you are part of a booking system, please enter your details to use their system on our site.
                Please make sure that both Affiliate and Code are selected/populated.
            </Card.Text>
            Booking affiliate
            {
                affiliates.map((bookingAffiliate: IBookingAffiliate, index: number) => <React.Fragment key={index}>
                    <Form.Check
                    label={bookingAffiliate.affiliate}
                    name="group1"
                    type={'radio'}
                    checked={bookingAffiliate.affiliate === affiliate}
                    onChange={() => dispatch(UpdateAffiliate(bookingAffiliate.affiliate))}
                    id={bookingAffiliate.affiliate}/>
                    {
                        bookingAffiliate.description &&
                        <Card.Text className="affiliate-description">{bookingAffiliate.description}</Card.Text>
                    }
                </React.Fragment>)
            }
            {
                affiliate !== BookingAffiliateEnum.none &&
                <div className="affiliate-input">
                    <div>Affiliate code <span className="red-text">*</span></div>
                    <Form.Control value={affiliateCode ? affiliateCode : ""} onChange={(event: any) => dispatch(UpdateAffiliateCode(event.target.value))} className="affiliate-control" type="search" />
                </div>
                
            }
            {
                affiliate === BookingAffiliateEnum.resRequest &&
                <div className="affiliate-input">
                    <div>Principal ID / Client name <span className="red-text">*</span></div>
                    <Form.Control value={clientName ? clientName : ""} onChange={(event: any) => dispatch(UpdateClientName(event.target.value))} className="affiliate-control" type="search" />
                </div>
            }
            </Card.Body>
        </Card>
    )
}