import { IProfileItem, IProfileItemDeletePayload, IProfileItemMediaUpdate } from "./types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import { createProfileItemDb_service, deleteProfileItemDb_service, getProfileItems, updateProfileItemsDb_service } from "api/profileitem.service";
import { showGlobalToast } from "components/toast/toast.slice";
import { uploadBlob, deleteBlob } from "api/blob.service";
import { DeleteBlob, IMediaItem } from "../types";
import store from "app/store";
import { logError } from 'utils/General';

const initialState = {
  profileItems: [] as IProfileItem[],
  mediaItems: [] as IMediaItem[],
  isValidated: false,
  loading: true,
  saving: false,
  submitting: false
};

export const isFormPageProfileItemsComplete = (profileItems: IProfileItem[], pageId: string): boolean => {
  return !!(profileItems && profileItems.length && profileItems.length > 0);
}

export const createProfileItem = createAsyncThunk('profileItem/Create', async (payload: IProfileItem, { dispatch, rejectWithValue }) => {
  try {
    const response = await createProfileItemDb_service(payload);
    return response.data;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

export const uploadRoomBlob = createAsyncThunk('blob/Upload', async (payload: FormData, { dispatch, rejectWithValue }) => {
  //uploadRoomBlob: If you open the Sleeping Arrangements overlay and upload an image a blob is created but the MediaItem is only created when they click Done, because the parentId is the ProfileItem. See comment in API BlobService.UploadAsync
  try {
    const response = await uploadBlob(payload);
    var multipleImages = payload.getAll('files');
    dispatch(showGlobalToast({title: "Success", message: multipleImages && multipleImages.length > 1 ? "Images added" : "Image added"}));
    return response.data;
  }
  catch(err) {
    logError(err);
    dispatch(showGlobalToast({title: "Failure", message: "Image upload failed"}));
    return rejectWithValue(false);
  }
})

export const uploadRoomMedia = createAsyncThunk('blob/UploadRoomMedia', async (payload: FormData, { dispatch, rejectWithValue }) => {
  try {
    const response = await uploadBlob(payload);
    var multipleImages = payload.getAll('files');
    dispatch(showGlobalToast({title: "Success", message: multipleImages && multipleImages.length > 1 ? "Images added" : "Image added"}));
    return response.data;
  }
  catch(err) {
    logError(err);
    dispatch(showGlobalToast({title: "Failure", message: "Image upload failed"}));
    return rejectWithValue(false);
  }
})

export const getProfileItemsById = createAsyncThunk('profileitem/GetProfileItems', async (id: string, { rejectWithValue }) => {
  try {
    const response = await getProfileItems(id);
    return response && response.data ? response.data : null;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

export const getProfileItemCount = createAsyncThunk('blob/getProfileItemCount', async (payload: string | void, { dispatch, rejectWithValue }) => {
   return store.getState().profileItemSlice.profileItems?.length;
})

export const deleteRoomBlob = createAsyncThunk('blob/Delete', async (payload: DeleteBlob, { dispatch, rejectWithValue }) => {
  try {
    const response = await deleteBlob(payload);
    dispatch(showGlobalToast({title: "Success", message: payload.blobNames.length > 1 ? "Images deleted" : "Image deleted"}));
    return response.data;
  }
  catch(err) {
    logError(err);
    dispatch(showGlobalToast({title: "Failure", message: "Image delete failed"}));
    return rejectWithValue(false);
  }
})

export const updateProfileItemsDb = createAsyncThunk('profileitem/UpdateProfileItemsDb', async (payload: IProfileItem[], {dispatch, rejectWithValue }) => {
  try {
    const response = await updateProfileItemsDb_service(payload.filter(x => x.isChanged));
    if (response && response.status && response.data && response.status === 200) {
      dispatch(showGlobalToast({title: "Success", message: "Sleeping arrangements saved"}));
    }

    return payload;
  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})

export const deleteProfileItem = createAsyncThunk('profileitem/DeleteProfileItem', async (payload: IProfileItemDeletePayload, { dispatch, rejectWithValue }) => {
  try {
    const response = await deleteProfileItemDb_service(payload);  
    
    if (response.status === 200 && response.data.id !== undefined)
    {
      dispatch(showGlobalToast({title: "Success", message: `${payload.type} deleted`}));
    }
    
    return response.data;    

  }
  catch(err) {
    logError(err);
    return rejectWithValue(false);
  }
})


const profileItemSlice = createSlice({
    name: 'profileItemSlice',
    initialState,
    reducers: {
      ResetProfileItem(state) {
        state.profileItems = initialState.profileItems;
        state.mediaItems = initialState.mediaItems;
      },
      UpdateProfileItemMediaItems(state, action: PayloadAction<IMediaItem[]>) {
        state.mediaItems = action.payload;
      },
      UpdateExistingProfileItemMedia(state, action: PayloadAction<IProfileItemMediaUpdate>) {
        state.mediaItems = [];
        let profileItemsClone = _.cloneDeep(state.profileItems);

        const index = profileItemsClone.findIndex(x => x.id === action.payload.id);
        if(index > -1) {
          profileItemsClone[index].media = action.payload.media;
        }
        state.profileItems = profileItemsClone;
      },
      UpdateProfileItems(state, action: PayloadAction<IProfileItem[]>) {
        let profileItemsClone = _.cloneDeep(state.profileItems);
        const changedItems = action.payload;
        for (let i = 0; i < changedItems.length; i++) {
          let index = profileItemsClone.findIndex(x => x.id === changedItems[i].id);
          profileItemsClone[index] = changedItems[i];
        }
        state.profileItems = profileItemsClone;
      }
    },
    extraReducers: builder => {
      builder.addCase(createProfileItem.pending, (state) => {
        state.mediaItems = [];
      })
      builder.addCase(createProfileItem.fulfilled, (state, action: PayloadAction<IProfileItem>) => {   
        state.saving = false;
        let index =  state.profileItems.findIndex(i => i.id === action.payload.id);
        if (index > -1) {           
          state.profileItems[index] = action.payload;
        } else {        
          let profilesItems = _.cloneDeep(state.profileItems)
          profilesItems.push(action.payload)         
          state.profileItems = profilesItems;
        }     
      })
      builder.addCase(deleteProfileItem.fulfilled, (state, action) => { 
        let index =  state.profileItems.findIndex(i => i.id === action.payload.id);
        if (index > -1) { 
          state.profileItems.splice(index, 1)          
        }         
      })
      builder.addCase(uploadRoomBlob.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(uploadRoomBlob.fulfilled, (state, action: PayloadAction<IMediaItem[]>) => {
        state.mediaItems = [...state.mediaItems, ...action.payload];
        state.loading = false;
      })
      builder.addCase(deleteRoomBlob.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(deleteRoomBlob.fulfilled, (state) => {
        state.loading = false;
      })
      builder.addCase(uploadRoomMedia.fulfilled, (state, action: PayloadAction<IMediaItem[]>) => {
        let clonedProfileItems =_.cloneDeep(state.profileItems);
        
        const index =  clonedProfileItems.findIndex(i => i.id === action.payload[0].parentId);
       
        if (index > -1) { 
          clonedProfileItems[index].media = [...clonedProfileItems[index].media, ...action.payload];         
          state.profileItems = clonedProfileItems;
          state.mediaItems = [...state.mediaItems, ...action.payload];         
        }
      })
      builder.addCase(getProfileItemsById.pending, (state) => {   
        state.loading = true;
      })
      builder.addCase(getProfileItemsById.fulfilled, (state, action) => {   
        state.loading = false;
        if (action.payload) {
          state.profileItems = action.payload;
        }
      })
      builder.addCase(updateProfileItemsDb.fulfilled, (state, action) => {
        if (action.payload) {
          let profileItemsClone = _.cloneDeep(state.profileItems);
          const changedItems = action.payload;
          for (let i = 0; i < changedItems.length; i++) {
            let index = profileItemsClone.findIndex(x => x.id === changedItems[i].id);
            profileItemsClone[index] = changedItems[i];
          }
          state.profileItems = profileItemsClone;
        }
      })
    },
});

export const { 
  ResetProfileItem,
  UpdateProfileItemMediaItems,
  UpdateProfileItems,
  UpdateExistingProfileItemMedia
} = profileItemSlice.actions;

export default profileItemSlice.reducer;