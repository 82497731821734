import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = { 
  showUpdateProfileMenu: true,   
};

const applicationSlice = createSlice({
    name: 'headerSlice',
    initialState,
    reducers: {
      ToggleUpdateProfileMenu(state, action: PayloadAction<boolean>) {
        state.showUpdateProfileMenu = action.payload;
      }  
    },
    extraReducers: builder => {      
    },
});

export const {
  ToggleUpdateProfileMenu
} = applicationSlice.actions;

export default applicationSlice.reducer;