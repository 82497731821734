import React, { useEffect, useState } from 'react';
import { getAddress, loadMapApi } from 'utils/GoogleMapsUtils';
import { Form } from 'react-bootstrap';
import './google.places.search.scss';

interface IProps {
    type?: string;
    callBack: (placeResult: google.maps.places.PlaceResult, hasError: boolean) => void;
    value?: string;
    label?: string;
    placeHolder?: string;
}

export const GooglePlacesSearch = ({callBack, type = '', value = '', label = '', placeHolder = ''}: IProps) => {
  const [place, setPlace] = useState<string | undefined>(value);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let googleMapScript = null;
    if (typeof google === 'undefined') {
      googleMapScript = loadMapApi();
    }

    const initAutocomplete = () => {
      const profileNameBox: HTMLInputElement = document.getElementById("address-input") as HTMLInputElement;
      if (!profileNameBox.getAttribute("isSearchBoxLoaded")) {
        const searchBox = new google.maps.places.SearchBox(profileNameBox);
        profileNameBox.setAttribute('isSearchBoxLoaded', 'loaded');          
        searchBox.addListener("places_changed", (e: any) => {
          const places = searchBox.getPlaces();
          if (places && places.length > 0) {
            const place: google.maps.places.PlaceResult = places[0];
            if(type === 'name') {
              setPlace(place.name);
            }
            else {
              setPlace(getAddress(place));
              setHasError(false);
            }
            callBack(place, hasError);
          }
        });
      }
    }
    if (typeof google === 'undefined') {
      googleMapScript?.addEventListener('load', function () {
        initAutocomplete();
      });
    }
    else
    {
      initAutocomplete();
    }
  }, [callBack, type, hasError]);

  const handlePlaceChange = (event: any) => {
    setPlace(event.target.value);
    const freeTextPlace: google.maps.places.PlaceResult = {
      name: event.target.value
    }
    callBack(freeTextPlace, false);
  }

  return (
      <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control value={place} onChange={event => handlePlaceChange(event)} id="address-input" name="address-input-name" type="search" placeholder={ placeHolder } autoComplete="off" />
      </Form.Group>
  )
}