import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import _ from 'lodash';
import { MultiSelectToggle } from './features/multiselect.toggle/multiselect.toggle';
import './multiselect.scss';
import { BsCaretDownFill } from 'react-icons/bs';
import { IndeterminateCheckboxEnum } from 'components/indeterminate.checkbox/types';
import { IndeterminateCheckbox } from 'components/indeterminate.checkbox/indeterminate.checkbox';
import { IMultiSelectData } from 'components/profile/types';
import { AcceptDeclineModal } from 'components/accept.decline.modal/accept.decline.modal';
import { pluralise, replaceAll } from 'utils/General';

interface MultiSelectProps {
    data: IMultiSelectData[];
    multiSelectCallback: (data: IMultiSelectData[]) => void;
    title: string;
    hasSelectAll?: boolean;

    //Parameters to handle confirmation dialog to confirm deletion if items are de-selected.
    confirmDeselectCallback?: (data: IMultiSelectData[]) => void;
    getNumDeselectMediaItemsCallback?: (data: IMultiSelectData[]) => number;
    confirmDeselectOkText?: string;
    confirmDeselectCancelText?: string;
    confirmDeselectTitleText?: string;
    confirmDeselectBodyText?: string;       //"Delete {n} {document}?"  converted to eg. "Delete 3 documents"
}

export const MultiSelect = ({data, multiSelectCallback, title, hasSelectAll = false, confirmDeselectCallback, getNumDeselectMediaItemsCallback,
    confirmDeselectOkText, confirmDeselectCancelText, confirmDeselectTitleText, confirmDeselectBodyText }: MultiSelectProps) => {

    const [ menuItems, setMenuItems ] = useState<IMultiSelectData[]>(data);
    const [ show, setShow ] = useState(false);
    const [ selectAll, setSelectAll ] = useState<IndeterminateCheckboxEnum>(IndeterminateCheckboxEnum.unchecked);
    const [ initialMenuItems, setInitialMenuItems ] = useState<IMultiSelectData[]>([]);
    const [ newlyAddedSections, setNewlyAddedSections ] = useState<IMultiSelectData[]>([]);
    const [ showConfirmDeleteModal, setShowConfirmDeleteModal ] = useState<boolean>(false);
    const [ confirmDeleteBodyTextLocal, setConfirmDeleteBodyTextLocal ] = useState<string>('');
    const [ confirmDeleteTitleTextLocal, setConfirmDeleteTitleTextLocal ] = useState<string>('');
    const [ deSelectedItems, setDeSelectedItems ] = useState<IMultiSelectData[]>([]);

    useEffect(() => {
        setMenuItems(data);
        setInitialMenuItems( _.cloneDeep(data));
    }, [data]);

    useEffect(() => {
        // Select all
        if (hasSelectAll) {
            const determineSelectAll = () => {
                if (menuItems.filter(x => x.isChecked).length === menuItems.length) {
                    setSelectAll(IndeterminateCheckboxEnum.checked);
                } else if (menuItems.filter(x => x.isChecked).length === 0) {
                    setSelectAll(IndeterminateCheckboxEnum.unchecked);
                } else {
                    setSelectAll(IndeterminateCheckboxEnum.indeterminate);
                }
            }
            determineSelectAll();
        }
    }, [menuItems, hasSelectAll]);

    const onItemClick = (index: number) => {
        let items = _.cloneDeep(menuItems);
        items[index].isChecked = !items[index].isChecked;
        setMenuItems(items);
    }

    const checkDeSelectedItems = () => {
        if (confirmDeselectCallback) {
            const existingItems = initialMenuItems?.filter(x => x.isChecked);
            const deSelectedItemsTemp = existingItems.filter(x => x.isChecked && menuItems.find(y => y.key === x.key)?.isChecked === false);
            const newlyAddedItems = menuItems.filter(x => x.isChecked && initialMenuItems.find(y => y.key === x.key && !y.isChecked));
            setNewlyAddedSections(newlyAddedItems);  //They may have added new sections as well as de-selecting existing sections.
            const numDeSelectedSections = deSelectedItemsTemp.length;

            let numDeselectedMediaItems = 0;
            //We know how many checkboxes (sections) have been de-selected, next we need the total number of objects (eg. documents) associated with the de-selected sections.
            if (getNumDeselectMediaItemsCallback) {
                numDeselectedMediaItems = getNumDeselectMediaItemsCallback(deSelectedItemsTemp);
            }
            if (numDeselectedMediaItems > 0 && getNumDeselectMediaItemsCallback) {
                //Interpolate the message strings, replacing placeholders with literals, then open the confirmation modal.
                setConfirmDeleteBodyTextLocal(formatMessage(confirmDeselectBodyText, numDeselectedMediaItems, numDeSelectedSections));
                setConfirmDeleteTitleTextLocal(formatMessage(confirmDeselectTitleText, numDeselectedMediaItems, numDeSelectedSections));
                setDeSelectedItems(deSelectedItemsTemp);
                setShowConfirmDeleteModal(true);
                return false;
            }
        }
        return true;
    }

    const onDoneClick = () => {
        if (checkDeSelectedItems()) {
            setShow(false);
            multiSelectCallback(menuItems);
        }
    }

    const formatMessage = (messageText: string | undefined, numDeSelectedObjects: number, numDeSelectedSections: number): string => {
        //Replace placeholders with string literals.
        if (messageText) {
            messageText = replaceAll(messageText, '{NUMSECTIONS}', numDeSelectedSections.toString());   //Number of de-selected checkboxes.
            messageText = replaceAll(messageText, '{NUMOBJECTS}', numDeSelectedObjects.toString());                 //Number of objects (mediaItems) that would be deleted as a result.
            
            let openPos = messageText.indexOf('{');
            while (openPos >= 0) {
                let closePos = messageText.indexOf('}', openPos + 1);
                if (closePos > openPos) {
                    const wordWithBrackets = messageText.substring(openPos, closePos + 1);
                    const wordWithParams = messageText.substring(openPos + 1, closePos);        //Format: word|plural suffix|num sections
                    const tokens = wordWithParams.split('|');
                    if (tokens.length === 3) {
                        const word: string = tokens[0];             //eg. category
                        const pluralSuffix: string = tokens[1];     //'s' or 'ies'
                        const countType: string = tokens[2];        //Either NUMSECTIONS or NUMOBJECTS
                        const numItems = countType === 'NUMSECTIONS' ? numDeSelectedSections : numDeSelectedObjects;
                        if (word === 'its') {
                            const newString = numItems !== 1 ? 'their' : word;
                            messageText = replaceAll(messageText, wordWithBrackets, newString);
                        }
                        else {
                            messageText = replaceAll(messageText, wordWithBrackets, pluralise(word, numItems, pluralSuffix));
                        }
                        openPos = messageText.indexOf('{');
                    }
                }
                else {
                    openPos = messageText.indexOf('{', openPos + 1);
                }
            }
        }
       return messageText ? messageText : '';
    }

    const onSelectAll = () => {
        let items = _.cloneDeep(menuItems);
        items.forEach(item => item.isChecked = !selectAll);
        setMenuItems(items);
    }

    const selectFromNewItems = (data: IMultiSelectData[], newItems: IMultiSelectData[]): IMultiSelectData[] => {
        //Update data by checking items that are found in newItems.
        let dataCopy = _.cloneDeep(data);
        for (let i = 0; i < dataCopy.length; i++) {
            if (newItems.find(x => x.key === dataCopy[i].key)) {
                dataCopy[i].isChecked = true;
            }
        }
        return dataCopy;
    }

    const deleteCallbackLocal = (doDelete: boolean) => {
        setShowConfirmDeleteModal(false);
        if (!doDelete) {
            setMenuItems(selectFromNewItems(initialMenuItems, newlyAddedSections));
        }
        else {
            setShowConfirmDeleteModal(false);
            setShow(false);
            if (confirmDeselectCallback) {
                confirmDeselectCallback(deSelectedItems);
            }
            if (newlyAddedSections.length > 0) {
                //If they confirm that they want to de-select and delete associated mediaItems, we need to update with any newly added sections they may have selected.
                multiSelectCallback(menuItems);
            }
        }
    }

    return (
        <>
            <Dropdown className="multiselect" show={show}>
                <Dropdown.Toggle onClick={() => setShow(!show)} className="multiselect-toggle" as={MultiSelectToggle}>
                    {title} <BsCaretDownFill />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        hasSelectAll && 
                        <div onClick={() => onSelectAll()} className="select-all">
                            <IndeterminateCheckbox 
                            value={selectAll} 
                            callBack={onSelectAll} />
                            Select all
                        </div>
                    }
                    {
                        menuItems.map((item, index) => {
                            return <div className="ms-dropdown-item" key={index} onClick={() => onItemClick(index)}>
                                <div className="ms-dropdown-title"><Form.Check readOnly={true} checked={item.isChecked} />{item.title}</div>
                                {
                                    item.subtitle && <div className="ms-dropdown-subtitle">{item.subtitle}</div>
                                }
                            </div>
                        })
                    }
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => onDoneClick()}>Done</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            { confirmDeselectCallback && confirmDeselectTitleText && confirmDeleteBodyTextLocal && showConfirmDeleteModal &&
                <AcceptDeclineModal showModal={showConfirmDeleteModal} callBack={deleteCallbackLocal} 
                    title={confirmDeleteTitleTextLocal} body={confirmDeleteBodyTextLocal} 
                    declineButtonText={confirmDeselectCancelText} acceptButtonText={confirmDeselectOkText}
                />
            }
        </>
    )
}