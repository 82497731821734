import { IProfileSummary } from 'components/profile/types';
import React from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import landingImage from './images/landingImage.png'; 
import './grid.row.scss';
import { LabelPill } from 'components/label.pill/label.pill';
import { GetDate } from 'utils/DateUtils';
import { ContentRatingBar } from 'components/content.rating.bar/content.rating.bar';
import { MenuButton } from 'components/menu.button/menu.button';
import { ToDoButton } from 'components/to.do.button/to.do.button';
import { useHistory } from 'react-router-dom';
import { BsEyeFill } from "react-icons/bs";

interface GridRowProps {
    profileSummary: IProfileSummary;
    defaultPageUrl: string;
} 

export const GridRow = ({profileSummary, defaultPageUrl}: GridRowProps) => {
    const history = useHistory();
    const preview = () => {
        window.open('https://andrew.dev.wetu.com/'+profileSummary.id, '_blank');
    }

    return (
        <tr className="profile-row" onDoubleClick={() => history.push(defaultPageUrl)}>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    { (profileSummary.imageURL) &&
                        <div><Image src={profileSummary.imageURL} className="room-image"></Image></div>
                    }
                    { (!profileSummary.imageURL) &&
                        <div><Image src={landingImage} className="room-image"></Image></div>
                    }
                    <div className="name-text">
                        <div className="category-name">{profileSummary.category}</div>
                        <div className="cursor-pointer" onClick={() => history.push(defaultPageUrl)}>{profileSummary.name}
                            <OverlayTrigger overlay={(props) => (
                                <Tooltip id="tooltip" {...props}>
                                Preview profile
                                </Tooltip>
                                )}
                                placement="bottom">
                                <BsEyeFill className="eye-icon" onClick={() => preview()}/>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    <LabelPill label={profileSummary.status}/>
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    <ToDoButton status={profileSummary.status}></ToDoButton>
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    {profileSummary.isPublished ? "Public" : "Private"}
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    <ContentRatingBar rating={profileSummary.contentRating} />
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    {profileSummary.isEnhanced ? "Enhanced" : "Basic"}
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    {profileSummary.type === "Accommodation" ? "Stays" : profileSummary.type}
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    {GetDate(profileSummary.lastModified)}
                </div>
            </td>
            <td className="profile-cell">
                <div className="cell-wrapper">
                    <MenuButton url={defaultPageUrl} ></MenuButton>
                </div>
            </td>
        </tr>     
    )
}