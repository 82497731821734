import React, { useState } from 'react';
import { Container, Row, Form } from 'react-bootstrap';
import './pill.text.input.scss';
import { BsFillPlusCircleFill, BsX } from "react-icons/bs";

interface IProps {
    title?: string;
    addCallBack: Function;
    removeCallBack: Function;
    changeCallBack: Function;
    items: string[];
    unique: boolean;
    message: string;
}

export const PillTextInput = ({title, items, addCallBack, removeCallBack, changeCallBack, unique, message}:IProps) => {

    const [ newName, setNewName ] = useState("");
    const onRemove = (name: string) => {
        removeCallBack(name);
    }

    const onChange = (value: string) => {
        if(!unique && value.endsWith(',')){
          return;
        }
        changeCallBack(value);
        setNewName(value);
    }

    const handleKeyPress = (event:KeyboardEvent) => {
        if((event.key === 'Enter' || event.key === ',') && unique){
            setNewName("");
            addCallBack(newName);
        }
    }

    const addButtonClick = () => {
        setNewName("");
        addCallBack(newName);
    }

    const cancelOnClick= () => {
        setNewName("");
    }
    
    return (
        <Container onKeyPress={(event:any) => handleKeyPress(event)} onClick={() => cancelOnClick()}>
            <Row className="additional-row">
                <Form.Group className="text-input-group" >
                 {title &&
                  <Form.Label>{title}</Form.Label>
                 }
                  <Form.Control value={newName} onChange={event => onChange(event.target.value)}/>
                    { (newName !== "") &&
                      <div className={unique ? "add-text" : "disabled add-text"} > 
                        <div className="add-button" onClick={() => addButtonClick()}>
                          <BsFillPlusCircleFill className="add-cross"/>
                        </div>
                        <div className="add-text-div" onClick={() => addButtonClick()}>
                        { (unique) &&
                          <span className="bold">{"Add: "}</span> 
                        }
                        {message}
                        </div>
                      </div>
                    }
                  </Form.Group>
              </Row>
              <Row className="additional-row">
                <div className="card-container">
                  { items &&
                      items.map((item,index) => <div className="label-pill" key={index} >{item}<BsX className="cross" onClick={() => onRemove(item)}/></div>)
                  }
                </div> 
              </Row>
        </Container>
    )
}