export const logError = (err: any) => {
  console.error(err);                   //eg. "Error: Request failed with status code 500."
  if (err?.response?.data) {
    console.error(err.response.data);   //ex.Message and StackTrace from API.
  }
}

//TypeScript overload based on https://stackoverflow.com/questions/61364475/how-to-define-overloaded-signatures-for-a-const-in-typescript
export type Pluralise = {
  (word: string, count: number, suffix?: string): string;
  (word: string, isPlural: boolean, suffix?: string): string;
}

export const pluralise: Pluralise = (word: string, checker: number | boolean, pluralSuffix?: string): string => {
  pluralSuffix = pluralSuffix ? pluralSuffix : 's'; //If plural suffix not supplied assume 's'.
  if (typeof checker === 'number') {
    return checker !== 1 ? addPlural(word, pluralSuffix) : word;
  }
  else if (typeof checker === 'boolean') {
    return checker ? addPlural(word, pluralSuffix) : word;
  }
  return 'unknown';
}

const addPlural = (word: string, pluralSuffix: string) => {
  if (pluralSuffix === 'ies' || pluralSuffix === 'ves') {   //https://www.teachstarter.com/au/teaching-resource/rules-for-plurals-s-es-ies-ves/
    word = word.slice(0, -1);   //category => categor. But most words are just 's', eg. document => documents
  }
  return word + pluralSuffix;
}

export const replaceAll = (text: string, findString: string, replaceString: string): string => {
  const array = text.split(findString);
  return array.join(replaceString);
}